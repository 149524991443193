import { Form, useSearchParams, useSubmit } from 'react-router-dom';
import React from 'react';
import { HiOutlineStar, HiStar } from 'react-icons/hi';
import { Button } from '@mantine/core';
import { mergeParams } from './utils';

export const MyBatchesButton = () => {
  const [searchParams] = useSearchParams();
  const submit = useSubmit();

  const onlyMyBatches = searchParams.get('myBatches') === 'true';

  const Star = onlyMyBatches ? (
    <HiStar color={'#FEBC2E'} size={20} />
  ) : (
    <HiOutlineStar color={'#FEBC2E'} size={18} />
  );
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        submit(mergeParams(e.currentTarget, searchParams));
      }}
    >
      <Button
        variant="transparent"
        leftSection={Star}
        fw={onlyMyBatches ? 700 : 400}
        type="submit"
        c="font1.0"
        px={0}
      >
        My Batches
      </Button>
      <input
        hidden
        readOnly
        value={onlyMyBatches ? '' : 'true'}
        name={'myBatches'}
      />
    </Form>
  );
};
