import React, { useState, useContext } from 'react';
import {
  Box,
  Flex,
  Table,
  TextInput,
  Text,
  MantineStyleProp,
} from '@mantine/core';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';
import classes from './GlossaryTable.module.css';

import {
  NeverTranslateIcon,
  TranslateAsIcon,
  NeverTranslateAsIcon,
} from '../../icons/GlossaryIcons';
import { X, CheckMark } from '../../icons/MiscIcons';

const icons = {
  never_translate: <NeverTranslateIcon />,
  never_translate_as: <NeverTranslateAsIcon />,
  translate_as: <TranslateAsIcon />,
};
const glossaryRules = {
  never_translate: 'Never Translate',
  translate_as: 'Translate',
  never_translate_as: "Don't Translate",
};
const columns: { heading: string; sx?: MantineStyleProp }[] = [
  { heading: 'Rule' },
  { heading: 'Case Sensitive', sx: { textAlign: 'center', width: 80 } },
  { heading: 'MT Override', sx: { textAlign: 'center', width: 80 } },
  { heading: 'Comment' },
];

export const GlossaryTable = () => {
  const [filter, setFilter] = useState('');

  const allGlossaryTerms = useContext(SegmentEditorContext).glossary;
  console.log('GlossaryTable::allGlossaryTerms', allGlossaryTerms);
  const filteredTerms = allGlossaryTerms.filter(
    (g) =>
      g.source_text.toLowerCase().includes(filter.toLowerCase()) ||
      g.target_text.some((t) => t.toLowerCase().includes(filter.toLowerCase()))
  );

  const Rule = ({ term }: { term: GlossaryItem }) => {
    const n = term.target_text.length;
    return (
      <Flex align="center" gap={5}>
        {icons[term.rule || 'translate_as']}
        <Flex>
          <Text>{glossaryRules[term.rule]}&nbsp;</Text>
          <Text className={classes.term}>{term.source_text}</Text>
          {term.target_text.length ? (
            <>
              <span>&nbsp;as&nbsp;</span>
              {term.target_text.map((t, i) => (
                <React.Fragment key={t}>
                  <Text className={classes.term}>{t}</Text>
                  {i < n - 1 ? <span>,&nbsp;</span> : null}
                </React.Fragment>
              ))}
            </>
          ) : null}
        </Flex>
      </Flex>
    );
  };

  const TH = ({ heading, sx }: { heading: string; sx?: MantineStyleProp }) => (
    <th>
      <Flex style={sx}>{heading}</Flex>
    </th>
  );

  return (
    <Flex direction={'column'} py={10}>
      <Flex pb="0.5rem">
        <TextInput
          className={classes.search}
          placeholder="Search glossary term..."
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          style={{ borderRadius: 32 }}
        />
      </Flex>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <thead style={{ position: 'sticky' }}>
            <tr>
              {columns.map((c) => (
                <TH key={c.heading} heading={c.heading} sx={c.sx} />
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredTerms.map((g) => (
              <tr key={g.source_text_hash}>
                <td>
                  <Rule term={g} />
                </td>
                <td>
                  <Flex justify={'center'}>
                    {g.is_case_sensitive ? <CheckMark /> : <X />}
                  </Flex>
                </td>
                <td>
                  <Flex justify={'center'}>
                    {g.is_mt ? <CheckMark /> : <X />}
                  </Flex>
                </td>
                <td>
                  <Box>{g.comment}</Box>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Flex>
  );
};
