const dashboardRC = {
  /* Page Routes */
  PAGE_NOT_FOUND: '*',
  PAGE_ROOT: '/',
  PAGE_LOGIN_REDIRECT: '/post_login',
  PAGE_LOGOUT_REDIRECT: '/post_logout',
  PAGE_SILENT_RENEW: '/silent_renew',
  PAGE_LOGIN: '/login',
  PAGE_LOGOUT: '/logout',
  PAGE_DEV_LOGIN: '/dev-login',
  PAGE_CREATE_ACCOUNT: 'create-account',
  PAGE_CREATE_PROJECT: 'create-project',
  PAGE_DASHBOARD: 'dashboard',
  PAGE_TRANSLATIONS: 'translations',
  PAGE_LANGUAGES: 'languages',
  PAGE_GLOSSARY: 'glossary',
  PAGE_NO_TRANSLATE_RULES: 'no-translate-rules',
  PAGE_METRICS: 'metrics',
  PAGE_TRANSLATED_TRAFFIC: 'translated-traffic',
  PAGE_WORLD_TRAFFIC: 'world-traffic',
  PAGE_SETTINGS: 'settings',
  PAGE_LANGUAGE_SETTINGS: 'language-settings',
  PAGE_CUSTOM_CSS_EDITOR: 'custom-css-editor',
  PAGE_LANGUAGE_SELECTOR: 'language-selector',
  PAGE_DEPLOYMENT: 'deployment',
  PAGE_INJECTION: 'injection',
  PAGE_PROJECT_SCOPE: 'project-scope',
  PAGE_ACCOUNT: 'account',
  PAGE_MY_PROFILE: 'my-profile',
  PAGE_WORDS_SERVED_USAGE_DETAILS: 'words-served-usage-details',
  PAGE_MT_USAGE_DETAILS: 'mt-usage-details',
  PAGE_BILLING_INFORMATION: 'billing-information',
  PAGE_MANAGE_SUBSCRIPTION: 'manage-subscription',
  PAGE_ORDER_CONFIRMATION: 'order-confirmation',
  PAGE_DOWNGRADE_CONFIRMATION: 'downgrade-confirmation',
  PAGE_CANCELLATION_CONFIRMATION: 'cancellation-confirmation',
  PAGE_USER_MANAGEMENT: 'user-management',
  PAGE_QUOTES: 'quotes',
  PAGE_DESKTOP_APP: 'desktop-app',
  PAGE_STORE_DEBUG: 'groovy',

  /* Page Titles */
  PAGE_TITLE_NOT_FOUND: 'Page Not Found',
  PAGE_TITLE_ROOT: 'Dashboard',
  PAGE_TITLE_LOGIN_REDIRECT: 'Login Redirect',
  PAGE_TILE_LOGOUT_REDIRECT: 'Logout Redirect',
  PAGE_TITLE_SILENT_RENEW: '',
  PAGE_TITLE_LOGIN: 'Login',
  PAGE_TITLE_LOGOUT: 'Logout',
  PAGE_TITLE_CREATE_ACCOUNT: 'Create Account',
  PAGE_TITLE_CREATE_PROJECT: 'Create Project',
  PAGE_TITLE_DASHBOARD: 'Dashboard',
  PAGE_TITLE_TRANSLATIONS: 'Translations',
  PAGE_TITLE_LANGUAGES: 'Languages',
  PAGE_TITLE_GLOSSARY: 'Glossary',
  PAGE_TITLE_NO_TRANSLATE_RULES: 'No Translate Rules',
  PAGE_TITLE_METRICS: 'Metrics',
  PAGE_TITLE_TRANSLATED_TRAFFIC: 'Translated Traffic',
  PAGE_TITLE_WORLD_TRAFFIC: 'World Traffic',
  PAGE_TITLE_SETTINGS: 'Project Settings',
  PAGE_TITLE_LANGUAGE_SETTINGS: 'Language Settings',
  PAGE_TITLE_LANGUAGE_SELECTOR: 'Language Selector',
  PAGE_TITLE_DEPLOYMENT: 'Deployment',
  PAGE_TITLE_INJECTION: 'Injection',
  PAGE_TITLE_PROJECT_SCOPE: 'Project Scope',
  PAGE_TITLE_ACCOUNT: 'Account',
  PAGE_TITLE_MY_PROFILE: 'My Profile',
  PAGE_TITLE_WORDS_SERVED_USAGE_DETAILS: 'Words Served',
  PAGE_TITLE_MT_USAGE_DETAILS: 'MT Usage',
  PAGE_TITLE_BILLING_INFORMATION: 'Billing Information',
  PAGE_TITLE_ORDER_CONFIRMATION: 'Order Confirmation',
  PAGE_TITLE_CANCELLATION_CONFIRMATION: 'Cancellation Confirmation',
  PAGE_TITLE_USER_MANAGEMENT: 'User Management',
  PAGE_TITLE_QUOTES: 'Quotes',
  PAGE_TITLE_DEBUG: 'Debug',
  PAGE_TITLE_DESKTOP_APP: 'Get Desktop',

  /* External Links */
  DR_STORE: 'https://store.digitalriver.com/store/defaults/en_US',
} as const;

export type RoutesConstants = typeof dashboardRC;
export default dashboardRC;
