import { useLoaderData } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import MTUsageSummary from './MTUsageSummary';
import MTUsageChart from './MTUsageChart';
import moment from 'moment/moment';
import { DashboardLoaderResponse } from '../../../loaders/dashboardLoader';
import {
  MTUsageResponse,
  queryMTUsage,
} from '../../../queries/dashboardQueries';

export type MTUsageType = {
  timeslice: string;
  count: number;
};

export const MTUsage = ({ thresholdValue }: { thresholdValue: number }) => {
  const { concatenatedKey } = useLoaderData() as DashboardLoaderResponse;
  const { data }: { data: MTUsageResponse } = useSuspenseQuery(
    queryMTUsage(concatenatedKey)
  );

  const mTUsageData = data.map(
    ({ num_words_mt, timeslice }) =>
      ({ count: Number(num_words_mt), timeslice }) as MTUsageType
  );

  const date = new Date();
  const todaysDate = moment(date).format('YYYY-MM-DD') || '';

  const accumulatedMTUsageData = mTUsageData.reduce(
    (acc, { count, timeslice }, index) => {
      acc[index] = {
        timeslice,
        count: (acc[index - 1]?.count ?? 0) + count,
      } as MTUsageType;
      return acc;
    },
    [] as Array<MTUsageType>
  );

  return (
    <>
      <MTUsageSummary
        todaysDate={todaysDate}
        mTUsageData={mTUsageData}
        accumulatedMTUsageData={accumulatedMTUsageData}
      />
      <MTUsageChart
        todaysDate={todaysDate}
        accumulatedMTUsageData={accumulatedMTUsageData}
        thresholdValue={thresholdValue}
      />
    </>
  );
};
