import { QueryClient } from '@tanstack/react-query';
import { Params } from 'react-router-dom';
import { projectQuery, xapisEnv } from '@glweb/xapis-client';
import {
  queryRecommendedPlan,
  querySources,
} from '../../queries/selectPlanQueries';

export const selectPlanLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: { params: Params<string>; request: Request }) => {
    const { projectId } = params;
    if (!projectId) {
      throw new Response('Project key is not defined', {
        status: 404,
        statusText: 'Project key is not defined',
      });
    }

    const { payKey } = xapisEnv.getHost;
    const url = new URL(request.url);
    const threshold = url.searchParams.get('threshold');

    const data = await queryClient.ensureQueryData(projectQuery(projectId));
    const origin_name = data.project?.origin_name || '';

    await queryClient.ensureQueryData(
      queryRecommendedPlan(origin_name, payKey, threshold || '')
    );

    await queryClient.ensureQueryData(querySources(payKey));

    return { threshold, url: origin_name };
  };

export type SelectPlanLoaderResponse = Awaited<
  ReturnType<Awaited<ReturnType<typeof selectPlanLoader>>>
>;
