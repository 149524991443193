import { queryOptions, useSuspenseQueries } from '@tanstack/react-query';
import { isSuccessStatus } from 'helpers';
import { Xapis } from '@glweb/xapis-client';
import { queryQuickQuote } from './shared/queryQuickQuote';
import { querySkus } from './shared/querySkus';
import { querySubscriptions } from './shared/querySubscriptions';

export const querySources = (payKey: string) =>
  queryOptions({
    queryKey: ['sources', payKey],
    queryFn: async () => {
      try {
        const response = await Xapis.Source.get(payKey);
        if (!response || !isSuccessStatus(response.status) || !response.data) {
          throw new Error('Failed to fetch sources');
        }
        return response.data;
      } catch (error) {
        console.error(error);
        return {
          default_source_id: '',
          payment_sources: [],
        } as PaySourceResponse;
      }
    },
  });

export const queryBillingHistory = (payKey: string, projectKey: string) =>
  queryOptions({
    queryKey: ['billingHistory', payKey, projectKey],
    queryFn: async () => {
      const response = await Xapis.Invoice.get(payKey, projectKey);
      if (
        !response ||
        !isSuccessStatus(response.status) ||
        !response.data ||
        !response.data.orders
      ) {
        throw new Error('Failed to fetch orders');
      }
      return response.data.orders;
    },
  });

export const useBillingQueries = ({
  payKey,
  projectKey,
  isExpired,
  shouldQueryQuickQuote,
  origin_name,
}: {
  payKey: string;
  projectKey: string;
  isExpired: boolean;
  shouldQueryQuickQuote: boolean;
  origin_name: string;
}) => {
  const queries = [
    querySubscriptions(payKey, projectKey, isExpired),
    querySkus(payKey),
    querySources(payKey),
    queryBillingHistory(payKey, projectKey),
    queryQuickQuote(shouldQueryQuickQuote, origin_name),
  ] as const;
  const results = useSuspenseQueries({ queries });

  return {
    subscriptions: results[0].data as Subscription[],
    skus: results[1].data as SkuWCreatedAndLastMod[],
    sourceData: results[2].data as PaySourceResponse,
    billingHistory: results[3].data as BillingHistory[],
    quickQuote: results[4].data as QuickQuoteResponse,
  };
};
