import { CustomTable } from 'ui';
import { LoadingSkeleton } from 'ui';
import { MRT_ColumnDef } from 'mantine-react-table';
import { WordsServedUrl } from './WordsServedUrls';

const WordsServedTable = ({
  loading,
  data,
  columns,
}: {
  loading: boolean;
  data: WordsServedUrl[];
  columns: MRT_ColumnDef<WordsServedUrl>[];
}) => {
  if (loading) {
    return <LoadingSkeleton height={450} />;
  }
  return (
    <CustomTable
      columns={columns}
      data={data}
      enableBottomToolbar={false}
      paperProps={{ style: { boxShadow: 'none' } }}
      enableSorting={false}
      containerProps={{ mih: 450, h: 450 }}
    />
  );
};
export default WordsServedTable;
