import { Card, Text } from '@mantine/core';
import React from 'react';
import ConfirmDeleteMenu from './ConfirmDeleteMenu';

import { MRT_ColumnDef } from 'mantine-react-table';
import { CustomTable, NoDataFoundPlaceHolder } from 'ui';
import { getBasicColumn } from '../../../utils';

export type Rule = {
  pattern: string;
  desc: string;
  uri: string;
};

type Props = {
  detailedRules: Rule[];
  rulesList: string[];
  yyTarget: TranslationKey;
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>;
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};

const RulesCard = ({
  detailedRules,
  rulesList,
  yyTarget,
  setYYTarget,
  setSpiderTree,
}: Props) => {
  const columns: MRT_ColumnDef<Rule>[] = [
    {
      ...getBasicColumn('desc', 'rule', true),
      Cell: ({ cell }) => <Text>Url {cell.getValue() as string}</Text>,
      size: 120,
      grow: false,
    },
    {
      ...getBasicColumn('uri', 'value', true),
    },
    {
      ...getBasicColumn('pattern', 'pattern', true),
    },
    {
      ...getBasicColumn(''),
      id: 'delete',
      Cell: ({ row }) => {
        return (
          <ConfirmDeleteMenu
            ruleToRemove={row.original.pattern as string}
            rulesList={rulesList}
            yyTarget={yyTarget}
            setYYTarget={setYYTarget}
            setSpiderTree={setSpiderTree}
          />
        );
      },
      size: 60,
      grow: false,
    },
  ];

  return (
    <Card p={0}>
      <CustomTable
        columns={columns}
        data={detailedRules}
        enableSorting={false}
        enableBottomToolbar={false}
        containerProps={{ h: '30vh', mih: '30vh' }}
        enableRowVirtualization={false}
        renderEmptyRowsFallback={() => (
          <NoDataFoundPlaceHolder height={'20vh'} />
        )}
      />
    </Card>
  );
};

export default RulesCard;
