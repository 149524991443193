import React from 'react';
import { AnchorLink, Header } from 'ui';
import dashboardRC from '../../../routerConstants';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import { Flex } from '@mantine/core';

export default function MyBatchesHeader() {
  return (
    <Header
      minHeight={40}
      variant="h2"
      title="My Batches"
      flexItems={
        <AnchorLink
          to={`../${dashboardRC.PAGE_TRANSLATIONS}/${dashboardRC.PAGE_ACTIVE_BATCHES}?myBatches=true`}
          fw={700}
          td="none"
        >
          <Flex align="center" gap={5}>
            View All
            <MdOutlineArrowRightAlt size={24} />
          </Flex>
        </AnchorLink>
      }
    />
  );
}
