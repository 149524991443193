import {
  Anchor,
  Box,
  CloseButton,
  Flex,
  FlexProps,
  Input,
  Loader,
  MantineStyleProps,
  PolymorphicComponentProps,
  Text,
  TextInput,
  TextInputProps,
  Title,
  TitleOrder,
  TitleProps,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import * as React from 'react';
import { MdSearch } from 'react-icons/md';
import { Link, LinkProps, To } from 'react-router-dom';
import classes from './Generic.module.css';
import { InfoIcon } from './StaticIcons';

export const LinkWrapper = ({
  children,
  to,
  target = '_self',
  isDesktop,
  onClick,
}: {
  children: React.ReactNode;
  to?: To;
  target?: React.HTMLAttributeAnchorTarget;
  isDesktop?: boolean;
  onClick?: () => void;
}) => {
  return !to || isDesktop ? (
    children
  ) : (
    <Link
      onClick={onClick}
      to={to}
      target={target}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </Link>
  );
};

// Replaces Mantine's Card component to improve responsiveness
// Accepts any Flex component props
export const FlexCard = (
  props: PolymorphicComponentProps<'div', FlexProps>
) => {
  const { children, className, ...others } = props;
  const classNames = className
    ? `${classes.flexCard} ${className}`
    : classes.flexCard;
  return (
    <Flex className={classNames} {...others}>
      {children}
    </Flex>
  );
};

type HeaderProps = {
  title: React.ReactNode;
  tooltipLabel?: string;
  wrapContent?: boolean;
  flexContainerProps?: FlexProps;
  flexItems?: React.ReactNode;
  flexItemsProps?: FlexProps;
  titleProps?: TitleProps;
  blur?: boolean;
  variant?: 'h1' | 'h2' | 'h3';
  minHeight?: FlexProps['mih'];
};

export const Header = ({
  title,
  tooltipLabel,
  wrapContent = false,
  flexContainerProps,
  flexItems,
  flexItemsProps,
  titleProps,
  blur,
  variant = 'h1',
  minHeight,
}: HeaderProps) => {
  const { colors } = useMantineTheme();
  const order = (variant ? Number(variant.charAt(1)) : 2) as TitleOrder;

  const mainProps: FlexProps = {
    ...(!wrapContent && {
      wrap: 'nowrap',
    }),
    ...(wrapContent && {
      pt: { base: 15, md: 0 },
    }),
    ...flexContainerProps,
  };

  const itemsProps: FlexProps = {
    ...(wrapContent && {
      w: { base: '100%', sm: 'fit-content' },
      pb: { base: 10, md: 0 },
    }),
    ...flexItemsProps,
  };

  return (
    <Flex
      w="100%"
      mih={minHeight || 55}
      wrap={{ base: 'wrap', sm: 'nowrap' }}
      justify="space-between"
      align="center"
      gap={10}
      style={{ filter: blur ? 'blur(1.25px)' : 'none' }}
      {...mainProps}
    >
      <Title order={order} lineClamp={2} display="inline" {...titleProps}>
        {title}
        {tooltipLabel && (
          <Tooltip disabled={blur} label={tooltipLabel}>
            <Box display="inline-block" ml={5}>
              <InfoIcon fill={colors.secondary1[0]} size={15} />
            </Box>
          </Tooltip>
        )}
      </Title>
      {flexItems && (
        <Flex
          align="flex-end"
          justify={{ base: 'flex-start', sm: 'end' }}
          columnGap={10}
          {...itemsProps}
        >
          {flexItems}
        </Flex>
      )}
    </Flex>
  );
};

export const SearchBar = ({
  searchText,
  onSearch,
  ...props
}: {
  searchText: string;
  onSearch: (value: string) => void;
} & Partial<TextInputProps> &
  Partial<MantineStyleProps>) => (
  <TextInput
    maw="100%"
    value={searchText}
    classNames={{
      input: classes.searchBarInput,
      section: classes.searchBarRightSection,
    }}
    rightSection={<MdSearch size={20} color="var(--mantine-color-icons1-3)" />}
    placeholder="Search..."
    onChange={(event) => onSearch(event.currentTarget.value)}
    {...props}
  />
);

export const SearchInput = ({
  maxWidth,
  searchText,
  setSearchText,
  placeholder,
}: {
  maxWidth: string | number;
  searchText: string;
  setSearchText: (value: string) => void;
  placeholder?: string;
}) => (
  <Input
    w="100%"
    maw={maxWidth}
    value={searchText}
    classNames={{
      input: classes.searchBarInput,
      section: classes.searchBarRightSection,
    }}
    rightSection={
      searchText ? (
        <CloseButton onClick={() => setSearchText('')} />
      ) : (
        <MdSearch color="#00328D" size={20} />
      )
    }
    rightSectionPointerEvents="all"
    placeholder={placeholder || 'Search...'}
    onChange={(e) => {
      setSearchText(e?.target?.value || '');
    }}
  />
);

export const CloseIcon = <
  FnType extends React.MouseEventHandler<HTMLButtonElement>,
>({
  onClick,
  size,
  color,
}: {
  onClick: FnType;
  size?: string | number;
  color?: string;
}) => (
  <CloseButton
    onClick={onClick}
    c={color || 'text.0'}
    bg="transparent"
    size={size || 30}
  />
);

export const Wait = ({
  children,
  loader,
  text,
  direction,
}: {
  loader?: 'oval' | 'bars' | 'dots';
  text?: string;
  direction?: 'column' | 'row';
  children?: React.ReactNode;
}) => {
  return (
    <>
      <Box style={{ height: 40, WebkitAppRegion: 'drag' }} />
      <Flex
        direction={direction || 'column'}
        align="center"
        justify="center"
        gap={20}
        h="100vh"
      >
        <Loader variant={loader || 'oval'} color="#0071DB" size="lg" />
        {text && <Text fz="md">{text || ''}</Text>}
        {children}
      </Flex>
    </>
  );
};

export const AnchorLink = ({
  to,
  target,
  onClick,
  state,
  children,
  ...props
}: {
  to?: To;
  target?: React.HTMLAttributeAnchorTarget;
  state?: Record<string, unknown>;
  onClick?: () => void;
  children: React.ReactNode;
} & MantineStyleProps &
  Partial<LinkProps>) => (
  <Anchor
    to={to || ''}
    target={target}
    onClick={onClick}
    component={to ? Link : undefined}
    state={state}
    {...props}
  >
    {children}
  </Anchor>
);
