import { QueryClient } from '@tanstack/react-query';
import {
  GLGO_PROJECT_TYPE,
  GLWEB_PROJECT_TYPE,
  OLJS_PROJECT_TYPE,
} from 'helpers';
import RestrictedRoute from '../../RestrictedRoute';
import BillingInformation from '../../components/account/BillingInformation';
import CancelPlan from '../../components/account/cancel-plan/CancelPlan';
import AccountMTUsage from '../../components/account/mt-account-usage/AccountMTUsage';
import MyProfile from '../../components/account/my-profile/MyProfile';
import editProfileAction from '../../components/account/my-profile/editProfileAction';
import SelectPlan from '../../components/account/select-plans/SelectPlan';
import WordsServedUsageDetails from '../../components/account/words-served-usage-details/WordsServedUsageDetails';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import {
  USER_ROLES_BILLING_PAGE,
  USER_ROLES_MT_USAGE_PAGE,
  USER_ROLES_WORDS_SERVED_PAGE,
} from '../../constants';
import accountMTUsageLoader from '../../loaders/account/accountMTUsageLoader';
import billingLoader from '../../loaders/account/billingLoader';
import { selectPlanLoader } from '../../loaders/account/selectPlanLoader';
import usageLoader from '../../loaders/account/usageLoader';
import dashboardRC from '../../routerConstants';

// type RouteObjectWithRequiredPath = Omit<RouteObject, 'path'> & { path: string };

export const composeAccountRoutes = (queryClient: QueryClient) => [
  {
    path: dashboardRC.PAGE_MY_PROFILE,
    element: <MyProfile />,
    errorElement: <ErrorBoundary />,
    action: editProfileAction,
  },
  {
    path: dashboardRC.PAGE_WORDS_SERVED_USAGE_DETAILS,
    id: 'usage',
    element: (
      <RestrictedRoute
        allowedRoles={USER_ROLES_WORDS_SERVED_PAGE}
        allowedProjectTypes={new Set([GLGO_PROJECT_TYPE])}
      >
        <WordsServedUsageDetails />
      </RestrictedRoute>
    ),
    loader: usageLoader(queryClient),
    errorElement: <ErrorBoundary />,
  },
  {
    path: dashboardRC.PAGE_MT_USAGE_DETAILS,
    id: 'mt-usage',
    element: (
      <RestrictedRoute
        allowedRoles={USER_ROLES_MT_USAGE_PAGE}
        allowedProjectTypes={new Set([GLWEB_PROJECT_TYPE, OLJS_PROJECT_TYPE])}
      >
        <AccountMTUsage />
      </RestrictedRoute>
    ),
    loader: accountMTUsageLoader(queryClient),
    errorElement: <ErrorBoundary />,
  },
  {
    path: dashboardRC.PAGE_BILLING_INFORMATION,
    id: 'billing',
    errorElement: <ErrorBoundary />,
    loader: billingLoader(queryClient),
    children: [
      {
        index: true,
        element: (
          <RestrictedRoute allowedRoles={USER_ROLES_BILLING_PAGE}>
            <BillingInformation />
          </RestrictedRoute>
        ),
        errorElement: <ErrorBoundary />,
      },
      {
        path: dashboardRC.PAGE_MANAGE_SUBSCRIPTION,
        children: [
          {
            index: true,
            element: (
              <RestrictedRoute allowedRoles={USER_ROLES_BILLING_PAGE}>
                <SelectPlan />
              </RestrictedRoute>
            ),
            loader: selectPlanLoader(queryClient),
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'cancel',
            element: (
              <RestrictedRoute allowedRoles={USER_ROLES_BILLING_PAGE}>
                <CancelPlan />
              </RestrictedRoute>
            ),
            errorElement: <ErrorBoundary />,
          },
        ],
      },
    ],
  },
];
