import { SVGAttributes } from 'react';

type IconProps = {
  svg: { path: string; attr?: SVGAttributes<SVGElement> };
  size?: string | number;
  onClick?: () => void;
};

type ActionProps = {
  onClick?: () => void;
  size?: number | string;
  color?: string;
};

const Icon = ({ svg, size, onClick }: IconProps) => {
  const { path, attr } = svg;
  return (
    <svg
      onClick={() => onClick && onClick()}
      width={size || attr?.width || '20'}
      height={size || attr?.height || '20'}
      viewBox={attr?.viewBox || '0 0 20 20'}
      fill={attr?.fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d={path}
          stroke={attr?.stroke || 'currentColor'}
          strokeWidth={attr?.strokeWidth || 1}
          strokeLinecap={attr?.strokeLinecap || 'round'}
          strokeLinejoin={attr?.strokeLinejoin || 'round'}
        />
      </g>
    </svg>
  );
};

export const CommandIcon = ({ size, color, onClick }: ActionProps) => {
  return (
    <svg
      width={size || 8}
      height={size || 8}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8 2.4H5.6V1.2C5.6 0.9792 5.7792 0.8 6 0.8H6.8C7.0208 0.8 7.2 0.9792 7.2 1.2V2C7.2 2.2208 7.0208 2.4 6.8 2.4ZM7.2 5.6V6.8C7.2 7.0208 7.0208 7.2 6.8 7.2H6C5.7792 7.2 5.6 7.0208 5.6 6.8V5.6H7.2ZM3.2 4.8H4.8V3.2H3.2V4.8ZM2 7.2H1.2C0.9792 7.2 0.8 7.0208 0.8 6.8V6C0.8 5.7792 0.9792 5.6 1.2 5.6H2.4V6.8C2.4 7.0208 2.2208 7.2 2 7.2ZM0.8 2V1.2C0.8 0.9792 0.9792 0.8 1.2 0.8H2C2.2208 0.8 2.4 0.9792 2.4 1.2V2.4H1.2C0.9792 2.4 0.8 2.2208 0.8 2ZM7.2 0H5.6C5.158 0 4.8 0.358 4.8 0.8V2.4H3.2V0.8C3.2 0.358 2.842 0 2.4 0H0.8C0.358 0 0 0.358 0 0.8V2.4C0 2.842 0.358 3.2 0.8 3.2H2.4V4.8H0.8C0.358 4.8 0 5.158 0 5.6V7.2C0 7.642 0.358 8 0.8 8H2.4C2.842 8 3.2 7.642 3.2 7.2V5.6H4.8V7.2C4.8 7.642 5.158 8 5.6 8H7.2C7.642 8 8 7.642 8 7.2V5.6C8 5.158 7.642 4.8 7.2 4.8H5.6V3.2H7.2C7.642 3.2 8 2.842 8 2.4V0.8C8 0.358 7.642 0 7.2 0Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export const BoldIcon = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M5.5 4H11.3C12.2 4 12.8 4.5 13.3 5.2 13.6 5.8 13.6 5.8 13.7 6.5 13.7 7.6 13.6 7.6 13.4 8.2 13 8.8 12.8 9.2 12.1 9.4H5.5V4ZM5.5 9.4H12.1C13.3 9.9 13.8 10.2 14.4 11.2 14.5 11.7 14.6 12.1 14.6 12.6 14.6 13.4 14.5 14 14.4 14.3 13.7 15.5 13.3 15.6 12.2 16H5.5Z',
    attr: {
      strokeWidth: 2,
    },
  };

  return <Icon svg={svg} size={size} />;
};

export const ItalicIcon = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M16 4H8.8M11.2 16H4M12.4 4 7.6 16',
    attr: {
      strokeWidth: 1.4,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} size={size} />;
};

export const EmIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="green">
      <text fill="red" style={{ fill: 'blue' }}>
        Em
      </text>
    </svg>
  );
};

export const UnderlineIcon = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M6.325 4V10C6.325 11.2 6.85 12.16 7.06 12.4 7.9 13.6 9.58 13.6 10 13.6 10.42 13.6 12.1 13.6 12.94 12.4 13.15 12.16 13.675 11.2 13.675 10V4M4.75 16H15.25',
    attr: {
      strokeWidth: 1.4,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} size={size} />;
};

export const OpenLink = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M8.3 3H5.6C5 3 4 3.1 3.6 3.6 3 4.1 3 5 3 5.6V14.4C3 15.3 3.2 16.1 3.6 16.5 4 17 4.9 17 5.6 17H14.4C15.3 17 16.1 16.9 16.5 16.5 16.88 16.14 17 15.3 17 14.6V12M18 7.4V2M18 2H12.7m5.3 0L11 9',
    attr: {
      width: 20,
      height: 20,
      viewBox: '0 0 20 20',
      strokeWidth: 1.8,
      stroke: color || '#2C49AC',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} onClick={onClick} />;
};

export const CloseLink = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M9 3H5.6C5 3 4 3.1 3.6 3.6 3 4.1 3 5 3 5.6V14.4C3 15.3 3.2 16.1 3.6 16.5 4 17 4.9 17 5.6 17H14.4C15.3 17 16.1 16.9 16.5 16.5 16.88 16.14 17 15.3 17 14.6V11.01M15.99 4.02 8.04 11.9H14M8 11.92V6',
    attr: {
      width: 20,
      height: 20,
      viewBox: '0 0 20 20',
      strokeWidth: 1.8,
      stroke: color || '#2C49AC',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} onClick={onClick} />;
};
export const GoBack = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M 15.8 18 C 15.7 12.2 11.7 9.7 8 9.6 V 14.9 L 2.1 9.1 C 2 9 2 9 2.1 8.8 L 8 3.1 V 3.1 L 8.1 7.5 C 14.7 7.5 18 13.1 18 18 H 15.8 Z',
    attr: {
      stroke: 'none',
      fill: color || 'currentColor',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const GoForward = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M 4 18 C 4 13 7.1 10 12 10 V 15.4 L 18 9.1 L 12 3.1 V 3.1 L 12 8 C 6.4 8 2.1 11 2 18 H 2 Z',
    attr: {
      stroke: 'none',
      fill: color || 'currentColor',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const UndoIcon = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M 10 19 C 4.7 18.9 2.5 14.6 2.4 11.5 H 4.4 C 4.6 15 6.8 17.2 10 17.2 C 14.9 17.2 15.9 13.6 16 11 C 15.8 7.6 13 6.3 10.1 6.3 V 10.1 L 5.2 5.6 C 5.1 5.5 5.2 5.5 5.2 5.4 L 10 1 C 10 1 10 1 10 1 C 10 1.1 10 1.1 10 1.1 L 10 4.4 C 16.3 4.5 17.8 8.9 17.8 10.9 C 17.8 16.4 14.7 18.9 10 19 Z',
    attr: {
      fill: color || 'currentColor',
      width: size || 20,
      height: size || 20,
      stroke: 'none',
    } as SVGAttributes<SVGElement>,
  };
  return <Icon svg={svg} onClick={onClick} />;
};

export const Delete = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M11.5 8V13.2M8.5 8V13.2M5.5 5V13.8C5.5 14.6 5.5 15.1 5.7 15.4 5.8 15.7 6 16 7 16H13C14 16 14.2 15.7 14.3 15.4 14.5 15.1 14.5 14.6 14.5 13.8V5M5.5 5H7M5.5 5H4M14.5 5H13M14.5 5H16M7 5H13M7 5C7 4.3 7 3.9 7.1 3.6 7.3 3.3 7.6 3 7.9 2.8 8.2 2.7 8.6 2.7 9.3 2.7H10.8C11.4 2.7 11.8 2.7 12.1 2.8 12.4 3 12.7 3.3 12.9 3.6 13 3.9 13 4.3 13 5',
    attr: {
      strokeWidth: 1.5,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const CloseIcon = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M15 15 5 5 M15 5 5 15',
    attr: {
      width: size || 20,
      height: size || 20,
      stroke: color || 'currentColor',
      strokeLinejoin: 'round',
      strokeWidth: 2,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} onClick={onClick} />;
};

export const CloseCircle = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M 13 13 L 7 7 M 13 7 L 7 13 M 10 2 C -0.6 2 -0.6 18 10 18 C 20.4 18 20.8 2 10 2',
    attr: {
      width: size || 20,
      height: size || 20,
      stroke: color || 'currentColor',
      strokeLinejoin: 'round',
      strokeWidth: 2,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} onClick={onClick} />;
};

export const PlusIcon = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M10 4 10 16 M4 10 16 10',
    attr: {
      fill: 'currentColor',
      strokeLinejoin: 'round',
      strokeWidth: 2,
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const ArrowDown = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M5 7.5L10 12.5L15 7.5',
    attr: {
      width: 20,
      height: 20,
      viewBox: '0 0 20 20',
      strokeWidth: 2,
      stroke: '#00328D',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const SortAscIcon = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M10 14H14M10 10H16.5M10 6H19M4 17V3M4 3 7 6M4 3 1 6',
    attr: {
      width: size || 20,
      height: size || 20,
      viewBox: '0 0 20 20',
      strokeWidth: 1.4,
      stroke: color || 'currentColor',
      strokeLinecap: 'round',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const HistoryIcon = ({ size, color, onClick }: ActionProps) => {
  const svg = {
    path: 'M9.9 6.6V9.9L11.6 11.6M2.5 9C2.8 7.3 3.5 5.6 5 4.4 6.3 3.1 8 2.4 9.8 2.4 11.7 2.4 13.4 3.1 14.7 4.2 16.2 5.4 17.1 7.2 17.3 8.9 17.5 10.7 17.1 12.5 16.1 14.1 15 15.5 13.4 16.6 11.7 17.1 9.9 17.5 8 17.3 6.4 16.4 4.8 15.5 3.5 14.1 3 12.3M2.5 16.5V12.3H6.6',
    attr: {
      width: size || 20,
      height: size || 20,
      viewBox: '0 0 20 20',
      strokeWidth: 1.6,
      stroke: color || 'currentColor',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const FilterIcon = ({ size, color }: ActionProps) => {
  const svg = {
    path: 'M3.17 4.06H8.22M8.22 4.06C8.22 4.99 8.97 5.74 9.9 5.74 10.83 5.74 11.58 4.99 11.58 4.06M8.22 4.06C8.22 3.13 8.97 2.38 9.9 2.38 10.83 2.38 11.58 3.13 11.58 4.06M11.58 4.06H16.63M3.17 9.96H13.27M13.27 9.96C13.27 10.88 14.02 11.64 14.95 11.64 15.88 11.64 16.63 10.88 16.63 9.96 16.63 9.02 15.88 8.27 14.95 8.27 14.02 8.27 13.27 9.02 13.27 9.96ZM6.54 15.84H16.63M6.54 15.84C6.54 14.91 5.78 14.16 4.85 14.16 3.93 14.16 3.17 14.91 3.17 15.84 3.17 16.77 3.93 17.52 4.85 17.52 5.78 17.52 6.54 16.77 6.54 15.84Z',
    attr: {
      width: size || 20,
      height: size || 20,
      viewBox: '0 0 20 20',
      strokeWidth: 1.2,
      stroke: color || '#003B71',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    } as SVGAttributes<SVGElement>,
  };

  return <Icon svg={svg} />;
};

export const RevealTagsIcon = () => {
  const svg = {
    path: 'M6 6 2 10 6 14M14 6 18 10 14 14M12 3 8 17',
    attr: {
      strokeWidth: 1.5,
    },
  };
  return <Icon svg={svg} />;
};
export const MoreIcon = () => {
  const svg = {
    path: 'M3 10H3.4M9.8 10H10.2M16.6 10H17',
    attr: {
      strokeColor: 'red',
      strokeWidth: 4,
    },
  };
  return <Icon svg={svg} />;
};
