import { Flex } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Dispatch, SetStateAction, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { CustomModal, useGLGOParams } from 'ui';
import classes from './DeleteNoTxRule.module.css';
import { updateNoTxRules } from './noTxRuleActions';

type Props = {
  id: number;
  yyTarget: TranslationKey | undefined;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const DeleteNoTxRule = ({ id, yyTarget, setLoading }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { projectKey } = useGLGOParams();

  function handleDeleteRule(id: number) {
    setShowModal(false);
    if (!id || !yyTarget || !projectKey) return;
    setLoading(true);
    updateNoTxRules({ id, projectKey, target: yyTarget })
      .then(() => {
        notifications.show({
          message: 'Successfully delete the no translate rule.',
        });
      })
      .catch(() => {
        notifications.show({
          message: 'Something went wrong with deleting the rule.',
        });
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <CustomModal
        title="Are you sure you want to delete this rule?"
        opened={showModal}
        onClose={() => setShowModal(false)}
        footerActions={[
          { label: 'Cancel', action: () => setShowModal(false) },
          { label: 'Confirm', action: () => handleDeleteRule(id) },
        ]}
      />
      <Flex className={classes.icon} onClick={() => setShowModal(true)}>
        <FaTrash color="var(--mantine-color-font1-1)" />
      </Flex>
    </>
  );
};
