import { useEffect } from 'react';
import { useSetupStepsContext } from '../ProjectSetupProvider';

export default function useSelectedLanguages() {
  const { setIsNextButtonDisabled, selectedTargetLangCodes } =
    useSetupStepsContext();

  useEffect(() => {
    if (selectedTargetLangCodes.length > 0) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
  }, [selectedTargetLangCodes.length, setIsNextButtonDisabled]);
}
