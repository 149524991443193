import {
  FilterBy,
  FiltersValues,
  FilterOption,
  Filters,
  filters,
  FiltersOptions,
} from '../store/DashboardStore';
import { getSegmentStatus } from '../components/Indicators/statusCodes';
import { SortBy, SearchBy } from '../types/editor';
import { getJliffText } from '../components/Jliff/jliffFunctions';

export const isAutoTranslation = (source: string | undefined) =>
  /backfill|ddt|mt/i.test(source || '');

export const capitilize = (str: string) =>
  str
    .split(' ')
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(' ');

// Ignore segments
export function segmentIsIgnorable(segment: Segment) {
  return (
    segment.is_ignorable ||
    segment.segment_hash === '0' ||
    !segment.segment_jliff
  );
}

export const getFiltersOptions = (
  obj: SegmentsObj,
  attributes: BlockAttributes | null | undefined
): FiltersOptions => {
  const options: FiltersOptions = { source: [], status: [], type: [] };
  const domAttributes = attributes?.domAttributes || [];

  Object.keys(obj).forEach((sHash) => {
    const values: { [key in Filters]: string } = {
      source: '',
      status: '',
      type: '',
    };
    const segment = obj[sHash];
    values.source = isAutoTranslation(segment.translation_source || 'backfill')
      ? 'Automatic'
      : 'Human';
    values.status = getSegmentStatus(segment.status_code || '');
    values.type = domAttributes.includes(segment.segment_hash)
      ? 'Attribute'
      : 'Text';

    Object.keys(values).forEach((key) => {
      const value = values[key as keyof typeof values];
      const option = (
        options[key as keyof typeof options] as FilterOption[]
      ).find((s) => s.value === value);
      if (!option) {
        const label = capitilize(value);
        (options[key as keyof typeof options] as FilterOption[]).push({
          label,
          value,
          count: 1,
        });
      } else {
        option.count++;
      }
    });
  });

  return options;
};

export const getTranslationSouce = (segment: Segment) => {
  if (!segment.target_jliff) return 'Untranslated';
  if (!segment.translation_source) return 'Unknown';
  if (isAutoTranslation(segment.translation_source))
    return 'Automatic Translation';
  if (segment.first_name || segment.last_name)
    return `${segment.first_name} ${segment.last_name}`;
  return segment.translation_source;
};

export function getActiveFilters(filterBy: FilterBy, externalFilter: boolean) {
  const activeFilters: [string, string][] = [];
  if (externalFilter) activeFilters.push(['block', 'block']);
  Object.entries<FiltersValues>(filterBy).forEach(([filter, values]) => {
    if (filter === 'date') {
      if (values[0] || values[1]) {
        activeFilters.push([filter, filter]);
      }
      return;
    } else if (values.length) {
      values.forEach(
        (value) =>
          typeof value === 'string' && activeFilters.push([filter, value])
      );
    }
  });

  return activeFilters;
}
export function getFiltersCount(filterBy: FilterBy, contextMode: boolean) {
  if (!contextMode) {
    const filters = ['u'];
    const params = new URLSearchParams(window.location.search);
    return filters.reduce(
      (count, filter) => (params.has(filter) ? count + 1 : count),
      0
    );
  }

  let count = 0;
  Object.entries<FiltersValues>(filterBy).forEach(([filter, values]) => {
    if (filter === 'date') {
      if (values[0] || values[1]) count++;
    } else count += values.length;
  });

  return count;
}

export function sortSegments(
  segmentsObj: SegmentsObj,
  hashes: string[],
  attributes: BlockAttributes,
  by: SortBy
) {
  let sorted = hashes;
  if (!by.field) return sorted;
  if (by.field === 'type') {
    const value = (hash: string) =>
      attributes?.domAttributes?.includes(hash) ? 0 : 1;
    sorted = by.ascending
      ? hashes.sort((a, b) => value(a) - value(b))
      : hashes.sort((a, b) => value(b) - value(a));
  } else if (by.field === 'status') {
    const value = (hash: string) =>
      getSegmentStatus(segmentsObj[hash].status_code || '');
    sorted = by.ascending
      ? hashes.sort((a, b) => value(a).localeCompare(value(b)))
      : hashes.sort((a, b) => value(b).localeCompare(value(a)));
  } else if (by.field === 'source') {
    const value = (hash: string) =>
      segmentsObj[hash].last_name || segmentsObj[hash].first_name
        ? (segmentsObj[hash].last_name || '') +
          (segmentsObj[hash].first_name || '')
        : segmentsObj[hash].email ||
          (isAutoTranslation(segmentsObj[hash].translation_source)
            ? 'Automatic'
            : 'Untranslated');
    sorted = by.ascending
      ? hashes.sort((a, b) => value(a).localeCompare(value(b)))
      : hashes.sort((a, b) => value(b).localeCompare(value(a)));
  } else if (by.field === 'date') {
    const value = (hash: string) =>
      new Date(segmentsObj[hash].translation_utc || Date()).getTime();
    sorted = by.ascending
      ? hashes.sort((a, b) => value(a) - value(b))
      : hashes.sort((a, b) => value(b) - value(a));
  }
  return sorted;
}

function filterIsEmpty(filterBy: FilterBy, searchBy: SearchBy) {
  const dateIsEmpty = filterBy.date[0] === null && filterBy.date[1] === null;
  const searchIsEmpty = searchBy.text === '';
  const otherFiltersEmpty = filters.every(
    (filter) => filterBy[filter as keyof typeof filterBy].length === 0
  );
  return dateIsEmpty && otherFiltersEmpty && searchIsEmpty;
}

export function filterSegments(
  segments: SegmentsObj,
  sHashes: string[],
  filterBy: FilterBy,
  searchBy: SearchBy,
  attributes?: BlockAttributes
) {
  // Filter is empty
  if (filterIsEmpty(filterBy, searchBy)) return sHashes;
  const dateFrom = filterBy.date[0] ? filterBy.date[0].getTime() : 0;
  const dateTo =
    (filterBy.date[1]?.getTime() || dateFrom) + 1000 * 60 * 60 * 24;

  const segmentsToShow: string[] = [];
  sHashes.forEach((sHash) => {
    let filtersResult = true;
    const segment = segments[sHash];
    // First filter by text search
    if (searchBy.text) {
      filtersResult =
        segment.segment_text
          .toLowerCase()
          .includes(searchBy.text.toLowerCase()) ||
        segment.target_text.toLowerCase().includes(searchBy.text.toLowerCase());
    }
    if (!filtersResult) return;
    // Then filter by other filters (that are not empty)
    const filters = Object.keys(filterBy).filter(
      (f) => filterBy[f as keyof typeof filterBy].length
    );
    filters.forEach((filter) => {
      let showSegment = true;
      if (filter === 'date' && dateFrom) {
        const date = new Date(segment.translation_utc || 0).getTime();
        showSegment = date >= dateFrom && date <= dateTo;
        // Segment is already filtered out
      } else if (filtersResult && showSegment) {
        const values = filterBy[filter as keyof typeof filterBy] || [];
        switch (filter) {
          case 'status':
            showSegment = (values as FilterBy['status']).includes(
              getSegmentStatus(segment.status_code || '')
            );
            break;
          case 'source':
            showSegment = (values as FilterBy['source']).includes(
              isAutoTranslation(segment.translation_source || 'backfill')
                ? 'Automatic'
                : 'Human'
            );
            break;
          case 'type':
            showSegment = (values as FilterBy['type']).includes(
              attributes?.domAttributes?.includes(sHash) ? 'Attribute' : 'Text'
            );
            break;
          default:
            break;
        }
      }
      filtersResult = filtersResult && showSegment;
    });
    if (filtersResult) segmentsToShow.push(sHash);
  });
  return segmentsToShow;
}

export function formatDate(date?: string) {
  const dateObj = new Date(date || '');
  const dateIsValid = !isNaN(dateObj.getTime());

  return dateIsValid
    ? dateObj.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    : '';
}

export function blocksToSegments(blocks: BlockSegment) {
  // Convert Blocks object to Segments object
  // Keep segments that are in blocks object or not saved.
  const newSegments: SegmentsObj = {};
  Object.keys(blocks).forEach((bHash) => {
    blocks[bHash].forEach((segment) => {
      if (segmentIsIgnorable(segment)) return;
      const sHash = segment.segment_hash;
      newSegments[sHash] = segment;
    });
  });

  return newSegments;
}

export function processSegmentResponse(segments: Segment[]) {
  return segments.reduce((acc: SegmentsObj, segment: Segment) => {
    const { target_jliff } = segment;
    if (Array.isArray(target_jliff) && target_jliff.length > 0)
      acc[segment.segment_hash] = segment;
    return acc;
  }, {});
}

export function generateSegmentsObject(segments: Segment[]) {
  return segments.reduce((acc: SegmentsObj, segment: Segment) => {
    acc[segment.segment_hash] = segment;
    return acc;
  }, {});
}

export function getSourceGlossary(
  segment: Segment,
  glossaryItems: GlossaryItem[]
) {
  const segmentGlossary: GlossaryItem[] = [];

  if (glossaryItems?.length) {
    glossaryItems.forEach((g) => {
      const regex = new RegExp(
        `\\b${g.source_text}\\b`,
        g.is_case_sensitive ? 'g' : 'ig'
      );
      const match = segment.segment_text.match(regex);
      if (match) {
        segmentGlossary.push({ ...g, count: match.length });
      }
    });
  }
  return segmentGlossary;
}

export function findGlossaryViolations(
  jliffs: Jliff[],
  glossaryItems: GlossaryItem[]
) {
  if (!glossaryItems?.length) return [];
  const glossaryViolations: GlossaryItem[] = [];
  const jliffText = getJliffText(jliffs);

  glossaryItems.forEach((g) => {
    if (g.rule === 'never_translate') {
      const regex = new RegExp(
        `\\b${g.source_text}\\b`,
        g.is_case_sensitive ? 'g' : 'gi'
      );
      const match = jliffText.match(regex);
      if (!match || match.length !== g.count) {
        glossaryViolations.push(g);
      }
    } else if (g.rule === 'translate_as') {
      const regex = new RegExp(
        `\\b${g.target_text[0]}\\b`,
        g.is_case_sensitive ? 'g' : 'gi'
      );
      const match = jliffText.match(regex);
      if (!match || match.length !== g.count) {
        glossaryViolations.push(g);
      }
    } else if (g.rule === 'never_translate_as') {
      let found = 0;
      // find if any of the target_text is in the jliff text
      g.target_text.forEach((t) => {
        const regex = new RegExp(
          `\\b${t}\\b`,
          g.is_case_sensitive ? 'g' : 'gi'
        );
        const match = jliffText.match(regex);
        if (match) {
          found++;
        }
      });
      if (found) glossaryViolations.push({ ...g, count: found });
    }
  });

  return glossaryViolations;
}
