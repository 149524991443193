import { Loader, Text } from '@mantine/core';
import updateAllAppearances from '../updateAllAppearances';
import { CustomModal } from 'ui';

type Props = {
  showOverrideCSSModal: boolean;
  setShowOverrideCSSModal: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  updatedCustomCSS: string;
  yyTarget: TranslationKey | undefined;
};

const ConfirmOverrideCSSModal = ({
  showOverrideCSSModal,
  setShowOverrideCSSModal,
  loading,
  setLoading,
  updatedCustomCSS,
  yyTarget,
}: Props) => {
  return (
    <CustomModal
      title="Updating Language Selector Configuration"
      opened={showOverrideCSSModal}
      onClose={() => setShowOverrideCSSModal(false)}
      footerActions={[
        { label: 'Cancel', action: () => setShowOverrideCSSModal(false) },
        {
          label: loading ? <Loader color="cta1.6" size="sm" /> : 'Save',
          action: () => {
            updateAllAppearances(
              yyTarget!,
              updatedCustomCSS,
              setShowOverrideCSSModal,
              setLoading
            );
          },
          disabled: loading,
        },
      ]}
    >
      <Text fz="md" ta="center">
        Changing the Language Selector configuration will override any CSS added
        in the CSS editor.
        <br />
        <br />
        Are you sure you would like to continue?
      </Text>
    </CustomModal>
  );
};

export default ConfirmOverrideCSSModal;
