import React from 'react';
import {
  Box,
  Card,
  Divider,
  Flex,
  ScrollArea,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import {
  DOMAIN,
  addDeploymentValue,
  removeDeploymentValue,
  TargetFormValue,
  hasValidDeploymentValues,
} from '../utils';
import { MdAdd, MdOutlineRemoveCircleOutline } from 'react-icons/md';
import { isValidDomain } from 'helpers';
import FooterButtons from '../FooterButtons';
import { useDeploymentContext } from '../DeploymentContextProvider';

const ConfigureDomainMethod = () => {
  const { colors } = useMantineTheme();
  const {
    loading,
    setLoading,
    setSelectedMethod,
    activeProject: { origin_name: domain = '' } = {},
    targets = [],
    updateDeploymentDetails,
  } = useDeploymentContext();

  const defaultTargetValues = targets.map((target) => {
    const parsedDomain = domain.replaceAll('www.', '');
    const targetLangCode = target.target_lang_code.toLowerCase();
    return {
      ...target,
      deployment_value: [`${targetLangCode}.${parsedDomain}`],
    };
  });

  const { control, register, handleSubmit, setFocus, setValue, getValues } =
    useForm({
      defaultValues: {
        deploymentMethod: DOMAIN,
        deploymentName: '',
        targetValues: defaultTargetValues,
      },
    });
  const { fields: targetValues } = useFieldArray({
    control,
    name: 'targetValues',
  });

  const handleUpdate = async (data: {
    deploymentMethod: string;
    deploymentName: string;
    targetValues: TargetFormValue[];
  }) => {
    const {
      deploymentName = '',
      deploymentMethod = '',
      targetValues = [],
    } = data || {};

    const deploymentValues = targetValues.map(
      ({ deployment_value }) => deployment_value
    );

    if (!hasValidDeploymentValues(deploymentValues, DOMAIN)) {
      const invalidIndicies = deploymentValues.reduce(
        (accum: number[][], values, targetIndex) => {
          const hasEmptyValue = values.includes('');
          const invalidValueFound = values.find(
            (value) => !isValidDomain(value)
          );
          const emptyValueIndex = values.indexOf('');
          const invalidValueIndex = invalidValueFound
            ? values.indexOf(invalidValueFound)
            : -1;
          const valueIndex = hasEmptyValue
            ? emptyValueIndex
            : invalidValueIndex;
          valueIndex !== -1 && accum.push([targetIndex, valueIndex]);
          return accum;
        },
        []
      );
      const [targetIndex = -1, valueIndex = -1] = invalidIndicies[0] || [];
      setFocus(`targetValues.${targetIndex}.deployment_value.${valueIndex}`);
      notifications.show({
        message: 'A valid Domain pattern is required per language',
      });
      return;
    }

    setLoading(true);

    updateDeploymentDetails({
      deploymentName,
      deploymentValues,
      deploymentMethod,
    })
      .then(() => {
        notifications.show({
          message: 'Successfully configured new deployment details!',
        });
      })
      .catch(() => {
        notifications.show({
          message:
            'Oops, something went wrong while configuring new deployment method',
        });
      })
      .finally(() => {
        setSelectedMethod('');
        setLoading(false);
      });
  };

  return (
    <Card mt={30} maw="40rem" p="2rem">
      <Flex rowGap="1.5rem" direction="column">
        <Flex w="100%" align="center" columnGap="0.75rem">
          <Title order={1}>Deployment Methods</Title>
        </Flex>
        <Divider size="xs" orientation="horizontal" color="divider.1" />
        <Text fz="md" fw={600}>
          Please specify Domain pattern per language:
        </Text>
        <Flex w="100%" justify="center">
          <ScrollArea
            h="23.5rem"
            pr="1rem"
            className="scrollArea"
            offsetScrollbars
          >
            {targetValues.map(
              (
                {
                  id = '',
                  target_lang_name = '',
                  target_lang_code = '',
                  deployment_value = [],
                },
                targetIndex
              ) => {
                const isLastIndex = targetIndex === targetValues.length - 1;
                const hasMultipleValues = deployment_value.length > 1;
                return (
                  <Box key={id}>
                    <Flex py={hasMultipleValues ? '1.15rem' : 0}>
                      <Flex
                        w="35%"
                        h={hasMultipleValues ? '2.75rem' : 'auto'}
                        align="center"
                        pr="1rem"
                      >
                        <Text truncate>{target_lang_name}</Text>
                      </Flex>
                      <Flex
                        w="50%"
                        direction="column"
                        rowGap="0.5rem"
                        justify={hasMultipleValues ? 'flex-start' : 'center'}
                        px="1rem"
                      >
                        {deployment_value.map((value, valueIndex) => (
                          <TextInput
                            key={`${id}-${value}-${valueIndex}`}
                            maw="15rem"
                            size="md"
                            {...register(
                              `targetValues.${targetIndex}.deployment_value.${valueIndex}`
                            )}
                            defaultValue={value.toLowerCase()}
                          />
                        ))}
                      </Flex>
                      <Flex
                        w="15%"
                        mih="5rem"
                        align={hasMultipleValues ? 'flex-end' : 'center'}
                      >
                        <Flex h="2.5rem" align="center" columnGap="0.5rem">
                          {hasMultipleValues && (
                            <Box
                              h={25}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                removeDeploymentValue(
                                  target_lang_code,
                                  getValues,
                                  setValue
                                )
                              }
                            >
                              <MdOutlineRemoveCircleOutline
                                size={25}
                                color={colors.icon[0]}
                              />
                            </Box>
                          )}
                          <Box
                            h={25}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              addDeploymentValue(
                                target_lang_code,
                                getValues,
                                setValue
                              )
                            }
                          >
                            <MdAdd size={25} color={colors.icon[0]} />
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                    {!isLastIndex && (
                      <Divider
                        size="xs"
                        orientation="horizontal"
                        color="divider.1"
                      />
                    )}
                  </Box>
                );
              }
            )}
          </ScrollArea>
        </Flex>
        <Divider size="xs" orientation="horizontal" color="divider.1" />
        <FooterButtons
          loading={loading}
          onCancel={() => {
            setSelectedMethod('');
          }}
          onSave={handleSubmit(handleUpdate)}
        />
      </Flex>
    </Card>
  );
};

export default ConfigureDomainMethod;
