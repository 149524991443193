import { MantineColor, StyleProp } from '@mantine/core';
import TranslationBatchIcon from '../../assets/images/TranslationBatchIcon.svg';
import TestingBatchIcon from '../../assets/images/TestingBatchIcon.svg';
import ReviewBatchIcon from '../../assets/images/ReviewBatchIcon.svg';
import ValidationBatchIcon from '../../assets/images/ValidationBatchIcon.svg';

export const getStatusColor = (status: string): StyleProp<MantineColor> => {
  switch (status.toLowerCase()) {
    case 'to do': {
      return 'status1.0';
    }
    case 'in progress': {
      return 'status1.1';
    }
    case 'ready': {
      return 'status1.2';
    }
    case 'completed': {
      return 'status1.3';
    }
    case 'cancelled': {
      return 'status1.4';
    }
    default: {
      return 'status1.3';
    }
  }
};

export const getPhaseIcon = (phase: string) => {
  switch (phase.toLowerCase()) {
    case 'translation': {
      return TranslationBatchIcon;
    }
    case 'testing': {
      return TestingBatchIcon;
    }
    case 'review': {
      return ReviewBatchIcon;
    }
    case 'validation': {
      return ValidationBatchIcon;
    }
    default: {
      return '';
    }
  }
};

export const mergeParams = (
  form: HTMLFormElement,
  searchParams: URLSearchParams,
  clearForm?: boolean
) => {
  const originalFormData = new FormData(form);
  const submitFormData = new FormData(form);

  // Append existing search parameters to form data
  for (const [key, value] of searchParams.entries()) {
    if (!originalFormData.has(key)) {
      submitFormData.append(key, value);
    }
  }

  //remove empty keys
  const keysToRemove = [] as string[];
  submitFormData.forEach((value, key) => {
    if (!value) {
      keysToRemove.push(key);
      //remove keys from original form when clearing the form
    } else if (clearForm && originalFormData.has(key)) {
      keysToRemove.push(key);
    }
  });

  keysToRemove.forEach((key) => submitFormData.delete(key));

  // return the formData with the provided options
  return submitFormData;
};

//assumes batch names are of the form RT-Hash-CreatedDate and turns them to RT-Hash
//will not affect names that don't have a "-" in them
export const normalizeBatchName = (name: string) =>
  name.split('-').slice(0, 2).join('-');
