import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.layer.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.layer.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider, getAuthManager, initUser } from 'auth';
import { hasDashboardAPIs, isDesktopApp } from 'helpers';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from 'react-router-dom';
import {
  dashboardTheme,
  UserProvider,
  Wait,
  PermissionsOverrideProvider,
} from 'ui';
import C from './constants';
import { composeRoutes } from './GLGOroutes';
import './global.css';
import RecommendedThresholdProvider from './providers/RecommendedThresholdProvider';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // the time a query can be stale before it's considered invalid, RQ default is 0 minutes
      gcTime: 1000 * 60 * 15, // the time inactive queries will be kept in the cache, RQ default is 5 minutes
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).queryClient = queryClient;

const isDesktop = isDesktopApp();
if (isDesktop && !hasDashboardAPIs) {
  throw new Error('Missing required Desktop APIs');
}

const { GTM_TAG_ID } = C;
const tagManagerParams = { gtmId: GTM_TAG_ID };

TagManager.initialize(tagManagerParams);

const createRouter = isDesktop ? createHashRouter : createBrowserRouter;
const routes = composeRoutes(queryClient);

const MantineWrapper = ({ children }: { children: React.ReactNode }) => (
  <MantineProvider theme={dashboardTheme || {}}>{children}</MantineProvider>
);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Change this to true to show the React Query DevTools
// eslint-disable-next-line no-constant-binary-expression
const showRQDevTools = false && import.meta.env.DEV;
// Render the loader while we wait for the user to be initialized
root.render(
  <MantineWrapper>
    <Wait text="Checking authentication status" />
  </MantineWrapper>
);
// Initialize the user data and render the app
initUser().then((user) => {
  root.render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider userManager={getAuthManager()}>
        <UserProvider user={user}>
          <PermissionsOverrideProvider>
            <MantineWrapper>
              <RecommendedThresholdProvider>
                <Notifications />
                <RouterProvider router={createRouter(routes)} />
              </RecommendedThresholdProvider>
            </MantineWrapper>
          </PermissionsOverrideProvider>
        </UserProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={showRQDevTools} />
    </QueryClientProvider>
  );
});
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
