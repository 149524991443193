import { Flex } from '@mantine/core';
import { JliffViewer } from './JliffViewer';
import { Status } from '../Indicators/Status';
import { GlossaryViolations } from '../Glossary/GlossaryViolations';
import { SegmentIndicators } from '../Indicators/SegmentIndicators';
import { SegmentStatus } from '../../store/SegmentsState';
import { useContext } from 'react';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';

import classes from './Target.module.css';

type Props = {
  jliffs: Jliff[];
  segment: Segment;
  segmentStatus: SegmentStatus;
  glossaryErrors: GlossaryItem[];
  filterText: string;
};
export const Target = ({
  jliffs,
  segment,
  filterText,
  segmentStatus,
  glossaryErrors,
}: Props) => {
  const { settings } = useContext(SegmentEditorContext);
  const isSelected = false; // Component is always not selected

  return (
    <Flex className={classes.translation}>
      <Flex className={classes.left}>
        <Status segment={segment} />
        <GlossaryViolations
          violations={glossaryErrors}
          isSelected={isSelected}
        />
      </Flex>
      <JliffViewer
        jliffs={jliffs}
        showAllTags={false}
        filterText={filterText}
        isSelected={isSelected}
        htmlDir={settings.htmlDir}
      />
      <SegmentIndicators status={segmentStatus} />
    </Flex>
  );
};
