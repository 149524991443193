import { Button, Flex, Skeleton, Text, Title } from '@mantine/core';
import { NOOP } from 'helpers';
import { useNavigation } from 'react-router-dom';
import { LinkWrapper } from 'ui';
import CurrentPlan from './CurrentPlan';

const SubscriptionInfoBlock = ({
  loading,
  subscriptions,
  isExpired,
}: {
  loading: boolean;
  subscriptions: Subscription[];
  isExpired: boolean;
}) => {
  if (loading) {
    <Flex direction="column" gap={5}>
      <Skeleton h={8} width={200} />
      <Skeleton h={8} width={230} />
      <Skeleton h={8} width={260} />
      <Skeleton h={8} width={250} />
    </Flex>;
  }
  if (subscriptions.length === 0)
    return <Text>No subscription info found.</Text>;

  const [currentSub] = subscriptions;

  return <CurrentPlan sub={currentSub} isExpired={isExpired} />;
};

type Props = {
  subscriptions: Subscription[];
  isExpired: boolean;
  openRestartModal: () => void;
  isCancelled: boolean;
};

const PaidPlanInformation = ({
  subscriptions,
  isExpired,
  openRestartModal,
  isCancelled,
}: Props) => {
  const loading = useNavigation().state !== 'idle';

  return (
    <>
      <Flex
        align={{ base: 'start', lg: 'center' }}
        direction={{ base: 'column', lg: 'row' }}
        justify={{ base: 'start', lg: 'space-between' }}
        mb="1.5rem"
        columnGap="1rem"
      >
        <Title order={3} tt="uppercase">
          Current Plan / Threshold
        </Title>
        <LinkWrapper to={isCancelled ? undefined : 'manage-subscription'}>
          <Button
            px={0}
            variant="transparent"
            onClick={isCancelled ? openRestartModal : () => NOOP}
          >
            {`${isCancelled ? 'Restart' : 'Manage'} Subscription`}
          </Button>
        </LinkWrapper>
      </Flex>
      <SubscriptionInfoBlock
        loading={loading}
        subscriptions={subscriptions}
        isExpired={isExpired}
      />
    </>
  );
};

export default PaidPlanInformation;
