import React, { ReactNode, useMemo, useState, forwardRef } from 'react';
import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { AiOutlineSearch } from 'react-icons/ai';

const SELECT_ALL = 'SELECT_ALL';

type Props =
  | MultiSelectProps
  | {
      data: {
        value: string;
        label: string;
      }[];
      children?: ReactNode;
      onChange?: (value: string[]) => void;
      type: 'Add' | 'Edit';
      withCheckIcon: boolean;
    };

const MultiSelectAll = forwardRef<
  HTMLInputElement,
  Props & {
    targetOptions: {
      value: string;
      label: string;
    }[];
  }
>(
  (
    { children, targetOptions, withCheckIcon, onChange, type, ...props },
    ref
  ) => {
    const [values, setValues] = useState<string[]>([]);

    const dataWithSelectAll = useMemo(() => {
      if (!targetOptions) return [];

      return targetOptions.length === values.length
        ? targetOptions
        : [
            {
              label: 'SELECT ALL',
              value: SELECT_ALL,
            },
            ...targetOptions,
          ];
    }, [targetOptions, values]);

    const multiSelectAllOnChange = (selectedValues: string[]): void => {
      const lastValue = selectedValues[selectedValues.length - 1];

      const getData = (): string[] => {
        if (lastValue === SELECT_ALL && targetOptions) {
          return targetOptions.map((dataPoint) => {
            if (typeof dataPoint === 'string') {
              return dataPoint;
            }

            if (typeof dataPoint === 'object' && 'value' in dataPoint) {
              const { value } = dataPoint;
              return value;
            }

            return '';
          });
        }
        return selectedValues;
      };

      const newValues = getData();
      setValues(newValues);
      if (typeof onChange === 'function') {
        onChange(newValues);
      }
    };

    return (
      <MultiSelect
        ref={ref}
        placeholder="Language"
        disabled={type === 'Edit'}
        withCheckIcon={withCheckIcon}
        data={dataWithSelectAll}
        onChange={multiSelectAllOnChange}
        comboboxProps={{
          withinPortal: true,
          zIndex: 99999,
        }}
        value={values}
        rightSection={<AiOutlineSearch size={18} />}
        {...props}
      >
        {children}
      </MultiSelect>
    );
  }
);

export default MultiSelectAll;
