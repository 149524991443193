import { useCallback, useEffect, useMemo, useState } from 'react';
import { Fetcher } from '@glweb/xapis-client';
import useProjectsStore, {
  type ProjectStore,
} from '../../../store/ProjectsStore';
import { useSetupStepsContext } from '../ProjectSetupProvider';

export default function useInjectionStatus() {
  const { setIsNextButtonDisabled } = useSetupStepsContext();
  const { activeProject, setActiveProject } = useProjectsStore(
    (state: ProjectStore) => state
  );

  const [isInjected, setIsInjected] = useState(false);

  const controller = useMemo(() => {
    return new AbortController();
  }, []);

  const signal = useMemo(() => {
    return controller.signal;
  }, [controller.signal]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  const { projectKey: activeProjectKey = '' } = activeProject || {};

  const getInjectionStatus = useCallback(() => {
    if (!isInjected && activeProjectKey) {
      Fetcher(
        `Project/${activeProjectKey}`,
        ({ data }) => {
          const { project_status: projectStatus = '' } = data || {};
          if (projectStatus === 'UNVERIFIED') {
            setIsInjected(false);
            setTimeout(() => {
              getInjectionStatus();
            }, 5000);
          } else {
            setIsInjected(true);
            setIsNextButtonDisabled(false);
            setActiveProject(data);
          }
        },
        { errorMessage: 'Unable to get injection status at this time.' },
        {},
        undefined,
        'json',
        signal
      );
    }
    return false;
  }, [
    activeProjectKey,
    isInjected,
    setActiveProject,
    setIsNextButtonDisabled,
    signal,
  ]);

  useEffect(() => {
    getInjectionStatus();
  }, [getInjectionStatus]);

  return [isInjected, setIsInjected];
}
