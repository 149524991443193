import { Box, Card, Divider, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { getPrettyDate } from 'helpers';
import { useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import { Header, useCurrentProjectData, useGLGOParams } from 'ui';
import EstimateInformation from './EstimateInformation';
import FreeTrialInformation from './FreeTrialInformation';
import ManagePaymentMethods from './ManagePaymentMethods';
import NewPaymentModal from './NewPaymentModal';
import PaidPlanInformation from './PaidPlanInformation';
import classes from './PlanInformation.module.css';
import RestartSubModal from './subs-action-modals/RestartSubModal';
import { sortSourcesByDefault } from './utils';

export type PlanOption = Pick<
  Subscription,
  'threshold_value' | 'subscription_type' | 'sku_name' | 'sku' | 'price'
> & { is_canceled?: boolean };

type Props = {
  skus: SkuWCreatedAndLastMod[];
  subscriptions: Subscription[];
  sources: PaymentSource[];
  default_source_id: string;
  quickQuote: QuickQuoteResponse | null;
};

const PlanInformation = ({
  skus,
  subscriptions,
  sources,
  default_source_id,
  quickQuote,
}: Props) => {
  const revalidator = useRevalidator();

  const { project } = useCurrentProjectData();
  const subscription_status = project.subscription_status || 'expired';

  const project_key = useGLGOParams().projectKey || '';

  const sub =
    subscriptions.length > 0 ? subscriptions[0] : ({} as Subscription);

  const {
    sku = '',
    price = 0,
    next_renewal_date = '',
    words_served = 0,
    is_canceled = false,
  } = sub;
  const isExpired = subscription_status === 'expired';
  const isFreePlan = subscriptions.length > 0 && price === 0;
  const date = getPrettyDate(next_renewal_date);

  const sortedCards = sortSourcesByDefault(sources, default_source_id);
  const [cards, setCards] = useState(sortedCards);
  const [translatedTraffic, setTranslatedTraffic] = useState(10);

  const [paymentModalOpened, { open: openPayModal, close: closePayModal }] =
    useDisclosure(false);
  const [
    restartModalOpened,
    { open: openRestartModal, close: closeRestartModal },
  ] = useDisclosure(false);

  const restartParams = {
    project_key,
    sku,
    revalidator,
    closeModal: closeRestartModal,
  };

  const isQuickQuoteCached =
    quickQuote?.qmessage &&
    quickQuote?.qmessage.length > 0 &&
    quickQuote?.qmessage.includes('result was cached');

  return (
    <Flex w="100%" direction="column">
      <Header title="Plan Information" />
      <Card
        display="flex"
        shadow="xs"
        p="2rem 1.5rem"
        radius="md"
        className={classes.planInfoContainer}
      >
        <RestartSubModal
          opened={restartModalOpened}
          onClose={closeRestartModal}
          renewalDate={date}
          restartParams={restartParams}
          isExpired={isExpired}
        />
        <NewPaymentModal
          opened={paymentModalOpened}
          onClose={closePayModal}
          cards={cards}
          setCards={setCards}
          sources={sources}
          default_source_id={default_source_id}
        />
        <Flex direction={{ base: 'column', lg: 'row' }} rowGap="1rem">
          <Box w={{ base: '100%', lg: '50%' }}>
            {isFreePlan && isQuickQuoteCached ? (
              <FreeTrialInformation
                nextRenewalDate={next_renewal_date}
                wordsServedUsed={words_served}
                skus={skus}
                translatedTraffic={translatedTraffic}
                loading={Object.keys(quickQuote).length === 0}
                isExpired={isExpired}
                isQuickQuoteCached={isQuickQuoteCached}
                quickQuote={quickQuote}
              />
            ) : (
              <PaidPlanInformation
                subscriptions={subscriptions}
                isExpired={isExpired}
                isCancelled={is_canceled}
                openRestartModal={openRestartModal}
              />
            )}
          </Box>
          <Divider
            color="divider.0"
            orientation="vertical"
            size="sm"
            mx="2rem"
          />
          <Box w={{ base: '100%', lg: '50%' }}>
            {isFreePlan && isQuickQuoteCached ? (
              <EstimateInformation
                translatedTraffic={translatedTraffic}
                setTranslatedTraffic={setTranslatedTraffic}
                isQuickQuoteCached={isQuickQuoteCached}
                quickQuote={quickQuote}
              />
            ) : (
              <ManagePaymentMethods
                default_source_id={default_source_id}
                sources={sources}
                cards={cards}
                setCards={setCards}
                openPayModal={openPayModal}
              />
            )}
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};

export default PlanInformation;
