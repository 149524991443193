import {
  getUserKey,
  Xapis,
} from '@glweb/xapis-client/src/xapis-wrappers/xapis';
import useProjectsStore from '../../store/ProjectsStore';

export const startSpiderJob = async (translationKey: string) => {
  const userKey = getUserKey();
  const projectName = useProjectsStore.getState().activeProject.projectName;

  const body = {
    job_name: `${projectName}-topology-crawl`,
    user_key: userKey,
    debug_level: 0,
    status: 'START',
    seo_only: 'OFF',
    max_spider_minutes: 10,
  };

  return await Xapis.SpiderJob.post(translationKey, body);
};
