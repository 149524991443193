import { createTranslationMap } from './translations';
import createIntersectionSet from './createIntersectionSet';
import {
  useCurrentProjectData,
  usePermissionsOverrideContext,
  useUserProvider,
} from 'ui';

const USER_TYPE_CLIENT = 'client' as const;
const USER_TYPE_CONTRACTOR = 'contractor' as const;

export const userTypes: UserType[] = [
  USER_TYPE_CLIENT,
  USER_TYPE_CONTRACTOR,
] as const;

export const USER_ROLE_TRANSPERFECT_ADMIN = 'TP Admin' as const;
export const USER_ROLE_TRANSPERFECT_USER = 'TP User' as const;
export const USER_ROLE_GLGO_ADMIN = 'GLGO Admin' as const;
export const USER_ROLE_ADMIN = 'Admin' as const;
export const USER_ROLE_BILLING = 'Billing' as const;
export const USER_ROLE_DEVELOPER = 'Developer' as const;
export const USER_ROLE_PROJECT_MANAGER = 'Project Manager' as const;
export const USER_ROLE_LINGUIST = 'Linguist' as const;

export const userRoles: UserRole[] = [
  USER_ROLE_TRANSPERFECT_ADMIN,
  USER_ROLE_TRANSPERFECT_USER,
  USER_ROLE_GLGO_ADMIN,
  USER_ROLE_ADMIN,
  USER_ROLE_BILLING,
  USER_ROLE_DEVELOPER,
  USER_ROLE_PROJECT_MANAGER,
  USER_ROLE_LINGUIST,
] as const;

export type UserRole =
  | typeof USER_ROLE_TRANSPERFECT_ADMIN
  | typeof USER_ROLE_TRANSPERFECT_USER
  | typeof USER_ROLE_GLGO_ADMIN
  | typeof USER_ROLE_ADMIN
  | typeof USER_ROLE_BILLING
  | typeof USER_ROLE_DEVELOPER
  | typeof USER_ROLE_PROJECT_MANAGER
  | typeof USER_ROLE_LINGUIST
  | '';

export type ProjectUserPermission = User & {
  languagePermissionSet: Set<string>;
  rolePermissionSet: Set<UserRole>;
};
export type ProjectUserPermissionMap = Record<string, ProjectUserPermission>;

// TODO: use "...defaultUser" instead, getting this error:
// TODO: Uncaught ReferenceError: Cannot access 'defaultUser' before initialization
//     at projectPermissions.ts:28:45
export const defaultProjectUserPermission = {
  email: '',
  first_name: '',
  company_name: '',
  last_name: '',
  phone: '',
  user_key: '',
  languagePermissionSet: new Set(),
  rolePermissionSet: new Set(),
} as ProjectUserPermission;

// Prefix in the group_key that determines role. Ex: "BILL-ABE1-8813-5A4E" => BILLING
const BILLING_PREFIX = 'BILL';
const DEVELOPER_PREFIX = 'DEVL';
const PROJECT_MANAGER_PREFIX = 'PRMG';

// Special static groups in XAPIS that gives all permissions for all projects and languages
const TPT_ADMIN_GROUP_KEY = 'TPTX-ADMN-GRUP-XXXX';
export const TPT_USER_GROUP_KEY = 'TPTX-USER-GRUP-XXXX';
const GLGO_ADMINS_GROUP_KEY = 'BE44-5536-1E57-6064';

export const getPrefixGroupKey = (prefix: string, projectKey: string) =>
  prefix.concat(projectKey.slice(projectKey.indexOf('-')));

const getUserRole = (
  projectTranslationMap: Record<string, TranslationKey>,
  projectKey: string,
  groupKey: string
): UserRole => {
  if (projectTranslationMap[groupKey]) {
    return USER_ROLE_LINGUIST;
  }

  switch (groupKey) {
    case TPT_ADMIN_GROUP_KEY:
      return USER_ROLE_TRANSPERFECT_ADMIN;
    case TPT_USER_GROUP_KEY:
      return USER_ROLE_TRANSPERFECT_USER;
    case GLGO_ADMINS_GROUP_KEY:
      return USER_ROLE_GLGO_ADMIN;
    case projectKey:
      return USER_ROLE_ADMIN;
    case getPrefixGroupKey(BILLING_PREFIX, projectKey):
      return USER_ROLE_BILLING;
    case getPrefixGroupKey(DEVELOPER_PREFIX, projectKey):
      return USER_ROLE_DEVELOPER;
    case getPrefixGroupKey(PROJECT_MANAGER_PREFIX, projectKey):
      return USER_ROLE_PROJECT_MANAGER;
    default:
      return '';
  }
};

export const getGroupKey = (projectKey: string, userRole: UserRole) => {
  switch (userRole) {
    case USER_ROLE_TRANSPERFECT_ADMIN:
      return TPT_ADMIN_GROUP_KEY;
    case USER_ROLE_TRANSPERFECT_USER:
      return TPT_USER_GROUP_KEY;
    case USER_ROLE_GLGO_ADMIN:
      return GLGO_ADMINS_GROUP_KEY;
    case USER_ROLE_ADMIN:
      return projectKey;
    case USER_ROLE_BILLING:
      return getPrefixGroupKey(BILLING_PREFIX, projectKey);
    case USER_ROLE_DEVELOPER:
      return getPrefixGroupKey(DEVELOPER_PREFIX, projectKey);
    case USER_ROLE_PROJECT_MANAGER:
      return getPrefixGroupKey(PROJECT_MANAGER_PREFIX, projectKey);
    case USER_ROLE_LINGUIST:
      return 'Use translation_key instead';
    default:
      return '';
  }
};

export const createProjectUserPermissionMap = (
  project: ProjectKey | undefined,
  projectTranslationMap?: Record<string, TranslationKey>
): ProjectUserPermissionMap => {
  const { groups: projectGroups = [], project_key = '' } = project || {};

  // Create the projectTranslationMap only if necessary, best to pass it into this function
  const localProjectTranslationMap =
    projectTranslationMap || createTranslationMap(project);

  return projectGroups.reduce((acc: ProjectUserPermissionMap, projectGroup) => {
    const { group_key, group_members = [] } = projectGroup;

    const userRole = getUserRole(
      localProjectTranslationMap,
      project_key,
      group_key
    );

    if (userRole) {
      group_members.forEach((groupMember: User) => {
        const { user_key = '' } = groupMember;
        const user: ProjectUserPermission = acc[user_key]
          ? { ...acc[user_key] }
          : {
              rolePermissionSet: new Set(),
              languagePermissionSet: new Set(),
              ...groupMember,
            };

        user.rolePermissionSet.add(userRole);
        if (userRole === USER_ROLE_LINGUIST) {
          // It's determined that group_key is the same as an associated translation_key
          user.languagePermissionSet.add(group_key);
        }

        acc[user_key] = user;
      });
    }

    return acc;
  }, {});
};

const hasPermissions = (permissionsSet: Set<string>, allowedSet: Set<string>) =>
  Boolean(createIntersectionSet(permissionsSet, allowedSet).size);

const getAllowedSetType = (allowedSet: Set<string>) => {
  let type = '';

  const userRoleSet = new Set([...userRoles]);
  const userTypeSet = new Set([...userTypes]);

  for (const value of [...allowedSet]) {
    if (userRoleSet.has(value as UserRole)) {
      type = 'role';
      break;
    }
    if (userTypeSet.has(value as UserType)) {
      type = 'userType';
      break;
    }
  }

  return type;
};

export const useViewPermissions = () => {
  const {
    overrides: {
      user_type: overriddenUserType,
      rolePermissionSet: overriddenRolesSet,
    },
  } = usePermissionsOverrideContext();

  const {
    xapisUser: { user_key, user_type },
  } = useUserProvider();

  const { projectUserPermissionMap } = useCurrentProjectData();

  const rolePermissions =
    projectUserPermissionMap[user_key]?.rolePermissionSet ?? new Set();

  const userTypePermissions = new Set(user_type && [user_type]);

  const hasViewPermissions = (
    permissionsSet: Set<UserRole | UserType> = new Set(),
    allowedSet: Set<UserRole | UserType> = new Set(),
    shouldOverride: boolean = true
  ): boolean => {
    const allowedSetType = getAllowedSetType(allowedSet);

    let overriddenSet;

    if (shouldOverride) {
      const hasOverriddenRolesSet =
        overriddenRolesSet && [...overriddenRolesSet].length > 0;

      if (allowedSetType === 'role' && hasOverriddenRolesSet) {
        overriddenSet = overriddenRolesSet;
      }
      if (allowedSetType === 'userType' && overriddenUserType) {
        overriddenSet = new Set([overriddenUserType]);
      }
    }

    return hasPermissions(overriddenSet ?? permissionsSet, allowedSet);
  };

  return {
    rolePermissions,
    userTypePermissions,
    hasViewPermissions,
  };
};
