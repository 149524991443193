// TODO: Remove using this store (using for development)
import { fileSaver, getURLSearchParamsString } from 'helpers';
import { xapisEnv, xapisService } from '../service-wrappers/xapisService';
import { setKlogLevelHeader } from './utils';

let userKey = '';

export const setUserKey = (key: string) => {
  userKey = key;
};

export const getUserKey = () => userKey;

export const Xapis = {
  Glossary: {
    get: async (keys: string, limit: number = 0): Promise<GlossaryResponse> =>
      xapisService
        .get(`/Glossary/${keys || ''}?user_key=${userKey}&limit=${limit}`)
        .then((res) => ({ status: res.status, glossary: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
    delete: async (
      keys: string,
      hash: string
    ): Promise<{ status: number; data: string }> => {
      const key = Array.isArray(keys) ? keys.join('|') : keys;

      return xapisService
        .delete(
          `/Glossary/${key || ''}?user_key=${userKey}&source_text_hash=${hash}`
        )
        .then((res) => {
          return { status: res.status, data: res.data?.message };
        })
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        }));
    },
    post: async (
      keys: string | string[],
      payload: GlossaryAPIPayload | GlossaryAPIPayload[]
    ): Promise<{ status: number; data: string }> => {
      const key = Array.isArray(keys) ? keys.join('|') : keys;
      const data = Array.isArray(payload)
        ? payload.map((item) => ({ ...item, translation_source: userKey }))
        : [{ ...payload, translation_source: userKey }];
      return xapisService
        .post(`/Glossary/${key || ''}?user_key=${userKey}`, data)
        .then((res) => {
          return { status: res.status, data: res.data?.message };
        })
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        }));
    },
  },
  ContentPage: {
    get: async (
      tKey: string,
      limit: number = 0
    ): Promise<{ status: number; data: ContentPageAPIResponse | string }> =>
      xapisService
        .get(`/ContentPage/${tKey}?user_key=${userKey}&limit=${limit}`)
        .then((res) => ({ status: res.status, data: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        })),
  },
  User: {
    get: async (
      email: string
    ): Promise<{ status: number; data: User; message?: string }> =>
      xapisService
        .get(`/User/${email}`)
        .then((res) => ({ status: res.status, data: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: { user_key: '' },
          message: e.response?.data?.message || e.message,
        })),
    post: async (
      data: Partial<User>
    ): Promise<{ status: number; data: User }> =>
      xapisService
        .post(`/User?user_key=${userKey}`, data)
        .then((res) => ({
          status: res.status,
          data: res.data,
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: { user_key: '' },
          message: e.response?.data?.message || e.message,
        })),
    put: async (
      selectedUserKey: string,
      data: Partial<User>
    ): Promise<{ status: number; data: User }> =>
      xapisService
        .put(`/User/${selectedUserKey}?user_key=${userKey}`, data)
        .then((res) => ({
          status: res.status,
          data: res.data,
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: { user_key: '' },
          message: e.response?.data?.message || e.message,
        })),
    delete: async (
      selectedUserKey: string
    ): Promise<{ status: number; data: User }> =>
      xapisService
        .delete(`/User/${selectedUserKey}?user_key=${userKey}`)
        .then((res) => ({
          status: res.status,
          data: res.data,
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        })),
  },
  Project: {
    get: async (
      projectKey: string,
      isDetails?: boolean
    ): Promise<ProjectResponse> => {
      return xapisService
        .get(
          `/Project/${projectKey}?user_key=${userKey}&details=${isDetails ? 1 : 0}`
        )
        .then((res) => ({ status: res.status, project: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          project: undefined,
        }));
    },
    put: async (
      projectKey: string,
      data: Partial<ProjectKey>
    ): Promise<ProjectResponse> =>
      xapisService
        .put(`/Project/${projectKey}?user_key=${userKey}`, data)
        .then((res) => ({ status: res.status, project: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          project: undefined,
        })),
  },
  BlockSegmentData: {
    post: async (
      blocksData: BlockData[] | null,
      translationKey: string
    ): Promise<{ status: number; data: BlockSegment | string }> =>
      xapisService
        .post(`/BlockSegmentData/${translationKey}`, blocksData)
        .then((res) => ({
          status: res.status,
          data: res.data.blocks,
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        })),
  },
  LanguageCodes: {
    get: async (engine: 'google' | 'ty') =>
      xapisService
        .get(`LanguageCode/${engine}`)
        .then((res) => ({
          status: res.status,
          language_codes: res.data.language_codes || [],
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  ProjectTranslation: {
    get: async (projectKey: string, translationKey?: string) => {
      return xapisService
        .get(
          `ProjectTranslation/${projectKey}${translationKey ? '/' + translationKey : ''}?user_key=${userKey}`
        )
        .then((res) => ({
          status: res.status,
          data: res.data || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        }));
    },
    post: async (projectKey: string, data: Partial<TranslationKey>) =>
      xapisService
        .post(`ProjectTranslation/${projectKey}?user_key=${userKey}`, data)
        .then((res) => ({
          status: res.status,
          data: res.data || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
    put: async (
      projectKey: string,
      translationKey: string,
      data: Partial<TranslationKey>
    ): Promise<ProjectTranslationResponse> =>
      xapisService
        .put(
          `ProjectTranslation/${projectKey}/${translationKey}?user_key=${userKey}`,
          data
        )
        .then((res) => ({
          status: res.status,
          data: res.data || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  SpiderTree: {
    get: async (
      translationKey: string,
      contentUrlHash?: string
    ): Promise<SpiderTreeResponse> =>
      xapisService
        .get(
          `SpiderTree/${translationKey}${contentUrlHash ? `/${contentUrlHash}` : ''}?user_key=${userKey}`
        )
        .then(({ status = 0, data: { data = [] } = {} }) => {
          const tree = !contentUrlHash ? filterRootTree(data) : data;
          return {
            status,
            tree,
          };
        })
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  ConfigHistory: {
    get: async (translationKey: string, type: string) =>
      xapisService
        .get<{ revisions: RevisionItem[] }>(
          `ConfigHistory/${translationKey}/${type}?user_key=${userKey}&limit=0`
        )
        .then(({ status = 0, data: { revisions = [] } = {} }) => ({
          status,
          revisions,
        }))
        .catch((e) => ({
          revisions: [] as RevisionItem[],
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  Group: {
    post: async (groupData: GroupData) =>
      xapisService
        .post(`/Group?user_key=${userKey}`, groupData)
        .then((res) => ({
          status: res.status,
          data: res.data.users || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  GroupUser: {
    post: async (groupKey: string, addUserKeys: string[]) =>
      xapisService
        .post(
          `/GroupUser/${groupKey}/${addUserKeys.join(',')}?user_key=${userKey}`
        )
        .then((res) => ({
          status: res.status,
          data: res.data.users || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
    delete: async (groupKey: string, removeUserKeys: string[]) =>
      xapisService
        .delete(
          `/GroupUser/${groupKey}/${removeUserKeys.join(',')}?user_key=${userKey}`
        )
        .then((res) => ({
          status: res.status,
          data: res.data.users || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  Metrics: {
    get: async (query?: Record<string, string>) =>
      xapisService
        .get(`/Metrics?user_key=${userKey}&${getURLSearchParamsString(query)}`)
        .then((res) => ({
          status: res.status,
          data: res.data || [],
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          mesage: e.response?.data?.message || e.message,
          data: undefined,
        })),
  },
  SKU: {
    get: async ({
      payKey,
      xapisEnvStr,
      uKey,
      klogLevel,
    }: {
      payKey: string;
      xapisEnvStr?: string;
      uKey?: string;
      klogLevel?: string;
    }) => {
      if (xapisEnvStr) xapisService.defaults.baseURL = xapisEnvStr;
      return xapisService
        .get(`Pay/SKU/${payKey}?user_key=${uKey ?? userKey}`, {
          headers: {
            ...setKlogLevelHeader(klogLevel),
          },
        })
        .then((res) => {
          return {
            status: res.status,
            data: res.data || [],
          };
        })
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          data: undefined,
        }))
        .finally(() => {
          if (xapisEnvStr)
            xapisService.defaults.baseURL = xapisEnv.getHost.xapisUrl;
        });
    },
  },
  Checkout: {
    post: async (
      payKey: string,
      projectKey: string,
      data: { items: { sku: string; quantity: number }[] },
      xapisEnvStr?: string,
      klogLevel?: string
    ) => {
      if (xapisEnvStr) xapisService.defaults.baseURL = xapisEnvStr;
      return xapisService
        .post(
          `Pay/Checkout/${payKey}/${projectKey}?user_key=${userKey}`,
          data,
          {
            headers: {
              ...setKlogLevelHeader(klogLevel),
            },
          }
        )
        .then((res) => ({
          status: res.status,
          data: res.data || [],
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          data: undefined,
        }))
        .finally(() => {
          if (xapisEnvStr)
            xapisService.defaults.baseURL = xapisEnv.getHost.xapisUrl;
        });
    },
  },
  ProjectUser: {
    delete: async ({
      projectKey,
      removedUserKey,
    }: {
      projectKey: string;
      removedUserKey: string;
    }) =>
      xapisService
        .delete(
          `/ProjectUser/${projectKey}/${removedUserKey}?user_key=${userKey}`
        )
        .then((res) => ({
          status: res.status,
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  Subscription: {
    get: async (payKey: string, projectKey: string, getHistory = false) => {
      return xapisService
        .get(
          `Pay/Subscription${getHistory ? 'History' : ''}/${payKey}/${projectKey}`
        )
        .then((res) => ({
          status: res.status,
          data: res.data || [],
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          data: undefined,
        }));
    },
    delete: async ({
      payKey,
      projectKey,
      uKey,
      klogLevel,
      xapisEnvStr,
    }: {
      payKey: string;
      projectKey: string;
      uKey: string;
      klogLevel?: string;
      xapisEnvStr?: string;
    }) => {
      if (xapisEnvStr) xapisService.defaults.baseURL = xapisEnvStr;
      return xapisService
        .delete(`Pay/Subscription/${payKey}/${projectKey}?user_key=${uKey}`, {
          headers: {
            ...setKlogLevelHeader(klogLevel),
          },
        })
        .then((res) => ({
          status: res.status,
          data: res.data || [],
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          data: undefined,
        }))
        .finally(() => {
          if (xapisEnvStr)
            xapisService.defaults.baseURL = xapisEnv.getHost.xapisUrl;
        });
    },
    patch: async ({
      payKey,
      dateOverride,
      uKey,
      projectKey,
      klogLevel,
      xapisEnvStr,
    }: {
      payKey: string;
      dateOverride: string;
      uKey: string;
      projectKey: string;
      klogLevel?: string;
      xapisEnvStr?: string;
    }) => {
      if (xapisEnvStr) xapisService.defaults.baseURL = xapisEnvStr;
      return xapisService
        .patch(
          `Pay/Subscription/${payKey}/${projectKey}?date_override=${dateOverride}&user_key=${uKey}`,
          {},
          {
            headers: {
              ...setKlogLevelHeader(klogLevel),
            },
          }
        )
        .then((res) => ({
          status: res.status,
          data: res.data || [],
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          data: undefined,
        }))
        .finally(() => {
          if (xapisEnvStr)
            xapisService.defaults.baseURL = xapisEnv.getHost.xapisUrl;
        });
    },
    put: async (payKey: string, projectKey: string, sku: string) => {
      return xapisService
        .put(`Pay/Subscription/${payKey}/${projectKey}/${sku}`, {
          is_canceled: false,
        })
        .then((res) => ({
          status: res.status,
          data: res.data || [],
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          data: undefined,
        }));
    },
  },
  Batches: {
    get: async ({
      projectKey,
      batchFetchType,
    }: {
      projectKey: string;
      batchFetchType: 'cards' | 'history' | 'active';
    }) =>
      xapisService
        .get(
          `Batch/${projectKey}?user_key=${userKey}&${batchFetchType}=1&limit=0`
        )
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
    patch: async ({
      translationKey,
      batchKeyList,
      params,
    }: {
      translationKey: string;
      batchKeyList: string;
      params: Record<string, string>;
    }) =>
      xapisService
        .patch(
          `Batch/${translationKey}/${batchKeyList}?user_key=${userKey}&${getURLSearchParamsString(params)}`
        )
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: e.response?.data,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  BatchDetails: {
    get: async ({
      translationKey,
      batchKey,
    }: {
      translationKey: string;
      batchKey: string;
    }) =>
      xapisService
        .get(`Batch/${translationKey}/${batchKey}?user_key=${userKey}&detail=1`)
        .then(({ status, data }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  Orders: {
    get: async (payKey: string, { order_id }: { order_id: string }) =>
      xapisService
        .get(`Pay/Order/${payKey}?order_id=${order_id}`)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  CRM: {
    Hubspot: {
      get: async (email: string) => {
        return xapisService
          .get(`CRM/objects/contacts/${email}?idProperty=email`)
          .then(({ status = 0, data = {} }) => ({
            data,
            status,
          }))
          .catch((e) => ({
            data: undefined,
            status: e.response?.status || 500,
            message: e.response?.data?.message || e.message,
          }));
      },
      patch: async (contactId: string, data: HubspotContact) => {
        return xapisService
          .patch(`CRM/objects/contacts/${contactId}`, data, {})
          .then(({ status = 0, data = {} }) => ({
            data,
            status,
          }))
          .catch((e) => ({
            data: undefined,
            status: e.response?.status || 500,
            message: e.response?.data?.message || e.message,
          }));
      },
      post: async (data: HubspotContact) => {
        return xapisService
          .post('CRM/objects/contacts', data, {})
          .then(({ status = 0, data = {} }) => ({
            data,
            status,
          }))
          .catch((e) => ({
            data: undefined,
            status: e.response?.status || 500,
            message: e.response?.data?.message || e.message,
          }));
      },
    },
  },
  Invoice: {
    get: async (payKey: string, projectKey: string, invoiceFileId = '') => {
      return xapisService
        .get(
          `Pay/Invoice/${payKey}/${projectKey}${invoiceFileId ? `/${invoiceFileId}` : ''}?user_key=${userKey}`,
          {
            ...(invoiceFileId && {
              responseType: 'blob',
            }),
          }
        )
        .then(({ headers = {}, status = 0, data = {} }) => {
          if (invoiceFileId && data) {
            // Default fileName, will instead use the fileName generated from XPay if it exists (ex. 'vat-invoice5960978751047971851.pdf')
            const fileName = 'Invoice.pdf';
            const blob = new Blob([data], { type: 'application/pdf' });
            fileSaver(fileName, headers as Record<string, string>, blob);
          }
          return {
            data,
            status,
          };
        })
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
  },
  Source: {
    get: async (payKey: string) => {
      return xapisService
        .get(`Pay/Source/${payKey}/${userKey}`)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
    patch: async (payKey: string, sourceId: string) => {
      return xapisService
        .patch(`Pay/Source/${payKey}/${userKey}/${sourceId}`)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
    post: async (payKey: string, sourceId: string) => {
      return xapisService
        .post(`Pay/Source/${payKey}/${userKey}/${sourceId}`)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
    delete: async (payKey: string, sourceId: string) => {
      return xapisService
        .delete(`Pay/Source/${payKey}/${userKey}/${sourceId}`)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
  },
  QuickQuote: {
    get: async (domain: string) => {
      return xapisService
        .get(`QuickQuote/${domain}`)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
    post: async (params: {
      email: string;
      start_url: string;
      pay_key: string;
      max_spider_minutes: string;
    }) => {
      return xapisService
        .post(`QuickQuote?${getURLSearchParamsString(params)}`)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
  },
  SpiderJob: {
    post: async (
      tKey: string,
      body: {
        job_name: string;
        user_key: string;
        debug_level: number;
        status: string;
        seo_only: string;
        max_spider_minutes: number;
      }
    ) => {
      return xapisService
        .post(`SpiderJob/${tKey}`, body)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
  },
};

interface DefaultResponse {
  status: number;
  message?: string;
}

export interface GlossaryResponse extends DefaultResponse {
  glossary?: GlossaryAPIResponse;
}
export interface ContentPageResponse extends DefaultResponse {
  data?: ContentPageAPIResponse;
}

export interface ProjectResponse extends DefaultResponse {
  project: ProjectKey | undefined;
}

export interface LanguageCodeResponse extends DefaultResponse {
  language_codes?: LanguageCode[];
}

export interface ProjectTranslationResponse extends DefaultResponse {
  data?: TranslationKey;
}

export interface SpiderTreeResponse extends DefaultResponse {
  tree?: SpiderTreeNode[];
}

export interface ConfigHistoryResponse extends DefaultResponse {
  revisions: RevisionItem[];
}

// filter fn running on initial tree render
// maps over root node
const filterRootTree = (tree: SpiderTreeNode[]) =>
  tree.map(
    (node, index) =>
      ({
        ...node,
        id: `${index}`,
        stringKey: `${index}`,
        name: node.label,
        children_fetched: false,
      }) as SpiderTreeNode
  );

export type HubspotContact = {
  id?: string;
  properties?: {
    email?: string;
    firstname?: string;
    lastname?: string;
    website?: string;
    phone?: string;
    estimated_words_served?: number;
    recommended_plan?: string;
    hs_all_assigned_business_unit_ids?: number;
    glgo_account_create_date?: number;
    hs_marketable_status?: boolean;
    hs_marketable_reason_type?: string;
    subscribed_to_marketing?: boolean;
  };
  createdAt?: string;
  updatedAt?: string;
  archived?: boolean;
};

export const defaultProjectKey: ProjectKey = {
  additional_origins: [] as string[],
  base_domain: '',
  disable_ope: true,
  deployment_method: '',
  deployment_name: '',
  is_active: true,
  is_frozen: true,
  is_glnow_enabled: true,
  is_onelinktoken_validated: true,
  is_pci: true,
  is_translation_disabled: false,
  is_referer_validated: false,
  created_user: '',
  created_utc: '',
  groups: [] as Group[], // Included with details=1
  lastmod_user: '',
  lastmod_utc: '',
  origin_name: '',
  project_config: '',
  project_config_hash: '',
  project_dbname: '',
  project_key: '',
  project_name: '',
  project_status: '',
  project_type: '',
  quote_ticket: '',
  root_domain: '',
  skeleton_version: 0,
  subscription_pay_key: '',
  subscription_sku: '',
  subscription_type: '',
  subscription_status: 'active',
  subscription_value: 0,
  threshold_type: '',
  threshold_value: 0,
  translations: [] as TranslationKey[],
  yy_translation_key: '',
};

export const defaultSubscription = {
  anniversary_day: 0,
  anniversary_day_nice: '',
  anniversary_month: 0,
  anniversary_month_abbrev: '',
  anniversary_month_name: '',
  bill_to_email: '',
  bill_to_name: '',
  billing_agreement_key: '',
  created_user: '',
  created_utc: '',
  currency_code: '',
  days_from_renewal_notice: 0,
  dr_contact: '',
  dr_cookie_policy: '',
  dr_privacy_policy: '',
  dr_selling_entity_id: '',
  words_served: '0',
  dr_selling_entity_name: '',
  dr_terms_conditions: '',
  invoice_file_id: '',
  is_canceled: false,
  last_order_utc: '',
  lastmod_user: '',
  lastmod_utc: '',
  next_month_abbrev: '',
  next_month_full: '',
  next_renewal_date: '',
  pay_status: '',
  payment_info: '',
  payment_source_id: '',
  price: 0,
  project_key: '',
  purchase_lang_code: '',
  sku: '',
  sku_description: '',
  sku_name: '',
  subscription_type: '',
  subscription_value: 0,
  threshold_type: '',
  threshold_value: 0,
};
