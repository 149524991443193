import { Xapis, xapisEnv } from '@glweb/xapis-client';
import { Card, Container, Flex, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { isSuccessStatus } from 'helpers';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment/moment';
import { useState } from 'react';
import { MdCloudDownload } from 'react-icons/md';
import { useNavigation } from 'react-router-dom';
import {
  CustomTable,
  failureNotification,
  LoadingSkeleton,
  NoDataFoundPlaceHolder,
  useCurrentProjectData,
} from 'ui';
import { getBasicColumn } from '../utils';

const InvoiceDownloadIcon = ({
  invoiceFileId = '',
}: {
  invoiceFileId: string;
}) => {
  const { project } = useCurrentProjectData();
  const project_key = project?.project_key || '';
  const payKey = xapisEnv.getHost.payKey;

  const hasGeneratedInvoiceFile = invoiceFileId.length > 0;

  const downloadInvoice = async () => {
    const res = await Xapis.Invoice.get(payKey, project_key, invoiceFileId);

    if (!isSuccessStatus(res.status)) {
      failureNotification('Something went wrong with downloading the invoice.');
    }
  };

  return hasGeneratedInvoiceFile ? (
    <Flex style={{ cursor: 'pointer' }} onClick={downloadInvoice}>
      <MdCloudDownload fontSize={22} color="var(--mantine-color-font1-1)" />
    </Flex>
  ) : (
    <Flex justify="center">Pending...</Flex>
  );
};

type Props = {
  billingHistory: BillingHistory[];
};

const BillingHistory = ({ billingHistory }: Props) => {
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const { state } = useNavigation();
  const loading = state !== 'idle';

  const [isMobileTableExpanded, setIsMobileTableExpanded] = useState(false);

  const columns: MRT_ColumnDef<BillingHistory>[] = [
    {
      ...getBasicColumn('updatedTime', 'date issued'),
      Cell: ({ cell }) => {
        const dateIssued =
          moment(cell.getValue() as string).format('MM/DD/YYYY') || '';
        return <Text>{dateIssued}</Text>;
      },
    },
    {
      ...getBasicColumn('items.0.productDetails.name', 'sku name'),
    },
    {
      ...getBasicColumn('id', 'invoice id'),
      Cell: ({ cell }) => {
        const invoiceId = cell.getValue() as string;
        const hiddenId = invoiceId.substring(0, 5) + 'XXXXX' || invoiceId || '';
        return <Text>#{hiddenId}</Text>;
      },
    },
    {
      ...getBasicColumn('capturedAmount', 'amount (USD)'),
      Cell: ({ cell }) => {
        return `$${(cell.getValue() as number).toFixed(2)}`;
      },
    },
    {
      ...getBasicColumn('invoiceFiles.0.id', 'invoice'),
      Cell: ({ cell }) => (
        <InvoiceDownloadIcon invoiceFileId={cell.getValue() as string} />
      ),
      enableSorting: false,
      size: 115,
      grow: false,
    },
  ];

  const mobileColumns: MRT_ColumnDef<BillingHistory>[] = [
    {
      ...getBasicColumn('updatedTime', 'date issued'),
      Cell: ({ cell }) => {
        const dateIssued =
          moment(cell.getValue() as string).format('MM/DD/YYYY') || '';
        return <Text>{dateIssued}</Text>;
      },
    },
    {
      ...getBasicColumn('id', 'invoice id'),
      Cell: ({ cell }) => {
        const invoiceId = cell.getValue() as string;
        const hiddenId = invoiceId.substring(0, 5) + 'XXXXX' || invoiceId || '';
        return <Text>#{hiddenId}</Text>;
      },
    },
    {
      ...getBasicColumn('invoiceFiles.0.id', 'invoice'),
      Cell: ({ cell }) => (
        <InvoiceDownloadIcon invoiceFileId={cell.getValue() as string} />
      ),
      Header: () => (
        <Flex
          w="100%"
          justify="center"
          style={{ cursor: 'pointer' }}
          onClick={() => setIsMobileTableExpanded(true)}
        >
          <Text fw={700} fz="1.5rem">
            ...
          </Text>
        </Flex>
      ),
      enableSorting: false,
      size: 95,
      grow: false,
    },
  ];

  return (
    <Container m={0} my={20} p={0} maw="100%">
      <Card p={loading ? 23 : 0} radius={0}>
        {loading ? (
          <LoadingSkeleton height={630} />
        ) : billingHistory.length === 0 ? (
          <NoDataFoundPlaceHolder height={676} />
        ) : (
          <CustomTable
            data={billingHistory}
            columns={
              isMobileSize && !isMobileTableExpanded ? mobileColumns : columns
            }
            bottomToolbarString={
              billingHistory.length === 1 ? 'Invoice' : 'Invoices'
            }
          />
        )}
      </Card>
    </Container>
  );
};

export default BillingHistory;
