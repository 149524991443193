import { LIVE } from '@glweb/constants';
import { type HubspotContact, Xapis, xapisEnv } from '@glweb/xapis-client';

const createContact = async (
  data: HubspotContact
): Promise<Record<string, string | number>> => {
  const response = await Xapis.CRM.Hubspot.post(data);
  const createdId = response.data.id || '';
  const status = response.data.status || '';
  const category = response.data.category || '';

  return { createdId, status, category };
};

const getContactId = async (email: string): Promise<string> => {
  const response = await Xapis.CRM.Hubspot.get(email);
  return response.data.id || '';
};

export const handleHubSpotContact = async (
  data: HubspotContact,
  email = ''
) => {
  const { label = '' } = xapisEnv.getHost;
  const isLiveEnv = label === LIVE;

  if (!isLiveEnv) return;

  try {
    const id = await getContactId(email);
    if (id) {
      return await Xapis.CRM.Hubspot.patch(id, data);
    } else {
      return await createContact(data);
    }
  } catch (error) {
    console.error('ERROR:', error);
    return Promise.reject({});
  }
};

export default handleHubSpotContact;
