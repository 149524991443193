import {
  Box,
  Button,
  Flex,
  Menu,
  Paper,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { isSuccessStatus } from 'helpers';
import React, { useCallback, useMemo, useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { MdCheckBox } from 'react-icons/md';
import { useNavigation } from 'react-router-dom';
import { Xapis, xapisEnv } from '@glweb/xapis-client';
import { PaymentSourceLogo } from './PaymentSourceLogo';
import { sortSourcesByDefault } from './utils';

const PaymentMethodLogo = ({
  paymentSource,
  defaultSourceId,
}: {
  paymentSource: PaymentSource;
  defaultSourceId: string;
}) => {
  const colors = useMantineTheme().colors;
  const { id: paymentSourceId = '' } = paymentSource || {};

  const isDefaultSource = defaultSourceId === paymentSourceId;

  return (
    <ThemeIcon
      h="2.5rem"
      style={{
        color: isDefaultSource ? colors.badge[4] : colors.background[5],
        borderColor: isDefaultSource ? colors.badge[4] : colors.background[5],
      }}
      variant="outline"
      w="4rem"
    >
      <PaymentSourceLogo {...paymentSource} />
    </ThemeIcon>
  );
};

const PaymentMethodText = (paymentSource: PaymentSource) => {
  const { type = '', owner } = paymentSource || {};

  // Keep wrapped in useMemo to avoid constant re-rendering
  const paymentSourceInfo = useMemo(
    () => paymentSource[type as keyof PaymentSource] || {},
    [type, paymentSource]
  );

  const topText = useMemo(() => {
    switch (type) {
      case 'creditCard': {
        const { brand = '', lastFourDigits = '????' } =
          (paymentSourceInfo as CreditCard) || {};
        return `${brand} ending in ${lastFourDigits}`;
      }
      case 'payPalBilling':
        return 'Paypal';
      default:
        return type;
    }
  }, [type, paymentSourceInfo]);

  const bottomText = useMemo(() => {
    switch (type) {
      case 'creditCard': {
        const { expirationMonth = 0, expirationYear = 0 } =
          (paymentSourceInfo as CreditCard) || {};

        const expirationString = `${expirationMonth < 10 ? '0' : ''}${expirationMonth}/${expirationYear}`;

        return `Expiry ${expirationString}`;
      }
      case 'payPalBilling': {
        const { email = '' } = owner || {};
        return email;
      }
      default:
        return type;
    }
  }, [type, paymentSourceInfo, owner]);

  return (
    <Flex ml="1rem" direction="column">
      <Text fw={600}>{topText}</Text>
      <Text>{bottomText}</Text>
    </Flex>
  );
};

const PaymentMethodRender = ({
  paymentSource,
  defaultSourceId,
}: {
  paymentSource: PaymentSource;
  defaultSourceId: string;
}) => {
  return (
    <Flex align="center">
      <PaymentMethodLogo
        paymentSource={paymentSource}
        defaultSourceId={defaultSourceId}
      />
      <PaymentMethodText {...paymentSource} />
    </Flex>
  );
};

type Props = {
  cards: PaymentSource[];
  setCards: React.Dispatch<React.SetStateAction<PaymentSource[]>>;
  defaultSourceId: string;
  setDefaultSourceId: React.Dispatch<React.SetStateAction<string>>;
};

export const PaymentMethods = ({
  cards,
  setCards,
  defaultSourceId,
  setDefaultSourceId,
}: Props) => {
  const colors = useMantineTheme().colors;
  const { payKey } = xapisEnv.getHost;

  const { state } = useNavigation();
  const loadersRunning = state !== 'idle';
  const [cardsLoading, setCardsLoading] = useState(false);

  const handleChangeDefault = useCallback(
    (cardId: string) => {
      setCardsLoading(true);
      Xapis.Source.patch(payKey, cardId)
        .then(({ data }) => {
          const sortedCards = sortSourcesByDefault(
            data.sources,
            data.defaultSourceId
          );
          setCards(sortedCards);
          setDefaultSourceId(data.defaultSourceId);
          notifications.show({
            message: 'Successfully changed your default card!',
          });
        })
        .catch((e) => {
          console.error(e);
          notifications.show({
            message: 'Something went wrong with changing your default card.',
          });
        })
        .finally(() => setCardsLoading(false));
    },
    [payKey, setCards, setDefaultSourceId]
  );

  const handleDeleteSource = useCallback(
    (cardId: string) => {
      setCardsLoading(true);
      Xapis.Source.delete(payKey, cardId)
        .then((res) => {
          if (isSuccessStatus(res.status)) {
            const updatedSources = cards.filter((c) => c.id !== cardId);
            setCards(updatedSources);
            notifications.show({
              message: 'Successfully deleted your payment method.',
            });
          } else {
            throw Error('Whoops, an error occurred');
          }
        })
        .catch((e) => {
          console.error(e);
          const isSourceInUse = e.response.status === 409;
          notifications.show({
            message: isSourceInUse
              ? 'This payment method is in use and cannot be deleted.'
              : 'Something went wrong with deleting your payment method.',
          });
        })
        .finally(() => setCardsLoading(false));
    },
    [payKey, cards, setCards]
  );

  if (cards.length > 0) {
    return (
      <Box opacity={cardsLoading || loadersRunning ? 0.2 : 1}>
        {cards.map((source: PaymentSource) => {
          const { id: sourceId = '' } = source;
          const isDefaultSource = defaultSourceId === sourceId;
          return (
            <Paper
              key={sourceId}
              radius="md"
              mb="0.75rem"
              mt="0.5rem"
              p="1rem 1.25rem"
              w="100%"
              display="flex"
              shadow="none"
              style={{
                backgroundColor: isDefaultSource
                  ? colors.background[8]
                  : colors.background[0],
                border: `2px solid ${isDefaultSource ? colors.border[3] : colors.border[9]}`,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <PaymentMethodRender
                paymentSource={source}
                defaultSourceId={defaultSourceId}
              />
              {isDefaultSource ? (
                <MdCheckBox size="1.5rem" fill={colors.background2[1]} />
              ) : (
                <div
                  style={{ position: 'relative', textAlign: 'end', width: 64 }}
                >
                  <Menu position="bottom-end" width={130}>
                    <Menu.Target>
                      <Button px={0} variant="transparent">
                        <HiDotsHorizontal size="1.5rem" />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item onClick={() => handleChangeDefault(sourceId)}>
                        Make Default
                      </Menu.Item>
                      <Menu.Item onClick={() => handleDeleteSource(sourceId)}>
                        Delete
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              )}
            </Paper>
          );
        })}
      </Box>
    );
  }

  return <p>No sources found.</p>;
};

export default PaymentMethods;
