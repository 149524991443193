import { Flex } from '@mantine/core';
import { NoDataFoundIcon } from 'images';

const NoDataFoundPlaceHolder = ({
  height = 0,
}: {
  height?: number | string;
}) => (
  <Flex
    h={height || 'inherit'}
    flex={height ? 0 : 1}
    align="center"
    justify="center"
    data-testid="no-data-icon"
  >
    <NoDataFoundIcon name="no-data" />
  </Flex>
);

export default NoDataFoundPlaceHolder;
