import { decodeProjectId } from 'helpers';
import { Params } from 'react-router-dom';
import { defaultSubscription, Xapis, xapisEnv } from '@glweb/xapis-client';

export type DowngradeConfirmationLoaderData = {
  status: number;
  project?: ProjectKey | undefined;
  message?: string | undefined;
  activeSubscription: Subscription;
};

async function downgradeConfirmationLoader({
  params,
}: {
  params: Params<string>;
  request: Request;
}) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const projectKey = decodeProjectId(projectId);
  const response = await Xapis.Project.get(projectKey);

  const data = {
    activeSubscription: defaultSubscription,
    ...response,
  };

  const { payKey } = xapisEnv.getHost;
  try {
    const subscriptionsRes = await Xapis.Subscription.get(payKey, projectKey);
    const { subscriptions: subs } = subscriptionsRes.data;

    data.activeSubscription = subs[0];
  } catch (err) {
    console.error(
      'error in Xapis.Subscription.get in downgradeConfirmationLoader:',
      err
    );
  }

  return data;
}

export default downgradeConfirmationLoader;
