import { Menu, UnstyledButton } from '@mantine/core';
import React from 'react';
import { BsIntersect } from 'react-icons/bs';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';

import { useCurrentProjectData } from 'ui';
import handleAddRule from '../utils/handleAddRule';
import handleRemoveRule from '../utils/handleRemoveRule';

type Props = {
  node: SpiderTreeNode;
  isIncluded: boolean;
  isDisabledChildNode: boolean;
  currentIncludeRules: string[];
  isSourceOfChildrenOnlyRule: boolean;
  yyTarget: TranslationKey | undefined;
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>;
  spiderTree: SpiderTreeNode[];
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};

type Inclusion = 'self' | 'parentAndChildren' | 'childrenOnly';

export const IncludeActionsMenu = ({
  node,
  isIncluded,
  isDisabledChildNode,
  currentIncludeRules,
  isSourceOfChildrenOnlyRule,
  yyTarget,
  setYYTarget,
  spiderTree,
  setSpiderTree,
}: Props) => {
  const { project } = useCurrentProjectData();

  const { id, has_children: hasChildren, is_fake: isFake } = node;
  const handleInclude = (type: Inclusion) => {
    handleAddRule(
      type,
      node,
      'include',
      project,
      yyTarget,
      setYYTarget,
      spiderTree,
      setSpiderTree
    );
  };

  const handleRemove = () => {
    handleRemoveRule(
      node,
      'include',
      currentIncludeRules,
      project,
      yyTarget,
      setYYTarget
    );
  };

  if ((spiderTree.length === 1 && id === '0') || isDisabledChildNode)
    return <></>;

  return (
    <div
      style={{ position: 'relative', textAlign: 'end', width: 64 }}
      onClick={(e) => e.stopPropagation()}
    >
      <Menu position="bottom-end" width={150}>
        <Menu.Target>
          <UnstyledButton
            mr="3.5rem"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {isIncluded || isSourceOfChildrenOnlyRule ? (
              <BsIntersect fill="#5D6B89" style={{ marginLeft: '0.2rem' }} />
            ) : (
              <HiOutlineDotsHorizontal color="#006CD1" size={22} />
            )}
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          {isIncluded || isSourceOfChildrenOnlyRule ? (
            <Menu.Item onClick={() => handleRemove()}>
              Undo{' '}
              {isSourceOfChildrenOnlyRule ? 'Include Children' : 'Inclusion'}
            </Menu.Item>
          ) : hasChildren ? (
            <>
              <Menu.Item
                disabled={isFake}
                onClick={() => handleInclude('parentAndChildren')}
              >
                Parent & Children
              </Menu.Item>
              <Menu.Item
                disabled={isFake}
                onClick={() => handleInclude('self')}
              >
                Parent Only
              </Menu.Item>
              <Menu.Item onClick={() => handleInclude('childrenOnly')}>
                Children Only
              </Menu.Item>
            </>
          ) : (
            <Menu.Item onClick={() => handleInclude('self')}>
              Include Page
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default IncludeActionsMenu;
