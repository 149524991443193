import { ZapierFields } from '@glweb/constants';

export const sendZapierData = async (
  url: string,
  data: ZapierFields & {
    fq_hidden_url_c: string;
    first_name: string;
    last_name: string;
    email: string;
    fq_email_sub_c: boolean;
    ga_key_c: string;
    ga_landing_c: string;
    utm_campaign_c: string;
    utm_content_c: string;
    utm_medium_c: string;
    utm_source_c: string;
  }
) => {
  try {
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      throw new Error('POST failed, status: ' + res.status);
    }
    // eslint-disable-next-line no-console
    console.log('Success response from Zapier', res);
  } catch (error) {
    // fail silently, do not throw
    console.error('Error sending data to Zapier:', error);
  }
};
