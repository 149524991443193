import { useState, useContext } from 'react';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';

import { Box, Popover } from '@mantine/core';
import { Insertable, InsertElement } from './InsertElement';
import QuickAction from '../Generic/QuickAction';
import { MoreIcon } from '../../icons/ActionIcons';
import classes from './InsertMore.module.css';
type Props = {
  addElement?: (insert: Insertable) => void;
};
export const InsertMore = ({ addElement }: Props) => {
  const [showMore, setShowMore] = useState(false);
  const { viewOnly } = useContext(SegmentEditorContext).settings;

  return (
    <Popover
      withinPortal
      opened={showMore}
      onChange={setShowMore}
      position="bottom-end"
      arrowSize={0}
      // offset={10}
      shadow="md"
      classNames={{ dropdown: classes.dropdown }}
    >
      <Popover.Target>
        <Box>
          <QuickAction
            icon={<MoreIcon />}
            isActive={showMore}
            isDisabled={viewOnly || false}
            onClick={() => setShowMore((o) => !o)}
          />
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <InsertElement addElement={addElement} onClose={setShowMore} />
      </Popover.Dropdown>
    </Popover>
  );
};
