import { useSearchParams } from 'react-router-dom';
import { MultiSelector } from './MultiSelector';
import { encodeKey } from 'helpers';
import { FloatingPosition } from '@mantine/core';

type Props = {
  options: { label: string; value: string }[];
  queryKey?: string;
  encodeKeys?: boolean;
  selectAll?: boolean;
  position?: FloatingPosition | undefined;
  shouldRevalidate?: boolean;
};
const QueryMultiSelector = ({
  options,
  queryKey = 't',
  encodeKeys = false,
  selectAll = false,
  position,
  shouldRevalidate = false,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const encodedOptions = options.map((o) =>
    encodeKeys ? { label: o.label, value: encodeKey(o.value) } : { ...o }
  );
  const selected = searchParams.getAll(queryKey) || [];

  const isAllSelected = selected.length === 0;
  const areNoneSelected = selected.length === 1 && selected.includes('0');

  const handleTargetSelection = (value: string) => {
    let newSelection = selected.filter((v) => v);

    if (value === 'RESET') {
      // Reset to default
      searchParams.delete('t');
    } else if (value === 'All') {
      // Toggle All ('' is used to select none)
      isAllSelected ? searchParams.set('t', '0') : searchParams.delete('t');
    } else {
      if (isAllSelected) {
        // Remove selected from All
        newSelection = encodedOptions
          .map((o) => o.value)
          .filter((v) => v !== value);
        newSelection.forEach((value) => searchParams.append('t', value));
      } else if (selected.includes(value)) {
        // Remove selected from current selection
        newSelection = newSelection.filter((t) => t !== value);
        // If none are selected, set to '' (none)
        newSelection.length === 0 && selectAll
          ? searchParams.set('t', '0')
          : searchParams.delete('t', value);
      } else {
        // Add to current selection
        if (areNoneSelected) searchParams.delete('t', '0');
        newSelection.push(value);
        // If all are selected, set to 'All'
        newSelection.length === encodedOptions.length
          ? searchParams.delete('t')
          : searchParams.append('t', value);
        if (newSelection.length === encodedOptions.length)
          newSelection = ['All'];
      }
    }
    setSearchParams(searchParams, { state: { revalidate: shouldRevalidate } });
  };

  const selectedValues = isAllSelected
    ? encodedOptions.map((o) => o.value)
    : selected;

  return (
    <MultiSelector
      title="Languages"
      selectAll={selectAll}
      position={position}
      options={encodedOptions}
      selected={selectedValues}
      onSelect={handleTargetSelection}
    />
  );
};

export default QueryMultiSelector;
