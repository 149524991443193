import { NOOP, UserRole } from 'helpers';
import { createContext, useContext, useState } from 'react';

export type Overrides = {
  user_type: UserType | '';
  rolePermissionSet: Set<UserRole>;
};

interface PermissionsOverrideContext {
  overrides: Overrides;
  setOverrides: React.Dispatch<React.SetStateAction<Overrides>>;
}

export const PermissionsOverrideContext =
  createContext<PermissionsOverrideContext>({
    overrides: {
      user_type: '',
      rolePermissionSet: new Set(),
    },
    setOverrides: NOOP,
  });

export const usePermissionsOverrideContext = () => {
  const context = useContext(PermissionsOverrideContext);
  if (!context) {
    throw new Error(
      'usePermissionsOverrideProvider must be used within PermissionsOverrideProvider'
    );
  }
  return context;
};

export function PermissionsOverrideProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [overrides, setOverrides] = useState<Overrides>({
    user_type: '',
    rolePermissionSet: new Set(),
  });

  return (
    <PermissionsOverrideContext.Provider value={{ overrides, setOverrides }}>
      {children}
    </PermissionsOverrideContext.Provider>
  );
}
