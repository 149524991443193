import { getUserKey, Xapis } from '@glweb/xapis-client';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { encodeKey, isSuccessStatus } from 'helpers';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useGLGOParams } from 'ui';
import {
  ActiveBatches,
  DetailedBatch,
  HistoryBatches,
} from '../loaders/batchesLoader';

type BatchFilters =
  | 'q'
  | 'batch_name'
  | 'phase_status'
  | 'phase'
  | 'assigned_user'
  | 'my_batches'
  | 't_keys';

const filterBatch = (
  batch: ActiveBatch | HistoryBatch,
  filter: Record<BatchFilters, string> | undefined
) => {
  if (!filter) {
    return true;
  }

  const isMyBatches = filter.my_batches === 'true';

  const phaseIsComplete = 'phase' in batch && batch.phase === 'complete';

  const batchNameDoesNotMatch =
    filter.batch_name?.length > 0 &&
    (!batch.batch_name || !filter.batch_name.includes(batch.batch_name));

  const phaseDoesNotMatch =
    filter.phase?.length > 0 &&
    'phase' in batch &&
    (!batch.phase || !filter.phase.includes(batch.phase));

  const phaseStatusDoesNotMatch =
    filter.phase_status?.length > 0 &&
    (!batch.phase_status || !filter.phase_status.includes(batch.phase_status));

  const assignedUserDoesNotMatch =
    filter.assigned_user?.length > 0 &&
    !isMyBatches &&
    (!batch.assigned_user ||
      !filter.assigned_user.includes(batch.assigned_user));

  const notMyBatch =
    isMyBatches &&
    (!batch.assigned_user || getUserKey() !== batch.assigned_user);

  const queryStringNotFound =
    filter.q?.length > 0 &&
    !Object.entries(batch).some((entry) =>
      entry.toString().toLowerCase().includes(filter.q.toLowerCase())
    );

  const notInSelectedLanguages =
    filter.t_keys?.length > 0 &&
    !filter.t_keys.includes(encodeKey(batch.translation_key));

  switch (true) {
    case phaseIsComplete:
    case batchNameDoesNotMatch:
    case phaseDoesNotMatch:
    case phaseStatusDoesNotMatch:
    case assignedUserDoesNotMatch:
    case notMyBatch:
    case queryStringNotFound:
    case notInSelectedLanguages:
      return false;
    default:
      return true;
  }
};

export const activeBatchesQuery = (
  projectKey: string,
  filter?: Record<BatchFilters, string>
) => {
  const filterBatches = (batches: ActiveBatch[]) =>
    batches
      .filter((batch) => filterBatch(batch, filter))
      .sort(
        (a, b) =>
          (moment(b.created_utc) as unknown as number) -
          (moment(a.created_utc) as unknown as number)
      );

  return queryOptions({
    queryKey: ['allBatches', projectKey],
    queryFn: () => getActiveBatchesQueryData(projectKey),
    select: (data) => ({ ...data, batches: filterBatches(data.batches) }),
  });
};

export const batchHistoryQuery = (
  projectKey: string,
  filter?: Record<BatchFilters, string> | undefined
) => {
  const filterBatches = (batches: HistoryBatch[]) =>
    batches
      .filter((batch) => filterBatch(batch, filter))
      .sort(
        (a, b) =>
          (moment(b.created_utc) as unknown as number) -
          (moment(a.created_utc) as unknown as number)
      );

  return queryOptions({
    queryKey: ['batchHistory', projectKey],
    queryFn: () => getBatchHistoryQueryData(projectKey),
    select: (data) => ({ ...data, batches: filterBatches(data.batches) }),
  });
};

export const batchDetailsQuery = (
  translationKey: string | undefined,
  batchKey: string,
  searchText?: string
) => {
  const filterUrls = (urls: BatchURL[]) =>
    urls.filter(
      (url) =>
        !searchText ||
        url.content_url.toLowerCase().includes(searchText?.toLowerCase())
    );

  return queryOptions({
    queryKey: ['batchDetails', translationKey, batchKey],
    queryFn: () => getBatchDetails(translationKey, batchKey),
    select: (data) => ({ ...data, urls: filterUrls(data.urls) }),
  });
};

async function getBatchDetails(
  translationKey: string | undefined,
  batchKey: string
) {
  if (!translationKey) {
    throw new Error('invalid batch');
  }
  const response = await Xapis.BatchDetails.get({
    translationKey,
    batchKey,
  });
  if (!isSuccessStatus(response.status) || !response.data) {
    throw new Error('Failed to fetch batch details');
  }
  return response.data as BatchDetailsResponse;
}

async function getActiveBatchesQueryData(projectKey: string) {
  const response = await Xapis.Batches.get({
    projectKey,
    batchFetchType: 'active',
  });
  if (!isSuccessStatus(response.status) || !response.data) {
    throw new Error('Failed to fetch batches data');
  }

  return {
    batches: response.data.batches,
    assigneeOptions: getNonDuplicatedAssignees(response.data.batches),
  } as ActiveBatches;
}

async function getBatchHistoryQueryData(projectKey: string) {
  const response = await Xapis.Batches.get({
    projectKey,
    batchFetchType: 'history',
  });
  if (!isSuccessStatus(response.status) || !response.data) {
    throw new Error('Failed to fetch batches data');
  }

  return {
    batches: response.data.batches,
    assigneeOptions: getNonDuplicatedAssignees(response.data.batches),
  } as HistoryBatches;
}

const getNonDuplicatedAssignees = (batches: HistoryBatch[] | ActiveBatch[]) => {
  const assignees = batches
    .map(({ assigned_user }: HistoryBatch | ActiveBatch) => assigned_user)
    .filter((assignee: string) => assignee);
  return Array.from(new Set(assignees));
};

export function useDetailedBatch() {
  const { projectKey } = useGLGOParams();

  const [searchParams] = useSearchParams();

  const batchKey = searchParams.get('batch') ?? '';
  const searchText = searchParams.get('q') ?? '';

  if (!projectKey) {
    throw new Error('projectKey is missing from route params');
  }

  const {
    data: { batches: allBatches },
  } = useSuspenseQuery(activeBatchesQuery(projectKey));
  const batch = allBatches.find(({ batch_key }) => batch_key === batchKey);

  const { data } = useSuspenseQuery(
    batchDetailsQuery(batch?.translation_key, batchKey, searchText)
  );
  return { batch: data, allBatches } as DetailedBatch;
}

export function useFilteredActiveBatches() {
  const { projectKey } = useGLGOParams();
  const [searchParams] = useSearchParams();

  const batchName = searchParams.get('batchName');
  const phaseStatus = searchParams.get('status');
  const phase = searchParams.get('phase');
  const assignedUser = searchParams.get('assignedUser');
  const q = searchParams.get('q');
  const isMyBatches = searchParams.get('myBatches');
  const tkeys = searchParams.getAll('t') ?? [];

  if (!projectKey) {
    throw new Error('projectKey is missing from route params');
  }

  const { data } = useSuspenseQuery(
    activeBatchesQuery(projectKey, {
      batch_name: batchName ?? '',
      phase_status: phaseStatus ?? '',
      phase: phase ?? '',
      assigned_user: assignedUser ?? '',
      q: q ?? '',
      my_batches: isMyBatches ?? '',
      t_keys: tkeys.join(','),
    })
  );

  return data as ActiveBatches;
}

export function useFilteredBatchHistory() {
  const { projectKey } = useGLGOParams();
  const [searchParams] = useSearchParams();

  const batchName = searchParams.get('batchName');
  const phaseStatus = searchParams.get('status');
  const assignedUser = searchParams.get('assignedUser');
  const q = searchParams.get('q');
  const isMyBatches = searchParams.get('myBatches');
  const tkeys = searchParams.getAll('t') ?? [];

  if (!projectKey) {
    throw new Error('projectKey is missing from route params');
  }

  const { data } = useSuspenseQuery(
    batchHistoryQuery(projectKey, {
      batch_name: batchName ?? '',
      phase_status: phaseStatus ?? '',
      assigned_user: assignedUser ?? '',
      q: q ?? '',
      my_batches: isMyBatches ?? '',
      t_keys: tkeys.join(','),
    } as Record<BatchFilters, string>)
  );

  return data as HistoryBatches;
}
