import React from 'react';
import { Flex } from '@mantine/core';
import QuickAction from './QuickAction';

export type Action = {
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
  label?: React.ReactNode;
};

type BarProps = {
  actions: Action[];
};

export const ActionBar = ({ actions }: BarProps) => {
  return (
    <Flex align="center" gap={2}>
      {actions.map((action, index) => {
        const {
          icon = <></>,
          label = '',
          active = false,
          disabled = false,
          onClick = () => undefined,
        } = action;

        return (
          <QuickAction
            key={index}
            icon={icon}
            isActive={active}
            isDisabled={disabled}
            onClick={() => onClick()}
            label={label as React.ReactNode}
          />
        );
      })}
    </Flex>
  );
};
