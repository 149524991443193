import { Flex, Grid, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useMemo } from 'react';
import { MTUsageType } from './MTUsage';

type Props = {
  todaysDate: string;
  mTUsageData: MTUsageType[];
  accumulatedMTUsageData: MTUsageType[];
};

const MTUsageSummary = ({
  todaysDate,
  mTUsageData,
  accumulatedMTUsageData,
}: Props) => {
  const isMobileSize = useMediaQuery('(max-width: 575px)');
  const { colors } = useMantineTheme();

  const hasData = accumulatedMTUsageData.length > 0;

  const mtdMTUsage = useMemo(() => {
    return (
      accumulatedMTUsageData[accumulatedMTUsageData.length - 1]?.count ?? 0
    );
  }, [accumulatedMTUsageData]);

  const mtdMTUsageWithCommas = mtdMTUsage.toLocaleString('en-US') || '0';

  const todaysTotalMTUsage = useMemo(() => {
    return Math.round(
      mTUsageData.reduce((accum, { timeslice = '', count = 0 }) => {
        if (timeslice.includes(todaysDate)) {
          return accum + count;
        }
        return accum;
      }, 0)
    );
  }, [mTUsageData, todaysDate]);

  const todaysTotalMTUsageWithCommas =
    todaysTotalMTUsage.toLocaleString('en-US') || '0';

  const todaysMTUsagePercentage = !(!todaysTotalMTUsage || !mtdMTUsage)
    ? ((todaysTotalMTUsage / (mtdMTUsage - todaysTotalMTUsage)) * 100).toFixed(
        2
      )
    : '0';

  return (
    <Grid w="100%">
      <Grid.Col span={{ base: 12, xs: 6 }}>
        <Flex w="100%" direction="column" rowGap={10} pr="1rem">
          <Title order={3} tt="uppercase">
            MONTH TO DATE
          </Title>
          <Text
            fw={700}
            fz="1.75rem"
            display="inline-flex"
            style={{ columnGap: 5, justifyContent: 'flex-end' }}
            truncate
          >
            {mtdMTUsageWithCommas}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col
        span={{ base: 12, xs: 6 }}
        px={{ base: 5, md: 'auto' }}
        style={
          isMobileSize
            ? {
                borderTop: `2px solid ${colors.divider[0]}`,
                borderBottom: `2px solid ${colors.divider[0]}`,
              }
            : {
                borderLeft: `2px solid ${colors.divider[0]}`,
              }
        }
      >
        <Flex w="100%" direction="column" px="1rem" rowGap={10}>
          <Title order={3} tt="uppercase">
            TODAY
          </Title>
          <Text fz="1.75rem" fw={700} ta="end" truncate>
            {hasData ? `+${todaysTotalMTUsageWithCommas}` : 'n/a'}
          </Text>
          {hasData && (
            <Text ta="end" truncate>
              (
              <Text
                style={(theme) => ({
                  color: theme.colors.text[5],
                })}
                span
              >
                +{todaysMTUsagePercentage}%
              </Text>
              &nbsp;change)
            </Text>
          )}
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default MTUsageSummary;
