import { Header } from 'ui';
import { Card } from '@mantine/core';
import React from 'react';
import dashboardRC from '../../routerConstants';
import BatchHistoryFilters from '../../components/batches/batch-history/BatchHistoryFilters';
import BatchHistoryTable from '../../components/batches/batch-history/BatchHistoryTable';

const BatchHistoryPage = () => {
  return (
    <>
      <Header title={dashboardRC.PAGE_TITLE_BATCH_HISTORY} />
      <BatchHistoryFilters />
      <Card p={0} mt={20} maw={1200}>
        <BatchHistoryTable />
      </Card>
    </>
  );
};
export default BatchHistoryPage;
