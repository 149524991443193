import { Text } from '@mantine/core';
import { CustomModal, useGLGOParams } from 'ui';
import { useRecommendedThresholdContext } from '../../../../providers/RecommendedThresholdProvider';
import dashboardRC from '../../../../routerConstants';
import { RestartParams } from '../planActions';

type Props = {
  opened: boolean;
  onClose: () => void;
  renewalDate: string;
  restartParams: RestartParams;
  isExpired: boolean;
};

const RestartSubModal = ({
  opened,
  onClose,
  renewalDate,
  isExpired,
}: Props) => {
  const { projectId } = useGLGOParams();
  const thresholdFromEstimate = useRecommendedThresholdContext().threshold;

  return (
    <CustomModal
      title="Restart your Subscription"
      size="lg"
      opened={opened}
      onClose={onClose}
      footerActions={[
        { label: 'Cancel', action: onClose },
        {
          label: 'Confirm',
          action: () =>
            window.location.replace(
              `/${projectId}/${dashboardRC.PAGE_ACCOUNT}/billing-information/manage-subscription${thresholdFromEstimate > 0 ? `?threshold=${thresholdFromEstimate}` : ''}`
            ),
        },
      ]}
    >
      <Text fz="md" ta="center">
        Your subscription {isExpired ? 'expired' : 'is set to expire'} on{' '}
        {renewalDate}. Click "Confirm" below to to be taken to the plan
        selection page. You will continue to be automatically billed at whatever
        tier you select plus applicable taxes on the new renewal date unless you
        cancel your subscription.
      </Text>
    </CustomModal>
  );
};

export default RestartSubModal;
