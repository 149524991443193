import {
  getAuthUserFromMain,
  saveAuthUserInMain,
  saveDesktopUser,
} from './desktopApi';
import {
  createXapisService,
  xapisEnv,
} from '@glweb/xapis-client/src/service-wrappers/xapisService';
import {
  setUserKey,
  Xapis,
} from '@glweb/xapis-client/src/xapis-wrappers/xapis';
import {
  getSavedProjectKey,
  isDesktopApp,
  isSuccessStatus,
  LAST_PROJECT_KEY_COOKIE,
} from 'helpers';
import { User as AuthUser, UserManager } from 'oidc-client-ts';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/useAuth';
import { isUserAuthenticated } from '../context/utils';
import { shouldSkipSSO } from '../utils/shouldSkipSSO';
import { getOidcConfig } from './oidcConfig';

// For OIDC debugging
// Log.setLevel(Log.DEBUG);
// Log.setLogger(console);

let userManager: UserManager;

function initUserManager() {
  const { ssoUrl } = xapisEnv.getHost || {};
  const oidcConfig = getOidcConfig(ssoUrl);

  userManager = new UserManager(oidcConfig);

  // ------- UserManager events -----------
  userManager.events.addAccessTokenExpiring(() => {
    refreshToken({ retry: true });
  });

  // Update access token on user loaded
  // Save user in Desktop app
  userManager.events.addUserLoaded((user) => {
    xapisEnv.setToken(user.access_token);
    saveAuthUserInMain({ user, id: oidcConfig.client_id });
  });
  // --- End of UserManager events
}

export const getAuthManager = () => userManager;

export async function fetchXapisUser(email?: string) {
  if (!email)
    return Promise.resolve({ xapisUser: null, message: 'Email not provided' });
  const data = await Xapis.User.get(email);
  if (isSuccessStatus(data.status)) {
    const xapisUser = data.data;
    setUserKey(xapisUser.user_key || '');
    saveDesktopUser(xapisUser);
    return { xapisUser, message: 'User fetched successfully' };
  } else {
    return { xapisUser: null, message: 'Error fetching user data' };
  }
}

async function refreshToken(options?: {
  currentUser?: AuthUser;
  retry?: boolean;
}) {
  if (shouldSkipSSO()) {
    const user = await userManager.getUser();
    const { email } = user?.profile || {};
    if (email) {
      const authUser = await generateFakeAuthUser(email);
      return authUser;
    } else return null;
  }

  return await userManager.signinSilent().catch((e) => {
    const { currentUser = null, retry = false } = options || {};
    if (retry) setTimeout(() => refreshToken(), 5000); // Retry after 5 seconds
    // Use existing user if silent signin fails and token not expired
    const isAuthenticated = isUserAuthenticated(currentUser);
    return isAuthenticated ? currentUser : null;
  });
}

export async function initUser(): Promise<User | null> {
  createXapisService();
  initUserManager();
  userManager.clearStaleState();
  // First check if we have user in local storage
  let currentUser = await userManager.getUser();
  // If not, and it's desktop app, try to get user from main storage
  if (!currentUser && isDesktopApp()) {
    const { client_id } = userManager.settings || {};
    currentUser = await getAuthUserFromMain(client_id);
    if (currentUser) {
      currentUser = new AuthUser(currentUser);
      // Store user oidc-client userManager
      await userManager.storeUser(currentUser);
    }
  }
  // If we have an auth user with expired token, remove the user
  if (currentUser && !isUserAuthenticated(currentUser)) {
    userManager.removeUser();
    currentUser = null;
  } else if (currentUser) userManager.events.load(currentUser);

  // Initialize last project key in session storage
  const lastProjectKey = await getSavedProjectKey();
  if (lastProjectKey) {
    sessionStorage.setItem(LAST_PROJECT_KEY_COOKIE, lastProjectKey);
  }
  // Fetch user from Xapis
  const email = currentUser?.profile?.email;
  const { xapisUser } = await fetchXapisUser(email);

  return Promise.resolve(xapisUser);
}

// Hook must be used within a AuthProvider
export const useRemoveAuthUser = () => {
  const auth = useAuth();
  const id = auth.settings?.client_id;
  return async () => {
    auth
      .removeUser()
      .catch((error) => {
        console.error('Error removing user:', error.toString());
      })
      .finally(() => {
        saveAuthUserInMain({ id, user: null });
      });
  };
};
export const useSignin = (url: string) => {
  const auth = useAuth();
  const navigate = useNavigate();
  return () => {
    if (shouldSkipSSO()) {
      navigate('/dev-login', { state: { path: url } });
    } else
      auth.signinRedirect({
        state: {
          path: url,
        },
      });
  };
};

export function logout() {
  const id = userManager.settings?.client_id;
  saveAuthUserInMain({ id, user: null });

  userManager.signoutRedirect().catch((error) => {
    console.error('Signout error:', error.toString());
  });
}

export const getAccessToken = async () => {
  const token = await userManager
    .getUser()
    .then((user) => user?.access_token || 'NoToken')
    .catch(() => 'NoUser');
  return token;
};

// Generate a fake auth user for bypassing SSO
export async function generateFakeAuthUser(
  email: string,
  state?: Record<string, string>
): Promise<AuthUser> {
  const expires_at = Math.round(Date.now() / 1000) + 86400;
  const aud = userManager.settings?.client_id || 'FakeAud';
  const user = new AuthUser({
    access_token: 'OneViewToSeeTheWorld',
    expires_at,
    profile: {
      email,
      email_verified: true,
      name: 'Fake User',
      sub: email,
      idp: 'localhost',
      iss: 'fake_iss',
      aud,
      iat: expires_at,
      exp: expires_at,
      state,
    },
    scope: 'offline_access',
    token_type: 'Bearer',
  });
  await userManager.storeUser(user);
  return user;
}
