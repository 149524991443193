import { Button } from '@mantine/core';
import { HiPencil } from 'react-icons/hi';
import React from 'react';

type Props = {
  onClick: () => void;
  [prop: string]: unknown;
};

const TableEditButton = (props: Props) => {
  return (
    <Button p={0} variant="transparent" {...props}>
      <HiPencil size={18} color={'var(--mantine-color-font1-1)'} />
    </Button>
  );
};
export default TableEditButton;
