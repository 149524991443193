import { Box, Flex, Text, Title, Tooltip } from '@mantine/core';
import { compactFormatter } from 'helpers';
import { useGetNavigationStatus } from 'ui';
import { DataWrapper } from '../../../shared/DataWrapper';
import { getTranslatedTraffic } from './trafficUtils';
import { TranslatedTrafficChart } from './TranslatedTrafficChart';

type Props = {
  prevTimeSlices: TranslatedTrafficTimeslice[];
  timeSlices: TranslatedTrafficTimeslice[];
};

const TranslatedTraffic = ({ prevTimeSlices, timeSlices }: Props) => {
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const tooltipLabel =
    'Translated traffic represents the number of page views to one of your translated pages for the language and time period specified. ' +
    'Every time someone loads a page in a translated language, that counts as one page view.';

  const { totalTranslatedTraffic, convertedTranslatedTraffic } =
    getTranslatedTraffic(prevTimeSlices, timeSlices);

  return (
    <>
      <Tooltip label={tooltipLabel}>
        <Title
          order={3}
          w="fit-content"
          tt="uppercase"
          data-testid="pw-ms-translated-traffic-title"
        >
          TRANSLATED TRAFFIC
        </Title>
      </Tooltip>
      <Box
        h={140}
        mt="0.75rem"
        data-testid="pw-ms-translated-traffic-container"
      >
        <DataWrapper loading={loading} noDataFound={timeSlices.length === 0}>
          <Flex w="100%" columnGap={20} align="center">
            <Text fw={700} fz="2.25rem">
              {compactFormatter(totalTranslatedTraffic)}
            </Text>
            <TranslatedTrafficChart
              convertedTranslatedTraffic={convertedTranslatedTraffic}
            />
          </Flex>
        </DataWrapper>
      </Box>
    </>
  );
};

export default TranslatedTraffic;
