import { useSubmit } from 'react-router-dom';
import { Select } from '@mantine/core';
import { MdArrowDropDown } from 'react-icons/md';
import React from 'react';
import classes from './BatchSelector.module.css';
import { useDetailedBatch } from '../../../queries/batchesQueries';
import { normalizeBatchName } from '../utils';

export const BatchSelector = () => {
  const {
    batch: { batch_key },
    allBatches,
  } = useDetailedBatch();
  const submit = useSubmit();
  const batchOptions = allBatches.map(
    ({ batch_key, batch_name, target_language_name }) => {
      const name = normalizeBatchName(batch_name);

      return { label: `${name} | ${target_language_name}`, value: batch_key };
    }
  );
  return (
    <Select
      data={batchOptions}
      value={batch_key}
      onChange={(key) => submit(`batch=${key}`)}
      rightSection={<MdArrowDropDown size={24} />}
      w={300}
      classNames={classes}
      fw={700}
      allowDeselect={false}
    />
  );
};
