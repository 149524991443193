import React, { createContext, useContext, useEffect, useState } from 'react';
export interface XapisUserContext {
  xapisUser: User;
  userProjects: ProjectKey[];
  setXapisUser: (user: User | null) => void;
  setUserProjects: (projects: ProjectKey[]) => void;
}

export const XapisUserContext = createContext<XapisUserContext>({
  xapisUser: { user_key: '' },
  userProjects: [],
  setXapisUser: () => {},
  setUserProjects: () => {},
});

export const useUserProvider = () => {
  const context = useContext(XapisUserContext);
  if (!context) {
    throw new Error('useUserProvider must be used within UserProvider');
  }
  return context;
};

export function UserProvider({
  children,
  user,
}: {
  children: React.ReactNode;
  user: User | null;
}) {
  const [xapisUser, setXapisUser] = useState<User>({ user_key: '' });

  const updateUser = (u: User | null) => {
    setXapisUser(u || { user_key: '' });
  };

  const setUserProjects = (projects: ProjectKey[]) => {
    updateUser({ ...xapisUser, project_keys: projects }); //could user either xapisUser or getUser() since they should always match
  };

  useEffect(() => {
    if (user) {
      updateUser(user);
    }
  }, [user]);

  return (
    <XapisUserContext.Provider
      value={{
        xapisUser,
        userProjects: xapisUser.project_keys || [],
        setXapisUser: updateUser,
        setUserProjects,
      }}
    >
      {children}
    </XapisUserContext.Provider>
  );
}
