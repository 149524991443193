import { Grid, Flex, Slider, Text, Title } from '@mantine/core';
import CustomThemeButton from '../CustomThemeButton';
import {
  SelectorDefault,
  LanguageSelectorTheme,
} from '../../../setup/LanguageSelectorAppearances';

type Props = {
  selectedPreset: SelectorDefault;
  currentPreset: SelectorDefault;
  handleThemeChange: (theme: LanguageSelectorTheme) => void;
  handleWidthChange: (value: number) => void;
  selectorPositions: JSX.Element[];
};

const BasicCustomization = ({
  selectedPreset,
  currentPreset,
  handleThemeChange,
  handleWidthChange,
  selectorPositions,
}: Props) => (
  <Grid grow>
    <Grid.Col span={{ base: 12, md: 6 }} mb={10} p={10}>
      <Flex direction="column">
        <Title order={3} mt={15} tt="uppercase">
          Theme
        </Title>
        <Flex mt={20} gap={20} wrap="wrap" align="center" justify="center">
          <CustomThemeButton
            changeTheme={handleThemeChange}
            selectedPreset={selectedPreset}
            currentPreset={currentPreset}
          />
        </Flex>
      </Flex>
    </Grid.Col>
    <Grid.Col span={{ base: 12, md: 6 }} mb={20}>
      <Flex direction="column" pl={30} pr={0}>
        <Title order={3} mt={15} tt="uppercase">
          Position
        </Title>
        <Flex wrap="wrap" gap={10} mt={20} justify="center" align="center">
          <Flex direction="row" gap={10}>
            {selectorPositions[0]}
            {selectorPositions[1]}
          </Flex>
          <Flex direction="row" gap={10}>
            {selectorPositions[2]}
            {selectorPositions[3]}
          </Flex>
        </Flex>
      </Flex>
    </Grid.Col>
    <Grid.Col span={12}>
      <Flex direction="column">
        <Title order={3} mt={15} tt="uppercase">
          Width
        </Title>
        <Flex columnGap="1rem" align="center">
          <Slider
            onChange={handleWidthChange}
            value={selectedPreset.width ?? 175}
            min={50}
            max={1000}
            maw={1500}
            w="60%"
          />
          <Text>{selectedPreset.width ?? 175} px</Text>
        </Flex>
      </Flex>
    </Grid.Col>
  </Grid>
);

export default BasicCustomization;
