import { Select } from '@mantine/core';
import { useState } from 'react';
import { xapisEnv } from '@glweb/xapis-client';
import useProjectsStore, { type ProjectStore } from './store/ProjectsStore';

type ActiveStoreType = {
  title: string;
  content: unknown;
};

const StoreDebug = () => {
  const xapisHost = xapisEnv.getHost;
  const ProjectsStore = useProjectsStore((state: ProjectStore) => state);

  const [activeStore, setActiveStore] = useState<ActiveStoreType>();

  const stores = [
    {
      title: 'Xapis Host',
      content: xapisHost,
    },
    {
      title: 'ProjectsStore',
      content: ProjectsStore,
    },
  ];

  const storeViews = stores.map(({ title, content }) => (
    <div key={title} style={{ opacity: activeStore ? 0.3 : 1 }}>
      <p style={{ fontWeight: 'bold' }}>{title}</p>
      <pre>{JSON.stringify(content, null, 2)}</pre>
    </div>
  ));

  const selectedStore = activeStore ? (
    <div key={`${activeStore.title}`} style={{ fontSize: '1.2em' }}>
      <p style={{ fontWeight: 'bold', marginBottom: 0 }}>{activeStore.title}</p>
      <pre style={{ marginTop: 0 }}>
        {JSON.stringify(activeStore.content, null, 2)}
      </pre>
    </div>
  ) : (
    <p style={{ marginBottom: 30 }}>No store selected.</p>
  );

  return (
    <>
      <h3>The purpose of this page is to assist in debugging store state.</h3>
      <Select
        onChange={(label) => {
          const clickedStore = stores.find((store) => store.title === label);
          setActiveStore(clickedStore);
        }}
        searchable
        clearable
        style={{ maxWidth: 400, marginTop: '40px 0' }}
        label="Store Selector"
        placeholder="Pick a store to see its current values"
        defaultValue="-- select a store --"
        data={stores.map(({ title }) => ({ value: title, label: title }))}
      />
      <div>
        {selectedStore}
        {storeViews}
      </div>
    </>
  );
};
export default StoreDebug;
