const defaultAddress = {
  city: '',
  country: '',
  line1: '',
  postalCode: '',
};

const defaultOwner = {
  address: defaultAddress,
  email: '',
  firstName: '',
  lastName: '',
};

const defaultCreditCard = {
  brand: '',
  expirationMonth: 0,
  expirationYear: 0,
  lastFourDigits: '',
};

export const defaultPaymentSource = {
  clientSecret: '',
  createdTime: '',
  currency: '',
  creditCard: defaultCreditCard,
  id: '',
  owner: defaultOwner,
  reusable: false,
  state: '',
  type: 'creditCard',
};

const filterDefaultSource = (sources: PaymentSource[], defaultId: string) => {
  return (
    sources.find(({ id = '' }: PaymentSource) => id === defaultId) ||
    defaultPaymentSource
  );
};

export const sortSourcesByDefault = (
  sources: PaymentSource[],
  defaultId: string
) => {
  const defaultSource = filterDefaultSource(sources, defaultId);
  const isDefaultSourceFound = defaultSource.id !== '';

  let sortedSourcesByDefault: PaymentSource[] = sources;
  sortedSourcesByDefault = sortedSourcesByDefault.filter(
    ({ id = '' }) => id !== defaultId
  );

  if (isDefaultSourceFound) {
    sortedSourcesByDefault.unshift(defaultSource);
    return sortedSourcesByDefault;
  }
  return sources;
};
