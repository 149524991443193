import { xapisEnv } from '@glweb/xapis-client/src/service-wrappers/xapisService';
import { Button, Flex, useMantineTheme } from '@mantine/core';
import { DesktopOS, MAC_OS, WINDOWS_OS } from 'helpers';
import React from 'react';
import { FaApple, FaWindows } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

export const DownloadDesktopAppCard = ({ os }: { os: DesktopOS }) => {
  const colors = useMantineTheme().colors;
  const macDownloadUrl = xapisEnv.getHost.desktopDownloadMacUrl;
  const winDownloadUrl = xapisEnv.getHost.desktopDownloadExeUrl;

  const links: { [key in DesktopOS]: string } = {
    [MAC_OS]: macDownloadUrl,
    [WINDOWS_OS]: winDownloadUrl,
  };
  const icons: { [key in DesktopOS]: React.ReactNode } = {
    [MAC_OS]: <FaApple size={35} />,
    [WINDOWS_OS]: <FaWindows size={35} />,
  };

  return (
    <Flex
      w="50%"
      px="1rem"
      pt="1rem"
      pb="1.75rem"
      bg="background.0"
      direction="column"
      align="center"
      justify="center"
      rowGap="1rem"
      style={{
        border: `1px solid ${colors.border[1]}`,
        borderRadius: 12,
      }}
    >
      {icons[os]}
      <Link style={{ width: '100%' }} to={links[os]}>
        <Button w="100%">{`${os} Download`}</Button>
      </Link>
    </Flex>
  );
};
