import { Card } from '@mantine/core';
import moment from 'moment/moment';
import { useMemo } from 'react';
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as Retooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomChartTooltip from '../recharts/CustomChartTooltip';
import CustomDot from '../recharts/CustomDot';
import { getYAxisMaxValueAndWidth } from '../recharts/utils';
import { DataWrapper } from '../shared/DataWrapper';
import { getTimeSliceOptions } from '../utils';
import {
  getDayTimeSlices,
  getMonthTimeSlices,
  getMonthToDateTimeSlices,
} from './getTimeSlices';

type Props = {
  timeSlices: TranslatedTrafficTimeslice[];
  timeSliceFrom: string;
  loading: boolean;
};

const TranslatedTrafficChart = ({
  timeSlices,
  timeSliceFrom,
  loading,
}: Props) => {
  const isYearly = timeSliceFrom.includes('year');

  const isMonthToDate = useMemo(() => {
    const timeSliceFilterOptions = getTimeSliceOptions();
    const timeSliceFilterFound = timeSliceFilterOptions.find(
      ({ value: filterValue }) => filterValue === timeSliceFrom
    );
    return timeSliceFilterFound?.label === 'Month to Date';
  }, [timeSliceFrom]);

  const timeSlicedTranslatedTraffic = useMemo(() => {
    if (timeSlices.length === 0) return [];

    const parsedTranslatedTraffic = timeSlices.map(
      ({ timeslice, num_requests = '0' }) => {
        return { timeslice, count: Number(num_requests) };
      }
    );
    if (isMonthToDate) {
      return getMonthToDateTimeSlices(parsedTranslatedTraffic);
    }
    if (isYearly) {
      return getMonthTimeSlices(parsedTranslatedTraffic, timeSliceFrom);
    }
    return getDayTimeSlices(parsedTranslatedTraffic, timeSliceFrom);
  }, [isYearly, timeSliceFrom, timeSlices, isMonthToDate]);

  const yesterdaysDate = useMemo(() => {
    return moment(new Date())
      .subtract(1, 'days')
      .format(isYearly ? 'YYYY-MM' : 'YYYY-MM-DD');
  }, [isYearly]);

  const translatedTrafficYesterday = useMemo(() => {
    return (
      timeSlicedTranslatedTraffic.find(
        ({ timeslice }) => timeslice === yesterdaysDate
      )?.count || 0
    );
  }, [timeSlicedTranslatedTraffic, yesterdaysDate]);

  const { yAxisMaxValue = 0, yAxisWidth = 0 } = getYAxisMaxValueAndWidth(
    timeSlicedTranslatedTraffic
  );

  const lastTimeSlice =
    timeSlicedTranslatedTraffic[timeSlicedTranslatedTraffic.length - 1]
      ?.timeslice || '';

  const getTick = (props: {
    x: number;
    y: number;
    payload: Record<string, string>;
  }) => {
    const { x = 0, y = 0, payload: { value = '' } = {} } = props || {};

    const rotation = `rotate(-40, ${x}, ${y})`;
    const currentDate = moment(value).format(
      isYearly ? 'YYYY-MM' : 'YYYY-MM-DD'
    );
    const isLastTimeSlice = value === lastTimeSlice;

    if (isMonthToDate && !isLastTimeSlice && currentDate > yesterdaysDate) {
      return <text></text>;
    }

    return (
      <text
        x={x}
        y={y}
        dy={20}
        dx={-30}
        fill="black"
        fontSize={14}
        fontWeight={400}
        textAnchor="middle"
        transform={rotation}
      >
        {moment(value).format(isYearly ? 'YYYY/MM' : 'MM/DD') || ''}
      </text>
    );
  };

  return (
    <Card h={500} p={20}>
      <DataWrapper
        loading={loading}
        noDataFound={timeSlicedTranslatedTraffic.length === 0}
      >
        <ResponsiveContainer height="100%" width="100%">
          <AreaChart
            margin={{ top: 10, left: 20, right: 20 }}
            data={timeSlicedTranslatedTraffic}
          >
            <ReferenceLine
              x={lastTimeSlice}
              stroke="#071D49"
              strokeDasharray="3 3"
            />
            <ReferenceLine
              y={yAxisMaxValue}
              stroke="#071D49"
              strokeDasharray="3 3"
            />
            {isMonthToDate && (
              <ReferenceLine
                stroke="black"
                segment={[
                  { x: yesterdaysDate, y: 0 },
                  { x: yesterdaysDate, y: translatedTrafficYesterday },
                ]}
              />
            )}
            <defs>
              <linearGradient
                id="translatedTraffic"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="5%" stopColor="#0071DB" stopOpacity={0.5} />
                <stop offset="95%" stopColor="#0071DB" stopOpacity={0} />
              </linearGradient>
            </defs>
            <YAxis
              stroke="black"
              dataKey="count"
              width={yAxisWidth || 120}
              tickMargin={20}
              tickFormatter={(value) => value.toLocaleString()}
              tickLine={false}
              domain={[0, yAxisMaxValue]}
            />
            <XAxis
              stroke="black"
              dataKey="timeslice"
              interval="preserveStartEnd"
              tick={getTick}
              tickLine={false}
              allowDuplicatedCategory={false}
              height={60}
            />
            <Area
              dataKey="count"
              fill="url(#translatedTraffic)"
              stroke="#0071DB"
              strokeWidth={4}
              activeDot={{ r: 7, fill: '#0071DB', strokeWidth: 0 }}
              dot={isMonthToDate ? <CustomDot date={yesterdaysDate} /> : <></>}
            />
            <Retooltip content={<CustomChartTooltip />} cursor={false} />
          </AreaChart>
        </ResponsiveContainer>
      </DataWrapper>
    </Card>
  );
};

export default TranslatedTrafficChart;
