import { Box, Flex, Title } from '@mantine/core';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import { useUserProvider } from 'ui';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { handleCreateProject } from './handleProjectSetupSteps';

export const MobileActionHeader = ({ title }: { title: string }) => {
  const { xapisUser, setXapisUser } = useUserProvider();
  const {
    activeStep,
    setActiveStep,
    isCreatingProject,
    setIsCreatingProject,
    selectedSourceLangCode,
    selectedTargetLangCodes,
    selectedTheme,
    selectedPosition,
    projectDomain: domain,
    isNextButtonDisabled,
    setIsNextButtonDisabled,
  } = useSetupStepsContext();

  const isCreateProjectStep = activeStep === 1.5;
  const isAddSnippetInfoStep = activeStep === 2;

  return (
    <Flex
      w="100%"
      justify={!isAddSnippetInfoStep ? 'space-between' : 'center'}
      align="center"
    >
      {!isAddSnippetInfoStep ? (
        <Box
          p="0.5rem"
          onClick={() => {
            if (!isCreatingProject) {
              setActiveStep((prevStep) => {
                return prevStep - (activeStep <= 2 ? 0.5 : 1);
              });
            }
          }}
        >
          <MdArrowBackIos
            size={24}
            color={isCreatingProject ? '#757575' : '#0074E0'}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box>
        <Title fz={{ base: 'xl', sm: '1.5rem' }} order={1}>
          {title}
        </Title>
      </Box>
      {!isAddSnippetInfoStep ? (
        <Box
          p="0.5rem"
          onClick={() => {
            if (!isNextButtonDisabled && !isCreateProjectStep) {
              setIsNextButtonDisabled(true);
              setActiveStep((prevStep) => {
                return prevStep + (activeStep < 2 ? 0.5 : 1);
              });
            }
            if (isCreateProjectStep) {
              handleCreateProject(
                isCreatingProject,
                setIsCreatingProject,
                setActiveStep,
                selectedSourceLangCode,
                selectedTargetLangCodes,
                selectedTheme,
                selectedPosition,
                domain,
                xapisUser,
                setXapisUser
              );
            }
          }}
        >
          <MdArrowForwardIos
            size={24}
            color={
              isNextButtonDisabled && !isCreateProjectStep
                ? '#757575'
                : '#0074E0'
            }
          />
        </Box>
      ) : (
        <></>
      )}
    </Flex>
  );
};
