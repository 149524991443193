import axios, { AxiosResponse } from 'axios';
import { failureNotification } from 'ui';
import getAxiosUrlAndConfig from './getAxiosUrlAndConfig';

export type ResponseType =
  | 'arraybuffer'
  | 'document'
  | 'json'
  | 'text'
  | 'stream'
  | 'blob';
/**
 *
 * @param endpoint
 * @param setter
 * @param failed
 * @param moreParams
 * @param cleanup
 * @param responseType
 * @param signal
 * @constructor
 */
export const Fetcher = (
  endpoint: string,
  setter: (response: AxiosResponse) => void,
  failed: {
    errorMessage?: string;
    action?: () => void;
  },
  moreParams: object = {},
  cleanup?: () => void,
  responseType?: ResponseType,
  signal?: AbortSignal
) => {
  const { url, config } = getAxiosUrlAndConfig(
    endpoint,
    moreParams,
    signal,
    responseType
  );

  axios
    .get(url, config)
    .then((results) => {
      setter(results);
    })
    .catch((error) => {
      if (error?.message === 'canceled') return;
      failureNotification(error, failed?.errorMessage);
      failed.action && failed.action();
    })
    .finally(() => {
      cleanup && cleanup();
    });
};

export default Fetcher;
