import { Flex, Text, Title } from '@mantine/core';
import { Tip } from '../Generic/Tip';
import { AttributeIcon, Success, UnSaved } from '../../icons/IndicatorIcons';
import { BiErrorCircle } from 'react-icons/bi';

const Info = ({
  title,
  description,
}: {
  title: string;
  description?: string | React.ReactNode;
}) => {
  return (
    <>
      <Title order={6}>{title}</Title>
      {description && (
        <Text lh={1.2} mt={5} fw="normal">
          {description}
        </Text>
      )}
    </>
  );
};

type SegmentIndicatorsProps = {
  status?: {
    isSelected: boolean;
    isSaved: boolean;
    isUnsaved: boolean;
    isFailedSave: boolean;
    isAttribute?: boolean;
  };
};
export const SegmentIndicators = ({
  status,
}: // isSelected: isSegmentSelect,
SegmentIndicatorsProps) => {
  const indicatorsColors = {
    gray: '#757575',
    fill: 'white',
    success: '#40C057',
    error: '#D93025',
  };

  const { isSaved, isFailedSave, isUnsaved, isAttribute } = status || {};

  const statusColor = () => {
    if (isFailedSave) return indicatorsColors.error;
    if (isSaved) return indicatorsColors.success;
    return indicatorsColors.gray;
  };
  const statusIcon = () => {
    if (isFailedSave) return <BiErrorCircle />;
    if (isSaved) return <Success />;
    if (isUnsaved) return <UnSaved />;
    return <></>;
  };

  return (
    <Flex
      aria-label="segment indicators"
      w={32}
      pt={10}
      gap={4}
      direction="column"
      align={'center'}
    >
      <Tip
        variant="light"
        label={
          <Info
            title={'Attribute Text'}
            description={
              <span>
                This segment might not
                <br />
                be visible on the page.
              </span>
            }
          />
        }
        disabled={!isAttribute}
      >
        <Flex c={indicatorsColors.gray}>
          {isAttribute && <AttributeIcon />}
        </Flex>
      </Tip>
      <Tip variant="light" label={<Info title={'Unsaved changes'} />}>
        <Flex
          align={'center'}
          justify={'center'}
          c={statusColor()}
          style={{
            rect: { fill: indicatorsColors.fill },
          }}
        >
          {statusIcon()}
        </Flex>
      </Tip>
    </Flex>
  );
};
