import { GLWEB_SUPPORT_URL } from '@glweb/constants';
import { Flex, Text } from '@mantine/core';
import { AnchorLink, CustomModal } from 'ui';
import { useDeploymentContext } from './DeploymentContextProvider';
import { Method, deploymentMethods } from './utils';

const ConfirmMethodModal = () => {
  const {
    setShowChangeMethodModal,
    showConfirmMethodModal: opened,
    setShowConfirmMethodModal: setOpened,
    selectedMethod,
    setSelectedMethod,
  } = useDeploymentContext();

  const { description = '' } =
    deploymentMethods[selectedMethod as Method] || {};

  return (
    <CustomModal
      size="lg"
      title={selectedMethod}
      opened={opened}
      onClose={() => {
        setOpened(false);
        setSelectedMethod('');
      }}
      footerActions={[
        {
          label: 'Back',
          action: () => {
            setOpened(false);
            setShowChangeMethodModal(true);
          },
        },
        {
          label: 'Continue',
          action: () => {
            setOpened(false);
          },
          disabled: !selectedMethod,
        },
      ]}
    >
      <Flex direction="column" rowGap="1.5rem">
        {typeof description === 'string' ? (
          <Text fz="md">{description}</Text>
        ) : (
          description
        )}
        <Text fz="md">
          <AnchorLink
            fz="inherit"
            target="_blank"
            to={`${GLWEB_SUPPORT_URL}/article/130-what-are-the-different-deployment-methods`}
          >
            Click here
          </AnchorLink>{' '}
          to learn more.
        </Text>
      </Flex>
    </CustomModal>
  );
};

export default ConfirmMethodModal;
