import {
  ActionIcon,
  Button,
  Checkbox,
  MantineThemeOverride,
  Modal,
  Popover,
  Radio,
  ScrollArea,
  Tooltip,
  createTheme,
} from '@mantine/core';
import actionIconClasses from './modules/ActionIcon.module.css';
import modalClasses from './modules/Modal.module.css';
import checkboxClasses from './modules/Checkbox.module.css';
import popoverClasses from './modules/Popover.module.css';
import tooltipClasses from './modules/Tooltip.module.css';
import scrollAreaClasses from './modules/ScrollArea.module.css';
import buttonClasses from './modules/Button.module.css';
import radioClasses from './modules/Radio.module.css';

import { themeColors } from './colors';

export const segmentEditorTheme: MantineThemeOverride = createTheme({
  colors: themeColors,
  shadows: {
    md: '0px 4px 20px 0px rgba(0, 0, 0, 0.25)',
  },
  components: {
    Button: Button.extend({
      classNames: buttonClasses,
      defaultProps: {
        variant: 'default',
      },
    }),
    ActionIcon: ActionIcon.extend({
      classNames: actionIconClasses,
      defaultProps: {
        loaderProps: { color: 'blue.7' },
      },
    }),
    Modal: Modal.extend({
      classNames: modalClasses,
    }),
    Radio: Radio.extend({
      classNames: radioClasses,
    }),
    Checkbox: Checkbox.extend({
      classNames: checkboxClasses,
      defaultProps: {
        size: 'xs',
      },
    }),
    Popover: Popover.extend({
      classNames: popoverClasses,
      defaultProps: {
        withArrow: true,
        arrowSize: 12,
        offset: 3,
      },
    }),
    Tooltip: Tooltip.extend({
      classNames: tooltipClasses,
      defaultProps: {
        withArrow: true,
        arrowSize: 10,
      },
    }),
    ScrollArea: ScrollArea.extend({
      classNames: scrollAreaClasses,
    }),
  },
});
