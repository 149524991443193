import { getYYTarget, hexToObject } from 'helpers';

export const filterNodes = (
  translations: TranslationKey[],
  nodes: SpiderTreeNode[],
  id: string
) => {
  const yyTarget = getYYTarget(translations) as TranslationKey;
  const activeTranslationConfig = yyTarget?.translation_config || '';
  const config = hexToObject(activeTranslationConfig);
  const { spider_rules: { exclude_uris: excludeRules = [] } = {} } = config;
  return nodes.map((node, index) => {
    let isExcluded = false;
    if (excludeRules.length > 0) {
      excludeRules.forEach((rule: string) => {
        const nodeString = node.is_fake ? node.label : node.content_url_orig;
        if (new RegExp(rule).test(nodeString)) {
          isExcluded = true;
        }
      });
    }
    if (node.has_children) {
      return {
        ...node,
        name: node.content_url_orig,
        key: `${id}-${index}`,
        id: `${id}-${index}`,
        isExcluded: isExcluded,
        stringKey: `${id}-${index}`,
        children: [
          {
            label: 'HAS_CHILDREN',
            key: `${id}-${index}-0`,
          },
        ],
        children_fetched: false,
      };
    }
    return {
      ...node,
      // name: node.content_url_orig,
      key: `${id}-${index}`,
      id: `${id}-${index}`,
      isExcluded: isExcluded,
      stringKey: `${id}-${index}`,
      children_fetched: true,
    };
  });
};

export const mergeChildren = (
  children: SpiderTreeNode[],
  stringKey: string,
  crawlerTree: SpiderTreeNode[]
): SpiderTreeNode[] =>
  crawlerTree.map((node) => {
    if (node.stringKey === stringKey) {
      return { ...node, children_fetched: true, children: [...children] };
    }
    if (node.has_children && node.children) {
      return {
        ...node,
        children: mergeChildren(children, stringKey, node.children),
      };
    }
    return node;
  });

export default {
  filterNodes,
};
