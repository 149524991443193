import React from 'react';
import {
  Text,
  Flex,
  Button,
  Card,
  useMantineTheme,
  Box,
  Tooltip,
} from '@mantine/core';
import { Method, deploymentMethods } from './utils';
import { getStringEquality } from 'helpers';
import classes from './ChangeMethodModal.module.css';
import { useDeploymentContext } from './DeploymentContextProvider';
import { CustomModal } from 'ui';

const ChangeMethodModal = () => {
  const { colors } = useMantineTheme();

  const {
    showChangeMethodModal: opened,
    setShowChangeMethodModal: setOpened,
    setShowConfirmMethodModal,
    selectedMethod,
    setSelectedMethod,
    activeProject: {
      deployment_method: defaultMethod = '',
      origin_name: url = '',
    } = {},
  } = useDeploymentContext();

  return (
    <>
      <CustomModal
        size="lg"
        title="Change your deployment method"
        opened={opened}
        onClose={() => {
          setOpened(false);
          setSelectedMethod('');
        }}
        footerActions={[
          {
            label: 'Cancel',
            action: () => {
              setOpened(false);
              setSelectedMethod('');
            },
          },
          {
            label: 'Continue',
            action: () => {
              setOpened(false);
              setShowConfirmMethodModal(true);
            },
            disabled: !selectedMethod,
          },
        ]}
      >
        <Flex w="100%" direction="column" rowGap="1.5rem">
          {Object.keys(deploymentMethods).map((method: string) => {
            const isSelected = method === selectedMethod;

            const { Icon = () => <></>, UrlPreview = () => <></> } =
              deploymentMethods[method as Method] || {};

            const isDefaultMethod = getStringEquality(method, defaultMethod);

            if (isDefaultMethod) {
              return null;
            }

            return (
              <Card
                key={method}
                w="100%"
                px="1rem"
                py="0.75rem"
                pos="relative"
                className={classes.changeMethodCard}
                style={{
                  borderColor: isSelected ? colors.border[8] : colors.border[1],
                  overflow: 'visible',
                  borderRadius: 6,
                }}
              >
                <Flex w="100%" align="center" justify="space-between">
                  <Flex w="22%" columnGap="1rem" align="center">
                    <Icon colors={colors} />
                    <Text fz="md" fw={600} c="text2.6" tt="capitalize">
                      {method}
                    </Text>
                  </Flex>
                  <Tooltip
                    withArrow={false}
                    maw="fit-content"
                    position="top"
                    label={
                      <UrlPreview
                        url={url}
                        textColor="font1.0"
                        fontSize="auto"
                      />
                    }
                    zIndex={1001}
                  >
                    <Box w="58%" pr="0.5rem">
                      <Text truncate fz="md" fw={600} c="text2.6">
                        |&nbsp;&nbsp;&nbsp;
                        <UrlPreview url={url} />
                      </Text>
                    </Box>
                  </Tooltip>
                  <Button
                    variant={isSelected ? 'default' : 'outline'}
                    onClick={() => setSelectedMethod(method)}
                  >
                    Select
                  </Button>
                </Flex>
              </Card>
            );
          })}
        </Flex>
      </CustomModal>
    </>
  );
};

export default ChangeMethodModal;
