const { dashboardAPI, editorAPI, headerAPI } = window || {};
const { VITE_IS_GLWEB_DESKTOP } = import.meta.env;

export const isDesktopApp = () => {
  return VITE_IS_GLWEB_DESKTOP === true;
};

export const isStudioEditorWindow = () => {
  return Boolean(window.isStudioEditor);
};
export const isStudioHeaderWindow = () => {
  return Boolean(window.isStudioHeader);
};
export const hasDashboardAPIs = !!dashboardAPI;
export const hasEditorAPIs = !!editorAPI;
export const hasHeaderAPIs = !!headerAPI;
