import React, { useMemo, useState } from 'react';
import { Box, Card, Flex, ScrollArea } from '@mantine/core';
import TranslationStatusHeader from './TranslationStatusHeader';
import ActiveTranslationItem from './ActiveTranslationItem';
import { DeactivateModal } from './DeactivateModal';

type Props = {
  targets: TranslationKey[];
  removeActiveTranslation: (selectedTranslationKey: string) => void;
  updateTranslationStatus: (
    selectedTranslationKey: string,
    isLive: boolean
  ) => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ActiveTranslations = ({
  targets,
  removeActiveTranslation,
  updateTranslationStatus,
  isModalOpen,
  setIsModalOpen,
}: Props) => {
  const [selectedTranslation, setSelectedTranslation] =
    useState<TranslationKey | null>(null);

  const inactiveTranslations = targets.filter(
    ({ is_active = false }) => !is_active
  );

  const activeTargets = useMemo(
    () =>
      targets.filter(
        ({ target_lang_code = '', source_lang_code = '', is_active = false }) =>
          target_lang_code !== source_lang_code &&
          target_lang_code !== 'YY' &&
          is_active
      ),
    [targets]
  );

  return (
    <Box>
      <DeactivateModal
        isModalOpen={isModalOpen && selectedTranslation !== undefined}
        setIsModalOpen={setIsModalOpen}
        removeActiveTranslation={removeActiveTranslation}
        selectedTranslation={selectedTranslation}
        setSelectedTranslation={setSelectedTranslation}
      />
      <TranslationStatusHeader
        statusTitle={`Active (${activeTargets.length.toLocaleString()})`}
        status="active"
      />
      <Card
        mt={20}
        p={0}
        pt={2}
        data-testid="pw-project-settings-manage-languages-table-active"
      >
        <ScrollArea.Autosize
          type="always"
          offsetScrollbars={'y'}
          mah={
            inactiveTranslations.length > 0
              ? `calc(100vh - ${260 + Math.min(3, inactiveTranslations.length) * 65}px)`
              : 'calc(100vh - 200px)'
          }
        >
          <Flex py={5} px={15} direction="column">
            {activeTargets.map((target, index) => (
              <Box key={index} data-testid="pw-language-display-box">
                <ActiveTranslationItem
                  translation={target}
                  updateTranslationStatus={updateTranslationStatus}
                  setIsModalOpen={setIsModalOpen}
                  setSelectedTranslation={setSelectedTranslation}
                  isLastItem={index === activeTargets.length - 1}
                />
              </Box>
            ))}
          </Flex>
        </ScrollArea.Autosize>
      </Card>
    </Box>
  );
};

export default ActiveTranslations;
