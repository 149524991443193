import { dashboardRC } from '@glweb/constants';
import { Button, Menu, Text } from '@mantine/core';
import {
  USER_ROLES_IMPERSONATE_MODE,
  USER_ROLES_USER_MANAGEMENT_PAGE,
} from 'glweb/src/constants';
import { useViewPermissions, isDesktopApp } from 'helpers';
import React from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import { LinkWrapper, useUserProvider } from 'ui';
import { CircleAvatar } from '../shared/CircleAvatar';
import { logout } from 'auth';

type Props = {
  setShowAboutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDesktopToggled: React.Dispatch<React.SetStateAction<boolean>>;
  setShowImpersonateModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AccountMenu({
  setShowAboutModal,
  setIsDesktopToggled,
  setShowImpersonateModal,
}: Props) {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const isDesktop = isDesktopApp();

  const { rolePermissions, hasViewPermissions } = useViewPermissions();

  const menuItems = [
    {
      label: dashboardRC.PAGE_TITLE_ACCOUNT,
      to: `${projectId}/${dashboardRC.PAGE_ACCOUNT}`,
    },
    {
      label: dashboardRC.PAGE_TITLE_DESKTOP_APP,
      to: `${projectId}/${dashboardRC.PAGE_DESKTOP_APP}`,
    },
    {
      label: dashboardRC.PAGE_TITLE_USER_MANAGEMENT,
      to: `${projectId}/${dashboardRC.PAGE_USER_MANAGEMENT}`,
      userRoles: USER_ROLES_USER_MANAGEMENT_PAGE,
    },
    {
      label: 'Impersonate Mode',
      action: () => setShowImpersonateModal(true),
      userRoles: USER_ROLES_IMPERSONATE_MODE,
    },
    {
      label: 'About',
      action: () => setShowAboutModal(true),
    },
    { label: dashboardRC.PAGE_TITLE_LOGOUT, action: logout },
  ].filter(
    ({ label, userRoles }) =>
      !(isDesktop && label === dashboardRC.PAGE_TITLE_DESKTOP_APP) &&
      (!userRoles ||
        hasViewPermissions(
          rolePermissions,
          userRoles,
          label !== 'Impersonate Mode'
        ))
  );

  const { xapisUser: { first_name = '', last_name = '' } = {} } =
    useUserProvider();

  const resetSideNav = () => {
    setIsDesktopToggled(false);
  };

  return (
    <Menu
      width={175}
      offset={{ mainAxis: 5 }}
      closeOnClickOutside
      closeOnEscape
      position="bottom-end"
    >
      <Menu.Target>
        <Button
          c="text.0"
          px={0}
          variant="transparent"
          leftSection={
            <CircleAvatar name={first_name} backgroundColor="#6ED6CC" />
          }
          rightSection={<MdArrowDropDown size={22} />}
        >
          <Text c="inherit">
            {first_name} {last_name}
          </Text>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {menuItems.map(({ label, to, action }) => {
          const isActive = Boolean(to && pathname.includes(to));

          return (
            <LinkWrapper onClick={resetSideNav} key={label} to={to}>
              <Menu.Item
                onClick={() => {
                  resetSideNav();
                  action && action();
                }}
                aria-current={isActive}
                data-active={isActive}
              >
                {label}
              </Menu.Item>
            </LinkWrapper>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}
