import { QueryClient } from '@tanstack/react-query';
import { Params } from 'react-router-dom';
import { glossaryQuery } from '../queries/glossaryQuery';

const glossaryLoader =
  (queryClient: QueryClient) =>
  async ({ params }: { params: Params<string> }) => {
    const { projectId } = params || {};
    if (!projectId) {
      throw new Response('Project key is not defined', {
        status: 404,
        statusText: 'Project key is not defined',
      });
    }
    return await queryClient.ensureQueryData(glossaryQuery(projectId));
  };

export default glossaryLoader;

export type GlossaryLoaderResponse = Awaited<
  ReturnType<ReturnType<typeof glossaryLoader>>
>;
