import React from 'react';
import { Text, Group, Tooltip, Title } from '@mantine/core';
import { MRT_RowData } from 'mantine-react-table';

export const getEditDeleteColumn = (
  EditAction: ({ data }: { data: MRT_RowData }) => JSX.Element,
  DeleteAction: ({ data }: { data: MRT_RowData }) => JSX.Element
) => {
  return {
    accessorKey: '',
    header: '',
    id: 'actions',
    size: 80,
    Cell: ({ row }: MRT_RowData) => (
      <Group gap={10} pr={20} align="center" wrap="nowrap">
        <EditAction data={row.original} />
        <DeleteAction data={row.original} />
      </Group>
    ),
    enableSorting: false,
    grow: false,
  };
};

export const getBasicColumn = (
  key: string,
  header?: string,
  withTooltip?: boolean
) => {
  return {
    ...(withTooltip && {
      Cell: ({ cell }: MRT_RowData) => (
        <Tooltip label={cell.getValue()}>
          <Text data-value={cell.getValue()} truncate>
            {cell.getValue()}
          </Text>
        </Tooltip>
      ),
    }),
    accessorKey: key,
    header: header ?? key,
    Header: (
      <Title order={3} tt="uppercase">
        {header ?? key}
      </Title>
    ),
  };
};
