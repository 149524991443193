import {
  Badge,
  Box,
  Flex,
  Grid,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import {
  getPrettyDate,
  getRemainingSubscribedWordsServed,
  getRemainingSubscriptionDays,
} from 'helpers';
import { useRecommendedThresholdContext } from '../../../providers/RecommendedThresholdProvider';
import SubscribeButton from '../../shared/SubscribeButton';
import { type CardInterface } from '../../types';
import styles from '../Dashboard.styles';

type Props = CardInterface & {
  subscriptions: Subscription[];
  wordsServedAmount: number;
  isExpiredPlan: boolean;
};

const PlanDetails = ({
  children,
  subscriptions,
  wordsServedAmount,
  isExpiredPlan,
}: Props) => {
  const theme = useMantineTheme();
  const { recommendedPlanType } = useRecommendedThresholdContext();
  const showSubButton = recommendedPlanType === 'NORMAL';

  const {
    next_renewal_date: nextRenewalDate = '',
    sku_name: skuName = 'GlobalLink WEB Free',
    subscription_type: subscriptionType = 'MONTHLY',
    price: planPrice = 0,
  } = subscriptions[0] || {};

  const subscriptionDate = getPrettyDate(nextRenewalDate);

  const { is_canceled } =
    subscriptions.length > 0 ? subscriptions[0] : { is_canceled: true };
  const isPaidPlan = subscriptions.length > 0 && planPrice !== 0;
  const showWillNotRenewText = is_canceled && !isExpiredPlan;

  const remainingDays = getRemainingSubscriptionDays(nextRenewalDate);
  const remainingWordsServed =
    getRemainingSubscribedWordsServed(wordsServedAmount);

  const getSubscriptionText = (isExpiredPlan: boolean, isCanceled: boolean) => {
    if (isCanceled) {
      return isExpiredPlan ? 'EXPIRED DATE' : 'EXPIRATION DATE';
    } else {
      return 'NEXT RENEWAL DATE';
    }
  };

  return (
    <Box className={styles.planContainer(theme, isExpiredPlan)}>
      {isPaidPlan ? (
        <Box w="100%">
          <Grid w="100%" align="center" className="planType">
            <Grid.Col span={5}>
              <Title
                order={3}
                tt="uppercase"
                data-testid="pw-plan-details-current-plan"
              >
                {isExpiredPlan ? 'INACTIVE PLAN' : 'CURRENT PLAN'}
              </Title>
            </Grid.Col>
            <Grid.Col span={7}>
              <Flex
                w="100%"
                align="center"
                wrap={{ base: 'wrap', xs: 'nowrap' }}
              >
                <Box>
                  <Text display="inline-block" fw={600}>
                    {skuName}
                  </Text>
                  &nbsp;&nbsp;
                  {isPaidPlan && (
                    <Badge display="inline" size="md" className="planBadge">
                      {subscriptionType[0]}
                      <span style={{ textTransform: 'lowercase' }}>
                        {subscriptionType.substring(1)}
                      </span>
                    </Badge>
                  )}
                </Box>
              </Flex>
            </Grid.Col>
          </Grid>
          <Grid w="100%" align="center" className="planDate">
            <Grid.Col span={5}>
              <Title order={3} tt="uppercase">
                {getSubscriptionText(isExpiredPlan, is_canceled)}
              </Title>
            </Grid.Col>
            <Grid.Col span={7}>
              <Text fw={600}>
                {subscriptionDate}
                {showWillNotRenewText && (
                  <Text c="red" fs="italic" span>
                    &nbsp;(will not renew)
                  </Text>
                )}
              </Text>
            </Grid.Col>
          </Grid>
        </Box>
      ) : (
        <>
          <Grid w="100%" align="center" className="planType">
            {isExpiredPlan ? (
              <Grid.Col>
                <Flex
                  gap={{ base: 0, sm: 20 }}
                  align="center"
                  justify={{ base: 'space-between', sm: 'start' }}
                >
                  <Text fw={600}>Your free trial has expired.</Text>
                  {showSubButton && <SubscribeButton />}
                </Flex>
              </Grid.Col>
            ) : (
              <>
                <Grid.Col span={4}>
                  <Title
                    order={3}
                    tt="uppercase"
                    data-testid="pw-plan-details-current-plan"
                  >
                    Current Plan:
                  </Title>
                </Grid.Col>
                <Grid.Col span={8}>
                  <Flex w="100%" columnGap={10} align="center">
                    <Text fw={600}>Free Trial</Text>
                    {showSubButton && <SubscribeButton />}
                  </Flex>
                </Grid.Col>
              </>
            )}
          </Grid>
          {!isExpiredPlan && (
            <Grid w="100%" align="center" className="planType">
              <Grid.Col span={4}>
                <Title
                  order={3}
                  tt="uppercase"
                  data-testid="pw-plan-details-current-plan"
                >
                  Days/Words Remaining:
                </Title>
              </Grid.Col>
              <Grid.Col span={8}>
                <Flex columnGap={10} align="center">
                  <Text
                    fw={600}
                  >{`${remainingDays} / ${remainingWordsServed.toLocaleString()}`}</Text>
                </Flex>
              </Grid.Col>
            </Grid>
          )}
        </>
      )}
      {children}
    </Box>
  );
};

export default PlanDetails;
