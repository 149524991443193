import { notifications } from '@mantine/notifications';
import React from 'react';
import { Xapis } from '@glweb/xapis-client';
import { filterNodes, mergeChildren } from './filters';

export const handleExpandRow = (
  row: SpiderTreeNode,
  translations: TranslationKey[],
  yyTranslationKey: string,
  spiderTree: SpiderTreeNode[],
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>
) => {
  try {
    if (!row.children_fetched) {
      Xapis.SpiderTree.get(yyTranslationKey, row.content_url_hash)
        .then(({ tree = [] }) => {
          const filteredData = filterNodes(
            translations,
            tree,
            row.id
          ) as SpiderTreeNode[];
          const newCrawlerTree = mergeChildren(
            filteredData,
            row.id,
            spiderTree
          );
          setSpiderTree(newCrawlerTree);
        })
        .catch(() => {
          notifications.show({
            message: 'Unable to get spider tree node info at this time.',
          });
        });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`handle toggle error: ${JSON.stringify(error)}`);
  }
};

export default handleExpandRow;
