import React from 'react';
import { Divider, Flex, Title } from '@mantine/core';
import { StatusCircleIcon } from 'ui';

type Props = {
  statusTitle: string;
  status: 'live' | 'active' | 'inactive' | 'private';
  showStatusIcon?: boolean;
};

export const TranslationStatusHeader = ({
  statusTitle = 'Active',
  status = 'active',
  showStatusIcon = false,
}: Props) => {
  return (
    <Flex
      mt="1rem"
      direction="row"
      justify="space-between"
      columnGap={25}
      align="center"
    >
      {showStatusIcon && (
        <StatusCircleIcon status={status} size={20} borderSize={4} />
      )}
      <Divider
        label={
          <Title
            order={2}
            data-testid="pw-project-settings-manage-languages-languages-count"
          >
            {statusTitle}
          </Title>
        }
        w="100%"
        color="border.1"
        size="sm"
        labelPosition="left"
      />
    </Flex>
  );
};

export default TranslationStatusHeader;
