import { Flex, Select, Text, Textarea, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import isSuccessStatus from 'helpers/src/isSuccessStatus';
import React, { useState } from 'react';
import { CustomModal } from 'ui';
import { Notify } from '../../notify-client/notify';
import classes from './ContactUsModalForm.module.css';

type FormValues = {
  category: string;
  subject: string;
  description: string;
};

type Props = {
  showContactUsModal: boolean;
  setShowContactUsModal: (show: boolean) => void;
  userName: string;
  email: string;
  userKey: string;
  projectName: string;
  projectKey: string;
};

export default function ContactUsModalForm({
  showContactUsModal,
  setShowContactUsModal,
  userName,
  email,
  userKey,
  projectName,
  projectKey,
}: Props) {
  const [formValues, setFormValues] = useState<FormValues>({
    category: '',
    subject: '',
    description: '',
  });

  const [categoryError, setCategoryError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const validateFormFields = () => {
    if (!formValues.category)
      setCategoryError('Please enter a value for this field.');
    if (!formValues.subject)
      setSubjectError('Please enter a value for this field.');
    if (!formValues.description)
      setDescriptionError('Please enter a value for this field.');
    return formValues.category && formValues.subject && formValues.description;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateFormFields()) return;

    Notify.LogFeedback.post({
      level: 'info',
      userTitle: email,
      userName,
      userKey,
      projectKey,
      project: projectName,
      app: 'GlobalLinkGO',
      domain: 'Live',
      title: formValues.subject,
      message: formValues.description,
      issueType: formValues.category,
    }).then((res) => {
      if (isSuccessStatus(res.status)) {
        notifications.show({ message: 'Successfully sent your message!' });
        setShowContactUsModal(false);
      }
    });
  };

  return (
    <CustomModal
      title="Contact Us"
      opened={showContactUsModal}
      onClose={() => setShowContactUsModal(false)}
      footerActions={[
        {
          label: 'Submit',
          disabled:
            !formValues.category ||
            !formValues.description ||
            !formValues.subject,
          type: 'submit',
          formId: 'contact-us',
        },
      ]}
    >
      <form
        id="contact-us"
        className={classes.formRoot}
        onSubmit={handleSubmit}
        style={{ padding: '0 0.5rem' }}
      >
        <Flex direction="column">
          <Select
            label="Issue Type"
            onChange={(e: string | null) => {
              if (e === null) return;

              setFormValues({ ...formValues, category: e });
              if (categoryError) setCategoryError('');
            }}
            placeholder="Select issue type"
            comboboxProps={{
              withinPortal: true,
              zIndex: 99999,
            }}
            data={['Layout Issue', 'Functionality Issue', 'Improvement']}
            value={formValues.category}
            withAsterisk
          />
          {categoryError && (
            <Text c="#D93025" fz="xs">
              {categoryError}
            </Text>
          )}
        </Flex>
        <Flex direction="column">
          <TextInput
            label="Subject"
            onChange={(e) => {
              setFormValues({ ...formValues, subject: e.currentTarget.value });
              if (subjectError) setSubjectError('');
            }}
            placeholder="Enter a subject"
            withAsterisk
          />
          {subjectError && (
            <Text c="#D93025" fz="xs">
              {subjectError}
            </Text>
          )}
        </Flex>
        <Flex direction="column">
          <Textarea
            label="Description"
            value={formValues.description}
            minRows={5}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                description: e.currentTarget.value,
              });
              if (descriptionError) setDescriptionError('');
            }}
            placeholder="Enter a description"
            withAsterisk
          />
          {descriptionError && (
            <Text c="#D93025" fz="xs">
              {descriptionError}
            </Text>
          )}
        </Flex>
      </form>
    </CustomModal>
  );
}
