import { Box, Flex, TextInput } from '@mantine/core';
import { CloseIcon } from '../../icons/ActionIcons';
import { FilterSegments } from './FilterSegments';
import { FilterBy } from '../../store/DashboardStore';
import { MdSearch } from 'react-icons/md';
import { SearchBy } from '../../types/editor';
import classes from './SearchSegments.module.css';

type FilterProps = {
  filterCount: (filters: FilterBy) => number;
  segmentsCount: { total: number; filtered: number };
  onChange: (text: string) => void;
  searchBy: SearchBy;
  refetchSegments: (query: URLSearchParams) => void;
};

const SearchSegments = ({
  filterCount,
  segmentsCount,
  onChange,
  searchBy,
  refetchSegments,
}: FilterProps) => {
  const ClearText = () => {
    return (
      <Flex onClick={() => onChange('')}>
        <CloseIcon size={15} />
      </Flex>
    );
  };

  const Count = () => {
    const { total, filtered } = segmentsCount;
    const { format } = new Intl.NumberFormat('en-US');
    return (
      <Flex p={5} fz={12} fw={600}>
        <Box c="blue.6">{`${format(filtered)}`}&nbsp;</Box>
        <Box c="blue.8">{` of ${format(total)} Segments`}</Box>
      </Flex>
    );
  };

  return (
    <Flex gap={10} aria-label="search-segments">
      <Flex direction={'column'}>
        <TextInput
          classNames={{
            input: classes.searchInput,
            section: classes.searchRightIcon,
          }}
          rightSection={searchBy.text ? <ClearText /> : <MdSearch size={20} />}
          placeholder="Search phrase"
          value={searchBy.text}
          onChange={(ev) => onChange(ev.currentTarget.value)}
        />
        <Count />
      </Flex>
      <FilterSegments
        filterCount={filterCount}
        refetchSegments={refetchSegments}
      />
    </Flex>
  );
};

export default SearchSegments;
