import React from 'react';
import { Card, Flex, Text } from '@mantine/core';
import { BsFillCircleFill } from 'react-icons/bs';
import { compactFormatter } from 'helpers';
import moment from 'moment/moment';

type Props = {
  active?: boolean;
  payload?: Array<Record<string, string>>;
  label?: string;
};

export const CustomChartTooltip = ({ active, payload, label }: Props) => {
  const formatChartDateLabel = (date: string) => {
    if (date.length <= 7) {
      return moment(date).format('MMMM YYYY') || date;
    } else {
      return moment(date).format('MMMM D, YYYY') || date;
    }
  };

  const value = (payload && compactFormatter(Number(payload[0]?.value))) || 0;

  if (value && active && payload && payload.length) {
    return (
      <Card
        p={10}
        bg="background.0"
        bd="1px solid border1.0"
        style={{
          borderRadius: 6,
          boxShadow: 'none',
        }}
      >
        <Text fw={600}>{formatChartDateLabel(label || '')}</Text>
        <Flex justify="space-between" align="center">
          <BsFillCircleFill color="#0071DB" fontSize={10} />
          <Text fw={700}>{value}</Text>
        </Flex>
      </Card>
    );
  }

  return null;
};

export default CustomChartTooltip;
