import { Button, Flex, Menu } from '@mantine/core';
import { BatchSelector } from './BatchSelector';
import { BatchProgressDetails } from './BatchProgressDetails';
import { MdArrowDropDown } from 'react-icons/md';
import BatchActionsDropdown from '../BatchActionsDropdown';
import React from 'react';
import { isSuccessStatus, NOOP } from 'helpers';
import { failureNotification, successNotification } from 'ui';
import { BatchFieldOptions } from '../../../pages/batches/ActiveBatchesPage';
import { CompleteBatchesModal } from '../CompleteBatchModal';
import { CancelBatchesModal } from '../CancelBatchesModal';
import { useDisclosure } from '@mantine/hooks';
import { useDetailedBatch } from '../../../queries/batchesQueries';
import { useNavigate } from 'react-router-dom';
import { Xapis } from '@glweb/xapis-client';

export const BatchDetailsFilters = () => {
  const { batch } = useDetailedBatch();
  const navigate = useNavigate();

  const [
    cancelBatchModalOpened,
    { open: openCancelModal, close: closeCancelModal },
  ] = useDisclosure();
  const [
    completeBatchModalOpened,
    { open: openCompleteModal, close: closeCompleteModal },
  ] = useDisclosure();

  const handleBatchChange = (
    params: Partial<Record<Partial<BatchFieldOptions>, string>>
  ) => {
    return Xapis.Batches.patch({
      translationKey: batch.translation_key,
      batchKeyList: batch.batch_key,
      params: params,
    })
      .then((xapisBatchPatchResult) => {
        const { status, data } = xapisBatchPatchResult;
        const hasBatchNames = Boolean(data?.batches[0]?.batch_name);

        if (!isSuccessStatus(status)) {
          failureNotification(xapisBatchPatchResult);
        } else {
          hasBatchNames
            ? successNotification(
                xapisBatchPatchResult,
                `Batch${data.batches.length > 1 ? 'es' : ''} (${data.batches.map(({ batch_name }: ActiveBatch) => batch_name).join(', ')}) updated.`
              )
            : successNotification(xapisBatchPatchResult);
        }
      })
      .finally(() => {
        closeCompleteModal();
        closeCancelModal();
        if (params.phase === 'complete') {
          //return to active batches page
          navigate('..');
        }
      });
  };
  return (
    <>
      <Flex align="center" columnGap={20} rowGap={10} wrap="wrap">
        <BatchSelector />
        <BatchProgressDetails />
        <Menu>
          <Menu.Target>
            <Button rightSection={<MdArrowDropDown size={24} />}>
              Actions
            </Button>
          </Menu.Target>
          <BatchActionsDropdown
            handleBatchChange={handleBatchChange}
            openCancelBatchModal={openCancelModal}
            openCompleteBatchModal={openCompleteModal}
            isDetailed
            handleReviewAll={NOOP}
          />
        </Menu>
      </Flex>
      <CompleteBatchesModal
        multipleBatches={false}
        completeBatch={handleBatchChange}
        opened={completeBatchModalOpened}
        close={closeCompleteModal}
      />
      <CancelBatchesModal
        multipleBatches={false}
        cancelBatch={handleBatchChange}
        opened={cancelBatchModalOpened}
        close={closeCancelModal}
      />
    </>
  );
};
