import React from 'react';
import { Flex, UnstyledButton } from '@mantine/core';
import { Tip } from './Tip';
import classes from './QuickAction.module.css';

export default function QuickAction({
  icon = <></>,
  isActive = false,
  onClick = () => undefined,
  onMouseDown = () => undefined,
  isDisabled = false,
  label = '',
}: {
  icon: React.ReactNode;
  label?: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  onMouseDown?: () => void;
  isDisabled?: boolean;
}) {
  return (
    <Tip label={label} variant="dark" openDelay={900}>
      <UnstyledButton
        className={`${classes.quickAction} ${isActive ? classes.activeAction : ''}`}
        disabled={isDisabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        <Flex h="100%" align="center" justify="center">
          {icon}
        </Flex>
      </UnstyledButton>
    </Tip>
  );
}
