import { IconDeer } from '@tabler/icons';
import { HTMLAttributeAnchorTarget } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import {
  MdDashboard,
  MdFormatQuote,
  MdSettings,
  MdTimeline,
  MdTranslate,
} from 'react-icons/md';
import {
  USER_ROLES_ACTIVE_BATCHES_PAGE,
  USER_ROLES_BILLING_PAGE,
  USER_ROLES_DEPLOYMENT_PAGE,
  USER_ROLES_GLOSSARY_PAGE,
  USER_ROLES_INJECTION_PAGE,
  USER_ROLES_LANGUAGES_PAGE,
  USER_ROLES_LANGUAGE_SELECTOR_PAGE,
  USER_ROLES_LANGUAGE_SETTINGS_PAGE,
  USER_ROLES_MT_USAGE_PAGE,
  USER_ROLES_NO_TRANSLATE_PAGE,
  USER_ROLES_PROFILE_PAGE,
  USER_ROLES_PROJECT_SCOPE_PAGE,
  USER_ROLES_TRANSLATED_TRAFFIC_PAGE,
  USER_ROLES_WORDS_SERVED_PAGE,
  USER_ROLES_WORLD_TRAFFIC_PAGE,
} from './constants';
import { dashboardRC } from './routerConstants';
import { createUnionSet, UserRole } from 'helpers';
import { isStagingEnv } from '@glweb/xapis-client';

export type NavLink = {
  icon: React.ReactNode;
  label: string;
  path?: string;
  action?: () => void;
  pages?: SubNavLink[];
  userRoles?: Set<UserRole>;
};

type SubNavLink = Pick<NavLink, 'label' | 'path' | 'action' | 'userRoles'> & {
  link?: string;
  target?: HTMLAttributeAnchorTarget;
};

const navLinks: NavLink[] = [
  {
    icon: <MdDashboard size={20} />,
    label: dashboardRC.PAGE_TITLE_DASHBOARD,
    path: dashboardRC.PAGE_DASHBOARD,
  },
  {
    icon: <MdTranslate size={20} />,
    label: dashboardRC.PAGE_TITLE_TRANSLATIONS,
    path: dashboardRC.PAGE_TRANSLATIONS,
    userRoles: createUnionSet(
      USER_ROLES_NO_TRANSLATE_PAGE,
      USER_ROLES_GLOSSARY_PAGE,
      USER_ROLES_LANGUAGE_SELECTOR_PAGE,
      USER_ROLES_ACTIVE_BATCHES_PAGE
    ),
    pages: [
      {
        label: dashboardRC.PAGE_TITLE_LANGUAGES,
        path: dashboardRC.PAGE_LANGUAGES,
        userRoles: USER_ROLES_LANGUAGES_PAGE,
      },
      {
        label: dashboardRC.PAGE_TITLE_GLOSSARY,
        path: dashboardRC.PAGE_GLOSSARY,
        userRoles: USER_ROLES_GLOSSARY_PAGE,
      },
      {
        label: dashboardRC.PAGE_TITLE_NO_TRANSLATE_RULES,
        path: dashboardRC.PAGE_NO_TRANSLATE_RULES,
        userRoles: USER_ROLES_NO_TRANSLATE_PAGE,
      },
      ...(isStagingEnv
        ? [
            {
              label: dashboardRC.PAGE_TITLE_BATCHES,
              path: dashboardRC.PAGE_ACTIVE_BATCHES,
              userRoles: USER_ROLES_ACTIVE_BATCHES_PAGE,
            },
          ]
        : []),
    ],
  },
  {
    icon: <MdTimeline size={20} />,
    label: dashboardRC.PAGE_TITLE_METRICS,
    path: dashboardRC.PAGE_METRICS,
    userRoles: createUnionSet(
      USER_ROLES_TRANSLATED_TRAFFIC_PAGE,
      USER_ROLES_WORLD_TRAFFIC_PAGE
    ),
    pages: [
      {
        label: dashboardRC.PAGE_TITLE_TRANSLATED_TRAFFIC,
        path: dashboardRC.PAGE_TRANSLATED_TRAFFIC,
        userRoles: USER_ROLES_TRANSLATED_TRAFFIC_PAGE,
      },
      {
        label: dashboardRC.PAGE_TITLE_WORLD_TRAFFIC,
        path: dashboardRC.PAGE_WORLD_TRAFFIC,
        userRoles: USER_ROLES_WORLD_TRAFFIC_PAGE,
      },
    ],
  },
  {
    icon: <MdSettings size={20} />,
    label: dashboardRC.PAGE_TITLE_SETTINGS,
    path: dashboardRC.PAGE_SETTINGS,
    userRoles: createUnionSet(
      USER_ROLES_LANGUAGE_SETTINGS_PAGE,
      USER_ROLES_LANGUAGE_SELECTOR_PAGE,
      USER_ROLES_DEPLOYMENT_PAGE,
      USER_ROLES_INJECTION_PAGE,
      USER_ROLES_PROJECT_SCOPE_PAGE
    ),
    pages: [
      {
        label: dashboardRC.PAGE_TITLE_LANGUAGE_SETTINGS,
        path: dashboardRC.PAGE_LANGUAGE_SETTINGS,
        userRoles: USER_ROLES_LANGUAGE_SETTINGS_PAGE,
      },
      {
        label: dashboardRC.PAGE_TITLE_LANGUAGE_SELECTOR,
        path: dashboardRC.PAGE_LANGUAGE_SELECTOR,
        userRoles: USER_ROLES_LANGUAGE_SELECTOR_PAGE,
      },
      {
        label: dashboardRC.PAGE_TITLE_DEPLOYMENT,
        path: dashboardRC.PAGE_DEPLOYMENT,
        userRoles: USER_ROLES_DEPLOYMENT_PAGE,
      },
      {
        label: dashboardRC.PAGE_TITLE_INJECTION,
        path: dashboardRC.PAGE_INJECTION,
        userRoles: USER_ROLES_INJECTION_PAGE,
      },
      {
        label: dashboardRC.PAGE_TITLE_PROJECT_SCOPE,
        path: dashboardRC.PAGE_PROJECT_SCOPE,
        userRoles: USER_ROLES_PROJECT_SCOPE_PAGE,
      },
    ],
  },
  {
    icon: <IconDeer size={20} />,
    label: dashboardRC.PAGE_TITLE_DEBUG,
    path: dashboardRC.PAGE_STORE_DEBUG,
  },
  {
    icon: <MdFormatQuote size={20} />,
    label: dashboardRC.PAGE_TITLE_QUOTES,
    path: dashboardRC.PAGE_QUOTES,
  },
];

export const accountLinks: Omit<NavLink, 'pages'> & { pages: SubNavLink[] } = {
  icon: <BsFillPersonFill size={20} />,
  label: dashboardRC.PAGE_TITLE_ACCOUNT,
  path: dashboardRC.PAGE_ACCOUNT,
  pages: [
    {
      label: dashboardRC.PAGE_TITLE_MY_PROFILE,
      path: dashboardRC.PAGE_MY_PROFILE,
      userRoles: USER_ROLES_PROFILE_PAGE,
    },
    {
      label: dashboardRC.PAGE_TITLE_WORDS_SERVED_USAGE_DETAILS,
      path: dashboardRC.PAGE_WORDS_SERVED_USAGE_DETAILS,
      userRoles: USER_ROLES_WORDS_SERVED_PAGE,
    },
    {
      label: dashboardRC.PAGE_TITLE_MT_USAGE_DETAILS,
      path: dashboardRC.PAGE_MT_USAGE_DETAILS,
      userRoles: USER_ROLES_MT_USAGE_PAGE,
    },
    {
      label: dashboardRC.PAGE_TITLE_BILLING_INFORMATION,
      path: dashboardRC.PAGE_BILLING_INFORMATION,
      userRoles: USER_ROLES_BILLING_PAGE,
    },
  ],
};

export default navLinks;
