import { Badge, Flex, Image, Text } from '@mantine/core';
import {
  MRT_Cell,
  MRT_ColumnDef,
  MRT_RowData,
  MRT_RowSelectionState,
} from 'mantine-react-table';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import {
  AnchorLink,
  CustomTable,
  getBasicColumn,
  useCurrentProjectData,
} from 'ui';
import { CircleAvatar } from '../../shared/CircleAvatar';
import BatchProgressBar from '../BatchProgressBar';
import { getPhaseIcon, getStatusColor, normalizeBatchName } from '../utils';
import RowActionMenu from './RowActionMenu';
import dashboardRC from '../../../routerConstants';

type Props = {
  batches: ActiveBatch[];
  rowSelection: MRT_RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<MRT_RowSelectionState>>;
  handleBatchChange: (
    batches: string[],
    params: Record<string, string>
  ) => void;
  openCompleteBatchesModal: (batches: string[]) => void;
  openCancelBatchesModal: (batches: string[]) => void;
};
export const ActiveBatchesTable = ({
  batches,
  rowSelection,
  setRowSelection,
  handleBatchChange,
  openCompleteBatchesModal,
  openCancelBatchesModal,
}: Props) => {
  const { projectUserPermissionMap } = useCurrentProjectData();

  const columns: MRT_ColumnDef<ActiveBatch>[] = [
    {
      ...getBasicColumn('batch'),
      Cell: ({ row }: { row: MRT_RowData }) => {
        const { batch_key, target_language_name, batch_name } = row.original;
        const name = normalizeBatchName(batch_name);

        return (
          <Flex gap={3}>
            <AnchorLink
              to={`${dashboardRC.PAGE_BATCH_DETAILS}?batch=${batch_key}`}
            >
              {name}
            </AnchorLink>
            <Text>|</Text>
            <Text>{target_language_name}</Text>
          </Flex>
        );
      },
      size: 150,
      sortingFn: (rowA, rowB) =>
        rowA.original.batch_name.localeCompare(rowB.original.batch_name),
    },
    {
      ...getBasicColumn('created_utc', 'created'),
      Cell: ({ cell }: { cell: MRT_Cell<ActiveBatch, unknown> }) => (
        <Text>
          {moment.utc(cell.getValue() as string).format('MM/DD/YYYY')}
        </Text>
      ),
      size: 80,
    },
    {
      ...getBasicColumn('phase'),
      Cell: ({ cell }: { cell: MRT_Cell<ActiveBatch, unknown> }) => {
        const phase = cell.getValue() as string;
        if (!phase) {
          return '';
        }
        return (
          <>
            <Image w={16} h={16} mr={5} src={getPhaseIcon(phase)} alt="Phase" />
            <Text fw={700} tt="uppercase">
              {phase}
            </Text>
          </>
        );
      },
    },
    {
      ...getBasicColumn('phase_status', 'status'),
      Cell: ({ cell }: { cell: MRT_Cell<ActiveBatch, unknown> }) => {
        const status = cell.getValue() as string;

        if (!status) {
          return <></>;
        }

        return (
          <Badge
            bg={getStatusColor(status)}
            c="white"
            radius={4}
            tt="uppercase"
            w={100}
          >
            {status}
          </Badge>
        );
      },
      size: 75,
    },
    {
      ...getBasicColumn('word_count', 'words'),
      Cell: ({ cell }: { cell: MRT_Cell<ActiveBatch, unknown> }) => {
        const words = cell.getValue() as number;

        return (
          <Text
            ta="right"
            w={{ base: '100%', sm: '90%', md: '70%', lg: '50%', xl: '40%' }}
          >
            {`${words}` || '0'}
          </Text>
          //todo enable later when we have a link for this page

          // <AnchorLink>
          //   <Button variant="transparent" rightSection={<HiPencil />}>
          //     {words || '0'}
          //   </Button>
          // </AnchorLink>
        );
      },
      size: 70,
    },
    {
      ...getBasicColumn('urls'),
      Cell: ({ cell }: { cell: MRT_Cell<ActiveBatch, unknown> }) => {
        const urls = cell.getValue() as number;

        return (
          <Text
            ta="right"
            w={{ base: '100%', sm: '90%', md: '70%', lg: '50%', xl: '40%' }}
          >
            {`${urls}` || '0'}
          </Text>
          //todo enable later when we have a link for this page

          // <AnchorLink>
          //   <Button variant="transparent" rightSection={<BatchEditorIcon />}>
          //     {urls || '0'}
          //   </Button>
          // </AnchorLink>
        );
      },
      size: 60,
    },
    {
      ...getBasicColumn('progress'),
      Cell: ({ cell }: { cell: MRT_Cell<ActiveBatch, unknown> }) => {
        const progress = cell.getValue() as number;
        return <BatchProgressBar progress={progress} />;
      },
    },
    {
      ...getBasicColumn('assigned_user', 'assignee'),
      Cell: ({ cell }: { cell: MRT_Cell<ActiveBatch, unknown> }) => {
        const assigneeUserKey = cell.getValue() as string;
        const assignee =
          projectUserPermissionMap[assigneeUserKey] ?? ({} as User);
        const { first_name, last_name } = assignee;

        return (
          <Flex justify="center" align="center" gap={10}>
            {first_name && (
              <CircleAvatar name={first_name} backgroundColor="#6ED6CC" />
            )}
            <Text c="inherit">
              {first_name} {last_name}
            </Text>
          </Flex>
        );
      },
    },
    {
      ...getBasicColumn('due_date_utc', 'due'),
      Cell: ({ cell }: { cell: MRT_Cell<ActiveBatch, unknown> }) => {
        const dateString = cell.getValue() as string;

        if (!dateString) {
          return <></>;
        }

        return <Text>{moment.utc(dateString).format('MM/DD/YYYY')}</Text>;
      },
      size: 80,
    },
  ];

  return (
    <CustomTable
      columns={columns}
      data={batches}
      enableBottomToolbar={false}
      enableRowActions={true}
      enableRowSelection={true}
      enableSelectAll={false}
      mantineSelectCheckboxProps={{ indeterminate: undefined }}
      renderRowActions={({ row }: MRT_RowData) => (
        <RowActionMenu
          row={row}
          openCancelBatchModal={openCancelBatchesModal}
          openCompleteBatchModal={openCompleteBatchesModal}
          handleBatchChange={handleBatchChange}
        />
      )}
      positionActionsColumn={'last'}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
          size: 45,
          grow: false,
        },
        'mrt-row-select': {
          header: '',
          size: 40,
          grow: false,
        },
      }}
      onRowSelectionChange={setRowSelection}
      getRowId={(row) => row.batch_key}
      state={{ rowSelection, density: 'xs' }}
    />
  );
};
