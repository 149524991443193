import { useLocation, useNavigation } from 'react-router-dom';

/**
 * Returns an object containing the loading status and navigation status of the current location.
 * Primarily for use in components that rely on async data and correspondingly have load states.
 *
 * @return {{ isNavigating: boolean, isNavigatingAway: boolean }} An object with three boolean properties:
 *   - `isNavigating`: Indicates whether `navigation.state === 'loading'`
 *   -  `isNavigatingInPlace`: Indicates whether `navigation.state === 'loading'` and the user is _not_ navigating away
 *      (e.g., adding/removing search params while filtering chart views.)
 *   - `isNavigatingAway`: Indicates whether the user is navigating away from the current location.
 */
const useGetNavigationStatus = () => {
  const location = useLocation();
  const navigation = useNavigation();

  const isNavigating = navigation.state === 'loading';
  const isNavigatingAway =
    isNavigating && location.pathname !== navigation.location.pathname;
  const isNavigatingInPlace = isNavigating && !isNavigatingAway;

  return { isNavigating, isNavigatingInPlace, isNavigatingAway };
};

export default useGetNavigationStatus;
