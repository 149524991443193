import { Xapis } from '@glweb/xapis-client/src/xapis-wrappers/xapis';

export const fetchTranslatedTraffic = async (
  translationKey: string | string[],
  timeSliceFrom: string,
  type: string
) => {
  const newTranslationKey = Array.isArray(translationKey)
    ? translationKey.map((tKey) => tKey).join(',')
    : translationKey;

  const params = {
    type,
    series: 'num_requests',
    rollup: timeSliceFrom === '1 year' ? 'month' : 'day',
    timeslice_from: timeSliceFrom,
    translation_key: newTranslationKey,
  };

  return Xapis.Metrics.get(params);
};
