import { AccumulatedTranslatedTraffic } from '../../types';

export const getAccumulatedTraffic = (
  timeSlices: TranslatedTrafficTimeslice[]
) => {
  return timeSlices.reduce(
    (accum: AccumulatedTranslatedTraffic[], { num_requests, timeslice }) => {
      const prevTimesliceFound = accum.find(
        (accumTranslatedTraffic) =>
          accumTranslatedTraffic.timeslice === timeslice
      );
      if (prevTimesliceFound) {
        const timesliceIndex = accum.findIndex(
          ({ timeslice: prevTimeslice }) => prevTimeslice === timeslice
        );
        if (timesliceIndex !== -1) {
          accum[timesliceIndex] = {
            ...prevTimesliceFound,
            num_requests: prevTimesliceFound.num_requests + num_requests,
          };
        }
      } else accum.push({ num_requests: num_requests, timeslice });
      return accum;
    },
    []
  );
};

export const getTranslatedTraffic = (
  prevTimeSlices: TranslatedTrafficTimeslice[],
  timeSlices: TranslatedTrafficTimeslice[]
) => {
  const accumulatedTranslatedTraffic = getAccumulatedTraffic(timeSlices);

  const convert = ({ timeslice = '', num_requests = '' }) => ({
    timeslice,
    num_requests: Number(num_requests),
  });
  const getTotal = (traffic: { num_requests: number }[]) =>
    Math.round(
      traffic.reduce(
        (accum, { num_requests = 0 }) => accum + num_requests,
        0
      ) as number
    ) || 0;

  const convertedPrevTranslatedTraffic = prevTimeSlices.map(convert);
  const convertedTranslatedTraffic = accumulatedTranslatedTraffic.map(convert);

  const totalPrevTranslatedTraffic = getTotal(convertedPrevTranslatedTraffic);
  const totalTranslatedTraffic = getTotal(convertedTranslatedTraffic);

  return {
    accumulatedTranslatedTraffic,
    totalTranslatedTraffic,
    convertedTranslatedTraffic,
    totalPrevTranslatedTraffic,
  };
};
