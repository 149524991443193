import getNodeProperties from './getNodeProperties';

export const getFullPath = (nodeId: string, label: string): string => {
  // console.log("getting full path")
  const idArray = nodeId.split('-');
  if (idArray.length === 1) {
    return label || '';
  } else {
    idArray.pop();
    const parentId = idArray.join('-');
    const parent = document.querySelector(
      `[data-id='${parentId}']`
    ) as HTMLElement;
    if (parent?.dataset?.nodeinfo) {
      const {
        isFake: parentIsFake,
        contentUrlOrig,
        label: parentLabel,
        id: parentId,
      } = getNodeProperties(parent?.dataset?.nodeinfo || '{}');

      if (parentIsFake) {
        return getFullPath(parentId as string, parentLabel + label);
      } else {
        return contentUrlOrig + label;
      }
    }
  }
  return label;
};

export default getFullPath;
