import { notifications } from '@mantine/notifications';

const success = (e: string | unknown, successMessage? :string) => {
  if (successMessage) return notifications.show({ message: successMessage });

  if (typeof e === 'string') return notifications.show({ message: e });

  if (
    typeof e === 'object' &&
    e !== null &&
    'data' in e &&
    typeof e.data === 'object' &&
    e.data !== null &&
    'message' in e.data &&
    typeof e.data.message === 'string'
  ) {
    return notifications.show({ message: e.data.message });
  }

  return notifications.show({ message: 'Success!' });
};

export default success;
