import { MRT_RowData } from 'mantine-react-table';
import { Menu, UnstyledButton } from '@mantine/core';
import { HiDotsVertical } from 'react-icons/hi';
import React, { useState } from 'react';
import BatchActionsDropdown from '../BatchActionsDropdown';

type Props = {
  row: MRT_RowData;
  openCancelBatchModal: (batches: string[]) => void;
  openCompleteBatchModal: (batches: string[]) => void;
  handleBatchChange: (
    batches: string[],
    params: Record<string, string>
  ) => void;
};

const RowActionMenu = ({
  row,
  openCancelBatchModal,
  openCompleteBatchModal,
  handleBatchChange,
}: Props) => {
  const [opened, setOpened] = useState(false);
  return (
    <Menu
      closeDelay={200}
      position={'left-start'}
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <UnstyledButton
          bg={opened ? 'secondary1.1' : 'transparent'}
          p={5}
          style={{ borderRadius: 5, alignItems: 'center', display: 'flex' }}
        >
          <HiDotsVertical size={20} color="var(--mantine-color-cta1-2)" />
        </UnstyledButton>
      </Menu.Target>
      <BatchActionsDropdown
        batches={[row.original.batch_key]}
        openCancelBatchModal={openCancelBatchModal}
        openCompleteBatchModal={openCompleteBatchModal}
        handleBatchChange={(params) =>
          handleBatchChange([row.original.batch_key], params)
        }
      />
    </Menu>
  );
};

export default RowActionMenu;
