import { STAGING_GLGO_DASHBOARD_URL } from '@glweb/constants';
import { alpha2List, encodeProjectKey, isSuccessStatus } from 'helpers';
import { NavigateFunction } from 'react-router-dom';
import { Xapis, xapisEnv } from '@glweb/xapis-client';
import { failureNotification, successNotification } from 'ui';
import { dashboardRC } from '../../../routerConstants';
import createCheckoutSession, {
  type PayAddress,
  defaultPayAddress,
} from './createCheckoutSession';
import { type PlanOption } from './PlanInformation';
import { updateAllPaymentSourceAddresses } from './updatePaymentSourceAddress';

const deepCopyAddress = (source: PaymentSource) => {
  const {
    owner: { address = {}, email = '', firstName = '', lastName = '' } = {},
  } = source;

  const name = `${firstName} ${lastName}`;

  // Deep copy
  return {
    address: {
      ...address,
    },
    email,
    name,
    // phone,
  };
};

const getAddresses = (sources: PaymentSource[]) => {
  // Currently all payment source's addresses will update at once on DR Checkout's config function "onAddressComplete"
  // TODO: Remove this and properly add in unique addresses after the address book for XPAY is added:
  // TODO: https://techqa1.translations.com/browse/GLINFRA-249
  const filteredSources = sources[0] ? [sources[0]] : [];

  const addresses = filteredSources.map((source: PaymentSource) =>
    deepCopyAddress(source)
  );
  return [...new Set(addresses)]; // returns unique addresses
};

const getCheckoutData = (
  sku = '',
  phone: string,
  sources: PaymentSource[],
  defaultSource: PaymentSource
) => {
  const addresses = getAddresses(sources).map((a) => ({ ...a, phone }));
  const billTo = { ...deepCopyAddress(defaultSource), phone };
  const { email = '' }: Pick<PayAddress, 'email'> = billTo;

  return {
    items: [
      {
        sku,
        quantity: 1,
      },
    ],
    ...(addresses.length > 0 && {
      addresses,
      billTo,
      email,
    }),
  };
};

export type UpgradeParams = {
  xapisUser: User;
  selectedPlan: PlanOption | null;
  payKey: string;
  projectKey: string;
  sources: PaymentSource[];
  defaultSource: PaymentSource;
};

export const upgrade = async ({
  xapisUser,
  selectedPlan,
  payKey,
  projectKey = '',
  sources,
  defaultSource,
}: UpgradeParams) => {
  try {
    const { sku = '', subscription_type = '' } = selectedPlan || {};
    const isYearly = subscription_type === 'YEARLY';
    const billToCountries = isYearly
      ? alpha2List.filter((value: string) => value !== 'DE')
      : alpha2List;
    const { digitalRiverKey } = xapisEnv.getHost;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const drCheckout = new DigitalRiverCheckout(digitalRiverKey);
    const origin =
      window.location.hostname === 'localhost'
        ? STAGING_GLGO_DASHBOARD_URL
        : window.location.origin;
    const redirectUrl = `${origin}/${encodeProjectKey(projectKey)}`;
    // Documentation for Digital River checkout config:
    // https://docs.digitalriver.com/digital-river-api/general-resources/digitalrivercheckout.js-reference/digitalrivercheckout-object/configuring-the-modal
    const config = {
      options: {
        style: {
          modal: {
            logo: `${origin}/glweb-logo.svg`,
          },
        },
        thankYouPage: `${redirectUrl}/${dashboardRC.PAGE_ORDER_CONFIRMATION}`,
        billToCountries,
      },
      onInit: () => {},
      onReady: () => {},
      onAddressComplete: (address: {
        address: { billing: PayAddress; shipping: PayAddress };
      }) => {
        const { address: { billing: newAddress = defaultPayAddress } = {} } =
          address || {};
        updateAllPaymentSourceAddresses(newAddress, sources);
      },
      onDeliveryComplete: () => {},
      onPaymentCancel: () => {},
      onCheckoutComplete: ({ id }: { id: string }) =>
        sessionStorage.setItem('orderId', id),
      onError: () => {},
      onClose: () => {
        const payKey = xapisEnv.getHost.payKey;
        // It's possible that the user updated the payment sources during checkout, so we need to fetch again just in-case
        Xapis.Source.get(payKey);
      },
    };
    const { id: checkoutId } = await createCheckoutSession(
      getCheckoutData(sku, xapisUser.phone || '', sources, defaultSource),
      payKey,
      projectKey
    );
    drCheckout.createModal(checkoutId, config);
  } catch (error) {
    failureNotification(error);
  }
};

export type DowngradeParams = {
  xapisUser: User;
  selectedPlan: PlanOption | null;
  payKey: string;
  projectKey: string;
  navigate: NavigateFunction;
  sources: PaymentSource[];
  defaultSource: PaymentSource;
};

export const downgrade = async ({
  xapisUser,
  selectedPlan,
  payKey,
  projectKey,
  navigate,
  sources,
  defaultSource,
}: DowngradeParams) => {
  const { sku: currentSelectedSku = '' } = selectedPlan || {};

  const { items = [], needs_payment: needsPayment = false } =
    await createCheckoutSession(
      getCheckoutData(
        currentSelectedSku,
        xapisUser.phone || '',
        sources,
        defaultSource
      ),
      payKey,
      projectKey
    );

  if (items.length > 0 && !needsPayment) {
    navigate(`../../../${dashboardRC.PAGE_DOWNGRADE_CONFIRMATION}`);
  }
};

export type RestartParams = {
  project_key: string;
  sku: string;
  revalidator: {
    revalidate: () => void;
  };
  closeModal: () => void;
};

export const restart = async ({
  project_key,
  sku,
  revalidator,
  closeModal,
}: RestartParams) => {
  const { payKey } = xapisEnv.getHost;

  try {
    const res = await Xapis.Subscription.put(payKey, project_key, sku);

    if (isSuccessStatus(res.status)) {
      successNotification('Subscription restarted successfully.');
      revalidator.revalidate();
    }
  } catch (e) {
    console.error(e);
    failureNotification('Something went wrong.');
  }
  closeModal();
};

export const handleAddCardButtonClick = (
  phone: string,
  openModal: () => void
) => {
  if (phone) {
    openModal();
  } else {
    failureNotification(
      'Please ensure you have a valid phone number before attempting to add a card.'
    );
  }
};

type CancelParams = {
  xapisUser: User;
  payKey: string;
  projectKey: string;
  reason: string;
  navigate: NavigateFunction;
  cleanupOnError: () => void;
  notifyAction: () => void;
};

export const handleCancelSubscription = ({
  xapisUser,
  payKey,
  projectKey,
  navigate,
  cleanupOnError,
  notifyAction,
}: CancelParams) => {
  Xapis.Subscription.delete({
    payKey,
    projectKey,
    uKey: xapisUser.user_key,
  })
    .then(() => {
      navigate(
        `../../../../../${encodeProjectKey(projectKey)}/${dashboardRC.PAGE_CANCELLATION_CONFIRMATION}`
      );
      notifyAction();
    })
    .catch(() => {
      failureNotification(
        'We are unable to delete your subscription at this time.'
      );
      cleanupOnError();
    });
};
