import { type UserRole, useViewPermissions } from 'helpers';
import { useCurrentProjectData, useUserProvider } from 'ui';

export const RestrictedComponent = ({
  allowedRoles,
  allowedProjectTypes,
  allowedUserTypes,
  children,
}: {
  allowedRoles?: Set<UserRole>;
  allowedProjectTypes?: Set<string>;
  allowedUserTypes?: Set<UserType>;
  children: JSX.Element;
}) => {
  const {
    xapisUser: { user_type },
  } = useUserProvider();

  const {
    project: { project_type: projectType },
  } = useCurrentProjectData();

  const { rolePermissions, hasViewPermissions } = useViewPermissions();

  if (allowedRoles && !hasViewPermissions(rolePermissions, allowedRoles)) {
    return <></>;
  }

  if (allowedUserTypes && !allowedUserTypes.has(user_type!)) {
    return <></>;
  }

  if (allowedProjectTypes && !allowedProjectTypes.has(projectType)) {
    return <></>;
  }

  return children;
};
