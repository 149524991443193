/// <reference types="vite/client" />
import { LIVE_SSO_URL } from '@glweb/constants';
import { isDesktopApp } from 'helpers';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { shouldSkipSSO } from '../utils/shouldSkipSSO';

export function getOidcConfig(ssoUrl: string): UserManagerSettings {
  const isDesktop = isDesktopApp();
  if (shouldSkipSSO()) {
    return {
      client_id: 'AbraCadabra',
      redirect_uri: 'road-to-nowhere',
      authority: `localhost`,
      automaticSilentRenew: false,
      loadUserInfo: true,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };
  }
  const { origin, scope, clientId } = getOicdSettings(ssoUrl);

  return {
    authority: ssoUrl,
    client_id: clientId,
    redirect_uri: `${origin}/post_login${isDesktop ? '/' : ''}`,
    post_logout_redirect_uri: `${origin}/${isDesktop ? 'post_login/' : 'post_logout'}`,
    silent_redirect_uri: `${origin}/silent_renew`,
    scope,
    response_type: 'code',
    response_mode: isDesktop ? 'fragment' : 'query',
    redirectMethod: 'replace',
    automaticSilentRenew: false,
    revokeTokensOnSignout: true,
    loadUserInfo: true,
    silentRequestTimeoutInSeconds: 15,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };
}

function getOicdSettings(ssoUrl: string) {
  const isDesktop = isDesktopApp();
  if (
    isDesktop ||
    typeof window === 'undefined' ||
    typeof localStorage === 'undefined'
  )
    return {
      origin: 'glwebstudio:/',
      scope: 'openid email clients GLWebStudio offline_access',
      clientId: 'okXe98gEgCggcvOngSnIcl8SCzcl0P132',
    };
  const isLiveSso = ssoUrl === LIVE_SSO_URL;
  const { protocol, hostname, port } = window.location;
  const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  const scope = `openid email GlobalLinkGO clients ${isLiveSso ? '' : 'offline_access'}`;
  return { origin, scope, clientId: 'CqCVnyVRs72RyJT8NhEmI4oqXx8t3Maq2' };
}
