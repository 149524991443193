import { RouteObject } from 'react-router-dom';
import { CustomCssLanding } from 'ui';
import DeploymentWrapper from '../../components/settings/deployment/DeploymentWrapper';
import Injection from '../../components/settings/injection/Injection';
import addLanguagesAction from '../../components/settings/manage-languages/addLanguagesAction';
import ManageLanguages from '../../components/settings/manage-languages/ManageLanguages';
import CurrentScopeHome from '../../components/settings/project-scope/landing-page/CurrentScopeHome';
import ScopeUrlsTree from '../../components/settings/project-scope/ScopeUrlsTree';
import SelectorCard from '../../components/settings/selector-customization/SelectorCard';
import {
  USER_ROLES_DEPLOYMENT_PAGE,
  USER_ROLES_INJECTION_PAGE,
  USER_ROLES_LANGUAGE_SELECTOR_PAGE,
  USER_ROLES_LANGUAGE_SETTINGS_PAGE,
  USER_ROLES_PROJECT_SCOPE_PAGE,
} from '../../constants';
import languageCodesLoader from '../../loaders/languageCodesLoader';
import spiderTreeLoader from '../../loaders/spiderTreeLoader';
import RestrictedRoute from '../../RestrictedRoute';
import dashboardRC from '../../routerConstants';

const settingsRoutes: Array<RouteObject & { path: string }> = [
  {
    path: dashboardRC.PAGE_LANGUAGE_SETTINGS,
    children: [
      {
        index: true,
        loader: () => languageCodesLoader('ty'),
        action: addLanguagesAction,
        element: (
          <RestrictedRoute allowedRoles={USER_ROLES_LANGUAGE_SETTINGS_PAGE}>
            <ManageLanguages />
          </RestrictedRoute>
        ),
      },
      {
        path: dashboardRC.PAGE_CUSTOM_CSS_EDITOR,
        element: (
          <RestrictedRoute allowedRoles={USER_ROLES_LANGUAGE_SETTINGS_PAGE}>
            <CustomCssLanding />
          </RestrictedRoute>
        ),
      },
    ],
  },
  {
    path: dashboardRC.PAGE_LANGUAGE_SELECTOR,
    element: (
      <RestrictedRoute allowedRoles={USER_ROLES_LANGUAGE_SELECTOR_PAGE}>
        <SelectorCard />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_DEPLOYMENT,
    element: (
      <RestrictedRoute allowedRoles={USER_ROLES_DEPLOYMENT_PAGE}>
        <DeploymentWrapper />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_INJECTION,
    element: (
      <RestrictedRoute allowedRoles={USER_ROLES_INJECTION_PAGE}>
        <Injection />
      </RestrictedRoute>
    ),
  },
  {
    path: dashboardRC.PAGE_PROJECT_SCOPE,
    children: [
      {
        index: true,
        element: (
          <RestrictedRoute allowedRoles={USER_ROLES_PROJECT_SCOPE_PAGE}>
            <CurrentScopeHome />
          </RestrictedRoute>
        ),
      },
      {
        path: ':yyTranslationId/urls',
        element: (
          <RestrictedRoute allowedRoles={USER_ROLES_PROJECT_SCOPE_PAGE}>
            <ScopeUrlsTree />
          </RestrictedRoute>
        ),
        loader: spiderTreeLoader,
      },
    ],
  },
];

export default settingsRoutes;
