import { decodeProjectId, isValidPID } from 'helpers';
import { Params, useParams } from 'react-router-dom';

const useGLGOParams = (): Params<string> => {
  const params = useParams();
  if (!isValidPID(params.projectId)) return params;
  const projectKey = decodeProjectId(params.projectId || '');
  return { ...params, projectKey };
};

export default useGLGOParams;
