import React from 'react';
import { Box, Container, Grid, ScrollArea, Text } from '@mantine/core';
import { BasicHorizontalDivider } from '../../shared/BasicHorizontalDivider';
import { StatusCircleIcon } from 'ui';
import { CardInterface } from '../../../components/types';

type Props = CardInterface & {
  targets: TranslationKey[];
};

export const MyLanguages = ({ children, targets }: Props) => {
  if (targets.length > 0) {
    const filteredTargets = targets.filter(
      ({ source_lang_code = '', target_lang_code = '', is_active = false }) => {
        return (
          is_active &&
          target_lang_code !== 'YY' &&
          target_lang_code !== source_lang_code
        );
      }
    );

    return (
      <>
        <ScrollArea
          mah={300}
          data-testid="pw-my-languages-container"
          offsetScrollbars
          scrollbars="y"
        >
          {filteredTargets?.map(
            (
              { target_lang_name = '', target_lang_code = '', is_live = false },
              index
            ) => (
              <div key={target_lang_code}>
                <Grid py={10} align="center">
                  <Grid.Col ml={5} span={{ base: 4, lg: 2 }}>
                    <StatusCircleIcon
                      status={is_live ? 'live' : 'private'}
                      float="right"
                    />
                  </Grid.Col>
                  <Grid.Col
                    fw={600}
                    span={{ base: 6, lg: 8 }}
                    className="pw-my-languages-target-lang-name"
                  >
                    <Container p={0}>
                      <Text fw={600}>{target_lang_name}</Text>
                    </Container>
                  </Grid.Col>
                </Grid>
                {index < filteredTargets?.length - 1 ? (
                  <BasicHorizontalDivider mobileMargin="0" />
                ) : (
                  <></>
                )}
              </div>
            )
          )}
        </ScrollArea>
        <Box>{children}</Box>
      </>
    );
  }

  return null;
};

export default MyLanguages;
