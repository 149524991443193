import {
  Box,
  Button,
  Checkbox,
  Flex,
  FloatingPosition,
  Menu,
  ScrollArea,
  Text,
} from '@mantine/core';
import { MdArrowDropDown } from 'react-icons/md';
import classes from './MultiSelector.module.css';

type SelectorProps = {
  title: string;
  position?: FloatingPosition;
  height?: number | string;
  buttonWidth?: number | string;
  withinPortal?: boolean;
  selectAll?: boolean;
  options: {
    value: string;
    label: string;
  }[];
  selected: string[];
  onSelect: (value: string) => void;
  limit?: number;
};

export const MultiSelector = ({
  title = 'Select',
  position,
  height,
  buttonWidth,
  withinPortal,
  selectAll = false,
  options,
  selected,
  limit,
  onSelect,
}: SelectorProps) => {
  const isAllSelected = selected.length === options.length;

  const isOptionSelected = (value: string, i: number) => {
    if (limit && isAllSelected) {
      return i < limit;
    }
    return selected.includes(value);
  };

  return (
    <Flex>
      <Menu
        position={position || 'bottom-end'}
        withinPortal={withinPortal !== undefined ? withinPortal : true}
        data-testid="pw-metrics-summary-languages-button"
        closeOnItemClick={false}
        keepMounted
      >
        <Menu.Target>
          <Button
            variant="transparent"
            h={height || 35}
            w={buttonWidth || 'fit-content'}
            classNames={{
              root: classes.filterButton,
              inner: classes.filterText,
            }}
            rightSection={<MdArrowDropDown size={24} />}
          >
            {title}
          </Button>
        </Menu.Target>
        <Menu.Dropdown
          w={290}
          className={classes.filterMenu}
          data-testid="pw-metrics-summary-languages-dropdown"
        >
          <ScrollArea scrollbars="y" offsetScrollbars>
            <Box
              mah={222}
              component="ul"
              style={{ listStyleType: 'none', padding: 0, margin: 0 }}
            >
              {selectAll && (
                <Box component="li" className={classes.filterItem}>
                  <Flex
                    align="center"
                    columnGap={15}
                    onClick={() => onSelect('All')}
                  >
                    <Checkbox readOnly checked={isAllSelected} size="xs" />
                    <Text fw={isAllSelected ? 600 : 400}>All</Text>
                  </Flex>
                </Box>
              )}
              {options.map(({ label = '', value = '' }, i) => {
                const isActive = isOptionSelected(value, i);
                return (
                  <Box
                    component="li"
                    display="flex"
                    key={value}
                    onClick={() => onSelect(value)}
                    className={classes.filterItem}
                  >
                    <Flex align="center" columnGap={15}>
                      <Checkbox readOnly checked={isActive} size="xs" />
                      <Text fw={isActive ? 600 : 400}>{label}</Text>
                    </Flex>
                  </Box>
                );
              })}
            </Box>
          </ScrollArea>
        </Menu.Dropdown>
      </Menu>
    </Flex>
  );
};
