import { useDisclosure } from '@mantine/hooks';
import { useContext, useState } from 'react';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';

import { Flex, Popover, Text, UnstyledButton } from '@mantine/core';
import {
  formatDate,
  getTranslationSouce,
  isAutoTranslation,
} from '../../functions/segmentsFunctions';
import {
  GoBack,
  GoForward,
  HistoryIcon,
  UndoIcon,
} from '../../icons/ActionIcons';
import { useIsModified } from '../../store/SegmentsState';
import { ActionBar } from '../Generic/ActionBar';
import { ConfirmModal } from '../Generic/ConfirmModal';
import QuickAction from '../Generic/QuickAction';
import { TranslationHistory } from '../TranslationHistory/TranslationHistory';

import classes from './BottomBar.module.css';

type Props = {
  segment: Segment;
  latest?: Jliff[];
  isEdited: boolean;
  onUndoAll: () => void;
  onUndo: () => void;
  onRedo: () => void;
  undoRedoStatus: { canUndo: boolean; canRedo: boolean };
  applyTranslationHistory: (jliffs: Jliff[]) => void;
  deleteSegment: (segmentHash: string) => Promise<void>;
  showAllTags: boolean;
};

export const BottomBar = ({
  segment,
  isEdited,
  onUndo,
  onUndoAll,
  onRedo,
  undoRedoStatus,
  applyTranslationHistory,
  deleteSegment,
  showAllTags,
}: Props) => {
  const { viewOnly } = useContext(SegmentEditorContext).settings;
  const isAutomaticTranslation = isAutoTranslation(segment.translation_source);
  const disableDeleteProTranslation =
    viewOnly || isAutomaticTranslation || !segment.target_jliff;

  const isModified = useIsModified(segment.segment_hash);
  const translationDate = formatDate(segment.translation_utc || '');
  const { translationMethods } = useContext(SegmentEditorContext);

  const isUsesMt = ['MT', 'DDT'].some((method) =>
    translationMethods.includes(method)
  );
  const showHistoryAction = !isAutomaticTranslation || segment.num_history;

  const deleteModalMessage = isUsesMt
    ? 'This project uses MT backfill. By removing the Human Translation, the segment will be translated by machine.'
    : 'This project is HT only. By removing the Human Translation, the segment will become untranslated.';

  const [showHistory, { open, close }] = useDisclosure(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleDeletePreview = () => {
    setConfirmModalOpen(true);
    setShowDelete(false);
  };

  const confirmDelete = async () => {
    await deleteSegment(segment.segment_hash);
    setConfirmModalOpen(false); // close the modal after confirming
  };

  return (
    <Flex className={classes.bottomBar}>
      <Flex gap={4} align="center">
        {showHistoryAction ? (
          <QuickAction
            icon={<HistoryIcon size={18} />}
            isActive={showHistory}
            isDisabled={!segment.num_history && !isEdited}
            onClick={open}
            label="Translation History"
          />
        ) : (
          <div />
        )}

        <Popover
          disabled={disableDeleteProTranslation}
          opened={showDelete}
          onChange={setShowDelete}
          shadow="sm"
          position="top-start"
          withArrow
          arrowSize={8}
          arrowOffset={30}
        >
          <Popover.Target>
            <UnstyledButton
              className={classes.sourceName}
              onClick={() => setShowDelete(!showDelete)}
              disabled={disableDeleteProTranslation}
            >
              {getTranslationSouce(segment)}
            </UnstyledButton>
          </Popover.Target>
          <Popover.Dropdown>
            <UnstyledButton
              className={classes.deletePro}
              onClick={handleDeletePreview}
            >
              Remove PRO Translation
            </UnstyledButton>
          </Popover.Dropdown>
        </Popover>
        {!isAutomaticTranslation && (
          <Text fz={12} c="blue.7">
            {translationDate}
          </Text>
        )}
      </Flex>
      <ActionBar
        actions={[
          {
            icon: <GoBack />,
            onClick: onUndo,
            disabled: !undoRedoStatus.canUndo,
          },
          {
            icon: <GoForward />,
            onClick: onRedo,
            disabled: !undoRedoStatus.canRedo,
          },
          {
            icon: <UndoIcon />,
            onClick: onUndoAll,
            disabled: !isModified,
            label: 'Undo All Changes',
          },
        ]}
      />

      {showHistory && (
        <TranslationHistory
          segment={segment}
          onClose={close}
          applyTranslationHistory={applyTranslationHistory}
          showAllTags={showAllTags}
        />
      )}

      <ConfirmModal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onCancel={() => setConfirmModalOpen(false)}
        onConfirm={confirmDelete}
      >
        <Text>{deleteModalMessage}</Text>
      </ConfirmModal>
    </Flex>
  );
};
