import { useViewPermissions, UserRole } from 'helpers';
import { Navigate, Outlet } from 'react-router-dom';
import { useCurrentProjectData, useUserProvider } from 'ui';

const RestrictedRoute = ({
  allowedRoles,
  allowedProjectTypes,
  allowedUserTypes,
  children,
}: {
  allowedRoles?: Set<UserRole>;
  allowedProjectTypes?: Set<string>;
  allowedUserTypes?: Set<UserType>;
  children?: JSX.Element;
}) => {
  const {
    xapisUser: { user_type },
  } = useUserProvider();

  const {
    project: { project_type: projectType },
  } = useCurrentProjectData();

  const { rolePermissions, hasViewPermissions } = useViewPermissions();

  if (allowedRoles && !hasViewPermissions(rolePermissions, allowedRoles)) {
    return <Navigate to="/" replace />;
  }

  if (allowedProjectTypes && !allowedProjectTypes.has(projectType)) {
    return <Navigate to="/" replace />;
  }

  if (allowedUserTypes && !allowedUserTypes.has(user_type!)) {
    return <></>;
  }

  return children || <Outlet />;
};

export default RestrictedRoute;
