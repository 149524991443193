import { desktopRC } from '@glweb/constants';
import { Card, Flex, Grid, Text } from '@mantine/core';
import { encodeKey, quoteHTWordCount } from 'helpers';
import { OpenContext, StatusCircleIcon, useGLGOParams } from 'ui';

type Props = {
  quote: Quote;
  yyTarget?: TranslationKey;
};

export const QuoteCard = ({ quote, yyTarget }: Props) => {
  const { projectId = '' } = useGLGOParams();

  const { name, startUrl, status, projectKey } = quote || {};

  const quotePath = `/${projectId}/${desktopRC.QUOTE}/${encodeKey(yyTarget?.translation_key || '')}?quoteId=${quote.id}`;

  return name && startUrl ? (
    <Card p={0}>
      <Grid
        display="flex"
        mih={105}
        align="center"
        my="auto"
        px={{ base: 20, sm: 40 }}
        py={10}
      >
        <Grid.Col span={{ base: 4, sm: 4 }}>
          <Flex direction="row" align="center" columnGap={10}>
            <StatusCircleIcon status={status === 'open' ? 'live' : 'private'} />
            <Text fw={500}>{name}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text c="text2.5" fz="xs" fw={700}>
            START URL
          </Text>
          <Text
            fz="md"
            fw={500}
            style={{
              maxWidth: '12ch',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {startUrl}
          </Text>
        </Grid.Col>

        <Grid.Col span={3}>
          <Text c="text2.5" fz="xs" fw={700}>
            PROJECT
          </Text>
          <Text fz="md" fw={500}>
            {projectKey || 'N/A'}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 3, sm: 2 }}>
          <Text c="text2.5" fz="xs" fw={700}>
            # WORDS HT
          </Text>
          <Text c="text2.6" fz="md" fw={500}>
            {new Intl.NumberFormat().format(quoteHTWordCount(quote))}
          </Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex align={'center'} justify={'center'} gap={8}>
            <OpenContext path={quotePath} />
          </Flex>
        </Grid.Col>
      </Grid>
    </Card>
  ) : null;
};

export default QuoteCard;
