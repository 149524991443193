import { GLWEB_SUPPORT_URL } from '@glweb/constants';
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  List,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { encodeKey, getYYTarget, hexToObject } from 'helpers';
import { useMemo, useState } from 'react';
import { HiPencil } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { AnchorLink, useCurrentProjectData, useGLGOParams } from 'ui';
import ScopeChangeFormModal from './ScopeChangeFormModal';
import { defaultScopeDetails, scopeInfo } from './scopeInfo';

const getCurrentScope = (currentScope: string) => {
  return (
    scopeInfo.find(({ scopeName }: { scopeName: string }) => {
      return scopeName === currentScope;
    }) || defaultScopeDetails
  );
};

const getScopeIconProps = (name: string, color: string) => {
  const baseProps = { height: '20px', width: '20px' };
  return name === 'all'
    ? baseProps
    : { ...baseProps, fill: color, size: '20px' };
};

export const CurrentScopeHome = () => {
  const { projectId } = useGLGOParams();
  const { project: { translations = [] } = {} } = useCurrentProjectData();

  const yyTarget = getYYTarget(translations) as TranslationKey;

  const { scope_preset = '' } = useMemo(() => {
    return hexToObject(yyTarget?.translation_config || '');
  }, [yyTarget?.translation_config]);

  const defaultScope = ['all', 'include', 'exclude'].includes(scope_preset)
    ? scope_preset
    : 'all';

  const [currentScope, setCurrentScope] = useState(defaultScope);
  const [showFormModal, setShowFormModal] = useState(false);
  const { scopeName, scopeDesc, scopeColor, scopeButtonText, ScopeIcon } =
    getCurrentScope(currentScope);

  let scopeTitle = scopeName === 'all' ? 'Everything' : scopeName;
  scopeTitle = `${scopeTitle[0].toUpperCase()}${scopeTitle.substring(1)}`;

  if (!currentScope) return <></>;

  return (
    <>
      {showFormModal && (
        <ScopeChangeFormModal
          setShowFormModal={setShowFormModal}
          showFormModal={showFormModal}
          currentScope={currentScope}
          setCurrentScope={setCurrentScope}
        />
      )}
      <Card mt={16} w="100%" maw={530} p={{ base: 20, md: 30 }}>
        <Stack gap="2rem">
          <Stack gap={0}>
            <Flex
              w="100%"
              align="center"
              justify="space-between"
              columnGap="0.25rem"
            >
              <Flex columnGap="1rem" align="center">
                <ScopeIcon {...getScopeIconProps(scopeName, scopeColor)} />
                <Box>
                  <Title order={1} display="inline" fz="xl" ml={5}>
                    <Text fz="inherit" fw="inherit" c={scopeColor} span>
                      {scopeTitle}
                    </Text>
                    {scopeTitle === 'Everything'
                      ? ' on your site is in scope'
                      : " is your project's scope"}
                  </Title>
                </Box>
              </Flex>
              <ActionIcon
                p={5}
                size="auto"
                variant="transparent"
                onClick={() => setShowFormModal(true)}
              >
                <HiPencil size={24} color="#0071DB" />
              </ActionIcon>
            </Flex>
            <Flex direction="row" columnGap={30} pl={10} mt={20}>
              <Divider
                my={5}
                orientation="vertical"
                size="sm"
                color="border.1"
              />
              <Text>{scopeDesc}</Text>
            </Flex>
            <Link
              to={`${encodeKey(yyTarget?.translation_key || '', 't')}/urls`}
              style={{
                textDecoration: 'none',
                alignSelf: 'center',
              }}
            >
              <Button mt="1rem">{scopeButtonText}</Button>
            </Link>
          </Stack>
          <Stack gap="0.5rem">
            <Title order={3} tt="uppercase">
              What is the scope for your Project?
            </Title>
            <Box>
              <Text>
                For various reasons, there will inevitably be content on your
                site that doesn't require translation. Everything that is in
                scope will be translated. Anything out-of-scope will not be
                translated.
              </Text>
              <Text>
                There are multiple ways to manage what should and shouldn’t be
                translated.
              </Text>
              <List c="font1.1" fz="sm">
                <List.Item>
                  Individual words and phrases are best managed in the{' '}
                  <AnchorLink
                    fz="inherit"
                    to={`/${projectId}/translations/glossary`}
                  >
                    Glossary
                  </AnchorLink>
                  .
                </List.Item>
                <List.Item>
                  Content blocks or components are best managed using a CSS
                  class.
                </List.Item>
                <List.Item>
                  Entire pages and your site map as a whole are best managed
                  here in Project Scope.
                </List.Item>
              </List>
              <Text>
                To learn more,{' '}
                <AnchorLink
                  fz="inherit"
                  to={`${GLWEB_SUPPORT_URL}/article/124-can-i-limit-what-parts-of-my-site-are-translated`}
                  target="_blank"
                >
                  see here
                </AnchorLink>
                .
              </Text>
            </Box>
          </Stack>
        </Stack>
      </Card>
    </>
  );
};

export default CurrentScopeHome;
