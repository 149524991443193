import React from 'react';
import { Flex, Loader, Text, Modal } from '@mantine/core';

interface Props {
  loader?: 'oval' | 'bars' | 'dots';
  children?: React.ReactNode;
}
export const Wait = ({ loader, children }: Props) => {
  return (
    <Modal
      onClose={() => null}
      opened={true}
      withCloseButton={false}
      size="auto"
      centered
    >
      <Flex
        justify="center"
        align="center"
        gap={10}
        p={60}
        c="blue.7"
        fw={500}
        fz="bigger"
      >
        <Loader c="blue.7" variant={loader || 'oval'} />
        {children || <Text size="xl">Wait ...</Text>}
      </Flex>
    </Modal>
  );
};
