import { Divider, Grid } from '@mantine/core';
import { type CardInterface } from '../../../components/types';
import classes from './MetricsSummary.module.css';
import TranslatedTraffic from './translated-traffic/TranslatedTraffic';
import WordsServed from './words-served/WordsServed';
import WorldTraffic from './world-traffic/WorldTraffic';

type Props = CardInterface & {
  prevTimeSlices: TranslatedTrafficTimeslice[];
  timeSlices: TranslatedTrafficTimeslice[];
  worldTraffic: WorldTrafficResponse[];
  wordsServed: WordsServedResponse[];
  prevWordsServed: WordsServedResponse[];
};

const MobileDivider = (
  <Divider
    className={classes.mobileDivider}
    size="sm"
    orientation="horizontal"
  />
);

export const MetricsSummary = ({
  prevTimeSlices,
  timeSlices,
  worldTraffic,
  wordsServed,
  prevWordsServed,
}: Props) => (
  <Grid py="0.75rem">
    <Grid.Col
      className={classes.summaryCol}
      px={30}
      py={0}
      span={{ base: 12, md: 4 }}
    >
      <TranslatedTraffic
        prevTimeSlices={prevTimeSlices}
        timeSlices={timeSlices}
      />
    </Grid.Col>
    {MobileDivider}
    <Grid.Col
      className={classes.worldTrafficCol}
      px={30}
      py={0}
      span={{ base: 12, md: 4 }}
    >
      <WorldTraffic worldTraffic={worldTraffic} />
    </Grid.Col>
    {MobileDivider}
    <Grid.Col
      className={classes.summaryCol}
      px={30}
      py={0}
      span={{ base: 12, md: 4 }}
    >
      <WordsServed
        wordsServed={wordsServed}
        prevWordsServed={prevWordsServed}
      />
    </Grid.Col>
  </Grid>
);

export default MetricsSummary;
