import { useState } from 'react';
import { Box, Flex, Popover, UnstyledButton, Indicator } from '@mantine/core';
import { useSegmentEditorContext } from '../../context/SegmentEditorProvider';
import {
  FilterBy,
  useFilters,
  useExternalFilter,
} from '../../store/DashboardStore';

import { ContextFilters } from './ContextFilters';
import { CloseIcon, FilterIcon } from '../../icons/ActionIcons';
import { ServerFilters } from './ServerFilters';
import { getColor } from '../../theme/utils';
import { MdArrowDropDown } from 'react-icons/md';

import classes from './FilterSegments.module.css';
import { getFiltersCount } from '../../functions/segmentsFunctions';

type Props = {
  filterCount: (filters: FilterBy) => number;
  refetchSegments: (query: URLSearchParams) => void;
};

export const FilterSegments = ({ filterCount, refetchSegments }: Props) => {
  const {
    settings: { contextMode },
    contextAPI = {},
  } = useSegmentEditorContext();
  const { onResetFilter } = contextAPI;
  const [showFilters, setShowFilters] = useState(false);
  const filterBy = useFilters();
  const externalFilter = useExternalFilter();

  const resetExternalFilter = () => {
    if (typeof onResetFilter === 'function') onResetFilter();
  };
  const filtersCount = getFiltersCount(filterBy, contextMode);

  return (
    <Flex direction={'column'} gap={3}>
      <Popover
        withinPortal
        position="top-end"
        opened={showFilters}
        onChange={setShowFilters}
        closeOnEscape
        shadow="md"
        arrowSize={0}
        arrowOffset={20}
      >
        <Popover.Target>
          <Indicator
            disabled={!filtersCount}
            label={<Box className={classes.indicator}>{filtersCount}</Box>}
            offset={3}
            size={16}
            fw={600}
            color={getColor('blue', 8)}
          >
            <UnstyledButton
              className={classes.filterButton}
              onClick={() => setShowFilters(!showFilters)}
            >
              <FilterIcon />
              <Box>Filters</Box>
              <MdArrowDropDown size={20} />
            </UnstyledButton>
          </Indicator>
        </Popover.Target>
        <Popover.Dropdown className={classes.filterDropdown}>
          {contextMode ? (
            <ContextFilters
              setShowFilters={setShowFilters}
              filterCount={filterCount}
            />
          ) : (
            <ServerFilters
              setShowFilters={setShowFilters}
              refetchSegments={refetchSegments}
            />
          )}
        </Popover.Dropdown>
      </Popover>
      {externalFilter.length ? (
        <UnstyledButton
          className={classes.blockButton}
          onClick={resetExternalFilter}
        >
          <CloseIcon size={12} />
          <Box>Block</Box>
        </UnstyledButton>
      ) : null}
    </Flex>
  );
};
