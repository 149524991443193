import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Flex,
  Group,
  Image,
  Radio,
  Switch,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { randomId, useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { NOOP, isSuccessStatus } from 'helpers';
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { DeleterPromise, Xapis } from '@glweb/xapis-client';
import { CustomModal, failureNotification, successNotification } from 'ui';
import GLA from '../../../analytics/googleAnalytics';
import MultiSelectAll from '../../../components/shared/MultiSelectAll';
import AddNote from '../../setup/images/AddNote.svg';
import BluePlus from '../../setup/images/BluePlusSymbol.svg';
import CaseSensitive from '../../setup/images/CaseSensitiveIcon.svg';
import OverrideMT from '../../setup/images/OverrideMTSymbol.svg';
import RedMinus from '../../setup/images/RedMinusSymbol.svg';
import TranslateAs from '../../setup/images/TranslateAsGlossaryIcon.svg';
import Warning from '../../setup/images/WarningAmber.svg';
import TableEditButton from '../../TableEditButton';
import {
  NEVER_TRANSLATE,
  NEVER_TRANSLATE_AS,
  RuleType,
  TRANSLATE_AS,
  glossaryRules,
} from './glossaryConstants';
import classes from './GlossaryRuleForm.module.css';

const defaultFormValues: FormValues = {
  rule: NEVER_TRANSLATE,
  is_mt: true,
  is_case_sensitive: true,
  translation_keys: [],
  source_text: '',
  targetTextList: [{ targetText: '', id: randomId() }],
  comment: '',
};

const rulesLables = {
  [NEVER_TRANSLATE]: {
    label: 'Never Translate',
    tip: 'The term you enter will not be translated',
  },
  [TRANSLATE_AS]: {
    label: 'Translate As',
    tip: 'The term you enter should be translated a certain way',
  },
  [NEVER_TRANSLATE_AS]: {
    label: `Don't Translate As`,
    tip: (
      <Text>
        The term you enter should{' '}
        <Text span fs="italic">
          not
        </Text>{' '}
        be translated a certain way
      </Text>
    ),
  },
};

export type FormValues = {
  rule: RuleType;
  is_mt: boolean;
  is_case_sensitive: boolean;
  translation_keys: string[];
  source_text: string;
  targetTextList: {
    targetText: string;
    id: string;
  }[];
  comment: string;
};

const buttonText = {
  Add: 'Add Glossary Rule',
  Edit: <TableEditButton onClick={() => NOOP} />,
};
const MAX_COMMENT_SIZE = 250; // Maximum column size for "comment" in XAPIS_GLOSSARY table
type Props = {
  type: 'Add' | 'Edit';
  setLoading: Dispatch<SetStateAction<boolean>>;
  targetsOptions?: {
    value: string;
    label: string;
  }[];
  initialValues?: FormValues;
  buttonProps?: ButtonProps;
};

export const GlossaryRuleForm = ({
  type = 'Add',
  targetsOptions = [],
  initialValues = defaultFormValues,
  buttonProps = {},
  setLoading,
}: Props) => {
  const colors = useMantineTheme().colors;
  const [opened, { open, close }] = useDisclosure(false);
  const [openNotes, setOpenNotes] = useState(false);

  const cannotEditMessage = `
    The original Source Term and the applied Languages cannot be edited. 
    If you want to change either of those settings, you must delete this rule and create another.`;

  const form = useForm<FormValues>({
    initialValues,
    validate: {
      rule: (value) => !value && 'Must include a rule',
      source_text: (value) => !value && 'Term must be included',
      translation_keys: (values) =>
        values.length === 0 && 'Must include at least 1 language',
      comment: (value) =>
        value.length > MAX_COMMENT_SIZE &&
        `Cannot exceed ${MAX_COMMENT_SIZE} characters`,
      targetTextList: {
        targetText: (value, formValues) => {
          if (formValues.rule !== NEVER_TRANSLATE) {
            return !value.trim() ? 'Translation must be included' : null;
          }
        },
      },
    },
  });

  const onRuleChange = (rule: string) => {
    if (!glossaryRules.includes(rule as RuleType)) {
      failureNotification(`Could not recognize rule: "${rule}"`);
      return;
    }
    const isMt = rule !== NEVER_TRANSLATE_AS;
    form.setFieldValue('rule', rule as RuleType);
    form.setFieldValue('is_mt', isMt);

    if (
      (rule === NEVER_TRANSLATE_AS || rule === TRANSLATE_AS) &&
      form.values.targetTextList.length === 0
    ) {
      form.setFieldValue('targetTextList', [
        { targetText: '', id: randomId() },
      ]);
    } else if (rule !== NEVER_TRANSLATE_AS && rule !== TRANSLATE_AS) {
      form.setFieldValue(
        'targetTextList',
        form.values.targetTextList.length > 0 ? form.values.targetTextList : []
      );
    }
  };

  const rule = form?.values?.rule || NEVER_TRANSLATE;
  const isMtOverrideDisabled = rule === NEVER_TRANSLATE_AS;

  const closeModal = useCallback(() => {
    close();
    setOpenNotes(false);
  }, [close]);

  const openModal = () => {
    form.setValues(initialValues);
    open();
  };

  const clearPretranslateCache = async (translation_keys: string[]) => {
    return Promise.all([
      ...translation_keys.map((tKey) => {
        return DeleterPromise(`Pretranslate/${tKey}`);
      }),
    ]);
  };

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    form.onSubmit((formValues: FormValues) => {
      const values: Partial<FormValues> = { ...formValues };
      const { translation_keys = [], targetTextList } = values;
      const target_text = targetTextList?.map((t) => t.targetText) || [];
      delete values.targetTextList;
      const hasTargetText = values.rule !== NEVER_TRANSLATE;

      const payload = {
        ...values,
        target_text: hasTargetText ? target_text : '',
      } as GlossaryAPIPayload;

      closeModal();

      return clearPretranslateCache(translation_keys)
        .then(() => {
          return Xapis.Glossary.post(translation_keys, payload).then(
            (response) => {
              if (isSuccessStatus(response.status)) {
                successNotification(`Glossary Rule saved.`);
                if (response.data) {
                  GLA.analyticsEvent(
                    GLA.EVENT_ACTION_FORM_SUBMISSION,
                    GLA.EVENT_CATEGORY_GLOSSARY,
                    GLA.EVENT_LABEL_GLOSSARY_RULE_FORM
                  );
                }
              }
            }
          );
        })
        .catch(() => {
          notifications.show({
            message: 'Unable to add Glossary rule at this time',
          });
        })
        .finally(() => {
          closeModal();
          setLoading(false);
        });
    })(event);
  };

  const addTargetText = () =>
    form.insertListItem('targetTextList', { targetText: '', key: randomId() });

  return (
    <>
      <Button
        {...buttonProps}
        onClick={openModal}
        style={{ borderRadius: 3 }}
        fz="xs"
        miw="auto"
      >
        {buttonText[type]}
      </Button>
      <CustomModal
        id="pw-translations-add-glossary-rule"
        size="lg"
        title={`${type} Glossary Rule`}
        opened={opened}
        onClose={closeModal}
        footerActions={[
          {
            label: 'Save',
            type: 'submit',
            formId: 'add-glossary-rule',
            disabled: !form.isValid() || !form.isDirty(),
          },
        ]}
      >
        <form
          id="add-glossary-rule"
          className={classes.ruleForm}
          onSubmit={onFormSubmit}
          style={{ padding: '0 0.5rem' }}
        >
          <Title order={3} tt="uppercase">
            PICK A RULE
          </Title>
          <Radio.Group
            mt="1rem"
            name="ruleType"
            {...form.getInputProps('rule')}
            onChange={onRuleChange}
          >
            <Group data-testid="pw-translations-add-glossary-rule-tooltip">
              {glossaryRules.map((type) => (
                <Tooltip
                  key={type}
                  label={rulesLables[type as keyof typeof rulesLables].tip}
                >
                  <Radio
                    classNames={{
                      label: classes.radioLabel,
                      body: classes.radioBody,
                      root: classes.radioRoot,
                    }}
                    style={{
                      border:
                        rule === type
                          ? `1px solid ${colors.text3[2]}`
                          : `1px solid ${colors.badge[0]}`,
                    }}
                    value={type}
                    label={rulesLables[type as keyof typeof rulesLables].label}
                  />
                </Tooltip>
              ))}
            </Group>
          </Radio.Group>
          <Flex
            direction="column"
            rowGap="1rem"
            mt="2rem"
            data-testid="pw-translations-add-glossary-rule-options-tooltip"
          >
            <Title order={3} tt="uppercase">
              Options
            </Title>
            <Flex justify="space-between">
              <Text {...(isMtOverrideDisabled && { c: 'text.9' })}>
                Override Machine Translation
              </Text>
              <Tooltip
                label={
                  isMtOverrideDisabled
                    ? 'Not available for this rule type'
                    : 'This rule will override the machine translation engine'
                }
                zIndex={1001}
              >
                <Flex columnGap="1rem">
                  <Box>
                    <Image src={OverrideMT} />
                  </Box>
                  <Switch
                    checked={form.getInputProps('is_mt')?.value}
                    disabled={isMtOverrideDisabled || type === 'Edit'}
                    {...form.getInputProps('is_mt')}
                  />
                </Flex>
              </Tooltip>
            </Flex>
            <Flex justify="space-between">
              <Text>Case Sensitive</Text>
              <Tooltip
                label="This rule will only apply where the case matches what you enter"
                zIndex={1001}
              >
                <Flex columnGap="1rem">
                  <Box>
                    <Image src={CaseSensitive} />
                  </Box>
                  <Switch
                    checked={form.getInputProps('is_case_sensitive')?.value}
                    {...form.getInputProps('is_case_sensitive')}
                  />
                </Flex>
              </Tooltip>
            </Flex>
          </Flex>
          <Tooltip
            label={type === 'Edit' ? cannotEditMessage : ''}
            zIndex={1001}
          >
            <MultiSelectAll
              mt="1rem"
              label="Select Your Languages"
              data={targetsOptions}
              targetOptions={targetsOptions}
              onChange={(values: string[]) =>
                form.setFieldValue('translation_keys', values)
              }
              error={form.errors['translation_keys']}
              type={type}
            />
          </Tooltip>
          <Flex mt="1rem" direction="column" bg="gray.1" py=".25rem">
            <Tooltip
              label={type === 'Edit' ? cannotEditMessage : ''}
              zIndex={1001}
            >
              <TextInput
                label="Enter Your Term"
                placeholder="Source"
                disabled={type === 'Edit'}
                {...form.getInputProps('source_text')}
              />
            </Tooltip>
            {rule === TRANSLATE_AS && (
              <>
                <Divider
                  color="black"
                  size="sm"
                  mt="1rem"
                  mb="1rem"
                  labelPosition="center"
                  label={
                    <Box>
                      <Image src={TranslateAs} />
                    </Box>
                  }
                />
                <TextInput
                  placeholder="Translation"
                  {...form.getInputProps('targetTextList.0.targetText')}
                />
              </>
            )}
            {rule === NEVER_TRANSLATE_AS && (
              <>
                <Divider
                  color="black"
                  size="sm"
                  mt="1rem"
                  mb="1rem"
                  labelPosition="center"
                  label={
                    <Box>
                      <Image src={Warning} />
                    </Box>
                  }
                />
                {form.values.targetTextList.map((item, index) => (
                  <Group key={index} mt="xs">
                    <TextInput
                      placeholder="Disallowed term"
                      mt=".125rem"
                      style={{ flexGrow: 1 }}
                      {...form.getInputProps(
                        `targetTextList.${index}.targetText`
                      )}
                    />
                    {index === 0 ? (
                      <Box
                        onClick={addTargetText}
                        pr="1rem"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Image src={BluePlus} />
                      </Box>
                    ) : (
                      <Box
                        onClick={() =>
                          form.removeListItem('targetTextList', index)
                        }
                        pr="1rem"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Image src={RedMinus} />
                      </Box>
                    )}
                  </Group>
                ))}
              </>
            )}
          </Flex>
          <Button
            px={0}
            variant="transparent"
            mt="1rem"
            onClick={() => setOpenNotes(!openNotes)}
            rightSection={<Image src={AddNote} />}
          >
            Additional Notes
          </Button>
          {openNotes && (
            <>
              <Textarea
                autosize
                minRows={2}
                maxRows={4}
                {...form.getInputProps('comment')}
              />
              <Text ta="end" fw={600} ml="2rem" c="text.8">
                {form.values.comment.length}/250
              </Text>
              <Text ta="center" fw={600} c="red">
                {form?.values?.comment?.length > 250 &&
                  'Exceeded character limit.'}
              </Text>
            </>
          )}
        </form>
      </CustomModal>
    </>
  );
};
