import { Flex, Menu } from '@mantine/core';
import React from 'react';
import { BatchFieldOptions } from '../../pages/batches/ActiveBatchesPage';
import { MdArrowRight } from 'react-icons/md';
import { NOOP } from 'helpers';

type Props = {
  batches?: string[];
  handleBatchChange: (
    params: Partial<Record<Partial<BatchFieldOptions>, string>>
  ) => void;
  handleReviewAll?: () => void;
  isDetailed?: boolean;
  openCancelBatchModal: (batches: string[]) => void;
  openCompleteBatchModal: (batches: string[]) => void;
};

const BatchActionsDropdown = ({
  batches = [],
  handleBatchChange,
  openCancelBatchModal,
  openCompleteBatchModal,
  isDetailed = false,
  handleReviewAll = NOOP,
}: Props) => {
  return (
    <Menu.Dropdown miw={153}>
      {batches?.length === 1 && !isDetailed && (
        <Menu.Item disabled>Edit</Menu.Item>
      )}
      <Menu.Item closeMenuOnClick={false}>
        <Menu position="left-start" offset={30} closeDelay={200}>
          <Menu.Target>
            <Flex align="center" justify="space-between">
              Change Status
              <MdArrowRight size={24} />
            </Flex>
          </Menu.Target>
          <Menu.Dropdown miw={111}>
            {['To Do', 'In Progress', 'Ready'].map((status) => (
              <Menu.Item
                onClick={() =>
                  handleBatchChange({ phase_status: status.toLowerCase() })
                }
                key={status}
              >
                {status}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Menu.Item>
      <Menu.Item closeMenuOnClick={false}>
        <Menu position="left-start" offset={30} closeDelay={200}>
          <Menu.Target>
            <Flex align="center" justify="space-between">
              Change Phase
              <MdArrowRight size={24} />
            </Flex>
          </Menu.Target>
          <Menu.Dropdown miw={112}>
            {['Translation', 'Testing', 'Review', 'Validation'].map((phase) => (
              <Menu.Item
                onClick={() =>
                  handleBatchChange({
                    phase: phase.toLowerCase(),
                    phase_status: 'to do',
                  })
                }
                key={phase}
              >
                {phase}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Menu.Item>
      <Menu.Item
        onClick={() => openCompleteBatchModal(batches)}
      >{`Complete Batch${batches?.length > 1 ? 'es' : ''}`}</Menu.Item>
      <Menu.Item
        onClick={() => openCancelBatchModal(batches)}
      >{`Cancel Batch${batches?.length > 1 ? 'es' : ''}`}</Menu.Item>
      {isDetailed && (
        <Menu.Item disabled onClick={() => handleReviewAll?.()}>
          Mark All As Reviewed
        </Menu.Item>
      )}
    </Menu.Dropdown>
  );
};
export default BatchActionsDropdown;
