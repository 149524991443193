import { RouteObject } from 'react-router-dom';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { QuoteList } from './AllQuotes';
import { QuotesProject } from './ProjectQuotes';

const quoteRoutes: RouteObject[] = [
  {
    path: 'project',
    element: <QuotesProject />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'all',
    element: <QuoteList />,
    errorElement: <ErrorBoundary />,
  },
];

export default quoteRoutes;
