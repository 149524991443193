import { Button, Card, Flex, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  USER_ROLE_ADMIN,
  defaultProjectUserPermission,
  USER_ROLE_GLGO_ADMIN,
  useViewPermissions,
  isSuccessStatus,
  ProjectUserPermission,
  USER_ROLE_TRANSPERFECT_ADMIN,
  USER_ROLE_TRANSPERFECT_USER,
} from 'helpers';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { Xapis } from '@glweb/xapis-client';
import {
  CustomTable,
  failureNotification,
  getBasicColumn,
  getEditDeleteColumn,
  Header,
  SearchBar,
  useCurrentProjectData,
  useGLGOParams,
  useUserProvider,
} from 'ui';
import TableDeleteButton from '../../components/TableDeleteButton';
import TableEditButton from '../../components/TableEditButton';
import UserManagementModal from './UserManagementModal';

export type UserManagementRowData = ProjectUserPermission & {
  name: string;
  roles: string;
  languages: string;
};

export type ModalAction = 'Add' | 'Edit';

const UserManagementPage = () => {
  const { projectTranslationMap = {}, projectUserPermissionMap = {} } =
    useCurrentProjectData();

  const { projectKey } = useGLGOParams();

  const {
    xapisUser: { user_key: userKey },
  } = useUserProvider();

  const allowedRoles = new Set([
    USER_ROLE_ADMIN,
    USER_ROLE_TRANSPERFECT_ADMIN,
    USER_ROLE_TRANSPERFECT_USER,
    USER_ROLE_GLGO_ADMIN,
  ]);

  const { rolePermissions, hasViewPermissions } = useViewPermissions();
  const canManageUsers = hasViewPermissions(rolePermissions, allowedRoles);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [opened, { open, close }] = useDisclosure(false);
  const [modalAction, setModalAction] = useState<ModalAction>('Add');
  const [currentUser, setCurrentUser] = useState(defaultProjectUserPermission);

  const noTPUserPermissionMap = Object.fromEntries(
    Object.entries(projectUserPermissionMap).filter(
      ([, { rolePermissionSet }]: [
        string,
        { rolePermissionSet: Set<string> },
      ]) =>
        !rolePermissionSet.has(USER_ROLE_TRANSPERFECT_USER) &&
        !rolePermissionSet.has(USER_ROLE_TRANSPERFECT_ADMIN) &&
        !rolePermissionSet.has(USER_ROLE_GLGO_ADMIN)
    )
  );

  const filterData = (data: UserManagementRowData[]) => {
    const query = search.toLowerCase().trim();
    return data.filter((rowData) => {
      const { name = '', email = '', roles = '', languages = '' } = rowData;
      return (
        name.toLowerCase().includes(query) ||
        email.toLowerCase().includes(query) ||
        roles.toLowerCase().includes(query) ||
        languages.toLowerCase().includes(query)
      );
    });
  };

  const columns: MRT_ColumnDef<UserManagementRowData>[] = [
    {
      ...getBasicColumn('name', 'name', true),
    },
    {
      ...getBasicColumn('email', 'email', true),
    },
    {
      ...getBasicColumn('roles', 'roles', true),
    },
    {
      ...getBasicColumn('languages', 'languages', true),
    },
  ];

  const manageUserColumn = getEditDeleteColumn(
    ({ data }) => (
      <TableEditButton
        onClick={() => {
          setModalAction('Edit');
          setCurrentUser(projectUserPermissionMap[data.user_key]);
          open();
        }}
      />
    ),
    ({ data }) => (
      <TableDeleteButton
        onClick={() => handleRemoveUser(data as UserManagementRowData)}
        disabled={userKey === data.user_key}
      />
    )
  );

  canManageUsers && columns.push(manageUserColumn);

  const rowData = useMemo(
    () =>
      filterData(
        Object.values(noTPUserPermissionMap).map(
          (user) =>
            ({
              ...user,
              name: `${user.first_name} ${user.last_name}`,
              roles: Array.from(user.rolePermissionSet).join(', '),
              languages: Array.from(user.languagePermissionSet)
                .map(
                  (translationKey) =>
                    projectTranslationMap[translationKey].target_lang_name
                )
                .join(', '),
            }) as UserManagementRowData
        )
      ),
    [search, projectUserPermissionMap]
  );

  const handleRemoveUser = (row: UserManagementRowData) => {
    const { user_key } = row;

    setLoading(true);

    Xapis.ProjectUser.delete({
      projectKey: projectKey!,
      removedUserKey: user_key,
    }).then((response) => {
      if (!isSuccessStatus(response.status)) failureNotification(response);
      setLoading(false);
    });
  };

  return (
    <Flex direction="column" rowGap="1rem">
      <Header title="Users" />
      <Flex w="100%" justify="space-between" align="center" columnGap="1.25rem">
        <SearchBar
          w={400}
          searchText={search}
          onSearch={(value) => setSearch(value)}
          placeholder="Search by any field..."
        />
        {canManageUsers && (
          <Button
            px={0}
            variant="transparent"
            rightSection={<IoMdAdd size={18} />}
            onClick={() => {
              open();
              setModalAction('Add');
              setCurrentUser(defaultProjectUserPermission); // Clear current user data
            }}
          >
            Add User
          </Button>
        )}
      </Flex>
      <UserManagementModal
        opened={opened}
        close={close}
        modalAction={modalAction}
        user={currentUser}
      />
      <Card p={0}>
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <CustomTable
          data={rowData}
          columns={columns}
          bottomToolbarString={rowData.length === 1 ? 'User' : 'Users'}
        />
      </Card>
    </Flex>
  );
};

export default UserManagementPage;
