import React, { useState } from 'react';
import { Select, Stack, MultiSelect } from '@mantine/core';
import { CustomModal, usePermissionsOverrideContext } from 'ui';
import {
  userRoles,
  userTypes,
  useViewPermissions,
  type UserRole,
} from 'helpers';
import { USER_ROLES_IMPERSONATE_MODE } from '../../../constants';

type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ImpersonateModeModal({ opened, setOpened }: Props) {
  const { userTypePermissions, rolePermissions, hasViewPermissions } =
    useViewPermissions();

  const {
    overrides: {
      user_type: overriddenUserType,
      rolePermissionSet: overriddenRolesSet,
    },
    setOverrides,
  } = usePermissionsOverrideContext();

  const defaultRoles = [...rolePermissions];
  const defaultUserType = [...userTypePermissions][0] || null;
  const defaultOrOverriddenRoles =
    [...overriddenRolesSet].length > 0 ? [...overriddenRolesSet] : defaultRoles;
  const defaultOrOverriddenUserType = overriddenUserType || defaultUserType;

  const [roles, setRoles] = useState<UserRole[]>(defaultOrOverriddenRoles);
  const [userType, setUserType] = useState<UserType | null>(
    defaultOrOverriddenUserType
  );

  const apply = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setOverrides({
      user_type: userType || '',
      rolePermissionSet: new Set([...roles]),
    });

    setOpened(false);
  };

  const reset = () => {
    setRoles(defaultRoles);
    setUserType(defaultUserType);
  };

  const onCloseModal = () => {
    setOpened(false);
    setRoles(defaultOrOverriddenRoles);
    setUserType(defaultOrOverriddenUserType);
  };

  const isApplyDisabled = !userType && roles.length === 0;

  if (
    !hasViewPermissions(rolePermissions, USER_ROLES_IMPERSONATE_MODE, false)
  ) {
    return <></>;
  }

  return (
    <CustomModal
      title="Impersonate Mode"
      opened={opened}
      onClose={onCloseModal}
      footerActions={[
        {
          label: 'Reset',
          action: reset,
        },
        {
          label: 'Apply',
          action: apply,
          type: 'submit',
          formId: 'impersonate',
          disabled: isApplyDisabled,
        },
      ]}
    >
      <form id="impersonate">
        <Stack gap="0.5rem">
          <MultiSelect
            label="Roles"
            data={userRoles}
            value={roles}
            onChange={(values) => setRoles(values as UserRole[])}
            styles={{ dropdown: { zIndex: 10001 } }}
          />
          <Select
            label="User Type"
            data={userTypes}
            value={userType}
            onChange={(value) => setUserType(value as UserType | null)}
            styles={{ dropdown: { zIndex: 10001 } }}
          />
        </Stack>
      </form>
    </CustomModal>
  );
}
