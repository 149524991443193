// import { CardInterface } from '../../../../../types';
import { useIsFirstRender } from '@mantine/hooks';
import moment from 'moment';

import { LoadingSkeleton, useGetNavigationStatus } from 'ui';
import { CardInterface } from '../../types';
import { getAccumulatedWordsServed, getRemainingTimeslices } from './utils';
import WordsServedUsageSummary from './WordsServedUsageSummary';
import WordsServedUsageChart from './WordsServedUsageChart';

type Props = CardInterface & {
  monthlyWordsServed: WordsServedResponse[];
  thresholdValue: number;
};

const WordsServedUsage = ({ monthlyWordsServed, thresholdValue }: Props) => {
  const firstRender = useIsFirstRender();
  const loading = useGetNavigationStatus().isNavigatingInPlace && firstRender;

  const date = new Date();
  const todaysDate = moment(date).format('YYYY-MM-DD') || '';

  const accumulatedWordsServed = getAccumulatedWordsServed(monthlyWordsServed);
  const monthlyWordsServedWithRemaining =
    accumulatedWordsServed.length > 0
      ? [
          ...accumulatedWordsServed,
          ...getRemainingTimeslices(accumulatedWordsServed),
        ].sort(
          ({ timeslice: timesliceA = '' }, { timeslice: timesliceB = '' }) => {
            return timesliceA.localeCompare(timesliceB);
          }
        )
      : [];

  return loading ? (
    <LoadingSkeleton />
  ) : (
    <>
      <WordsServedUsageSummary
        todaysDate={todaysDate}
        thresholdValue={thresholdValue}
        monthlyWordsServed={monthlyWordsServed}
        monthlyWordsServedWithRemaining={monthlyWordsServedWithRemaining}
      />
      <WordsServedUsageChart
        todaysDate={todaysDate}
        monthlyWordsServed={monthlyWordsServed}
        monthlyWordsServedWithRemaining={monthlyWordsServedWithRemaining}
        thresholdValue={thresholdValue}
      />
    </>
  );
};

export default WordsServedUsage;
