import { MRT_Cell, MRT_ColumnDef } from 'mantine-react-table';
import { CustomTable, getBasicColumn } from 'ui';
import { Card, Image } from '@mantine/core';
import ReviewedIcon from '../../../assets/images/ReviewedIcon.svg';
import NotReviewedIcon from '../../../assets/images/NotReviewedIcon.svg';
import { useDetailedBatch } from '../../../queries/batchesQueries';

export const UrlsTable = () => {
  const {
    batch: { urls },
  } = useDetailedBatch();
  const columns: MRT_ColumnDef<BatchURL>[] = [
    {
      ...getBasicColumn('is_reviewed', 'reviewed'),
      Cell: ({ cell }: { cell: MRT_Cell<BatchURL, unknown> }) => {
        const reviewed = cell.getValue() as boolean;
        return (
          <Image
            src={reviewed ? ReviewedIcon : NotReviewedIcon}
            alt={`${reviewed ? 'Not ' : ''}Reviewed`}
          />
        );
      },
      enableSorting: false,
      grow: false,
      mantineTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      ...getBasicColumn('content_url', 'Urls'),
    },
    {
      ...getBasicColumn('word_count', 'words'),
      grow: false,
      mantineTableBodyCellProps: {
        align: 'right',
      },
    },
  ];
  return (
    <Card p={0}>
      <CustomTable columns={columns} data={urls} enableBottomToolbar={false} />
    </Card>
  );
};
