import { Box, PasswordInput, Popover, Progress, Text } from '@mantine/core';
import { useState } from 'react';
import { Control, UseFormRegister, useWatch } from 'react-hook-form';
import { FaCheck } from 'react-icons/fa6';
import { IoCloseSharp } from 'react-icons/io5';
import { AccountForm } from './types';

type Props = {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  control: Control<AccountForm, any>;
  register: UseFormRegister<AccountForm>;
};

const PasswordRequirement = ({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) => (
  <Text
    c={meets ? 'background2.1' : 'text.4'}
    style={{ display: 'flex', alignItems: 'center' }}
    mt={7}
  >
    {meets ? <FaCheck size="0.9rem" /> : <IoCloseSharp size="0.9rem" />}{' '}
    <Text c="inherit" ml={10} span>
      {label}
    </Text>
  </Text>
);

const AccountPasswordInput = ({ control, register }: Props) => {
  const passwordValue = useWatch({ control, name: 'password' });

  const requirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[a-z]/, label: 'Includes lowercase letter' },
    { re: /[A-Z]/, label: 'Includes uppercase letter' },
  ];

  function getStrength(password: string) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
  }

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(passwordValue)}
    />
  ));

  const [popoverOpened, setPopoverOpened] = useState(false);

  const strength = getStrength(passwordValue);

  const getProgressStyles = (strength: number) => {
    const color =
      strength === 100 ? '#188038' : strength > 50 ? '#FDF5B2' : '#D93025';

    return {
      root: { border: 'none' },
      bar: { backgroundColor: color },
      section: { backgroundColor: color },
    };
  };

  return (
    <Box w="100%">
      <Popover
        opened={popoverOpened}
        position="bottom"
        transitionProps={{ transition: 'pop' }}
        width="target"
      >
        <Popover.Target>
          <div
            onFocusCapture={() => setPopoverOpened(true)}
            onBlurCapture={() => setPopoverOpened(false)}
          >
            <PasswordInput
              w="100%"
              required
              label="Password"
              withAsterisk={false}
              {...register('password', {
                required: true,
                validate: () => {
                  if (strength === 100) return true;
                  return 'Your password is not strong enough, please review the requirements.';
                },
              })}
            />
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          <Progress
            styles={getProgressStyles(strength)}
            value={strength}
            size={5}
            mb="xs"
          />
          <PasswordRequirement
            label="Includes at least 6 characters"
            meets={passwordValue.length > 5}
          />
          {checks}
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
};

export default AccountPasswordInput;
