import { Box, Button, Card, Flex } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { xapisEnv } from '@glweb/xapis-client';
import { useCurrentProjectData, useUserProvider } from 'ui';
import { SelectPlanLoaderResponse } from '../../../loaders/account/selectPlanLoader';
import {
  queryRecommendedPlan,
  useSelectPlanQueries,
} from '../../../queries/selectPlanQueries';
import { downgrade, upgrade } from '../plan-information/planActions';
import { type PlanOption } from '../plan-information/PlanInformation';
import { defaultPaymentSource } from '../plan-information/utils';
import BackButton from '../shared/BackButton';
import CurrentActionModal from './CurrentActionModal';
import MobilePlanOptions from './MobilePlanOptions';
import PlanOptions from './PlanOptions';
import TopSection from './TopSection';
import { filterSkus, filterSubscription } from './utils';

export type SubscriptionType = 'monthly' | 'yearly';

type DiscountedPlan = PlanOption & { regularPrice: number };

export type ActionModalType = 'upgrade' | 'downgrade' | null;

const SelectPlan = () => {
  const { project } = useCurrentProjectData();
  const projectKey = project.project_key;

  const payKey = xapisEnv.getHost.payKey;
  const { url, threshold } = useLoaderData() as SelectPlanLoaderResponse;

  const {
    data: { name, threshold_value, skus },
  } = useSuspenseQuery(queryRecommendedPlan(url, payKey, threshold || ''));
  const { subscriptions, sourceData } = useSelectPlanQueries(
    payKey,
    projectKey
  );
  const sources = sourceData.payment_sources || [];
  const default_source_id = sourceData.default_source_id || [];
  const defaultSource =
    sources.find(({ id }) => id === default_source_id) || defaultPaymentSource;

  const subscription_status = project.subscription_status || 'expired';
  let currentPlan = subscriptions[0] || {};

  if (subscription_status === 'expired') currentPlan = {} as Subscription;
  const subType =
    'subscription_type' in currentPlan &&
    ['monthly', 'yearly'].includes(currentPlan.subscription_type.toLowerCase())
      ? (currentPlan.subscription_type.toLowerCase() as SubscriptionType)
      : 'monthly';

  const { xapisUser } = useUserProvider();
  const navigate = useNavigate();
  const [value, setValue] = useState<SubscriptionType>(subType);
  const [selectedPlan, setSelectedPlan] = useState<PlanOption | null>(null);
  const [actionModalType, setActionModalType] = useState<ActionModalType>(null);
  const isPaidPlan = currentPlan.sku !== '' && currentPlan.price > 0;
  const isCurrentActivePlan = isPaidPlan && !currentPlan.is_canceled;
  const isTabletView = useMediaQuery('(max-width: 991px)');

  let plans = filterSkus(skus, `${value === 'yearly' ? 'YEARLY' : 'MONTHLY'}`);

  const regularPrices = [240, 960, 1800, 3600, 6000] as const;
  if (value === 'yearly') {
    plans = plans.map((p, i) => ({
      ...p,
      regularPrice: regularPrices[i],
    })) as DiscountedPlan[];
  }

  const recommendedPlanOption = useMemo(
    () => plans.find((plan) => plan.threshold_value === threshold_value),
    [plans, threshold_value]
  );

  if (!selectedPlan && !isCurrentActivePlan && recommendedPlanOption) {
    setSelectedPlan(recommendedPlanOption);
  }

  function handleSubSelect(item: Subscription) {
    setSelectedPlan(filterSubscription(item));
  }

  const params = {
    xapisUser,
    selectedPlan,
    payKey,
    projectKey,
    sources,
    defaultSource,
  };

  const handleContinue = () => {
    const { price: activePrice = 0 } = currentPlan || {};
    const { price: nextPrice = 0 } = selectedPlan || {};

    if (activePrice >= nextPrice || nextPrice === 0) {
      currentPlan
        ? setActionModalType('downgrade')
        : downgrade({ ...params, navigate });

      return;
    }

    currentPlan && Object.keys(currentPlan).length > 0
      ? setActionModalType('upgrade')
      : upgrade(params);
  };

  const optionsProps = {
    plans,
    handleSubSelect,
    currentPlan,
    selectedPlan,
    recommendedPlan: {
      name,
      threshold_value,
    },
  };

  const actionButtons = (
    <Flex
      w="100%"
      align="center"
      justify={{
        base: 'center',
        md: isCurrentActivePlan ? 'space-between' : 'end',
      }}
      direction={{ base: 'column-reverse', md: 'row' }}
      rowGap="0.5rem"
    >
      {isCurrentActivePlan && (
        <Link to="cancel">
          <Button variant="outline">Cancel Subscription</Button>
        </Link>
      )}
      <Button disabled={selectedPlan === null} onClick={handleContinue}>
        Continue
      </Button>
    </Flex>
  );

  return (
    <>
      <CurrentActionModal
        params={{ ...params }}
        navigate={navigate}
        selectedPlan={selectedPlan}
        actionModalType={actionModalType}
        setActionModalType={setActionModalType}
      />
      <Box w="100%" maw={1200}>
        <BackButton text="Plan Information" />
        <Card
          display={{ base: 'flex', md: 'none' }}
          p="2.5rem 1.5rem"
          shadow="sm"
          radius="md"
          style={{ gap: 15 }}
        >
          <Flex direction="column" pb={10} gap={10} maw={400} mx="auto">
            <TopSection
              value={value}
              isTabletView={isTabletView}
              setSelectedPlan={setSelectedPlan}
              setValue={setValue}
            />
            <MobilePlanOptions {...optionsProps} />
            {actionButtons}
          </Flex>
        </Card>
        <Card
          display={{ base: 'none', md: 'flex' }}
          p="2rem 1.5rem"
          shadow="sm"
          radius="md"
          style={{ gap: 15 }}
        >
          <Flex direction="column" pb={10}>
            <TopSection
              value={value}
              setSelectedPlan={setSelectedPlan}
              setValue={setValue}
              isTabletView={isTabletView}
            />
          </Flex>
          <PlanOptions {...optionsProps} value={value} />
          {actionButtons}
        </Card>
      </Box>
    </>
  );
};

export default SelectPlan;
