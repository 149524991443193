import {
  BOTTOM_LEFT_POSITION,
  BOTTOM_RIGHT_POSITION,
  LanguageSelectorPosition,
  LanguageSelectorTheme,
  TOP_LEFT_POSITION,
  TOP_RIGHT_POSITION,
} from './LanguageSelectorAppearances';
import DarkBottomRight from './images/dark-bottom-right-language-selector.svg';
import DarkBottomLeft from './images/dark-bottom-left-language-selector.svg';
import DarkTopLeft from './images/dark-top-left-language-selector.svg';
import DarkTopRight from './images/dark-top-right-language-selector.svg';
import LightBottomRight from './images/light-bottom-right-language-selector.svg';
import LightBottomLeft from './images/light-bottom-left-language-selector.svg';
import LightTopLeft from './images/light-top-left-language-selector.svg';
import LightTopRight from './images/light-top-right-language-selector.svg';
import {
  DARK_MODE_THEME,
  LIGHT_MODE_THEME,
} from '../settings/selector-customization/CustomThemeButton';

export const getLanguageSelectorImage = (
  selectedTheme: LanguageSelectorTheme,
  selectedPosition: LanguageSelectorPosition
) => {
  switch (true) {
    case selectedTheme === DARK_MODE_THEME &&
      selectedPosition === BOTTOM_RIGHT_POSITION:
      return DarkBottomRight;
    case selectedTheme === DARK_MODE_THEME &&
      selectedPosition === BOTTOM_LEFT_POSITION:
      return DarkBottomLeft;
    case selectedTheme === DARK_MODE_THEME &&
      selectedPosition === TOP_LEFT_POSITION:
      return DarkTopLeft;
    case selectedTheme === DARK_MODE_THEME &&
      selectedPosition === TOP_RIGHT_POSITION:
      return DarkTopRight;
    case selectedTheme === LIGHT_MODE_THEME &&
      selectedPosition === BOTTOM_RIGHT_POSITION:
      return LightBottomRight;
    case selectedTheme === LIGHT_MODE_THEME &&
      selectedPosition === BOTTOM_LEFT_POSITION:
      return LightBottomLeft;
    case selectedTheme === LIGHT_MODE_THEME &&
      selectedPosition === TOP_LEFT_POSITION:
      return LightTopLeft;
    case selectedTheme === LIGHT_MODE_THEME &&
      selectedPosition === TOP_RIGHT_POSITION:
      return LightTopRight;
    default:
      return DarkBottomRight;
  }
};
