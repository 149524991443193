import moment from 'moment';
import { AccumulatedWordsServed, AccumulatedWordsServedMap } from '../types';

export const getAccumulatedWordsServed = (
  monthlyWordsServed: WordsServedResponse[]
): AccumulatedWordsServed[] => {
  const accumulatedMonthlyWordsServedMap = monthlyWordsServed.reduce(
    (accum: AccumulatedWordsServedMap, monthlyWordsServedObj) => {
      const { timeslice = '', count = 0 } = monthlyWordsServedObj;
      const accumTimeslice = accum[timeslice];
      const accumTimesliceCount = accumTimeslice?.count || 0;

      const numOfAccumTimeslices = Object.keys(accum).length;
      const lastAccumTimeslice = !accumTimeslice
        ? Object.keys(accum)[numOfAccumTimeslices - 1]
        : undefined;

      const lastAccumTimesliceCount = lastAccumTimeslice
        ? accum[lastAccumTimeslice]?.count || 0
        : 0;

      return {
        ...accum,
        [timeslice]: {
          timeslice,
          count: accumTimeslice
            ? accumTimesliceCount + count
            : lastAccumTimesliceCount + count,
        },
      };
    },
    {}
  );
  return Object.values(accumulatedMonthlyWordsServedMap).sort(
    ({ timeslice: timesliceA = '' }, { timeslice: timesliceB = '' }) =>
      timesliceA.localeCompare(timesliceB)
  ) as AccumulatedWordsServed[];
};

export const getRemainingTimeslices = (
  accumulatedWordsServed: AccumulatedWordsServed[]
) => {
  const date = new Date();
  const currentNumOfDays = date.getDate();
  const totalNumOfDays = moment(date).daysInMonth() || 0;
  const currentYearAndMonth = moment(date).format('YYYY-MM-') || '';
  const accumWordsServedTimeslices = accumulatedWordsServed.map(
    ({ timeslice }) => timeslice
  );

  const remainingData = [];
  let remainingCount = totalNumOfDays;
  while (remainingCount > 0) {
    const currentTimeslice =
      currentYearAndMonth +
        String(remainingCount < 10 ? `0${remainingCount}` : remainingCount) ||
      '';

    const hasTimeslices = accumWordsServedTimeslices.includes(currentTimeslice);

    let lastTimesliceCount = 0;

    if (!hasTimeslices) {
      const timeSliceDays = accumWordsServedTimeslices.filter((timeslice) =>
        moment(timeslice).isBefore(currentTimeslice)
      );
      // get latest count from the list of timeslices given from xapis, relative to the current timeslice
      const latestTimesliceDay = Math.max(
        ...timeSliceDays.map((tsd) => Number(moment(tsd).format('DD')))
      );

      const latestTimeslice =
        currentYearAndMonth +
        String(
          latestTimesliceDay < 10
            ? `0${latestTimesliceDay}`
            : latestTimesliceDay
        );

      lastTimesliceCount =
        accumulatedWordsServed.find(
          (wordsServedObject) => wordsServedObject.timeslice === latestTimeslice
        )?.count || 0;

      remainingData.push({
        timeslice: currentTimeslice,
        count: remainingCount > currentNumOfDays ? null : lastTimesliceCount,
      });
    }
    remainingCount--;
  }
  return remainingData;
};

export const getTick = (
  todaysDate: string = '',
  firstDayOfMonth: string = '',
  lastDayOfMonth: string = '',
  isMobileSize: boolean,
  props: { x: number; y: number; payload: Record<string, string> }
) => {
  const { x = 0, y = 0, payload: { value = '' } = {} } = props || {};

  const startBuffer = moment(firstDayOfMonth)
    .add(2, 'days')
    .format('YYYY-MM-DD');
  const endBuffer = moment(lastDayOfMonth)
    .subtract(2, 'days')
    .format('YYYY-MM-DD');

  const formatTick = (tick: string) => {
    const formatDate = (date: string) => {
      return moment(date).format('MM-DD') || '';
    };

    const isBeforeBuffer = moment(value).isBefore(startBuffer);
    const isAfterBuffer = moment(value).isAfter(endBuffer);

    if (tick !== firstDayOfMonth && isBeforeBuffer) return '';
    if (tick !== lastDayOfMonth && isAfterBuffer) return '';
    if (tick === firstDayOfMonth) return formatDate(firstDayOfMonth);
    if (tick === lastDayOfMonth) return formatDate(lastDayOfMonth);
    if (tick === todaysDate) return 'Today';
    return '';
  };

  const isToday = value === todaysDate;
  const isTodayAndFirstDay = isToday && value === firstDayOfMonth;
  const isTodayandLastDay = isToday && value === lastDayOfMonth;
  const isDefaultStyle = !isToday || isTodayAndFirstDay || isTodayandLastDay;

  const getXAxisPosition = () => {
    if (value === firstDayOfMonth) return !isMobileSize ? x + 20 : x - 20;
    if (value === lastDayOfMonth) return !isMobileSize ? x - 20 : x + 20;
    if (value === todaysDate) return x;
    return 0;
  };

  const xAxisPosition = getXAxisPosition();
  const formattedTickLabel = formatTick(value);

  const getTextAnchor = (tick: string) => {
    if (tick === startBuffer) return 'start';
    if (tick === endBuffer) return 'end';
    return 'middle';
  };

  return (
    <text
      x={xAxisPosition}
      y={y}
      dy={10}
      fill="black"
      fontSize={isDefaultStyle ? 14 : 12}
      fontWeight={isDefaultStyle ? 300 : 700}
      textAnchor={getTextAnchor(value)}
    >
      {formattedTickLabel}
    </text>
  );
};
