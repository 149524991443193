import { notifications } from '@mantine/notifications';
import { asciiToHex, getYYTarget, hexToObject } from 'helpers';
import React, { useCallback, useMemo, useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import { Xapis } from '@glweb/xapis-client';
import { CustomModal, useCurrentProjectData } from 'ui';
import ScopeOption from '../scope-option/ScopeOption';

type Props = {
  showFormModal: boolean;
  setShowFormModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentScope: string;
  setCurrentScope: React.Dispatch<React.SetStateAction<string>>;
};

const ScopeChangeFormModal = ({
  showFormModal,
  setShowFormModal,
  currentScope,
  setCurrentScope,
}: Props) => {
  const validator = useRevalidator();
  const { project, project: { translations = [] } = {} } =
    useCurrentProjectData();

  const [selectedScope, setSelectedScope] = useState('');

  const options = ['all', 'exclude', 'include'].filter(
    (scope) => scope !== currentScope
  );

  const yyTarget = useMemo(() => {
    return getYYTarget(translations) as TranslationKey;
  }, [translations]);

  const handleChangeScope = useCallback(() => {
    const {
      translation_config: activeTranslationConfig = '',
      translation_config_hash: activeTranslationConfigHash = '',
    } = yyTarget || {};

    const activeProjectKey = project?.project_key || '';
    const hash = activeTranslationConfigHash;
    const decodedConfig = hexToObject(activeTranslationConfig);

    const excludeUris = ['all', 'exclude'].includes(selectedScope)
      ? []
      : ['.*'];
    const emptyRules = {
      ...decodedConfig.spider_rules,
      exclude_uris: excludeUris,
      include_uris: [],
    };
    const updatedConfig = JSON.stringify({
      ...decodedConfig,
      spider_rules: emptyRules,
      scope_preset: selectedScope,
    });
    const updatedConfigHex = asciiToHex(updatedConfig);
    const data = {
      translation_config: updatedConfigHex,
      translation_config_hash: hash,
    };

    Xapis.ProjectTranslation.put(
      activeProjectKey,
      yyTarget?.translation_key || '',
      data
    )
      .then(() => {
        setCurrentScope(selectedScope);
        notifications.show({
          message: "Your project's scope updated successfully!",
        });
        validator.revalidate();
      })
      .catch(() => {
        notifications.show({
          message: "We are unable to update your project's scope at this time.",
        });
      });
  }, [
    project?.project_key,
    selectedScope,
    setCurrentScope,
    validator,
    yyTarget,
  ]);

  return (
    <CustomModal
      size="lg"
      title="Change your default scope"
      opened={showFormModal}
      onClose={() => {
        setShowFormModal(false);
      }}
      footerActions={[
        { label: 'Cancel', action: () => setShowFormModal(false) },
        {
          label: 'Save',
          action: () => {
            setShowFormModal(false);
            handleChangeScope();
          },
          disabled: !selectedScope,
        },
      ]}
    >
      {options.map((s) => (
        <ScopeOption
          key={s}
          scopeName={s}
          currentScope={currentScope}
          handleSelectedScope={setSelectedScope}
          selectedScope={selectedScope}
        />
      ))}
    </CustomModal>
  );
};

export default ScopeChangeFormModal;
