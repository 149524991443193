import axios from 'axios';
import { xapisEnv } from '@glweb/xapis-client';

const { notifyUrl } = xapisEnv.getHost;

const notify = axios.create({
  baseURL: notifyUrl,
});

export const Notify = {
  ContactUs: {
    post: async (data: SendContactUsParams) => {
      return notify
        .post('/contactus', data)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
  },
  LogFeedback: {
    post: async (data: SendFeedbackParams) => {
      return notify
        .post('/logFeedback', data)
        .then(({ status = 0, data = {} }) => ({
          data,
          status,
        }))
        .catch((e) => ({
          data: undefined,
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        }));
    },
  },
};

type SendContactUsParams = {
  app: string; // ex. GlobalLinkGO
  domain: string; //ex. Staging, RC, Live
  firstName: string;
  lastName: string;
  company?: string;
  email: string;
  phoneNumber?: string;
  how?: string;
  interest?: string;
  source?: string;
  message: string;
};

type SendFeedbackParams = {
  level: string;
  userTitle: string;
  userName: string;
  userKey: string;
  projectKey: string;
  project: string;
  app: string;
  domain: string;
  title: string;
  message: string;
  issueType: string;
};
