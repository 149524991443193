import { Box, Card, Flex, ScrollArea, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import moment from 'moment/moment';
import { useMemo } from 'react';

import {
  LoadingSkeleton,
  NoDataFoundPlaceHolder,
  useCurrentProjectData,
  useGetNavigationStatus,
} from 'ui';
import { filterTargets } from '../../metrics/trafficUtils';
import QueryMultiSelector from '../../selectors-filters/QueryMultiSelector';
import { BlueCardHeader } from '../../shared/BlueCardHeader';
import {
  getWordsServedGroupedByTimeSlice,
  getWordsServedWithRemainingTimeSlices,
} from './getWordsServed';
import { LanguagesMap, WordsServedGroupedByTimeSlice } from './types';
import classes from './WordsServedTimeSeries.module.css';
import WordsServedTimeSeriesChart from './WordsServedTimeSeriesChart';

type Props = {
  timeSliceFrom: string | undefined;
  translationKeys: string[];
  wordsServed: WordsServedResponse[];
};

const WordsServedTimeSeries = ({
  timeSliceFrom,
  translationKeys,
  wordsServed,
}: Props) => {
  const isTabletSize = useMediaQuery('(max-width: 991px)');
  const targets = useCurrentProjectData().project.translations || [];
  const filteredTargets = filterTargets(targets);

  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const isYearly = timeSliceFrom?.includes('year') || false;
  // TODO: incorp maxSelection logic into multiqueryselector
  // const selectedLanguagesLimit = 10;

  const numOfDaysInMonth = moment(new Date().getMonth()).daysInMonth() - 1 || 0;

  const languagesMap: LanguagesMap = useMemo(
    () =>
      filteredTargets.reduce((accum, { label, value }) => {
        return { ...accum, [value]: label };
      }, {}),
    [filteredTargets]
  );

  const wordsServedGroupedByTimeSlice: WordsServedGroupedByTimeSlice[] =
    getWordsServedGroupedByTimeSlice(languagesMap, wordsServed);
  const getLastTimeSliceWithData = () =>
    wordsServedGroupedByTimeSlice[wordsServedGroupedByTimeSlice.length - 1]
      ?.timeslice || '';
  const getFirstTimeSliceWithData = () =>
    wordsServedGroupedByTimeSlice[0]?.timeslice || '';

  const wordsServedWithRemainingTimeSlices =
    getWordsServedWithRemainingTimeSlices(
      getFirstTimeSliceWithData,
      getLastTimeSliceWithData,
      isYearly,
      numOfDaysInMonth,
      wordsServedGroupedByTimeSlice
    );

  const getLanguageColor = (
    label: string,
    colorType: string
  ): string | undefined => {
    const objFound = wordsServedGroupedByTimeSlice?.find((obj) => obj[label]);
    const languageObj = objFound && objFound[label];

    if (colorType === 'fill') {
      return languageObj?.colors?.fill;
    } else {
      return languageObj?.colors?.stroke;
    }
  };

  const isNoData =
    targets.length === 0 ||
    wordsServed.length === 0 ||
    translationKeys.length === 0;

  return (
    <>
      <BlueCardHeader>WORDS SERVED TIME SERIES</BlueCardHeader>
      <Card style={{ borderRadius: 0, borderColor: 'transparent' }}>
        <Flex justify="end">
          {' '}
          <QueryMultiSelector
            encodeKeys
            selectAll
            shouldRevalidate
            options={filteredTargets}
          />
        </Flex>
        {loading ? (
          <LoadingSkeleton height={500} />
        ) : isNoData ? (
          <NoDataFoundPlaceHolder height={500} />
        ) : (
          <Flex pos="relative" w="100%" p={20} wrap="wrap-reverse">
            <Flex
              h={450}
              w={isTabletSize ? '100%' : '75%'}
              mt={isTabletSize ? 20 : 0}
            >
              <WordsServedTimeSeriesChart
                filteredTargets={filteredTargets}
                getLanguageColor={getLanguageColor}
                getLastTimeSliceWithData={getLastTimeSliceWithData}
                isYearly={isYearly}
                numOfDaysInMonth={numOfDaysInMonth}
                wordsServedGroupedByTimeSlice={wordsServedGroupedByTimeSlice}
                wordsServedWithRemainingTimeSlices={
                  wordsServedWithRemainingTimeSlices
                }
              />
            </Flex>
            <Flex
              w={isTabletSize ? '100%' : '25%'}
              wrap="wrap"
              direction="column"
              align="flex-end"
              pos="relative"
            >
              {wordsServed &&
                wordsServed.length > 0 &&
                translationKeys.length > 0 && (
                  <ScrollArea className={classes.languagesScrollArea} mt={20}>
                    {filteredTargets.map(({ label = '', value = '' }) => {
                      const borderColor = getLanguageColor(label, 'border');
                      const fillColor = getLanguageColor(label, 'fill');

                      const isActive =
                        borderColor &&
                        fillColor &&
                        translationKeys.includes(value);
                      return (
                        isActive && (
                          <Flex
                            align="center"
                            columnGap={15}
                            px={15}
                            py={8}
                            key={value}
                          >
                            <Box
                              miw={20}
                              h={20}
                              className={classes.languageBadge as string}
                              style={{
                                border: `1px solid ${borderColor}`,
                                backgroundColor: fillColor,
                              }}
                            />
                            <Text fw={600}>{label}</Text>
                          </Flex>
                        )
                      );
                    })}
                  </ScrollArea>
                )}
            </Flex>
          </Flex>
        )}
      </Card>
    </>
  );
};

export default WordsServedTimeSeries;
