import { useMemo } from 'react';
import { BsCreditCard2Back, BsPaypal } from 'react-icons/bs';
import { FaCcDinersClub } from 'react-icons/fa';
import { GrAmex } from 'react-icons/gr';
import { RiMastercardFill, RiVisaLine } from 'react-icons/ri';
import { SiDiscover, SiJcb } from 'react-icons/si';

const CardLogo = (props: { brand: string }) => {
  const { brand } = props;
  switch (brand.toLocaleLowerCase()) {
    case 'americanexpress':
    case 'amex':
      return <GrAmex size="2.5rem" />;
    case 'dinersclub':
      return <FaCcDinersClub size="2.5rem" />;
    case 'discover':
      return <SiDiscover size="2.5rem" />;
    case 'jcb':
      return <SiJcb size="2.5rem" />;
    case 'mastercard':
      return <RiMastercardFill size="2.5rem" />;
    case 'visa':
      return <RiVisaLine size="2.5rem" />;
    default:
      return <BsCreditCard2Back size="1.5rem" />;
  }
};

export const PaymentSourceLogo = (paymentSource: PaymentSource) => {
  const { type = '' } = paymentSource;

  // Keep wrapped in useMemo to avoid constant re-rendering
  const paymentSourceInfo = useMemo(
    () => paymentSource[type as keyof PaymentSource] || {},
    [type, paymentSource]
  );

  switch (type) {
    case 'creditCard': {
      const { brand = '' } = (paymentSourceInfo as CreditCard) || {};
      return <CardLogo brand={brand} />;
    }
    case 'payPalBilling':
      return <BsPaypal size="2rem" />;
    default:
      return type;
  }
};

export default PaymentSourceLogo;
