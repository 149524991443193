import { User as AuthUser } from 'oidc-client-ts';

// send IPC message to window with user data

export const saveDesktopUser = (user: User) => {
  const { saveXapisUser } = window?.dashboardUserAPI || {};
  if (typeof saveXapisUser === 'function') {
    saveXapisUser(user);
  }
};

export const saveAuthUserInMain = ({
  id,
  user,
}: {
  id: string;
  user: AuthUser | null;
}) => {
  const { saveAuthUser } = window.dashboardUserAPI || {};
  if (typeof saveAuthUser === 'function') {
    return saveAuthUser({ id, user });
  }
};

export const getAuthUserFromMain = async (
  id: string
): Promise<AuthUser | null> => {
  const { getAuthUser } = window.dashboardUserAPI || {};
  if (typeof getAuthUser === 'function') {
    return getAuthUser(id);
  }
  return null;
};
