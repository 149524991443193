import { Loader, Text, Tooltip } from '@mantine/core';
import { hexToObject } from 'helpers';
import { MRT_ColumnDef } from 'mantine-react-table';
import React, { useMemo, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { CustomTable } from 'ui';
import { getBasicColumn } from '../../../../../components/utils';
import { ProjectQueryLoaderData } from '../../../../../loaders/projectQueryLoader';
import getDisabledStatus from '../utils/getDisabledStatus';
import getRuledStatus from '../utils/getRuledStatus';
import handleExpandRow from '../utils/handleExpandRow';
import ExcludeActionsMenu from './ExcludeActionsMenu';
import IncludeActionsMenu from './IncludeActionsMenu';

type Props = {
  scope: string;
  yyTarget: TranslationKey;
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>;
  spiderTree: SpiderTreeNode[];
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};
export const ScopeTree = ({
  scope,
  yyTarget,
  setYYTarget,
  spiderTree,
  setSpiderTree,
}: Props) => {
  const [maxDepth, setMaxDepth] = useState(1);

  const yyTranslationKey = yyTarget?.translation_key || '';
  const activeTranslationConfig = yyTarget?.translation_config || '';
  const {
    projectData: { translations = [] },
  } = useRouteLoaderData('project') as ProjectQueryLoaderData;
  const config = hexToObject(activeTranslationConfig);
  const rulesToUse = useMemo<string[]>(() => {
    if (scope === 'include') {
      return config?.spider_rules?.include_uris || [];
    }
    return config?.spider_rules?.exclude_uris || [];
  }, [config?.spider_rules, scope]);
  const columns: MRT_ColumnDef<SpiderTreeNode>[] = [
    {
      ...getBasicColumn('label', 'web pages'),
      Cell: ({ row }) => {
        const { id, label, is_fake, content_url_orig, name, has_children } =
          row.original;

        const depth = id?.split('-').length ?? 0;

        const ruledStatusParams = {
          label,
          is_fake,
          content_url_orig,
          id,
          name: typeof name === 'string' ? name : '',
        };

        const { isRuled: nodeIsRuled = false } =
          getRuledStatus(ruledStatusParams, rulesToUse) || {};
        const nodeInfo = JSON.stringify({
          ...row.original,
          depth,
          nodeIsRuled,
        });
        const hasChildren = has_children > 0;
        if (label === 'HAS_CHILDREN') {
          return <Loader size={18} />;
        }

        let color = '';

        switch (true) {
          case nodeIsRuled:
            color =
              scope === 'include'
                ? 'var(--mantine-color-font1-3'
                : 'var(--mantine-color-font1-4';
            break;
          case !hasChildren:
            color = 'var(--mantine-color-font1-2';
            break;
          default:
            break;
        }

        const displayValue = label || (name ?? '');
        return (
          <Tooltip label={displayValue}>
            <Text
              c={color}
              data-nodeinfo={nodeInfo}
              data-id={id}
              truncate
              fw={nodeIsRuled && scope === 'include' ? 700 : 400}
              td={nodeIsRuled && scope === 'exclude' ? 'line-through' : ''}
            >
              {displayValue}
            </Text>
          </Tooltip>
        );
      },
      size: 120,
    },
    {
      ...getBasicColumn('', ''),
      id: 'manage',
      enableSorting: false,
      size: 60,
      grow: false,
      Cell: ({ row }) => {
        const rowValue = row.original as SpiderTreeNode;

        const {
          id,
          name: nodeName,
          is_fake,
          label,
          content_url_orig,
        } = rowValue;

        const name =
          nodeName === 'fetch' ? <Loader size={20} /> : content_url_orig;

        const ruledStatusParams = {
          label,
          is_fake,
          content_url_orig,
          id,
          name: typeof name === 'string' ? name : '',
        };

        const { isRuled: nodeIsRuled = false, relevantRule = '' } =
          getRuledStatus(ruledStatusParams, rulesToUse) || {};

        const disabledStatusParams = {
          node: rowValue,
          rulesList: rulesToUse,
          nodeIsRuled: nodeIsRuled,
          relevantRule,
        };

        const { isSourceOfChildrenOnlyRule, isDisabledChildNode } =
          getDisabledStatus(disabledStatusParams);
        return scope === 'include' ? (
          <IncludeActionsMenu
            node={rowValue}
            isIncluded={nodeIsRuled}
            currentIncludeRules={rulesToUse}
            isDisabledChildNode={isDisabledChildNode}
            isSourceOfChildrenOnlyRule={isSourceOfChildrenOnlyRule}
            yyTarget={yyTarget}
            setYYTarget={setYYTarget}
            spiderTree={spiderTree}
            setSpiderTree={setSpiderTree}
          />
        ) : (
          <ExcludeActionsMenu
            currentExcludeRules={rulesToUse}
            node={rowValue}
            isExcluded={nodeIsRuled}
            isDisabledChildNode={isDisabledChildNode}
            isSourceOfChildrenOnlyRule={isSourceOfChildrenOnlyRule}
            yyTarget={yyTarget}
            setYYTarget={setYYTarget}
            spiderTree={spiderTree}
            setSpiderTree={setSpiderTree}
          />
        );
      },
    },
  ];

  return (
    <CustomTable
      columns={columns}
      data={spiderTree}
      mantineExpandButtonProps={({ row }) => ({
        onClick: () => {
          handleExpandRow(
            row.original,
            translations,
            yyTranslationKey,
            spiderTree,
            setSpiderTree
          );
          if (row.original?.id?.split('-')?.length === maxDepth) {
            setMaxDepth((depth) => depth + 1);
          }
        },
        variant: 'transparent',
      })}
      getSubRows={(row) => row.children}
      enableBottomToolbar={false}
      enableSorting={false}
      enableExpanding={true}
      getRowCanExpand={(row) => row.original.has_children > 0}
      enableExpandAll={false}
      initialState={{
        columnVisibility: { manage: scope !== 'all' },
      }}
      displayColumnDefOptions={{
        'mrt-row-expand': {
          header: '',
          size: 16 * maxDepth + 28,
          grow: false,
        },
      }}
      containerProps={scope !== 'all' ? { h: '40vh', mih: '40vh' } : undefined}
    />
  );
};
