import { decodeId, encodeKey } from 'helpers';
import { useSearchParams } from 'react-router-dom';

const useGenerateParamHook = (paramName: string, isEncoded?: boolean) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = isEncoded
    ? decodeId(searchParams.get(paramName) || '')
    : searchParams.get(paramName) || '';

  const setParamValue = (value?: string) => {
    if (!value) searchParams.delete(paramName);
    else {
      const newParams = new URLSearchParams(searchParams);
      const paramValue = isEncoded ? encodeKey(value) : value;
      newParams.set(paramName, paramValue);
      setSearchParams(newParams);
    }
  };
  return [paramValue, setParamValue] as [string, (value?: string) => void];
};

const useGenerateMultiParamHook = (paramName: string, isEncoded: boolean) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const values = searchParams.getAll(paramName);
  const paramValues = isEncoded ? values.map((v) => decodeId(v)) : values;

  const setParamValues = (values?: string[]) => {
    searchParams.delete(paramName);
    if (values?.length)
      values.forEach((value) =>
        searchParams.append(paramName, isEncoded ? encodeKey(value) : value)
      );

    setSearchParams(searchParams);
  };
  return [paramValues, setParamValues] as [
    string[],
    (values?: string[]) => void,
  ];
};

export const useTranslationParam = () => useGenerateParamHook('t', true);
export const useTranslationsParams = () => useGenerateMultiParamHook('t', true);
export const useUrlParam = () => useGenerateParamHook('url');
