import { useMediaQuery } from '@mantine/hooks';
import { useCallback } from 'react';
import {
  AddSnippetBodyContent,
  AddSnippetSideMenuContent,
} from './AddSnippetStep';
import {
  CopySnippetBodyContent,
  CopySnippetSideMenuContent,
} from './CopySnippetStep';
import {
  CreateProjectBodyContent,
  CreateProjectSideMenuContent,
} from './CreateProjectStep';
import {
  CustomizeAppearanceBodyContent,
  CustomizeAppearanceSideMenuContent,
} from './CustomizeAppearanceStep';
import {
  GetStartedBodyContent,
  GetStartedSideMenuContent,
} from './GetStartedStep';
import { GoGlobalBodyContent, GoGlobalSideMenuContent } from './GoGlobalStep';
import { MobileStepsSwitch } from './MobileStepsSwitch';
import { PreviewBodyContent, PreviewSideMenuContent } from './PreviewStep';
import { useSetupStepsContext } from './ProjectSetupProvider';
import {
  SelectLanguagesBodyContent,
  SelectLanguagesSideMenuContent,
} from './SelectLanguagesStep';

const StepsSwitch = ({
  contentType,
}: {
  contentType: 'body' | 'side-menu';
}) => {
  const isDesktopSize = useMediaQuery('(min-width: 1200px)');
  const { activeStep } = useSetupStepsContext();

  const isSideMenuContent = contentType === 'side-menu';
  const isBodyContent = contentType === 'body';

  const renderProjectSetupStepsContent = useCallback(() => {
    if (!isDesktopSize && isSideMenuContent) {
      return <MobileStepsSwitch />;
    }
    if (!isDesktopSize && isBodyContent) {
      return <></>;
    }

    switch (true) {
      case isSideMenuContent && activeStep === 0:
        return <GetStartedSideMenuContent />;
      case isBodyContent && activeStep === 0:
        return <GetStartedBodyContent />;
      case isSideMenuContent && activeStep === 0.5:
        return <CreateProjectSideMenuContent />;
      case isBodyContent && activeStep === 0.5:
        return <CreateProjectBodyContent />;
      case isSideMenuContent && activeStep === 1:
        return <SelectLanguagesSideMenuContent />;
      case isBodyContent && activeStep === 1:
        return <SelectLanguagesBodyContent />;
      case isSideMenuContent && activeStep === 1.5:
        return <CustomizeAppearanceSideMenuContent />;
      case isBodyContent && activeStep === 1.5:
        return <CustomizeAppearanceBodyContent />;
      case isSideMenuContent && activeStep === 2:
        return <AddSnippetSideMenuContent />;
      case isBodyContent && activeStep === 2:
        return <AddSnippetBodyContent />;
      case isSideMenuContent && activeStep === 3:
        return <CopySnippetSideMenuContent />;
      case isBodyContent && activeStep === 3:
        return <CopySnippetBodyContent />;
      case isSideMenuContent && activeStep === 4:
        return <PreviewSideMenuContent />;
      case isBodyContent && activeStep === 4:
        return <PreviewBodyContent />;
      case isSideMenuContent && activeStep === 5:
        return <GoGlobalSideMenuContent />;
      case isBodyContent && activeStep === 5:
        return <GoGlobalBodyContent />;
      default:
        return isSideMenuContent ? (
          <GetStartedSideMenuContent />
        ) : (
          <GetStartedBodyContent />
        );
    }
  }, [activeStep, isBodyContent, isDesktopSize, isSideMenuContent]);
  return renderProjectSetupStepsContent();
};

export default StepsSwitch;
