import { Header } from 'ui';

export default function WordsServedUsageHeader() {
  return (
    <Header
      variant="h2"
      title="Words Served Usage"
      tooltipLabel="This chart shows your total words served for the month, today's increase,
                      and the projected total for the month based on your usage up to today. You can view historical
                      data and more filters in your Account Settings."
    />
  );
}
