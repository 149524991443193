import {
  BackgroundImage,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Image,
  Text,
  Title,
} from '@mantine/core';
import { encodeProjectKey } from 'helpers';
import { Link } from 'react-router-dom';
import dashboardRC from '../../routerConstants';
import useProjectsStore, { type ProjectStore } from '../../store/ProjectsStore';
import GoGlobalBackground from './images/go-global-background.svg';
import GoGlobalPerson from './images/go-global-person.svg';

export const GoGlobalSideMenuContent = () => {
  const { activeProject } = useProjectsStore((state: ProjectStore) => state);
  const { projectKey: activeProjectKey = '' } = activeProject;
  const projectId = encodeProjectKey(activeProjectKey);

  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
      ta="center"
    >
      <Title fz="2rem" order={1}>
        You're ready to GO global!
      </Title>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz="xl" fw={500} ta="center">
        If everything looks good, you’re ready to
        <br />
        <Text fz="inherit" fw={700} span>
          GO global!
        </Text>
      </Text>
      <Link to={`/${projectId}/${dashboardRC.PAGE_DASHBOARD}`}>
        <Button w={{ base: '100%', xs: '7rem' }}>GO</Button>
      </Link>
      <Text fs="italic" fz="md" fw={700} ta="center">
        Want to change anything?
      </Text>
      <Text fz="xl" fw={400} ta="center">
        You can edit the appearance and languages from your dashboard.
      </Text>
    </Flex>
  );
};

export const GoGlobalBodyContent = () => (
  <BackgroundImage
    w="100%"
    h="100%"
    src={`"${GoGlobalBackground}"`}
    style={{
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
    }}
    pl="3rem"
    pr="2rem"
  >
    <Flex w="100%" h="100%" align="center" justify="center">
      <Image
        w="100%"
        maw="45rem"
        h="auto"
        src={GoGlobalPerson}
        alt="Person happy with their GLGO translation software."
      />
    </Flex>
  </BackgroundImage>
);

export const MobileGoGlobalStep = () => {
  const { activeProject } = useProjectsStore((state: ProjectStore) => state);
  const { projectKey: activeProjectKey = '' } = activeProject;
  const projectId = encodeProjectKey(activeProjectKey);
  return (
    <Card maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex
        w="100%"
        h="100%"
        rowGap="1.5rem"
        direction="column"
        justify="center"
        align="center"
      >
        <Title fz="1.5rem" order={1}>
          You're ready to GO global!
        </Title>
        <Divider w="100%" color="divider.0" size={2} />
        <Text fz="md" fw={500} ta="center">
          If everything looks good, you’re ready to GO global!
        </Text>
        <Box w={{ base: '100%', xs: '7rem' }}>
          <Link
            style={{ width: '100%' }}
            to={`/${projectId}/${dashboardRC.PAGE_DASHBOARD}`}
          >
            <Button w="100%">GO</Button>
          </Link>
        </Box>
        <Text fs="italic" fz="md" fw={700} ta="center">
          Want to change anything?
        </Text>
        <Text fw={500} ta="center">
          You can edit the appearance and languages from your dashboard.
        </Text>
      </Flex>
    </Card>
  );
};
