import { Box, Button, Flex, ScrollArea } from '@mantine/core';
import { useMemo } from 'react';
import { AiFillTool } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import { Header, useCurrentProjectData, useTranslationParam } from 'ui';
import InactiveTranslations from '../../components/settings/manage-languages/InactiveTranslations';
import TranslationStatusHeader from '../../components/settings/manage-languages/TranslationStatusHeader';
import dashboardRC from '../../routerConstants';
import { TranslationCard } from './TranslationCard';
import { TranslationEditor } from './TranslationEditor';

export const Languages = () => {
  const { projectId } = useParams();

  const { translations } = useCurrentProjectData();
  const [selectedTKey, setSelectedTKey] = useTranslationParam();

  const target = translations.find((t) => t.translation_key === selectedTKey);
  const activeTranslations = useMemo(() => {
    const languages = translations.filter(({ is_active = true }) => is_active);

    return languages.sort((t1, t2) => {
      if (t1.target_lang_name < t2.target_lang_name) return -1;
      if (t1.target_lang_name > t2.target_lang_name) return 1;
      return 0;
    });
  }, [translations]);

  const inactiveTranslations = useMemo(() => {
    const languages = translations.filter(
      ({ is_active = false }) => !is_active
    );

    return languages.sort((t1, t2) => {
      if (t1.target_lang_name < t2.target_lang_name) return -1;
      if (t1.target_lang_name > t2.target_lang_name) return 1;
      return 0;
    });
  }, [translations]);

  // target not found but selectedTKey is not empty
  if (selectedTKey && !target) {
    // Redirect to languages tab
    setTimeout(() => {
      setSelectedTKey('');
    }, 1500);
    return (
      <Box mt={30}>
        Invalid translation key. Please select a language from the list.
      </Box>
    );
  }

  return (
    <Flex h="100%" style={{ flexGrow: 1 }}>
      {target ? (
        <TranslationEditor segmentEditorRef={null} target={target} />
      ) : (
        <Box miw="min(850px, 100%)">
          <Header
            title="Languages"
            flexItems={
              <Link
                to={`/${projectId}/${dashboardRC.PAGE_SETTINGS}/language-settings`}
                style={{ textDecoration: 'none', color: '#0071DB' }}
              >
                <Button
                  px={0}
                  variant="transparent"
                  rightSection={<AiFillTool size={18} />}
                >
                  Manage Languages
                </Button>
              </Link>
            }
          />
          <TranslationStatusHeader
            statusTitle={`Active (${activeTranslations.length.toLocaleString()})`}
            status="active"
          />
          <ScrollArea.Autosize
            mah={
              inactiveTranslations.length > 0
                ? `calc(100vh - ${260 + Math.min(3, inactiveTranslations.length) * 65}px)`
                : 'calc(100vh - 200px)'
            }
            mt={20}
            type="always"
            data-testid="pw-translations-card-container"
          >
            <Flex direction="column" rowGap={10}>
              {activeTranslations?.length > 0 ? (
                activeTranslations.map((translation) => (
                  <TranslationCard
                    key={translation.translation_key}
                    translation={translation}
                    handleSelection={setSelectedTKey}
                  />
                ))
              ) : (
                <></>
              )}
            </Flex>
          </ScrollArea.Autosize>
          <InactiveTranslations
            targets={translations}
            inactiveTranslations={inactiveTranslations}
          />
        </Box>
      )}
    </Flex>
  );
};
