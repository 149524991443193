import { useMantineTheme } from '@mantine/core';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Header } from 'ui';

type Props = {
  text: string;
};

const BackButton = ({ text }: Props) => {
  const colors = useMantineTheme().colors;
  return (
    <Link
      to=".."
      style={{ display: 'block', textDecoration: 'none', width: 'fit-content' }}
    >
      <Header
        title={text}
        variant="h3"
        titleProps={{ tt: 'uppercase' }}
        flexItems={
          <MdOutlineArrowRightAlt
            color={colors.font1[0]}
            size={24}
            className="flip"
          />
        }
        flexContainerProps={{
          w: 'fit-content',
          wrap: 'nowrap',
          direction: 'row-reverse',
        }}
      />
    </Link>
  );
};

export default BackButton;
