import React from 'react';
import { Text } from '@mantine/core';
import { CustomModal } from 'ui';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  removeActiveTranslation: (selectedTranslationKey: string) => void;
  selectedTranslation: TranslationKey | null;
  setSelectedTranslation: React.Dispatch<
    React.SetStateAction<TranslationKey | null>
  >;
};
export const DeactivateModal = ({
  isModalOpen,
  setIsModalOpen,
  removeActiveTranslation,
  selectedTranslation,
  setSelectedTranslation,
}: Props) => {
  return (
    <CustomModal
      size="lg"
      title="Are you sure you want to deactivate this language?"
      opened={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        setSelectedTranslation(null);
      }}
      footerActions={[
        { label: 'Cancel', action: () => setIsModalOpen(false) },
        {
          label: 'Confirm',
          action: () => {
            if (selectedTranslation?.translation_key) {
              removeActiveTranslation(selectedTranslation?.translation_key);
              setIsModalOpen(false);
            }
          },
        },
      ]}
    >
      <Text fz="md" ta="center">
        Deactivating a language means that your site will no longer offer it as
        an option and no further translations will be generated. The metrics
        history and your saved translations will be retained.
        <br />
        <br />
        You can always reactivate the language later!
      </Text>
    </CustomModal>
  );
};
