import { queryOptions } from '@tanstack/react-query';
import { isSuccessStatus } from 'helpers';
import { Xapis } from '@glweb/xapis-client';

export const queryWordsServed = (
  concatenatedKey: string,
  timeSliceFrom: string,
  rollup: string
) =>
  queryOptions({
    queryKey: ['wordsServed', concatenatedKey, timeSliceFrom, rollup],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const response = await Xapis.Metrics.get({
        translation_key: concatenatedKey,
        timeslice_from: timeSliceFrom,
        rollup,
        type: 'groupby',
        groupby: 'timeslice,translation_key',
        series: 'tot_words_served',
      });
      if (
        !response ||
        !isSuccessStatus(response.status) ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch words served data');
      }
      return response.data.data;
    },
  });
