import { formattingTags } from './Jliff.style';

export function isFormattingTag(tag: string) {
  return formattingTags.includes(tag) && tag !== 'a';
}

export function haveTags(jliffs: Jliff[]) {
  return jliffs.some((jliff) => isTag(jliff));
}

function isSc(jliff: Jliff) {
  return jliff.kind === 'sc';
}
function isEc(jliff: Jliff) {
  return jliff.kind === 'ec';
}
export function isToken(jliff: Jliff) {
  return jliff.kind === 'ph';
}
export function isTag(jliff: Jliff) {
  return ['sc', 'ec'].includes(jliff.kind || '');
}
export function isText(jliff: Jliff) {
  return Object.prototype.hasOwnProperty.call(jliff, 'text');
  // return Object.keys(jliff).includes('text');
}

export function isInRange(n: number, range: { from: number; to: number }) {
  if (!range) return false;

  const { to, from } = range;
  if (typeof to !== 'number' && typeof from !== 'number') {
    console.log('isInRange Error: range not a number', range);
    return false;
  }
  return n >= from && n <= to;
}

/** Find the next ID to use for new tags */
export function getNextId(jliffs: Jliff[]) {
  return jliffs.reduce((id, jliff) => {
    const jliffId = (jliff.id || '').match(/^a(\d+)/);
    if (!jliffId) return id;
    return Math.max(id, Number(jliffId[1]) + 1);
  }, 1);
}

// Return Jliff slice to insert with new tags
/**
 * Creates an array of Jliff objects that represent the selected text with the tags before and after it.
 * @param id - A number that is used to generate unique IDs for each Jliff object.
 * @param tags - An array of strings representing the HTML tags that should be applied to the text.
 * @param text - An optional string representing the text content of the Jliff object.
 * @param before - An optional string representing text that inserted before the tag(s).
 * @param after - An optional string representing text that inserted after the tag(s).
 * @returns An array of Jliff objects that represent the specified text content with the specified tags.
 */
export function createJliffSlice(
  id: number,
  tags: string[],
  text?: string,
  before?: string,
  after?: string
) {
  if (tags.length > 1) tags = tags.filter((tag) => tag !== 'br');
  const isBr = tags.includes('br');
  let jliffs: Jliff[] = [];
  const common = (tag: string): Jliff => ({
    type: 'fmt',
    subType: `mx:${tag}`,
    canDelete: 'yes',
    canCopy: 'no',
    canReorder: 'yes',
  });

  if (text && !isBr) {
    jliffs = [{ text }];
    tags.forEach((tag) => {
      // Add start tag before text
      jliffs.unshift({
        id: `a${id}`,
        kind: 'sc',
        ...common(tag),
      });
      // Add end tag after text
      jliffs.push({
        startRef: `a${id++}`,
        id: `a${id++}`,
        kind: 'ec',
        ...common(tag),
      });
    });
  } else if (isBr) {
    jliffs = [
      {
        kind: 'ph',
        id: `a${id++}`,
        ...common('br'),
      },
    ];
  }
  // Add before and after text
  if (before) jliffs.unshift({ text: before });
  if (after) jliffs.push({ text: after });
  return jliffs;
}

export function mapJliff(jliffs: Jliff[]) {
  const booleanish = {
    yes: true,
    no: false,
  } as const;

  const tagsHistory: string[] = [];
  const idHistory: string[] = [];
  // Keeps information on tags (range, parent tags, tag type)
  const tagMap: {
    [id: string]: {
      range: { from: number; to: number };
      tags: string[];
      ancestors: string[];
      type: string;
      canDelete: boolean;
      canMove: boolean;
    };
  } = {};
  // jliff index to tag ID
  let indexMap: string[] = [];
  if (!jliffs || jliffs.length === 0) return { indexMap, tagMap };
  jliffs.forEach((jliff, i) => {
    const lastId = idHistory.length ? idHistory[idHistory.length - 1] : '';

    if (isSc(jliff) && jliff.id) {
      // New tag
      const tagId = jliff.id;
      const tag = jliff.subType?.split(':')[1] || 'span';
      tagsHistory.push(tag); // add tag to tags stack
      idHistory.push(tagId); // add ID to stack
      tagMap[tagId] = {
        range: {
          from: i,
          to: jliffs.length, // Assume it has no 'ec'
        },
        tags: [...tagsHistory],
        ancestors: [...idHistory],
        type: tag,
        // Moving whole element and delete not allowed
        // IF 'ec' is missing
        canMove: false, // assume 'ec' is missing
        canDelete: false, // assume 'ec' is missing
      };
      indexMap.push(tagId);
    } else if (isEc(jliff) && (jliff.startRef || lastId)) {
      const tagId = jliff.startRef || lastId;
      indexMap.push(tagId);
      if (!Object.keys(tagMap).includes(tagId)) {
        // NO start tag for this closing tag
        const tag = jliff.subType?.split(':')[1] || 'span';
        // Add the tag to all existing entries in tagMap
        Object.keys(tagMap).forEach((id) => {
          tagMap[id].tags.push(tag);
        });
        tagMap[tagId] = {
          range: {
            from: -1,
            to: i,
          },
          tags: [...tagsHistory, tag],
          ancestors: [...idHistory, tagId],
          type: tag,
          // Moving whole element and delete not allowed
          // when 'sc' is missing
          canMove: false,
          canDelete: false,
        };
        // Set the id for all previous indexes that are empty
        indexMap = indexMap.map((id) => (id ? id : tagId));
      } else {
        const canDelete = booleanish[jliff.canDelete || 'no'];
        // Updating existing tagId
        tagMap[tagId].range.to = i;
        tagMap[tagId].canMove = true;
        tagMap[tagId].canDelete = canDelete;
        // remove tag and id from history
        tagsHistory.pop();
        idHistory.pop();
      }
    } else if (isToken(jliff) && jliff.id) {
      // PH
      tagMap[jliff.id] = {
        range: {
          from: i,
          to: i,
        },
        tags: [...tagsHistory],
        ancestors: [...idHistory],
        type: 'ph',
        canMove: true,
        canDelete: false,
      };
      indexMap.push(jliff.id);
    } else if (isText(jliff)) {
      // text
      indexMap.push(lastId);
    } else {
      console.warn('mapJliff: unrecognized JLIFF element:', jliff, jliffs);
    }
  });

  return { indexMap, tagMap };
}

export function validateTagsOrder(jliffs: Jliff[]) {
  const scIndexes = jliffs.reduce(
    (arr: number[], el, i) => (el.kind === 'sc' ? [...arr, i] : arr),
    []
  );

  scIndexes.forEach((scIndex) => {
    const ecIndex = jliffs.findIndex(
      (jliff) => jliff.kind === 'ec' && jliff.startRef === jliffs[scIndex].id
    );

    if (scIndex > ecIndex && ecIndex !== -1) {
      console.warn(
        'Error: Found ec index:',
        ecIndex,
        'before sc index',
        scIndex
      );
      console.log(jliffs);
      return false;
    }
  });
  return true;
}

export function findValidDrops(jliffs: Jliff[], index: number) {
  const validTargets: boolean[] = new Array(jliffs.length + 1).fill(true);
  // IF it's not a tag, all drop locations are valid
  if (!isSc(jliffs[index]) && !isEc(jliffs[index])) return validTargets;

  let count = 0;
  // Going backward
  for (let i = index - 1; i >= 0; i--) {
    if (isSc(jliffs[i])) {
      count -= 1;
    } else if (isEc(jliffs[i])) {
      count = count < 0 ? -1 : (count += 1);
    }
    validTargets[i] = count === 0;
  }
  // Going forward
  count = 0;
  for (let i = index + 1; i < jliffs.length; i++) {
    if (isSc(jliffs[i])) {
      validTargets[i] = count === 0;
      count = count < 0 ? -1 : (count += 1);
    } else if (isEc(jliffs[i])) {
      validTargets[i] = count === 0;
      count -= 1;
    } else {
      validTargets[i] = count === 0;
    }
  }
  validTargets[validTargets.length - 1] = count === 0;
  return validTargets;
}

// eslint-disable-next-line no-useless-escape
const textRegEx = new RegExp(/([a-z]+|[0-9]+|\s+|[\W-])/, 'gi');

const isEmptyJliff = (jliff: Jliff[]) => {
  return !jliff?.length || jliff.every((el) => isText(el) && !el.text);
};

// Split text into words/space/punctuation/special characters
export function expandJliff(jliffs: Jliff[]) {
  const expanded: Jliff[] = [];
  jliffs.forEach((jliff) => {
    if (jliff.text) {
      const textArray = jliff.text.match(textRegEx);
      textArray?.forEach((text) => expanded.push({ text: text }));
    } else {
      expanded.push(jliff);
    }
  });
  return expanded;
}

// Add empty text elements between consecutive tags/tokens
export function textifyJliff(jliffs: Jliff[]) {
  if (isEmptyJliff(jliffs)) return [{ text: '' }];
  const textified: Jliff[] = [];
  const n = (jliffs && jliffs.length) || 0;
  for (let i = 0; i < n; i++) {
    const jliff = jliffs[i];
    if (isText(jliff)) {
      let text = jliff.text || '';
      while (i < n - 1 && isText(jliffs[i + 1])) {
        i += 1;
        text += jliffs[i].text;
      }
      // text.replace(/\s\s+/g, ' ');
      textified.push({ text });
    } else {
      // If the first element is not text, add an empty text element
      if (i === 0) textified.push({ text: '' });
      textified.push(jliff);
      // If the last element is not text, add an empty text element
      if (i === n - 1) textified.push({ text: '' });
      // If the next element is not text, add an empty text element
      else if (!isText(jliffs[i + 1])) textified.push({ text: '' });
    }
  }
  return textified;
}

// Join consecutive text elements and remove empty text elements
export function compressJliff(jliffs: Jliff[]) {
  if (isEmptyJliff(jliffs)) return [{ text: '' }];

  const compressed: Jliff[] = [];
  const n = jliffs.length;
  for (let i = 0; i < n; i++) {
    const jliff = jliffs[i];
    if (!isText(jliff)) {
      compressed.push(jliff);
    } else if (isText(jliff) && jliff.text) {
      let text = jliff.text || '';
      while (i < n - 1 && isText(jliffs[i + 1])) {
        i += 1;
        text += jliffs[i].text;
      }
      // text.replace(/\s\s+/g, ' ');
      compressed.push({ text });
    }
  }
  return compressed;
}

export function jliffsAreEqual(jliffs1: Jliff[], jliffs2: Jliff[]) {
  if (!jliffs1 && !jliffs2) return true; // deals with input values being null
  if (!jliffs1 || !jliffs2) return false; // deals with input values being null
  if (jliffs1.length !== jliffs2.length) return false;
  for (let i = 0; i < jliffs1.length; i++) {
    if (JSON.stringify(jliffs1[i]) !== JSON.stringify(jliffs2[i])) return false;
  }
  return true;
}

export function getJliffText(jliffs: Jliff[]) {
  let text: string = '';
  jliffs.forEach((jliff) => {
    if (isText(jliff)) text += jliff.text;
    else if (isToken(jliff)) {
      if (jliff.equiv === ' ') text += ' ';
      else text += '|';
    }
  });
  return text;
}
