import dashboardRC from '../../routerConstants';

const getSegNameFromPath = (pathname: string) => {
  const pageMappings = {
    [dashboardRC.PAGE_CREATE_ACCOUNT]: 'GLWeb:CREATE_ACCOUNT',
    [dashboardRC.PAGE_CREATE_PROJECT]: 'GLWeb:ACCOUNT_COMPLETION',
  } as const;

  if (pathname in pageMappings) {
    return pageMappings[pathname as keyof typeof pageMappings];
  }

  return null;
};

export default getSegNameFromPath;
