import React, { useEffect } from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { type ProjectQueryLoaderData } from './loaders/projectQueryLoader';

const { setWindowTitle } = window.dashboardAPI || {};

const ProjectOutletWrapper = () => {
  const { projectData } = useLoaderData() as ProjectQueryLoaderData;
  const project = projectData.project;

  useEffect(() => {
    const title = `${project.project_name} | GlobalLink Web`;
    document.title = title;
    // Set WebStudio window title
    if (typeof setWindowTitle === 'function') {
      setWindowTitle({ window: 'studio', title: `${title}Studio` });
    }
  }, [project.project_name]);

  return <Outlet />;
};

export default ProjectOutletWrapper;
