import {
  Button,
  Flex,
  Paper,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import {
  calculateEstimatedWordsServed,
  calculateMonthlyPageViews,
} from 'helpers';
import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { xapisEnv } from '@glweb/xapis-client';
import { useCurrentProjectData, useUserProvider } from 'ui';
import EstimateCalculation from '../../dashboard/words-served-estimate/EstimateCalculation';

type Props = {
  translatedTraffic: number;
  setTranslatedTraffic: React.Dispatch<React.SetStateAction<number>>;
  isQuickQuoteCached?: boolean;
  quickQuote: QuickQuoteResponse;
};
const EstimateInformation = ({
  translatedTraffic,
  setTranslatedTraffic,
  isQuickQuoteCached,
  quickQuote,
}: Props) => {
  const [averagePageWords, setAveragePageWords] = useState(0);
  const [pageViewsMonthly, setPageViewsMonthly] = useState(0);

  const { colors } = useMantineTheme();
  const { glgoMarketingUrl } = xapisEnv.getHost;
  const { xapisUser: { email = '' } = {} } = useUserProvider();

  const { project: { origin_name = '' } = {} } = useCurrentProjectData();

  const {
    spider_job: {
      num_words = '0',
      num_pages = '0',
      average_words_per_page = 0,
    } = {},
    traffic: { results: { visits = '0', pages_per_visit = '0' } = {} } = {},
  } = quickQuote || {};

  const monthlyPageViews = calculateMonthlyPageViews(visits, pages_per_visit);
  const estimatedWordsServed = calculateEstimatedWordsServed(
    translatedTraffic,
    averagePageWords,
    pageViewsMonthly
  );
  const analyzedWords = num_words.replace('+', '').toLocaleString();
  const analyzedPages = num_pages.replace('+', '').toLocaleString();

  useEffect(() => {
    setAveragePageWords(average_words_per_page);
  }, [average_words_per_page]);

  useEffect(() => {
    setPageViewsMonthly(monthlyPageViews);
  }, [monthlyPageViews]);

  return (
    <Flex w="100%" h="100%" rowGap="1rem" direction="column" justify="center">
      <Flex
        mih="2.25rem"
        justify="space-between"
        align="center"
        columnGap="1rem"
      >
        <Title order={3} tt="uppercase">
          Words Served Estimate
        </Title>
        <Link
          to={`${glgoMarketingUrl}/estimate?domain=${origin_name}&email=${email}`}
          target="_blank"
        >
          <Button variant="transparent" rightSection={<FaArrowRight />} pr={0}>
            See Full Analysis
          </Button>
        </Link>
      </Flex>
      <Paper
        h="100%"
        p="2rem"
        style={{
          borderRadius: 12,
          border: `1px solid ${colors.border[1]}`,
          boxShadow: 'none',
        }}
      >
        {isQuickQuoteCached ? (
          <EstimateCalculation
            monthlyPageViews={pageViewsMonthly}
            estimatedWordsServed={estimatedWordsServed}
            analyzedWords={analyzedWords}
            analyzedPages={analyzedPages}
            averageWordsPerPage={averagePageWords}
            translatedTraffic={translatedTraffic}
            setTranslatedTraffic={setTranslatedTraffic}
            setAveragePageWords={setAveragePageWords}
            setPageViewsMonthly={setPageViewsMonthly}
            stackProps={{ px: 0, py: 0 }}
          />
        ) : (
          <Text>Estimate Unavailable</Text>
        )}
      </Paper>
    </Flex>
  );
};

export default EstimateInformation;
