import { showNotification } from '@mantine/notifications';
import { isValidDomain } from 'helpers';
import { UseFormGetFieldState } from 'react-hook-form';
import { ProjectResponse, Xapis } from '@glweb/xapis-client';

export type FormValues = {
  domain: string;
  stagingDomain: string;
};

export const getDefaultStagingDomain = (domain: string) => {
  const hasWWWDot = domain.includes('www.');
  return hasWWWDot
    ? `${domain.substring(0, 4)}staging.${domain.substring(4)}`
    : `staging.${domain}`;
};

export const removeStagingDomain = (
  stagingDomains: string[],
  setStagingDomains: React.Dispatch<React.SetStateAction<string[]>>,
  setCurrentStagingDomain: React.Dispatch<React.SetStateAction<string>>,
  resetForm: (data: Record<string, string>) => void,
  project_key: string
) => {
  updateDomain('additional_origins', null, stagingDomains, project_key).then(
    (response) => {
      const {
        project: {
          origin_name: originName = '',
          additional_origins: additionalOrigins = [],
        } = {},
      } = response || {};
      setStagingDomains(additionalOrigins);
      setCurrentStagingDomain('');
      resetForm({
        stagingDomain: getDefaultStagingDomain(originName),
      });
    }
  );
};

export const updateDomain = async (
  key: 'origin_name' | 'additional_origins',
  domain: string | null,
  stagingDomains: string[],
  projectKey: string
): Promise<ProjectResponse> => {
  const isOrigin = key === 'origin_name';
  let value;

  if (isOrigin) {
    value = domain;
  } else {
    const removeStagingDomain = domain === null;
    value = [...stagingDomains];
    if (value.length > 0) {
      if (removeStagingDomain) {
        // remove first value in list
        value.splice(0, 1);
      } else {
        // replace first value in list
        value.splice(0, 1, domain);
      }
    } else {
      value = removeStagingDomain ? [] : [domain];
    }
  }

  return Xapis.Project.put(projectKey, {
    [key]: value,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const { response = {} } = error || {};
      showNotification({
        message: `Oops, something went wrong while updating${isOrigin ? '' : ' staging'} domain`,
        color: 'red',
      });
      return response;
    });
};

export const onSave = (
  data: FormValues,
  getFieldState: UseFormGetFieldState<FormValues>,
  resetForm: (data: Record<string, string>) => void,
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>,
  editDomain: boolean,
  setEditDomain: React.Dispatch<React.SetStateAction<boolean>>,
  setEditStagingDomain: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentStagingDomain: React.Dispatch<React.SetStateAction<string>>,
  stagingDomains: string[],
  setStagingDomains: React.Dispatch<React.SetStateAction<string[]>>,
  currentDomain: string,
  projectKey: string,
  projectType: string
) => {
  const { domain: newDomain = '', stagingDomain: newStagingDomain = '' } =
    data || {};

  const key = editDomain ? 'origin_name' : 'additional_origins';
  const isOrigin = key === 'origin_name';

  if (isOrigin) {
    if (getFieldState('domain').isDirty === false) {
      setEditDomain(false);
      return;
    }
    if (!isValidDomain(newDomain)) {
      showNotification({
        message: 'Invalid domain entered',
        color: 'red',
      });
      return;
    }
    setShowConfirmModal(true);
  } else {
    if (projectType !== 'GLGO') {
      showNotification({
        message:
          'Updating staging domain of a non-GLGO project is not supported',
        color: 'red',
      });
      return;
    } else if (stagingDomains.includes(newStagingDomain)) {
      setEditStagingDomain(false);
      return;
    } else if (
      !isValidDomain(newStagingDomain) ||
      newStagingDomain === currentDomain
    ) {
      showNotification({
        message: 'Invalid staging domain entered',
        color: 'red',
      });
      return;
    } else {
      updateDomain(
        'additional_origins',
        newStagingDomain,
        stagingDomains,
        projectKey
      ).then((response) => {
        const {
          project: {
            origin_name: originName = '',
            additional_origins: additionalOrigins = [],
          } = {},
        } = response || {};
        setStagingDomains(additionalOrigins);
        setCurrentStagingDomain(additionalOrigins[0] || '');
        setEditStagingDomain(false);
        resetForm({
          stagingDomain:
            additionalOrigins[0] || getDefaultStagingDomain(originName),
        });
      });
    }
  }
};
