import { RouteObject } from 'react-router-dom';
import RestrictedRoute from '../../RestrictedRoute';
import { TranslatedTraffic } from '../../components/metrics/TranslatedTraffic';
import { WorldTraffic } from '../../components/metrics/WorldTraffic';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import {
  USER_ROLES_TRANSLATED_TRAFFIC_PAGE,
  USER_ROLES_WORLD_TRAFFIC_PAGE,
} from '../../constants';
import dashboardRC from '../../routerConstants';

const metricsRoutes: Array<RouteObject & { path: string }> = [
  {
    path: dashboardRC.PAGE_TRANSLATED_TRAFFIC,
    id: 'translatedTraffic',
    element: (
      <RestrictedRoute allowedRoles={USER_ROLES_TRANSLATED_TRAFFIC_PAGE}>
        <TranslatedTraffic />
      </RestrictedRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: dashboardRC.PAGE_WORLD_TRAFFIC,
    id: 'worldTraffic',
    element: (
      <RestrictedRoute allowedRoles={USER_ROLES_WORLD_TRAFFIC_PAGE}>
        <WorldTraffic />
      </RestrictedRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
];

export default metricsRoutes;
