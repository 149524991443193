import {
  Avatar,
  Flex,
  Group,
  ScrollArea,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { hexToAscii } from 'helpers';
import { ReactNode } from 'react';
import { FiMail, FiUsers } from 'react-icons/fi';
import { AnchorLink, useCurrentProjectData } from 'ui';
import { BasicHorizontalDivider } from '../../shared/BasicHorizontalDivider';

export const MyContacts = () => {
  const { colors } = useMantineTheme();

  const {
    project: { project_config: projectConfig },
  }: { project: ProjectKey } = useCurrentProjectData();

  const projectContacts =
    (projectConfig && JSON.parse(hexToAscii(projectConfig)).project_contacts) ||
    [];

  const contacts = projectContacts.map(
    ({
      image,
      avatar,
      title,
      email,
    }: {
      image: string;
      avatar: ReactNode;
      title: string;
      email: string;
    }) => (
      <div key={email}>
        <Group w="100%" wrap="nowrap" m={5}>
          <Avatar src={image} size="md">
            {!image && <span>{avatar}</span>}
          </Avatar>
          <Flex direction="column" flex="grow">
            <Text fz="xs" fw={500}>
              {title}
            </Text>
            <AnchorLink
              to={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Flex wrap="nowrap" gap={5} align="center">
                <FiMail size={14} />
                <Text fz="xs" fw={400}>
                  {email}
                </Text>
              </Flex>
            </AnchorLink>
          </Flex>
        </Group>
        <BasicHorizontalDivider mobileMargin="0" />
      </div>
    )
  );

  return contacts.length > 0 ? (
    <ScrollArea w="100%" mah={190} offsetScrollbars>
      {contacts}
    </ScrollArea>
  ) : (
    <Flex
      h="100%"
      justify="center"
      align="center"
      direction="column"
      gap={20}
      p="1rem"
    >
      <FiUsers size={55} color={colors.font1[1]} />
      <Text ta="center">Team members will be added shortly</Text>
    </Flex>
  );
};

export default MyContacts;
