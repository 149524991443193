import {
  Card,
  Flex,
  LoadingOverlay,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { isStudioEditorWindow } from 'helpers';
import 'mantine-datatable/styles.css';
import { MRT_ColumnDef, MRT_RowData } from 'mantine-react-table';
import { Dispatch, SetStateAction } from 'react';
import { CustomTable, getBasicColumn, getEditDeleteColumn, InfoIcon } from 'ui';
import { DeleteNoTxRule } from './DeleteNoTxRule';
import { NoTranslateXRule } from './NoTxEditor';
import { NoTxRuleForm } from './NoTxRuleForm';
import { previewNoTxRule } from './contextFunctions';
import { useNoTxColors } from './useNoTxColors';

type Props = {
  rules: NoTranslateXRule[];
  yyTarget: TranslationKey | undefined;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  selectRule?: (id: number) => void;
};

const isStudioEditor = isStudioEditorWindow();

const NoTxRulesTable = ({
  rules,
  selectRule,
  yyTarget,
  setLoading,
  loading,
}: Props) => {
  const mantineColors = useMantineTheme().colors;
  const colors = useNoTxColors().table;
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const heightOffset = isMobileSize && !isStudioEditor ? 380 : 300;

  function handleRowClick(rule: NoTranslateXRule) {
    previewNoTxRule(rule.selector);
    selectRule && selectRule(rule.id);
  }

  const orderColumn = {
    ...getBasicColumn('order', ''),
    size: 65,
    grow: false,
    mantineTableHeadCellProps: { pl: 0 },
  };
  const selectorColumn: MRT_ColumnDef<NoTranslateXRule> = {
    ...getBasicColumn('selector.X', 'selector', !isMobileSize),
    Header: (
      <Flex align="center" tt="uppercase">
        <Title order={3} tt="uppercase">
          SELECTOR
        </Title>
        <Tooltip label="This supports any CSS selector.">
          <Flex align={'center'}>
            <InfoIcon fill={mantineColors.secondary1[0]} size={15} />
          </Flex>
        </Tooltip>
      </Flex>
    ),
    ...(isMobileSize && {
      Cell: ({ cell, row }) => (
        <Tooltip
          label={
            <Stack>
              <Text inherit>{cell.getValue() as string}</Text>
              {row.original.selector.comment && (
                <Text style={{ whiteSpace: 'wrap' }} inherit>
                  {row.original.selector.comment as string}
                </Text>
              )}
            </Stack>
          }
        >
          <Text truncate>{cell.getValue() as string}</Text>
        </Tooltip>
      ),
    }),
  };
  const commentColumn = getBasicColumn('selector.comment', 'description', true);
  const actionsColumn = getEditDeleteColumn(
    ({ data }: { data: MRT_RowData }) => {
      return (
        <NoTxRuleForm
          id={data.id}
          rules={rules}
          yyTarget={yyTarget}
          setLoading={setLoading}
        />
      );
    },
    ({ data }: { data: MRT_RowData }) => (
      <DeleteNoTxRule
        id={data.id}
        yyTarget={yyTarget}
        setLoading={setLoading}
      />
    )
  );

  const columns: MRT_ColumnDef<NoTranslateXRule>[] = [
    orderColumn,
    selectorColumn,
    commentColumn,
    actionsColumn,
  ];

  return (
    <Card p={0}>
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <CustomTable
        key={isMobileSize ? 'mobile' : 'desktop'} // force re-render on mobile/desktop switch
        data={rules}
        columns={columns}
        mantineTableBodyRowProps={({ row }) =>
          isStudioEditor
            ? {
                fw: row.original.isOnPage ? 400 : 100,
                fs: row.original.isOnPage ? 'normal' : 'italic',
                bg: row.original.isSelected
                  ? colors.background.selected
                  : colors.background.normal,
                c: row.original.isSelected
                  ? colors.font.selected
                  : colors.font.normal,
                onClick: () => {
                  handleRowClick(row.original);
                },
                style: {
                  cursor: 'pointer',
                  borderColor: '#CBD1F9',
                },
              }
            : { style: { borderColor: '#CBD1F9' } }
        }
        containerProps={{
          style: {
            maxHeight: `calc(100vh - ${heightOffset}px)`,
            minHeight: 300,
          },
        }}
        defaultColumn={{ size: 175 }}
        bottomToolbarString={rules.length === 1 ? 'Rule' : 'Rules'}
        initialState={{
          columnVisibility: { 'selector.comment': !isMobileSize }, // hide comment on mobile
        }}
      />
    </Card>
  );
};

export default NoTxRulesTable;
