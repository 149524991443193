import { useLoaderData, useSearchParams } from 'react-router-dom';
import { Header, useCurrentProjectData } from 'ui';
import {
  filterTargets,
  updateDateParam,
} from '../../../components/metrics/trafficUtils';
import DateFilter from '../../../components/selectors-filters/DateFilter';
import QueryMultiSelector from '../../../components/selectors-filters/QueryMultiSelector';
import { getTimeSliceOptions } from '../../../components/utils';
import { DashboardLoaderResponse } from '../../../loaders/dashboardLoader';

export default function MetricsSummaryHeader() {
  const { timeSliceFrom } = useLoaderData() as DashboardLoaderResponse;
  const { project } = useCurrentProjectData();

  const filteredTargets = filterTargets(project.translations || []);

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Header
      minHeight={40}
      wrapContent
      variant="h2"
      title="Metrics Summary"
      tooltipLabel="This section of your dashboard presents summary statistics related to your project.
            You can filter by language and time period. For a more detailed breakdown, you can go to the Metrics tab."
      flexItems={
        <>
          <QueryMultiSelector
            encodeKeys
            selectAll
            shouldRevalidate
            options={filteredTargets}
          />
          <DateFilter
            timeSliceOptions={getTimeSliceOptions()}
            timeSliceFrom={timeSliceFrom}
            onSelect={(value) =>
              updateDateParam(value, searchParams, setSearchParams)
            }
          />
        </>
      }
    />
  );
}
