import { useState } from 'react';
import {
  Checkbox,
  Combobox,
  Flex,
  Text,
  Pill,
  PillsInput,
  ScrollArea,
  useCombobox,
  Group,
  Box,
} from '@mantine/core';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { AiOutlineSearch } from 'react-icons/ai';
import { NOOP } from 'helpers';
import classes from './TargetLanguagesSelector.module.css';

type TargetLanguage = {
  value: string;
  label: string;
};

type Props = {
  targetLanguages: TargetLanguage[];
};

const TargetLanguagesSelector = ({ targetLanguages }: Props) => {
  const { selectedTargetLangCodes, setSelectedTargetLangCodes } =
    useSetupStepsContext();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');

  const handleValueSelect = (val: string) => {
    if (val === 'SELECT_ALL') {
      const allTargetLangCodes = targetLanguages.map(({ value }) => value);
      const isActive =
        selectedTargetLangCodes.length === allTargetLangCodes.length;
      setSelectedTargetLangCodes(isActive ? [] : allTargetLangCodes);
    } else {
      setSelectedTargetLangCodes((current) =>
        current.includes(val)
          ? current.filter((v) => v !== val)
          : [...current, val]
      );
    }
  };

  const handleValueRemove = (val: string) =>
    setSelectedTargetLangCodes((current) => current.filter((v) => v !== val));

  const selectedTargets = targetLanguages.filter((lang) =>
    selectedTargetLangCodes.includes(lang.value)
  );

  const allLangsSelected =
    selectedTargetLangCodes.length ===
    targetLanguages.map((l) => l.value).length;

  const values = selectedTargets.map((item) => (
    <Pill
      m="0.15rem"
      size="sm"
      key={item.value}
      style={{ cursor: 'pointer' }}
      onClick={() => handleValueRemove(item.value)}
      withRemoveButton
      onRemove={() => handleValueRemove(item.value)}
      tt="uppercase"
    >
      {item.label}
    </Pill>
  ));

  const options = [
    { label: 'Select All', value: 'SELECT_ALL' },
    ...targetLanguages,
  ]
    .filter(({ label }) =>
      label.toLowerCase().includes(search.trim().toLowerCase())
    )
    .map((item) => {
      const isSelected =
        allLangsSelected || selectedTargetLangCodes.includes(item.value);

      return (
        <Combobox.Option
          value={item.value}
          key={item.value}
          active={isSelected}
        >
          <Group gap="sm" align="flex-start" wrap="nowrap">
            <Checkbox
              aria-hidden
              mt="0.2rem"
              checked={isSelected}
              // leave this empty handler in. needed if checked prop is present
              onChange={NOOP}
              tabIndex={-1}
            />
            <Text fz="inherit" fw="inherit">
              {item.label}
            </Text>
          </Group>
        </Combobox.Option>
      );
    });

  return (
    <Flex direction="column" gap={30}>
      <Combobox
        store={combobox}
        withinPortal={true}
        onOptionSubmit={handleValueSelect}
        size="md"
      >
        <Combobox.DropdownTarget>
          <PillsInput
            size="md"
            classNames={{ input: classes.targetSelectorTextInput }}
            maw="25rem"
            pointer
            onClick={() => combobox.toggleDropdown()}
            rightSection={<AiOutlineSearch size={18} />}
          >
            <Pill.Group>
              <Combobox.EventsTarget>
                <PillsInput.Field
                  classNames={{ field: classes.pillsInputField }}
                  value={search}
                  onBlur={() => combobox.closeDropdown()}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(
                        selectedTargetLangCodes[
                          selectedTargetLangCodes.length - 1
                        ]
                      );
                    }
                  }}
                  placeholder="Add Languages"
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>
        <Combobox.Dropdown>
          <ScrollArea h={208} scrollbars="y" offsetScrollbars>
            <Combobox.Options>{options}</Combobox.Options>
          </ScrollArea>
        </Combobox.Dropdown>
      </Combobox>
      <ScrollArea
        h="7rem"
        p="1rem"
        scrollbars="y"
        type="always"
        pr={0}
        className={classes.scrollArea}
      >
        <Box pr={10}>{values}</Box>
      </ScrollArea>
    </Flex>
  );
};

export default TargetLanguagesSelector;
