import { useMantineTheme, Box } from '@mantine/core';

const StatusCircleIcon = ({
  status,
  size,
  borderSize,
  float,
}: {
  status: 'live' | 'active' | 'inactive' | 'private';
  size?: string | number;
  borderSize?: number;
  float?: 'right';
}) => {
  const theme = useMantineTheme();

  const getColor = (property: string) => {
    let color;
    if (property === 'backgroundColor') {
      switch (true) {
        case status === 'live':
          color = theme.colors.background2[1];
          break;
        case status === 'active':
          color = theme.colors.background2[2];
          break;
        case status === 'inactive':
          color = theme.colors.background2[3];
          break;
        case status === 'private':
        default:
          color = theme.colors.background2[5];
          break;
      }
      return color || '#707070';
    }
    switch (true) {
      case status === 'live':
        color = theme.colors.border[3];
        break;
      case status === 'active':
        color = theme.colors.border2[1];
        break;
      case status === 'inactive':
        color = theme.colors.border2[2];
        break;
      case status === 'private':
      default:
        color = theme.colors.border[2];
        break;
    }
    return color || '#C8DFF8';
  };

  return (
    <Box w={size ? Number(size) : 12}>
      <Box
        style={{
          width: size || 12,
          height: size ? Number(size) : 12,
          backgroundColor: getColor('backgroundColor'),
          border: `${borderSize || 2}px solid ${getColor('border')}`,
          borderRadius: 50,
          float: float || 'none',
        }}
      />
    </Box>
  );
};

export default StatusCircleIcon;
