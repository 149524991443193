import { Card, Stack } from '@mantine/core';
import { getYYTarget, hexToObject } from 'helpers';
import { useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { SpiderTreeResponse } from '@glweb/xapis-client';
import { useCurrentProjectData } from 'ui';
import ScopeDivider from './dividers/ScopeDivider';
import BackButtonSection from './landing-page/BackButtonSection';
import ExcludeRulesSection from './rules-section/ExcludeRulesSection';
import IncludeRulesSection from './rules-section/IncludeRulesSection';
import { ScopeTree } from './scope-option/scope-modes/ScopeTree';

const ScopeUrlsTree = () => {
  const { project: { translations = [] } = {} } = useCurrentProjectData();
  const { tree = [] } = useLoaderData() as SpiderTreeResponse;

  const yyTargetFound = getYYTarget(translations) as TranslationKey;

  const [yyTarget, setYYTarget] = useState(yyTargetFound);
  const [spiderTree, setSpiderTree] = useState(tree);

  const { scope_preset = '' } = useMemo(() => {
    return hexToObject(yyTarget?.translation_config || '');
  }, [yyTarget?.translation_config]);

  const currentScope = useMemo(() => {
    return scope_preset.length > 0 &&
      ['all', 'include', 'exclude'].includes(scope_preset)
      ? scope_preset
      : 'all';
  }, [scope_preset]);

  return (
    <Stack gap={20}>
      <BackButtonSection scope={currentScope} />
      <Card p={0}>
        <ScopeTree
          scope={currentScope}
          yyTarget={yyTarget}
          setYYTarget={setYYTarget}
          spiderTree={spiderTree}
          setSpiderTree={setSpiderTree}
        />
      </Card>
      {currentScope !== 'all' && <ScopeDivider currentScope={currentScope} />}
      {currentScope === 'all' ? (
        <></>
      ) : currentScope === 'exclude' ? (
        <ExcludeRulesSection
          yyTarget={yyTarget}
          setYYTarget={setYYTarget}
          setSpiderTree={setSpiderTree}
        />
      ) : (
        <IncludeRulesSection
          yyTarget={yyTarget}
          setYYTarget={setYYTarget}
          setSpiderTree={setSpiderTree}
        />
      )}
    </Stack>
  );
};

export default ScopeUrlsTree;
