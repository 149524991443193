import { xapisEnv } from '@glweb/xapis-client/src/service-wrappers/xapisService';
import {
  BackgroundImage,
  Box,
  Button,
  Divider,
  Flex,
  Stack,
  Text,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  calculateEstimatedWordsServed,
  calculateMonthlyPageViews,
  getRecommendedPlan,
} from 'helpers';
import { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useCurrentProjectData, useGLGOParams, useUserProvider } from 'ui';
import { useRecommendedThresholdContext } from '../../../providers/RecommendedThresholdProvider';
import { querySkus } from '../../../queries/shared/querySkus';
import BackgroundLogo from './background-logo.svg';
import EstimateCalculation from './EstimateCalculation';

type Props = {
  quickQuote: QuickQuoteResponse | null;
};

const WordsServedEstimate = ({ quickQuote }: Props) => {
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const { glgoMarketingUrl } = xapisEnv.getHost;

  const { projectId } = useGLGOParams();
  const { xapisUser: { email = '' } = {} } = useUserProvider();
  const setThreshold = useRecommendedThresholdContext().setThreshold;
  const { project } = useCurrentProjectData();
  const { origin_name = '' } = project;

  const { payKey } = xapisEnv.getHost;
  const { data: skus } = useSuspenseQuery(querySkus(payKey));

  const {
    spider_job: {
      num_words = '0',
      num_pages = '0',
      average_words_per_page = 0,
    } = {},
    traffic: { results: { visits = '0', pages_per_visit = '0' } = {} } = {},
  } = quickQuote || {};

  const [translatedTraffic, setTranslatedTraffic] = useState(10);
  const [averagePageWords, setAveragePageWords] = useState(0);
  const [pageViewsMonthly, setPageViewsMonthly] = useState(0);

  const monthlyPageViews = calculateMonthlyPageViews(visits, pages_per_visit);
  const estimatedWordsServed = calculateEstimatedWordsServed(
    translatedTraffic,
    averagePageWords,
    pageViewsMonthly
  );
  const {
    name = '',
    threshold_value = 0,
    sku_type,
  } = getRecommendedPlan(estimatedWordsServed, skus);

  useEffect(() => {
    setAveragePageWords(average_words_per_page);
  }, [average_words_per_page]);

  useEffect(() => {
    setPageViewsMonthly(monthlyPageViews);
  }, [monthlyPageViews]);

  useEffect(() => {
    // SubscribeButton in plan details and the main header needs access to the threshold value
    setThreshold(threshold_value);
  }, [setThreshold, threshold_value]);

  const isEnterprisePlan = sku_type === 'ENTERPRISE';
  const subscribeRoute = `/${projectId}/account/billing-information/manage-subscription?threshold=${threshold_value}`;
  const contactUsUrl = `${glgoMarketingUrl}/about-us#contact-us-form`;

  const analyzedWords = num_words.replace('+', '').toLocaleString();
  const analyzedPages = num_pages.replace('+', '').toLocaleString();

  return (
    <Flex wrap={{ base: 'wrap', md: 'nowrap' }}>
      <BackgroundImage
        w="100%"
        maw={{ base: '100%', md: '50%' }}
        px="1rem"
        src={`"${BackgroundLogo}"`}
      >
        <Stack align="center" py="5rem" ta="center">
          <Text fz="lg" fw={700}>
            You're on a free trial.
          </Text>
          <Text fz="md">
            Based on our scan of your website, here’s what we’d recommend.
          </Text>
          <Text fz="lg" fw={700}>
            {name}
          </Text>
          <Link
            to={isEnterprisePlan ? contactUsUrl : subscribeRoute}
            target={isEnterprisePlan ? '_blank' : '_self'}
          >
            <Button w="100%" miw="16rem" variant="light">
              {isEnterprisePlan ? 'Contact Us' : 'Subscribe'}
            </Button>
          </Link>
          <Link
            to={`${glgoMarketingUrl}/estimate?domain=${origin_name}&email=${email}`}
            target="_blank"
          >
            <Button variant="transparent" rightSection={<FaArrowRight />}>
              See Full Analysis
            </Button>
          </Link>
        </Stack>
      </BackgroundImage>
      {!isMobileSize && (
        <Divider
          orientation="vertical"
          mx="0.5rem"
          my="2rem"
          color="divider.1"
        />
      )}
      <Box w="100%" maw={{ base: '100%', md: '50%' }}>
        <EstimateCalculation
          monthlyPageViews={pageViewsMonthly}
          estimatedWordsServed={estimatedWordsServed}
          analyzedWords={analyzedWords}
          analyzedPages={analyzedPages}
          averageWordsPerPage={averagePageWords}
          translatedTraffic={translatedTraffic}
          setTranslatedTraffic={setTranslatedTraffic}
          setAveragePageWords={setAveragePageWords}
          setPageViewsMonthly={setPageViewsMonthly}
        />
      </Box>
    </Flex>
  );
};

export default WordsServedEstimate;
