import { Box } from '@mantine/core';
import { themeColors } from '../../theme/colors';
import { TagIcon, QTag } from '../../icons/TagIcons';
import { TagModes } from '../../types/editor';

type TagProps = {
  kind?: 'sc' | 'ec';
  type?: string;
  mode: TagModes;
};

export const tagColors: {
  [mode in TagModes]: { fill: string; color: string };
} = {
  show: { fill: themeColors.blue[2], color: themeColors.blue[8] },
  focus: { fill: themeColors.blue[8], color: 'white' },
  hide: { fill: 'inherit', color: 'inherit' },
} as const;

export const Tag = ({
  kind = 'sc',
  type = 'span',
  mode = 'hide',
}: TagProps) => {
  // Don't show tags if not in active or focus mode
  // Except for <q> tag

  if (mode === 'hide' && !(type === 'q')) {
    return null;
  }

  const { color = 'inherit', fill = 'inherit' } = tagColors[mode];
  // const fill =
  //   colors.fill[mode as keyof (typeof colors)['fill']] || 'transparent';

  const icon =
    type === 'q' ? <QTag kind={'none'} /> : <TagIcon kind={kind} tag={type} />;

  return (
    <Box
      component="span"
      c={color}
      display={'inline-flex'}
      style={{
        color,
        fill,
        transform: `translateY(3px)`,
      }}
    >
      {icon}
    </Box>
  );
};
