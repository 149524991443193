import { decodeProjectId } from 'helpers';
import { Params } from 'react-router-dom';
import { Xapis, xapisEnv } from '@glweb/xapis-client';

export type CancellationConfirmationLoaderData = {
  status: number;
  project?: ProjectKey | undefined;
  message?: string | undefined;
  next_renewal_date: Subscription['next_renewal_date'];
  is_canceled: Subscription['is_canceled'];
};

async function cancellationConfirmationLoader({
  params,
}: {
  params: Params<string>;
  request: Request;
}) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const projectKey = decodeProjectId(projectId);
  const response = await Xapis.Project.get(projectKey);

  const data = {
    next_renewal_date: '',
    is_canceled: false,
    ...response,
  };

  const { payKey } = xapisEnv.getHost;
  try {
    const subscriptionsRes = await Xapis.Subscription.get(payKey, projectKey);
    const { subscriptions: subs = [] } = subscriptionsRes.data;
    const activeSubscription = subs[0];

    if (activeSubscription)
      data.next_renewal_date = activeSubscription.next_renewal_date;
    data.is_canceled = activeSubscription.is_canceled;
  } catch (err) {
    console.error(
      'error in Xapis.Subscription.get in downgradeConfirmationLoader:',
      err
    );
  }

  return data;
}

export default cancellationConfirmationLoader;
