import { Xapis } from '@glweb/xapis-client/src/xapis-wrappers/xapis';
import { queryOptions, useSuspenseQueries } from '@tanstack/react-query';
import { queryWordsServed } from './shared/queryWordsServed';

export const queryWordsServedUrls = (
  concatenatedKey: string,
  timeSliceFrom: string
) =>
  queryOptions({
    queryKey: ['wordsServedUrls', concatenatedKey, timeSliceFrom],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const params = {
        translation_key: concatenatedKey,
        timeslice_from: timeSliceFrom || '1 month',
        type: 'url',
        series: 'tot_words_served',
        rollup: timeSliceFrom === '1 year' ? 'month' : 'day',
      };

      const response = await Xapis.Metrics.get(params);

      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch words served data');
      }
      return response.data.data;
    },
  });

export const useUsageQueries = ({
  concatenatedKey,
  timeSliceFrom,
}: {
  projectKey: string;
  payKey: string;
  concatenatedKey: string;
  timeSliceFrom: string;
}) => {
  const rollup = timeSliceFrom.includes('year') ? 'month' : 'day';
  const queries = [
    queryWordsServed(concatenatedKey, timeSliceFrom, rollup),
    queryWordsServedUrls(concatenatedKey, timeSliceFrom),
  ];

  const results = useSuspenseQueries({ queries });

  return {
    wordsServed: results[0].data as WordsServedResponse[],
    wordsServedUrls: results[1].data as WordsServedUrlsResponse[],
  };
};
