export type StatusLabel =
  | 'Live'
  | 'In Progress'
  | 'In Client Review'
  | 'No Translate'
  | 'Untranslated'
  | 'Other';

const statusCodes: { [key: string]: StatusLabel } = {
  done_icr: 'In Client Review',
  edited_icr: 'In Client Review',
  in_client_review: 'In Client Review',
  in_progress: 'In Progress',
  pending: 'In Progress',
  in_pd: 'In Progress',
  in_plt: 'In Progress',
  done_plt: 'In Progress',
  edited: 'In Progress',
  edited_ope: 'In Progress',
  final_no_plt: 'Live',
  final_no_icr: 'Live',
  gold: 'Live',
  final_revision: 'Live',
  live: 'Live',
  needs_review: 'Live',
  no_translate: 'No Translate',
  untranslated: 'Untranslated',
  other: 'Other',
};

export function getSegmentStatus(status: string) {
  const code = status.replaceAll(' ', '_');
  return statusCodes[code as keyof typeof statusCodes] || 'Other';
}

export const statusColors: {
  [status in StatusLabel]: { color: string; border: string };
} = {
  Live: { color: '#14CD5E', border: '#008535' },
  'In Progress': { color: '#8AC7FF', border: '#0071DB' },
  'No Translate': { color: '#FF7373', border: '#9B0000' },
  'In Client Review': { color: '#FFD600', border: '#D05800' },
  Untranslated: { color: '#EAF0FF', border: '#637392' },
  Other: { color: '#D398E7', border: '#5B096F' },
};
