import React from 'react';
import { Menu, NavLink } from '@mantine/core';
import { Link, useLocation, useParams } from 'react-router-dom';
import { MdArrowDropDown } from 'react-icons/md';
import { type NavLink as NavLinkType } from '../../navLinks';
import { LinkWrapper } from 'ui';
import { useViewPermissions, UserRole } from 'helpers';

type Props = {
  classes: CSSModuleClasses;
  link: NavLinkType;
  toggleMobile: () => void;
  isMobileSize: boolean;
  permissions: Set<UserRole | UserType>;
};

export const NavItem = ({
  classes,
  link,
  toggleMobile,
  isMobileSize,
  permissions,
}: Props) => {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const { hasViewPermissions } = useViewPermissions();

  const { label, icon, path: parentPath, pages, action: parentAction } = link;
  const isNotifications = label === 'Notifications';

  const isRootPath = parentPath?.charAt(0) === '/';
  const allowedPages = pages?.filter(
    ({ userRoles }) => !userRoles || hasViewPermissions(permissions, userRoles)
  );

  return (
    <NavLink
      defaultOpened={!isMobileSize}
      label={label}
      leftSection={icon}
      component={parentAction ? undefined : Link}
      to={isRootPath ? parentPath : `/${projectId}/${parentPath}`}
      rightSection={pages && <MdArrowDropDown size={24} />}
      className={classes.navLinkParent}
      active={Boolean(parentPath && pathname.includes(parentPath))}
      onClick={
        pages
          ? undefined
          : () => {
              !isNotifications && toggleMobile();
              parentAction && parentAction();
            }
      }
      childrenOffset={35}
    >
      {allowedPages?.map(({ label, path: childPath, link, target, action }) => (
        <NavLink
          key={label}
          label={label}
          component={action ? undefined : Link}
          to={link ?? `/${projectId}/${parentPath}/${childPath}`}
          target={target}
          className={classes.navLinkChild}
          active={Boolean(childPath && pathname.includes(childPath))}
          onClick={() => {
            toggleMobile();
            action && action();
          }}
        />
      ))}
    </NavLink>
  );
};

export const TabletNavItem = ({
  classes,
  link,
  permissions,
}: Pick<Props, 'link' | 'classes' | 'permissions'>) => {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const { hasViewPermissions } = useViewPermissions();

  const { icon, path: parentPath, pages } = link;

  const isRootPath = parentPath?.charAt(0) === '/';
  const allowedPages = pages?.filter(
    ({ userRoles }) => !userRoles || hasViewPermissions(permissions, userRoles)
  );

  return (
    <Menu
      offset={{ mainAxis: 15 }}
      disabled={!pages}
      trigger="click"
      position="right-start"
    >
      <Menu.Target>
        <NavLink
          label={icon}
          component={Link}
          to={isRootPath ? parentPath : `/${projectId}/${parentPath}`}
          onClick={(e) => pages && e.preventDefault()}
          className={classes.navLinkParent}
          active={Boolean(parentPath && pathname.includes(parentPath))}
          styles={{
            body: {
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            label: {
              height: 20,
            },
          }}
        />
      </Menu.Target>
      <Menu.Dropdown>
        {allowedPages
          ?.filter(
            ({ userRoles }) =>
              !userRoles || hasViewPermissions(permissions, userRoles)
          )
          .map(({ label, path: childPath }) => {
            const isActive = Boolean(childPath && pathname.includes(childPath));
            return (
              <LinkWrapper
                key={label}
                to={`${projectId}/${parentPath}/${childPath}`}
              >
                <Menu.Item aria-current={isActive} data-active={isActive}>
                  {label}
                </Menu.Item>
              </LinkWrapper>
            );
          })}
      </Menu.Dropdown>
    </Menu>
  );
};
