import { xapisEnv } from '@glweb/xapis-client';
import { useAuth } from 'auth';
import { hexToObject } from 'helpers';
import React, { Suspense } from 'react';
import { useLoaderData } from 'react-router-dom';
import {
  SegmentEditor,
  SegmentEditorConfig,
  SegmentEditorProvider,
  SegmentEditorRef,
} from 'segment-editor';
import { useUserProvider } from 'ui';
import { type GlossaryLoaderResponse } from '../../loaders/glossaryLoader';

type EditorProps = {
  target: TranslationKey;
  segmentEditorRef: React.RefObject<SegmentEditorRef> | null;
};

export function TranslationEditor({ segmentEditorRef, target }: EditorProps) {
  const html_dir = hexToObject(target.target_config).html_dir;

  const token = useAuth().user?.access_token || 'NoToken';
  const user = useUserProvider().xapisUser;

  const { glossary_items = [] } = useLoaderData() as GlossaryLoaderResponse;

  if (!target) return <div>Target/Project not defined</div>;

  const targetGlossaryItems = glossary_items?.length
    ? // Get glossary items for the current target and set empty legacy rules to 'translate_as'
      glossary_items
        .filter((g) => g.translation_key === target.translation_key)
        .map((g) => ({
          ...g,
          rule: g.rule || 'translate_as',
        }))
    : [];

  const { xapisUrl } = xapisEnv.getHost;

  const segmentEditorConfig: SegmentEditorConfig = {
    target,
    user,
    glossary: targetGlossaryItems,
    settings: {
      xapisHost: xapisUrl,
      contextMode: false,
      accessToken: token,
      disableTagEdit: false,
      viewOnly: false,
      htmlDir: html_dir,
    },
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SegmentEditorProvider config={segmentEditorConfig}>
        <SegmentEditor
          key={`${target.translation_key}`}
          ref={segmentEditorRef}
          contextData={null}
        />
      </SegmentEditorProvider>
    </Suspense>
  );
}
