import { Box, Tooltip } from '@mantine/core';
import { themeColors } from '../../theme/colors';

const tooltipColors = {
  light: {
    background: themeColors.blue[1],
    text: themeColors.blue[8],
    border: themeColors.blue[5],
  },
  dark: {
    text: 'white',
    background: themeColors.blue[8],
    border: themeColors.blue[8],
  },
};

const TooltipLabel = ({
  label,
  backgroundColor,
  maxWidth,
}: {
  label: React.ReactNode;
  backgroundColor: string;
  maxWidth?: number;
}) => {
  return (
    <Box
      maw={maxWidth || 'auto'}
      bg={backgroundColor}
      p="5px 10px"
      style={{
        borderRadius: 5,
      }}
    >
      {label}
    </Box>
  );
};

interface TipProps {
  children: React.ReactNode;
  label: React.ReactNode;
  disabled?: boolean;
  variant?: 'light' | 'dark';
  maxWidth?: number;
  openDelay?: number;
}
export const Tip = ({
  children,
  label,
  disabled,
  variant = 'dark',
  maxWidth,
  openDelay,
}: TipProps) => {
  if (!['light', 'dark'].includes(variant)) variant = 'dark';
  const colors = tooltipColors[variant];

  return (
    <Tooltip
      p={0}
      label={<TooltipLabel label={label} backgroundColor={colors.background} />}
      disabled={!label || disabled}
      c={colors.text}
      multiline
      openDelay={openDelay || 0}
      styles={{
        tooltip: {
          borderRadius: 7,
          maxWidth: maxWidth || 'auto',
        },
        arrow: {
          backgroundColor: colors.border,
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
