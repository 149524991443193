import { mergeParams } from './utils';
import { TextInput } from '@mantine/core';
import { MdSearch } from 'react-icons/md';
import { Form, useSearchParams, useSubmit } from 'react-router-dom';
import React from 'react';

const QuerySearchBar = () => {
  const submit = useSubmit();
  const [searchParams] = useSearchParams();

  return (
    <Form
      onChange={(event) => {
        submit(mergeParams(event.currentTarget, searchParams));
      }}
      onSubmit={(e) => e.preventDefault()}
    >
      <TextInput
        name={'q'}
        placeholder="Search..."
        rightSection={<MdSearch size={20} />}
        defaultValue={searchParams.get('q') ?? ''}
      />
    </Form>
  );
};
export default QuerySearchBar;
