import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import { Editor } from '@monaco-editor/react';
import { Flex, Loader, Button, Tooltip, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Xapis } from '@glweb/xapis-client';
import { AlertIcon } from 'images';
import {
  hexToAscii,
  isStudioEditorWindow,
  isSuccessStatus,
  isYYTranslation,
} from 'helpers';
import { saveCustomCss } from './customCssActions';
import { CSSHistoryModal } from './CSSHistoryModal';
import classes from './LanguageCSSEditor.module.css';
import LangSelectorPreview from './LangSelectorPreview';

type Props = {
  target?: TranslationKey;
  cssEditorCss?: string;
  setCssEditorCss?: Dispatch<SetStateAction<string>>;
  showPreviewButton?: boolean;
};

const isStudioEditor = isStudioEditorWindow();
const { updateCustomCss, gotElementSelector } = window.editorAPI || {};

const previewCss = (css: string) => {
  if (!updateCustomCss) return;
  updateCustomCss(css);
};
// NOTE: This component is used in several places in the codebase.
// It receives a target prop and let the user edit the custom_css
// for that target (which can be the YY target).
// It should NOT relay on the URL to fetch data.
const LanguageCSSEditor = ({
  target,
  cssEditorCss,
  setCssEditorCss,
  showPreviewButton = true,
}: Props) => {
  const revalidator = useRevalidator();
  const isYYtarget = isYYTranslation(target);
  const { custom_css = '' } = target || {};
  const currentCssValue = hexToAscii(custom_css);
  const [hasWarnings, setHasWarnings] = useState<boolean>(false);
  const [cssValue, setCssValue] = useState(currentCssValue || '');
  const [revisions, setRevisions] = useState<RevisionItem[]>([]);
  const [newSelector, setNewSelector] = useState<string>('');
  const [loading, setLoading] = useState(false);

  //if external state is supplied correctly use that instead of local state
  const cssToUse = cssEditorCss && setCssEditorCss ? cssEditorCss : cssValue;
  const setCssToUse =
    cssEditorCss && setCssEditorCss ? setCssEditorCss : setCssValue;

  const handleEditorChange = (value: string | undefined) => {
    if (!(typeof value === 'string')) return;
    setCssToUse(value);
    if (value === currentCssValue) previewCss(value);
  };
  const isUnsaved = cssToUse !== currentCssValue;

  const handleSave = () => {
    if (!target) {
      notifications.show({
        message: 'Oops, there was a problem with saving your edits.',
      });
      return;
    }
    setLoading(true);
    saveCustomCss({ target, cssValue: cssToUse })
      .then(({ status }) => {
        if (isSuccessStatus(status))
          notifications.show({ message: 'Successfully saved your edits!' });
        else {
          notifications.show({
            message: 'Oops, there was a problem with saving your edits.',
          });
        }
      })
      .catch(() => {
        notifications.show({
          message: 'Oops, there was a problem with saving your edits.',
        });
      })
      .finally(() => {
        revalidator.revalidate();
        fetchRevisions(target.translation_key);
        previewCss(cssToUse);
        setLoading(false);
      });
  };

  const addNewCssSelector = () => {
    setCssToUse((prev) => `${prev}\n${newSelector} {\n\n}`);
    setNewSelector('');
  };

  const fetchRevisions = (tKey: string) => {
    Xapis.ConfigHistory.get(tKey, 'custom_css').then(
      ({ status, revisions }) => {
        if (!isSuccessStatus(status)) {
          notifications.show({
            message: 'There was a problem with fetching the revisions.',
          });
        }
        setRevisions(revisions);
      }
    );
  };

  useEffect(() => {
    // This is needed for cases where the custom_css is updated
    // outside of this component (e.g. from the SelectorDefaults component)
    setCssToUse(currentCssValue);
  }, [custom_css]);

  useEffect(() => {
    if (!target) return;
    fetchRevisions(target.translation_key);
    gotElementSelector &&
      gotElementSelector((selector) => setNewSelector(selector));
  }, [target?.translation_key]);

  return (
    <Flex direction="column" gap={20}>
      {newSelector && (
        <Flex align={'center'} gap={8}>
          <Button variant="transparent" onClick={addNewCssSelector}>
            Add new CSS
          </Button>
          <Text className={classes.newSelector}>{newSelector}</Text>
          <Button variant="transparent" onClick={() => setNewSelector('')}>
            X
          </Button>
        </Flex>
      )}
      <Editor
        height="50vh"
        language="css"
        defaultValue={currentCssValue}
        loading={<Loader />}
        onChange={handleEditorChange}
        onValidate={(markers) => setHasWarnings(markers?.length > 0)}
        value={cssToUse}
      />
      <Flex w="100%" justify="space-between" gap={20}>
        <Flex gap={5}>
          <CSSHistoryModal
            revisions={revisions}
            unsavedCss={isUnsaved ? cssToUse : null}
          />
          {isYYtarget && showPreviewButton && (
            <LangSelectorPreview css={cssValue} key="custom" />
          )}
        </Flex>
        <Flex gap={15}>
          <Button
            variant="outline"
            disabled={cssToUse === currentCssValue}
            onClick={() => {
              setCssToUse(currentCssValue);
              previewCss(currentCssValue);
            }}
          >
            Cancel
          </Button>
          {isStudioEditor && (
            <Button
              disabled={cssToUse === currentCssValue}
              onClick={() => {
                previewCss(cssToUse);
              }}
            >
              Preview
            </Button>
          )}
          <Tooltip label="Check warnings before saving" disabled={!hasWarnings}>
            <Button
              disabled={!isUnsaved || loading}
              onClick={handleSave}
              leftSection={hasWarnings ? <AlertIcon size={18} /> : null}
            >
              {loading ? <Loader color="cta1.6" size="sm" /> : 'Save'}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LanguageCSSEditor;
