import {
  Button,
  Divider,
  Group,
  Menu,
  ScrollArea,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useAuth } from 'auth';
import {
  getNotificationTimeSlice,
  getPrettyTimeString,
  getRemainingSubscriptionDays,
} from 'helpers';
import React, { useState } from 'react';
import { IoMdNotifications } from 'react-icons/io';
import { MdArrowDropDown } from 'react-icons/md';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { defaultSubscription } from '@glweb/xapis-client';
import { useCurrentProjectData } from 'ui';
import { ProjectQueryLoaderData } from '../../loaders/projectQueryLoader';

const MobileNotificationsMenu = ({
  setShowMobileNotifications,
  children,
}: {
  setShowMobileNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}) => (
  <Stack flex={1} gap={0} style={{ overflow: 'hidden' }}>
    <Button
      h="3rem"
      variant="transparent"
      c="font1.0"
      justify="flex-start"
      leftSection={<MdArrowDropDown size={24} className="rotateLeft" />}
      onClick={() => setShowMobileNotifications(false)}
    >
      Navigation
    </Button>
    <Divider />
    <ScrollArea scrollbars="y">{children}</ScrollArea>
  </Stack>
);

export default function NotificationsMenu({
  showMobileNotifications,
  setShowMobileNotifications,
}: {
  showMobileNotifications?: boolean;
  setShowMobileNotifications?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const auth = useAuth();
  const { subscriptions } = useCurrentProjectData();
  const [searchParams, setSearchParams] = useSearchParams();
  const { releaseNotes = [] } = useRouteLoaderData(
    'project'
  ) as ProjectQueryLoaderData;

  const [firstSubscription = defaultSubscription] = subscriptions;
  const { next_renewal_date: nextRenewalDate, price } = firstSubscription;

  const { VITE_BUILD_TIMESTAMP = 'NOT_FOUND' } = import.meta.env || {};

  const addReleaseParam = () => {
    searchParams.set('viewNotes', 'latest');
    setSearchParams(searchParams);
  };

  const remainingDaysInSubscription =
    getRemainingSubscriptionDays(nextRenewalDate);

  const defaultNotifications = [];

  const timestampNow = new Date().getTime();
  const timestampLastBuild = new Date(VITE_BUILD_TIMESTAMP).getTime();
  const weekInMilliseconds = 1000 * 60 * 60 * 24 * 7;
  const timestampsDiff = timestampNow - timestampLastBuild;
  if (timestampsDiff < weekInMilliseconds && releaseNotes.length > 0) {
    defaultNotifications.push({
      isNew: true,
      description: (
        <span>
          A new release was just published!{' '}
          <UnstyledButton
            td="underline"
            c="text.8"
            fz="inherit"
            onClick={() => addReleaseParam()}
          >
            Check out the release notes.
          </UnstyledButton>
        </span>
      ),
      time: getPrettyTimeString(timestampsDiff),
    });
  }

  if (defaultNotifications.length === 0) {
    const encroachingDays = 7;
    if (price === 0 && remainingDaysInSubscription <= encroachingDays) {
      defaultNotifications.push({
        isNew: true,
        description: `Your Free Trial will run out in ${remainingDaysInSubscription} days.`,
        time: getNotificationTimeSlice(auth),
      });
    } else {
      defaultNotifications.push({
        isNew: false,
        description: 'You have no new notifications. Check back soon!',
        time: 'now',
      });
    }
  }

  const [notifications] = useState(defaultNotifications);

  const Notifications = () =>
    notifications.map(({ isNew, description, time }, index) => {
      const isBold = index === 0;
      return (
        <Group
          key={index}
          w="100%"
          h={60}
          py="0.25rem"
          px="1rem"
          align="center"
          bg={isBold ? '#ECF5FF' : undefined}
          wrap="nowrap"
        >
          <Group w="10%" align="center">
            <Text fz="xs" fw={700}>
              {isNew && 'NEW'}
            </Text>
          </Group>
          <Group w="75%" align="center">
            <Text fz="xs" fw={isBold ? 600 : 400} c="font1.1">
              {description}
            </Text>
          </Group>
          <Group h="100%" w="15%" align="flex-start" justify="flex-end">
            <Text fz="0.625rem" fw={isBold ? 600 : 400} c="font1.1">
              {time}
            </Text>
          </Group>
        </Group>
      );
    });

  if (showMobileNotifications && setShowMobileNotifications) {
    return (
      <MobileNotificationsMenu
        setShowMobileNotifications={setShowMobileNotifications}
      >
        <Notifications />
      </MobileNotificationsMenu>
    );
  }

  return (
    <Menu
      width={405}
      position="bottom-end"
      offset={{ mainAxis: 13 }}
      closeOnClickOutside
      closeOnEscape
      styles={{
        dropdown: {
          padding: 0,
        },
      }}
    >
      <Menu.Target>
        <UnstyledButton h={20}>
          <IoMdNotifications size={20} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Group py="0.5rem" px="1rem" justify="space-between">
          <Text fz="xs" fw={700}>
            Notifications
          </Text>
          {/* <UnstyledButton onClick={() => setNotification([])}>
            <Text fz="xs" fw={700}>
              Mark All as Read
            </Text>
          </UnstyledButton> */}
        </Group>
        <ScrollArea w="100%" h={180}>
          <Notifications />
        </ScrollArea>
      </Menu.Dropdown>
    </Menu>
  );
}
