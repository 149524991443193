/// <reference types="vite/client" />
import {
  HOST_OVERRIDE,
  hostConstants,
  HostEntry,
  HostLabel,
  hostLabels,
  LIVE,
  LOCAL,
  RC,
  STAGING,
} from '@glweb/constants';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timeout: any = null;

  return (...args: Parameters<T>) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export const debouncedInvalidateQueries = debounce(
  (response: AxiosResponse) => {
    // eslint-disable-next-line no-console
    console.log(
      `Invalidating all queries because of ${response.config.method} on ${response.config.url}`
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (typeof window !== 'undefined' && (window as any).queryClient) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).queryClient.invalidateQueries();
    }
  },
  1000
);

const { VITE_IS_GLWEB_DESKTOP } = import.meta.env;
const isDesktop = VITE_IS_GLWEB_DESKTOP === true;
let hostName: HostLabel | undefined = undefined;

export let xapisService: AxiosInstance;

export const xapisEnv = {
  token: 'TokenIsUndefined',
  host: getXapisEnvironment(),
  defaultHost: getDefaultEnvironment(),
  get getToken() {
    return this.token;
  },
  setToken(token: string) {
    this.token = token;
  },
  get getHost() {
    return this.host;
  },
  get getLabel() {
    return this.host?.label || '';
  },
  get getDefaultLabel() {
    return this.defaultHost?.label || '';
  },
};

export const createXapisService = () => {
  const { xapisUrl, label } = xapisEnv.getHost || {};
  const token = xapisEnv.getToken;
  if (label === hostName) return;
  hostName = label;

  try {
    xapisService = axios.create({
      baseURL: `${xapisUrl}`,
    });
    xapisService.defaults.headers.common.Authorization = `Bearer ${token || 'FakeTokenFromGLGO'}`;

    xapisService.interceptors.request.use(
      (config) => {
        const token = xapisEnv.getToken;
        if (config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    xapisService.interceptors.response.use((response: AxiosResponse) => {
      if (
        response.status >= 200 &&
        response.status < 300 &&
        response.config.method &&
        ['PUT', 'POST', 'DELETE', 'PATCH'].includes(
          response.config.method.toUpperCase()
        )
      ) {
        debouncedInvalidateQueries(response);
      }
      return response;
    });
  } catch (e) {
    console.error('Error creating Axios instance:', e);
  }
};

export function getXapisEnvironment(): HostEntry {
  const defaultHost = getDefaultEnvironment();
  if (isDesktop || typeof localStorage === 'undefined') {
    return defaultHost;
  }

  const overrideHostLabel = localStorage.getItem('HOST_OVERRIDE');

  if (!overrideHostLabel) return defaultHost;
  if (
    // If the override host is the default host OR it's invalid, remove the override
    overrideHostLabel === defaultHost.label ||
    !hostLabels.includes(overrideHostLabel as HostLabel)
  ) {
    localStorage.removeItem(HOST_OVERRIDE);
    return defaultHost;
  }
  return hostConstants[overrideHostLabel as HostLabel];
}

export function getDefaultEnvironment(): HostEntry {
  if (
    isDesktop ||
    typeof window === 'undefined' ||
    typeof localStorage === 'undefined'
  ) {
    return getDTDefaultEnvironment();
  } else {
    const webhost = window.location.origin;
    if (!webhost) throw new Error('Unknown XAPIS environment');
    if (webhost.includes('localhost')) {
      return hostConstants[LOCAL];
    } else if (webhost.includes('staging')) {
      return hostConstants[STAGING];
    } else if (webhost.includes('rc')) {
      return hostConstants[RC];
    } else if (
      webhost.includes('globallinkgo') ||
      webhost.includes('globallinkweb') ||
      webhost.includes('xpay.onelinkjs.com')
    ) {
      return hostConstants[LIVE];
    } else {
      throw new Error('Unknown XAPIS environment');
    }
  }
}

export function getDTDefaultEnvironment(): HostEntry {
  const { MODE } = import.meta.env;
  switch (MODE) {
    case 'staging':
      return hostConstants[STAGING];
    case 'rc':
      return hostConstants[RC];
    case 'production':
      return hostConstants[LIVE];
    default:
      return hostConstants[LOCAL];
  }
}

export const isStagingEnv =
  xapisEnv.getHost.label === 'Staging' || xapisEnv.getHost.label === 'Local';

export const isRcEnv = xapisEnv.getHost.label === 'RC';
