import { xapisEnv } from '@glweb/xapis-client/src/service-wrappers/xapisService';
import { Xapis } from '@glweb/xapis-client/src/xapis-wrappers/xapis';
import isSuccessStatus from 'helpers/src/isSuccessStatus';

export const handleQuickQuote = async (domain: string, email: string) => {
  const payKey = xapisEnv.getHost.payKey;
  const encodedDomain = encodeURIComponent(domain);
  const params = {
    email,
    start_url: encodedDomain,
    pay_key: payKey,
    max_spider_minutes: '10',
  };

  try {
    const { data: { qmessage = '' } = {}, status = 0 } =
      await Xapis.QuickQuote.get(domain);
    if (isSuccessStatus(status) && !qmessage.includes('result was cached')) {
      return await Xapis.QuickQuote.post(params);
    }
    return Promise.reject({});
  } catch (error) {
    return Promise.reject({});
  }
};
