import { Divider } from '@mantine/core';
import classes from './BasicHorizontalDivider.module.css';

export const BasicHorizontalDivider = ({
  mobileMargin,
  borderWidth,
}: {
  mobileMargin?: string | number;
  borderWidth?: number;
}) => (
  <Divider
    orientation="horizontal"
    className={classes.divider}
    m={{ base: mobileMargin ?? '0 20px', sm: 'auto' }}
    style={{ borderWidth: borderWidth ?? 1 }}
  />
);
