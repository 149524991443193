import { useState } from 'react';
import { Box, Flex, Popover, Text, UnstyledButton } from '@mantine/core';
import { useSegmentStatus } from '../../store/SegmentsState';
import { Tip } from '../Generic/Tip';
import { getSegmentStatus, StatusLabel } from './statusCodes';
import { useSegmentEditorContext } from '../../context/SegmentEditorProvider';
import { statusColors } from './statusCodes';

import classes from './Status.module.css';

type StatusProps = {
  segment: Segment;
  onSetToLive?: (setToLive: boolean) => void;
};

export const Status = ({ segment, onSetToLive }: StatusProps) => {
  const { settings } = useSegmentEditorContext();
  const sHash = segment.segment_hash;
  const { isSelected, isSetToLive } = useSegmentStatus(sHash);

  const currentLabel = getSegmentStatus(segment.status_code || '');
  const label = isSetToLive ? 'Live' : currentLabel;
  const setToLabel = isSetToLive ? currentLabel : 'Live';
  // Disable if segment is Live or view only
  const disableSetToLive = currentLabel === 'Live' || !!settings.viewOnly;
  const [showSetToLive, setShowSetToLive] = useState(false);

  function handleSetToLive() {
    setShowSetToLive(false);
    if (onSetToLive) {
      setTimeout(() => {
        // Delay to allow the popover to close
        onSetToLive(!isSetToLive);
      }, 100);
    }
  }

  const StatusDot = ({ code }: { code: StatusLabel }) => (
    <Box
      w={8}
      h={8}
      bg={statusColors[code]?.color}
      style={{
        borderRadius: '50%',
        border: `1px solid ${statusColors[code]?.border}`,
      }}
    ></Box>
  );

  return (
    <Popover
      disabled={disableSetToLive}
      withinPortal
      opened={showSetToLive}
      onChange={setShowSetToLive}
      offset={-8}
      shadow="md"
      closeOnEscape
    >
      <Popover.Target>
        <Box>
          <Tip label={label} disabled={showSetToLive}>
            <Flex
              className={`${classes.status}`}
              onClick={() => setShowSetToLive(!disableSetToLive)}
              mt={!isSelected ? 5 : -30}
              bg={isSetToLive ? 'gray.5' : 'white'}
            >
              <StatusDot code={label} />
            </Flex>
          </Tip>
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <Box className={classes.setToLive}>
          <Flex align={'center'} gap={8} px={6}>
            <StatusDot code={setToLabel} />
            <UnstyledButton onClick={handleSetToLive}>
              {isSetToLive ? `Set back to "${currentLabel}"` : 'Set to "Live"'}
            </UnstyledButton>
          </Flex>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
