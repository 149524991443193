import getFullPath from './getFullPath';

type NodeProps = Pick<
  SpiderTreeNode,
  'label' | 'is_fake' | 'content_url_orig' | 'id' | 'name'
>;

export const getRuledStatus = (nodeProps: NodeProps, rulesList: string[]) => {
  const { label, is_fake, content_url_orig, id, name } = nodeProps;
  if (
    [label, is_fake, content_url_orig, id, name].some(
      (property) => property === undefined
    )
  )
    return;

  let isRuled = false;
  let relevantRule = '';
  if (rulesList.length > 0) {
    for (let i = 0; i < rulesList.length; i++) {
      let nodeIdentifier = name || label;
      if (is_fake) {
        nodeIdentifier = getFullPath(id, label);
      }

      const nodeString = is_fake ? nodeIdentifier : content_url_orig;
      if (new RegExp(rulesList[i]).test(nodeString)) {
        isRuled = true;
        relevantRule = rulesList[i];
        break;
      }
    }
  }
  return { isRuled, relevantRule };
};

export default getRuledStatus;
