import { useSuspenseQuery } from '@tanstack/react-query';
import { projectQuery } from '@glweb/xapis-client';
import useGLGOParams from './glgoParams';

/**
 * A custom hook that fetches and returns the current project data based on the project key from the route.
 *
 * @throws {Error} If the projectKey is not present in the route params.
 * @returns {Awaited<ReturnType<typeof getProjectData>>} The current project data.
 */
function useCurrentProjectData() {
  const { projectKey } = useGLGOParams();

  if (!projectKey) {
    throw new Error('projectKey is missing from route params');
  }

  const { data: projectData } = useSuspenseQuery(projectQuery(projectKey));
  return projectData;
}

export default useCurrentProjectData;
