import { compactFormatter } from 'helpers';
import { Card, Flex, Stack, Text } from '@mantine/core';
import { BsFillCircleFill } from 'react-icons/bs';
import React from 'react';
import { formatTooltipDateLabel } from './utils';

type Props = {
  active?: boolean;
  payload?: Array<Record<string, string>>;
  label?: string;
};

export const CustomGraphTooltip = ({ active, payload, label }: Props) => {
  const values =
    payload &&
    payload
      .sort((a, b) => Number(b.value) - Number(a.value))
      .map((entry) => (
        <Flex key={entry.name} align="center" gap={5}>
          <BsFillCircleFill color={entry.stroke} fontSize={10} />
          <Text fw={700}>
            {entry.name} {compactFormatter(Number(entry.value)) || 0}
          </Text>
        </Flex>
      ));

  if (values && active && payload && payload.length) {
    return (
      <Card
        p={10}
        bg="background.0"
        bd="1px solid border1.0"
        style={{
          borderRadius: 6,
          boxShadow: 'none',
        }}
      >
        <Text fw={600} mb={10}>
          {formatTooltipDateLabel(label || '')}
        </Text>
        <Stack gap={5} align="center">
          {values}
        </Stack>
      </Card>
    );
  }
};
export default CustomGraphTooltip;
