import { ThemeColors, themeColors } from './colors';

type NumericRange<
  Start extends number,
  End extends number,
  Acc extends number[] = [],
> = Acc['length'] extends End
  ? Acc[number]
  : NumericRange<Start, End, [...Acc, Acc['length']]>;

type Range<Color extends keyof ThemeColors> = NumericRange<
  0,
  ThemeColors[Color]['length']
>;

export const getColor = <T extends keyof ThemeColors>(
  color: T,
  shade: Range<T>
) => {
  if (!themeColors[color]?.[shade]) {
    return `red`; // Fallback color
  }
  return `var(--mantine-color-${color}-${shade})`;
};

export const notificationColors = (
  type: 'success' | 'error' | 'warning' | 'info'
) => {
  switch (type) {
    case 'success':
      return themeColors.secondery[5];
    case 'error':
      return themeColors.secondery[2];
    case 'warning':
      return themeColors.secondery[1];
    default:
      return themeColors.blue[7];
  }
};
