import {
  cleanNoTxRules,
  getNoTxRuleHash,
  getTranslationConfigAndRules,
} from 'helpers';
import { useCurrentProjectData } from 'ui';
import { NoTxEditor } from './NoTxEditor';

export type NoTranslateXRule = {
  selector: UX;
  order: number;
  id: number;
  isOnPage?: boolean;
  isSelected?: boolean;
};

const NoTranslateLanding = () => {
  const { yyTranslation } = useCurrentProjectData();

  const { no_translate } = getTranslationConfigAndRules(yyTranslation);
  // remove duplicate rules and invalid X selectors
  // And only keep rules with X selector
  const noTxXrules = cleanNoTxRules(no_translate).filter(
    (rule) => (rule as UX).X
  ) as UX[];

  const rules: NoTranslateXRule[] = noTxXrules.map((rule, i) => ({
    selector: rule,
    id: getNoTxRuleHash(rule),
    order: i + 1,
    isOnPage: true, // In dashboard, all rules are considered active
  }));

  return <NoTxEditor rules={rules} target={yyTranslation} />;
};

export default NoTranslateLanding;
