import { Card, Grid, MantineStyleProps, Stack } from '@mantine/core';
import { RestrictedComponent } from '../shared/RestrictedComponent';
import { UserRole } from 'helpers';

const Background = ({
  isCard,
  children,
}: Pick<Props, 'isCard' | 'children'>) =>
  isCard ? (
    <Card w="100%" h="100%">
      {children}
    </Card>
  ) : (
    children
  );

const Layout = ({
  layout,
  maxColSpan,
  flex,
  children,
}: Pick<Props, 'maxColSpan' | 'flex' | 'children'> & {
  layout?: 'column' | 'stack';
}) => {
  switch (layout) {
    case 'column':
      return (
        <Grid.Col
          span={{
            base: 12,
            md: maxColSpan === 8 ? 12 : maxColSpan ?? 4,
            lg: maxColSpan,
          }}
        >
          {children}
        </Grid.Col>
      );
    case 'stack':
    default:
      return (
        <Stack w="100%" flex={flex || 1} gap="sm">
          {children}
        </Stack>
      );
  }
};

type Props = {
  children: React.ReactNode;
  header?: React.ReactNode;
  maxColSpan?: 4 | 8 | 12;
  flex?: MantineStyleProps['flex'];
  isCard?: boolean;
  isVisible?: boolean;
  isNested?: boolean;
  allowedRoles?: Set<UserRole>;
  allowedProjectTypes?: Set<string>;
};

/**
 * `DashboardCard` is a flexible component used to render a section or column within the dashboard's Grid layout.
 * It supports various layouts, visibility control, access restrictions based on user roles and project types,
 * and the option to wrap the content in a Mantine `Card` component.
 *
 * @param {Object} props - The props for the `DashboardCard` component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the card.
 * @param {React.ReactNode} [props.header] - Optional header to be displayed at the top of the card.
 * @param {4 | 8 | 12} [props.maxColSpan=4] - The maximum column span in a grid layout (default is 4).
 * @param {MantineStyleProps['flex']} [props.flex=1] - The flex property for the stack layout (default is 1).
 * @param {boolean} [props.isCard=true] - Determines whether to wrap the content inside a Mantine `Card` (default is true).
 * @param {boolean} [props.isVisible=true] - If `false`, the card will not be rendered (default is true).
 * @param {boolean} [props.isNested=false] - If `true`, the component uses the 'stack' layout; otherwise, it uses 'column' layout.
 * @param {Set<UserRole>} [props.allowedRoles] - A set of user roles that are allowed to view the card.
 * @param {Set<string>} [props.allowedProjectTypes] - A set of project types that are allowed to view the card.
 *
 * @returns {React.ReactNode} The rendered `DashboardCard` component or `<></>` if not visible or if access is restricted.
 *
 * @description
 * `DashboardCard` is a container for dashboard UI elements that can optionally display a header, control layout,
 * handle access restrictions, and optionally be wrapped in a `Card` component. The layout can be either a column
 * grid or stack based on the `isNested` prop. The card's visibility and access can be controlled using the `isVisible`,
 * `allowedRoles`, and `allowedProjectTypes` props.
 */
export const DashboardCard = ({
  children,
  header,
  maxColSpan = 4,
  flex = 1,
  isCard = true,
  isVisible = true,
  isNested = false,
  allowedRoles,
  allowedProjectTypes,
}: Props) => {
  if (!isVisible) {
    return <></>;
  }

  return (
    <RestrictedComponent
      allowedRoles={allowedRoles}
      allowedProjectTypes={allowedProjectTypes}
    >
      <Layout
        layout={isNested ? 'stack' : 'column'}
        maxColSpan={maxColSpan}
        flex={isNested ? flex : 1}
      >
        <Background isCard={isCard}>
          <Stack w="100%" h="100%">
            {header}
            <Layout>{children}</Layout>
          </Stack>
        </Background>
      </Layout>
    </RestrictedComponent>
  );
};
