import { useCurrentProjectData } from 'ui';
import React from 'react';
import { filterTargets } from '../../metrics/trafficUtils';
import { Flex } from '@mantine/core';

import QueryMultiSelector from '../../selectors-filters/QueryMultiSelector';
import { FiltersDropdown } from '../FiltersDropdown';
import { MyBatchesButton } from '../MyBatchesButton';
import { useFilteredBatchHistory } from '../../../queries/batchesQueries';
import QuerySearchBar from '../QuerySearchBar';

const BatchHistoryFilters = () => {
  const { project } = useCurrentProjectData();
  const { assigneeOptions } = useFilteredBatchHistory();

  const filteredTargets = filterTargets(project.translations || []);

  return (
    <>
      <Flex justify="space-between" gap={10}>
        <Flex gap={10} align="center" wrap="wrap">
          <QuerySearchBar />
          <QueryMultiSelector
            encodeKeys
            selectAll
            shouldRevalidate
            options={filteredTargets}
          />
          <FiltersDropdown assigneeOptions={assigneeOptions} history />
          <MyBatchesButton />
        </Flex>
      </Flex>
    </>
  );
};
export default BatchHistoryFilters;
