import { dashboardRC } from '@glweb/constants';
import { Box, Button, Flex, Image, Text } from '@mantine/core';
import { fetchXapisUser, useAuth } from 'auth';
import GLWebLogoColor from 'images/logos/glweb-logo-color.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUserProvider } from 'ui';

// Component to handle errors in fetching user data from Xapis
// It shows when a user is authenticated but xapis/User fails to fetch
const Oops = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const { user } = useAuth();
  const { setXapisUser } = useUserProvider();

  const email = user?.profile?.email;

  const tryAgain = async () => {
    if (email) {
      const { xapisUser, message } = await fetchXapisUser(email);
      setXapisUser(xapisUser || { user_key: '' });
      if (xapisUser) {
        // Navigate is needed to rerun loaders
        navigate(`${pathname}${search}`);
      } else {
        console.error('tryAgain::fetchXapisUser', { message });
      }
    }
  };

  return (
    <>
      <Box style={{ height: 40, WebkitAppRegion: 'drag' }}></Box>
      <Flex
        w="100%"
        mih={550}
        direction="column"
        justify="center"
        align="center"
        rowGap="3rem"
        px="1rem"
      >
        <Image
          src={GLWebLogoColor}
          width={280}
          alt="GlobalLinkWeb logo"
          style={{
            objectFit: 'cover',
            width: '17.5rem',
            height: ' auto',
          }}
        />
        <Flex w="100%" align="center" direction="column" rowGap="1.5rem">
          <Text fz="md" ta="center">
            Oops ... something went wrong with getting your projects.
          </Text>
          {email && (
            <Button h={47} w="100%" maw="22rem" onClick={tryAgain}>
              Try again
            </Button>
          )}
          <Text>
            {email
              ? 'If the problem persists, please logout and login again'
              : 'Please logout and login again'}
          </Text>
          <Link to={dashboardRC.PAGE_LOGOUT}>
            <Button h={47} w="100%" maw="22rem" variant="outline">
              Logout
            </Button>
          </Link>
        </Flex>
      </Flex>
    </>
  );
};

export default Oops;
