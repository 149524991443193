import { Xapis } from '@glweb/xapis-client';
import { queryOptions } from '@tanstack/react-query';
import { isSuccessStatus } from 'helpers';
import moment from 'moment/moment';

export type AccountMTUsageResponse = {
  timeslice: string;
  num_words_engine: string;
}[];

export const queryAccountMTUsage = (
  tKeys: string,
  timeSliceFrom: string = '1 month',
  timeSliceTo: string | undefined = undefined
) =>
  queryOptions({
    queryKey: ['MTAccountUsage', { tKeys, timeSliceFrom, timeSliceTo }],
    queryFn: async () => {
      if (!tKeys.length || tKeys === '0') return [] as AccountMTUsageResponse;

      const relativeRegex = /month|year|day/;
      const isRelative = relativeRegex.test(timeSliceFrom);

      const calculateRollup = () => {
        if (isRelative) {
          const [num, time] = timeSliceFrom.split(' ');
          if (time === 'year') {
            return 'month';
          }
          if (time === 'month') {
            if (Number(num) <= 10) {
              return 'day';
            }
            return 'month';
          }
          if (time === 'day') {
            if (Number(num) <= 6) {
              return 'hour';
            }
            return 'day';
          }
          return 'day';
        }
        const endDate = timeSliceTo ? new Date(timeSliceTo) : new Date();
        const startDate = new Date(timeSliceFrom);
        const diff = moment
          .duration(moment(endDate).diff(moment(startDate)))
          .asDays();
        if (diff <= 6) {
          return 'hour';
        }
        if (diff >= 300) {
          return 'month';
        }
        return 'day';
      };

      const rollup = calculateRollup();

      const { data, status } = await Xapis.Metrics.get({
        rollup: rollup,
        glnow: '1',
        type: 'timeseries',
        translation_key: tKeys,
        series: 'num_words_engine',
        timeslice_from: timeSliceFrom,
        timeslice_to: timeSliceTo,
      } as Record<string, string>);
      if (!isSuccessStatus(status) || !data) {
        throw new Error('Failed to fetch MTUsage data');
      }
      return data.data as AccountMTUsageResponse;
    },
  });

export default { queryAccountMTUsage };
