import { Select } from '@mantine/core';
import { MdArrowDropDown } from 'react-icons/md';
import classes from './DateFilter.module.css';

type TimeSliceOptions = {
  label: string;
  value: string;
}[];

type Props = {
  timeSliceOptions: TimeSliceOptions;
  timeSliceFrom: string;
  onSelect: (value: string) => void;
};

const DateFilter = ({ timeSliceOptions, timeSliceFrom, onSelect }: Props) => (
  <Select
    data-testid="pw-metrics-summary-date-filter-dropdown"
    classNames={{
      input: classes.filterButton,
      dropdown: classes.filterDropdown,
      section: classes.rightSection,
    }}
    placeholder="Month to Date"
    rightSection={<MdArrowDropDown size={24} />}
    onChange={(value) => value && onSelect(value)}
    value={timeSliceFrom}
    data={timeSliceOptions}
    withCheckIcon={false}
  />
);

export default DateFilter;
