import { type PlanOption } from '../plan-information/PlanInformation';

/**
 * Filters and maps the input Sku array based on the provided type.
 *
 * @param {SkuWCreatedAndLastMod[]} skus - Array of Sku objects to be filtered and mapped
 * @param {string} type - Type used for filtering the skus array
 * @return {PlanOption[]} Array of PlanOption objects after filtering and mapping
 */
export function filterSkus(
  skus: SkuWCreatedAndLastMod[],
  type: string
): PlanOption[] {
  return skus
    .filter((s) => s.subscription_type === type && s.price !== 0)
    .map((s) => ({
      threshold_value: s.threshold_value,
      subscription_type: s.subscription_type,
      sku_name:
        s.subscription_type === 'YEARLY'
          ? s.name.replace('(Annual)', '')
          : s.name,
      sku: s.sku,
      price: Number(s.price),
    }));
}

/**
 * Filters a subscription to include only fields needed for PlanOption.
 * @param subscription - Subscription object to be filtered
 * @returns Filtered PlanOption object
 */
export function filterSubscription(s: Subscription): PlanOption {
  return {
    threshold_value: s.threshold_value,
    subscription_type: s.subscription_type,
    sku_name:
      s.subscription_type === 'YEARLY'
        ? s.sku_name.replace('(Annual)', '')
        : s.sku_name,
    sku: s.sku,
    price: Number(s.price),
  };
}
