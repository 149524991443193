import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { Header, OpenContext } from 'ui';

import { desktopRC } from '@glweb/constants';
import { useLocation, useRouteLoaderData } from 'react-router-dom';
import { type QuotesLoaderResponse } from '../../loaders/quotesLoader';
import { QuoteCard } from './QuoteCard';

export const QuoteList = () => {
  const { quotes, yyTarget } = useRouteLoaderData(
    'quotes'
  ) as QuotesLoaderResponse;
  const { colors } = useMantineTheme();
  const currentPath = useLocation().pathname;

  const newQuotePath = `/${desktopRC.QUOTE}`;

  return (
    <>
      <Box maw={{ base: '100%', md: '90%', lg: '75%', xl: '65%' }}>
        <Header
          title={`Quotes (${Object.keys(quotes).length.toLocaleString()})`}
          flexItems={
            <Button
              bg="#0071DB"
              h={40}
              px={30}
              style={{
                borderRadius: 30,
                borderColor: colors.text[8],
              }}
              mt={20}
              onClick={() => {
                window.dashboardAPI?.openStudio(newQuotePath, currentPath);
              }}
              rightSection={<OpenContext color={'white'} path={newQuotePath} />}
            >
              New Website Quote
            </Button>
          }
        />
        <ScrollArea
          h="calc(100vh - 245px)"
          mt={20}
          data-testid="pw-quote-cards-container"
        >
          <Flex p={10} direction="column" rowGap={10}>
            {Object.keys(quotes).length > 0 ? (
              Object.values(quotes).map((quote) => (
                <QuoteCard key={quote.id} quote={quote} yyTarget={yyTarget} />
              ))
            ) : (
              <Text>No quotes available.</Text>
            )}
          </Flex>
        </ScrollArea>
      </Box>
    </>
  );
};
