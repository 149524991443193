import React from 'react';
import { Header } from 'ui';

type Props = {
  showBlur?: boolean;
};

export default function MTUsageHeader({ showBlur }: Props) {
  return (
    <Header
      minHeight={40}
      variant="h2"
      blur={showBlur}
      title="MT Usage"
      tooltipLabel="This chart shows your total machine translation usage for the month as well as today's increase."
    />
  );
}
