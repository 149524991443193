import { ActionIcon, Menu } from '@mantine/core';
import React from 'react';
import { FaTrash } from 'react-icons/fa';

import { useGLGOParams } from 'ui';
import handleUpdateRules from '../scope-option/utils/handleUpdateRules';

type Props = {
  ruleToRemove: string;
  rulesList: string[];
  yyTarget: TranslationKey;
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>;
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};

const ConfirmDeleteMenu = ({
  ruleToRemove,
  rulesList,
  yyTarget,
  setYYTarget,
}: Props) => {
  const { projectKey = '' } = useGLGOParams();

  return (
    <Menu position="bottom-end" width={135}>
      <Menu.Target>
        <ActionIcon bg="transparent" color="#006CD1">
          <FaTrash color="var(--mantine-color-font1-1)" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          onClick={() =>
            handleUpdateRules(
              projectKey,
              rulesList,
              ruleToRemove,
              yyTarget,
              setYYTarget
            )
          }
        >
          Confirm Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ConfirmDeleteMenu;
