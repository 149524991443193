import React from 'react';
import { dashboardRC } from '@glweb/constants';
import { isDesktopApp } from 'helpers';
import { HiPencil } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Header, useGLGOParams } from 'ui';

export default function PlanDetailsHeader() {
  const isDesktop = isDesktopApp();
  const { projectId } = useGLGOParams();

  return (
    <Header
      variant="h2"
      title="Plan Details"
      tooltipLabel="This shows your current subscription package and your subscription’s next renewal date. You can manage your subscription in your Account Settings."
      flexItems={
        !isDesktop && (
          <Link
            to={`/${projectId}/${dashboardRC.PAGE_ACCOUNT}/billing-information`}
          >
            <HiPencil className="cardActionIcon" />
          </Link>
        )
      }
    />
  );
}
