import { Navigate, Outlet, useMatch } from 'react-router-dom';

type Props = {
  parentRoute: string;
  redirectRoute: string;
};

export default function PageOutlet({ parentRoute, redirectRoute }: Props) {
  const match = useMatch(`/:projectId/${parentRoute}`);

  if (match) {
    return <Navigate to={redirectRoute} replace />;
  }

  return <Outlet />;
}
