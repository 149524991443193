import { QueryClient } from '@tanstack/react-query';
import { Navigate, RouteObject } from 'react-router-dom';
import { DevLogin } from './DevLogin';
import { ErrorView } from './ErrorView';
import { Logout } from './Logout';
import NavigationLayout from './NavigationLayout';
import PostLogin from './PostLogin';
import { PostLogout } from './PostLogout';
import ProjectOutletWrapper from './ProjectOutletWrapper';
import RestrictedRoute from './RestrictedRoute';
import { Root } from './Root';
import SilentRenew from './SilentRenew';
import StoreDebug from './StoreDebug';
import ErrorBoundary from './components/shared/ErrorBoundary';
import PageOutlet from './components/shared/PageOutlet';
import cancellationConfirmationLoader from './loaders/account/cancellationConfirmationLoader';
import downgradeConfirmationLoader from './loaders/account/downgradeConfirmationLoader';
import orderConfirmationLoader from './loaders/account/orderConfirmationLoader';
import dashboardLoader from './loaders/dashboardLoader';
import languageCodesLoader from './loaders/languageCodesLoader';
import metricsLoader from './loaders/metricsLoader';
import projectQueryLoader from './loaders/projectQueryLoader';
import quotesLoader from './loaders/quotesLoader';
import CancellationConfirmationPage from './pages/account/CancellationConfirmationPage';
import DowngradeConfirmationPage from './pages/account/DowngradeConfirmationPage';
import OrderConfirmationPage from './pages/account/OrderConfirmationPage';
import { composeAccountRoutes } from './pages/account/routes';
import DashboardPage from './pages/dashboard/DashboardPage';
import DesktopAppPage from './pages/desktop/DesktopAppPage';
import metricsRoutes from './pages/metrics/routes';
import CreateAccount from './pages/portal/CreateAccount';
import quoteRoutes from './pages/quotes/routes';
import settingsRoutes from './pages/settings/routes';
import ProjectSetupPage from './pages/setup/ProjectSetupPage';
import { composeTranslationsRoutes } from './pages/translations/routes';
import UserManagementPage from './pages/usermanagement/UserManagementPage';
import dashboardRC from './routerConstants';
import { USER_ROLES_USER_MANAGEMENT_PAGE } from './constants';

export const composeRoutes = (queryClient: QueryClient): Array<RouteObject> => [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorView />,
    children: [
      {
        element: <NavigationLayout />,
        errorElement: <ErrorView />,
        children: [
          {
            path: ':projectId',
            element: <ProjectOutletWrapper />,
            id: 'project',
            loader: projectQueryLoader(queryClient),
            children: [
              {
                index: true,
                element: <Navigate to={dashboardRC.PAGE_DASHBOARD} replace />,
              },
              {
                path: dashboardRC.PAGE_DASHBOARD,
                element: <DashboardPage />,
                id: 'dashboard',
                loader: dashboardLoader(queryClient),
              },
              {
                path: dashboardRC.PAGE_TRANSLATIONS,
                id: 'translations',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_TRANSLATIONS}
                    redirectRoute={dashboardRC.PAGE_LANGUAGES}
                  />
                ),
                errorElement: <ErrorBoundary />,
                children: composeTranslationsRoutes(queryClient),
              },
              {
                path: dashboardRC.PAGE_METRICS,
                id: 'metrics',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_METRICS}
                    redirectRoute={dashboardRC.PAGE_TRANSLATED_TRAFFIC}
                  />
                ),
                loader: metricsLoader,
                errorElement: <ErrorBoundary />,
                children: [...metricsRoutes],
              },
              {
                path: dashboardRC.PAGE_SETTINGS,
                id: 'settings',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_SETTINGS}
                    redirectRoute={dashboardRC.PAGE_LANGUAGE_SETTINGS}
                  />
                ),
                errorElement: <ErrorBoundary />,
                shouldRevalidate: () => {
                  return true;
                },
                children: [...settingsRoutes],
              },
              {
                path: dashboardRC.PAGE_ACCOUNT,
                id: 'account',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_ACCOUNT}
                    redirectRoute={dashboardRC.PAGE_MY_PROFILE}
                  />
                ),
                errorElement: <ErrorBoundary />,
                children: composeAccountRoutes(queryClient),
              },
              {
                path: dashboardRC.PAGE_QUOTES,
                id: 'quotes',
                element: (
                  <PageOutlet
                    parentRoute={dashboardRC.PAGE_QUOTES}
                    redirectRoute="project"
                  />
                ),
                loader: quotesLoader,
                errorElement: <ErrorBoundary />,
                children: [...quoteRoutes],
              },
              {
                path: dashboardRC.PAGE_STORE_DEBUG,
                element: <StoreDebug />,
              },
              {
                path: dashboardRC.PAGE_ORDER_CONFIRMATION,
                element: <OrderConfirmationPage />,
                loader: orderConfirmationLoader,
              },
              {
                path: dashboardRC.PAGE_DOWNGRADE_CONFIRMATION,
                element: <DowngradeConfirmationPage />,
                loader: downgradeConfirmationLoader,
              },
              {
                path: dashboardRC.PAGE_CANCELLATION_CONFIRMATION,
                element: <CancellationConfirmationPage />,
                loader: cancellationConfirmationLoader,
              },
              {
                path: dashboardRC.PAGE_USER_MANAGEMENT,
                element: (
                  <RestrictedRoute
                    allowedRoles={USER_ROLES_USER_MANAGEMENT_PAGE}
                  >
                    <UserManagementPage />
                  </RestrictedRoute>
                ),
              },
              {
                path: dashboardRC.PAGE_DESKTOP_APP,
                element: <DesktopAppPage />,
              },
            ],
          },
        ],
      },
      {
        path: dashboardRC.PAGE_CREATE_PROJECT,
        element: <ProjectSetupPage />,
        loader: () => languageCodesLoader('google'),
      },
    ],
  },
  {
    path: dashboardRC.PAGE_CREATE_ACCOUNT,
    element: <CreateAccount />,
    errorElement: <ErrorView />,
  },
  {
    path: dashboardRC.PAGE_LOGIN_REDIRECT,
    element: <PostLogin />,
  },
  {
    path: dashboardRC.PAGE_SILENT_RENEW,
    element: <SilentRenew />,
  },
  {
    path: dashboardRC.PAGE_LOGOUT,
    element: <Logout />,
  },
  {
    path: dashboardRC.PAGE_DEV_LOGIN,
    element: <DevLogin />,
  },
  {
    path: dashboardRC.PAGE_LOGOUT_REDIRECT,
    element: <PostLogout />,
  },
];
