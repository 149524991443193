import { Flex, Box } from '@mantine/core';

export const StatusBanner = ({
  statusBody,
  statusType,
}: {
  statusBody: React.ReactNode;
  statusType: 'translations' | 'subscription';
}) => (
  <Flex
    h={65}
    w="100%"
    align="center"
    justify="center"
    py="1rem"
    px={{ base: '1rem', sm: '2rem' }}
    bg={statusType === 'translations' ? '#FDF5B2' : '#F5DDDE'}
    bd="1px solid border1.0"
    style={{ borderRadius: 10 }}
  >
    <Box fw={500} ta="center">
      {statusBody}
    </Box>
  </Flex>
);
