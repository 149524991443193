import { Progress } from '@mantine/core';
import React from 'react';

const BatchProgressBar = ({
  progress,
  w = 200,
}: {
  progress: number;
  w?: number;
}) => (
  <Progress.Root size="xl" w={w}>
    <Progress.Section
      value={progress}
      bg="linear-gradient(90deg, #DBE1E5 0%, #83D4D9 100%)"
      style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
    >
      {progress >= 15 && (
        <Progress.Label c="font1.1">{progress}%</Progress.Label>
      )}
    </Progress.Section>
    <Progress.Section value={100 - progress} bg="border1.0">
      {progress < 15 && (
        <Progress.Label c="font1.1">{progress}%</Progress.Label>
      )}
    </Progress.Section>
  </Progress.Root>
);

export default BatchProgressBar;
