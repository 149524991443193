import { Button, Flex, Menu } from '@mantine/core';
import { MdArrowDropDown } from 'react-icons/md';
import React from 'react';
import BatchActionsDropdown from '../../BatchActionsDropdown';
import { useCurrentProjectData } from 'ui';
import { filterTargets } from '../../../metrics/trafficUtils';
import QueryMultiSelector from '../../../../components/selectors-filters/QueryMultiSelector';
import { MyBatchesButton } from '../../MyBatchesButton';
import { FiltersDropdown } from '../../FiltersDropdown';
import { BatchFieldOptions } from '../../../../pages/batches/ActiveBatchesPage';
import QuerySearchBar from '../../QuerySearchBar';

const ActiveBatchFilters = ({
  selectedBatches,
  assigneeOptions,
  handleBatchChange,
  openCancelBatchModal,
  openCompleteBatchModal,
}: {
  selectedBatches: string[];
  assigneeOptions: string[];
  handleBatchChange: (
    batches: string[],
    params: Partial<Record<Partial<BatchFieldOptions>, string>>
  ) => void;
  openCancelBatchModal: (batches: string[]) => void;
  openCompleteBatchModal: (batches: string[]) => void;
}) => {
  const { project } = useCurrentProjectData();

  const filteredTargets = filterTargets(project.translations || []);

  return (
    <>
      <Flex justify="space-between" gap={10}>
        <Flex gap={10} align="center" wrap="wrap">
          <QuerySearchBar />
          <QueryMultiSelector
            encodeKeys
            selectAll
            shouldRevalidate
            options={filteredTargets}
          />
          <FiltersDropdown assigneeOptions={assigneeOptions} />
          <MyBatchesButton />
        </Flex>
        <Menu>
          <Menu.Target>
            <Button
              disabled={!selectedBatches.length}
              rightSection={<MdArrowDropDown size={24} />}
            >
              Actions
            </Button>
          </Menu.Target>
          <BatchActionsDropdown
            batches={selectedBatches}
            handleBatchChange={(params) =>
              handleBatchChange(selectedBatches, params)
            }
            openCancelBatchModal={openCancelBatchModal}
            openCompleteBatchModal={openCompleteBatchModal}
          />
        </Menu>
      </Flex>
    </>
  );
};
export default ActiveBatchFilters;
