export const getNodeProperties = (nodeInfoJSON: string) => {
  let nodeInfo: Partial<SpiderTreeNode> & {
    nodeIsIncluded?: boolean;
    nodeIsExcluded?: boolean;
    nodeName?: string;
    depth?: 0;
  } = {};

  try {
    nodeInfo = JSON.parse(nodeInfoJSON);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`error parsing nodeInfo: '${e}'`);
  }

  return {
    id: nodeInfo?.id || null,
    isFake: nodeInfo?.is_fake || false,
    name: nodeInfo?.nodeName || '',
    depth: nodeInfo?.depth || 0,
    label: nodeInfo?.label || '',
    additionalPadding:
      nodeInfo?.children?.length && nodeInfo?.children?.length > 0 ? 0 : 1.5,
    hasChildren:
      (nodeInfo?.children?.length && nodeInfo?.children?.length > 0) || false,
    contentUrlHash: nodeInfo?.content_url_hash || '',
    contentUrl: nodeInfo?.content_url || '',
    isExcluded: nodeInfo?.nodeIsExcluded || false,
    isIncluded: nodeInfo?.nodeIsIncluded || false,
    contentUrlOrig: nodeInfo?.content_url_orig || '',
  };
};

export default getNodeProperties;
