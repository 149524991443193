import { Avatar, Text } from '@mantine/core';

export const CircleAvatar = ({
  name,
  format = true,
  circleSize,
  backgroundColor,
  textColor,
  fontSize,
}: {
  name: string | undefined;
  format?: boolean;
  circleSize?: number | string;
  backgroundColor?: string;
  textColor?: string;
  fontSize?: number | string;
}) => (
  <Avatar
    variant="filled"
    size={circleSize || 28}
    color={backgroundColor || 'background.5'}
    radius="50%"
  >
    <Text fw={700} c={textColor || 'font1.0'} fz={fontSize || '0.85rem'} lh={1}>
      {name ? (format ? name[0].toUpperCase() : name) : 'P'}
    </Text>
  </Avatar>
);
