import { Xapis } from '@glweb/xapis-client';
import { failureNotification } from 'ui';

export type PayAddress = {
  address: {
    city: string;
    country: string;
    line1: string;
    line2?: string;
    postalCode: string;
    state: string;
  };
  name: string;
  email: string;
  phone: string;
  saveForLater?: boolean;
};

export const defaultPayAddress = {
  address: {
    city: '',
    country: '',
    line1: '',
    line2: '',
    postalCode: '',
    state: '',
  },
  name: '',
  email: '',
  phone: '',
};

export type Item = {
  sku: string | undefined;
  quantity: number;
};

export const createCheckoutSession = async (
  payload: { items: Array<Omit<Item, 'sku'> & { sku: string }> },
  payKey: string,
  projectKey: string
) => {
  try {
    const res = await Xapis.Checkout.post(payKey, projectKey, payload);
    return res.data;
  } catch (error) {
    failureNotification(
      error,
      'Something went wrong when creating a checkout session.'
    );
    return error;
  }
};

export default createCheckoutSession;
