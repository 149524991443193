export const NoBatchesIcon = ({
  name = '',
  color = '#DBE1E5',
  size = 40,
}: {
  name?: string;
  color?: string;
  size?: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`icon icon-${name}`}
  >
    <circle cx="20" cy="20" r="19.5" fill={color} stroke={color} />
    <path
      d="M11 28.0091V15.0685L17 10.0913H27.0091C28.1087 10.0913 29 10.9826 29 12.0822V28.0091C29 29.1086 28.1087 29.9999 27.0091 29.9999H12.9909C11.8913 29.9999 11 29.1086 11 28.0091Z"
      fill="white"
    />
    <path
      d="M11 15.0685V28.0091C11 29.1086 11.8913 29.9999 12.9909 29.9999H27.0091C28.1087 29.9999 29 29.1086 29 28.0091V12.0822C29 10.9826 28.1087 10.0913 27.0091 10.0913H17M11 15.0685L17 10.0913M11 15.0685H15.0091C16.1087 15.0685 17 14.1771 17 13.0776V10.0913"
      stroke={color}
      strokeWidth="0.99543"
    />
    <path
      d="M21.003 23.1628L19.6209 21.8033L19.6372 21.787C20.5591 20.7671 21.2479 19.5603 21.656 18.2499H23.2504V17.1666H19.4413V16.0833H18.353V17.1666H14.5439V18.2445H20.6221C20.2576 19.2899 19.6808 20.2812 18.8972 21.1478C18.3911 20.5899 17.9721 19.9778 17.6402 19.3333H16.5519C16.9491 20.2162 17.4933 21.0503 18.1734 21.8033L15.4037 24.5224L16.1764 25.2916L18.8972 22.5833L20.5895 24.2678L21.003 23.1628ZM24.0666 20.4166H22.9783L20.5296 26.9166H21.6179L22.2274 25.2916H24.8121L25.427 26.9166H26.5153L24.0666 20.4166ZM22.6409 24.2083L23.5225 21.8628L24.404 24.2083H22.6409Z"
      fill={color}
    />
  </svg>
);
