import { MantineStyleProp } from '@mantine/core';
import { ElementModes } from '../../types/editor';

import { themeColors } from '../../theme/colors';
import { tagColors } from '../Markers/Tag';

// q: `&::before {content: open-quote}; &::after { content: close-quote};`,
const tagStyle: Record<string, Record<string, string>> = {
  b: { fontWeight: 'bold' },
  a: { textDecoration: 'underline', color: themeColors.blue[6] },
  strong: { fontWeight: 'bold' },
  i: { fontStyle: 'italic' },
  em: { fontStyle: 'italic' },
  u: { textDecoration: 'underline' },
  ins: { textDecoration: 'underline' },
  s: { textDecoration: 'line-through' },
  del: { textDecoration: 'line-through' },
  small: { fontSize: 'smaller' },
  big: { fontSize: 'larger' },
  sup: { verticalAlign: 'super', fontSize: 'smaller' },
  sub: { verticalAlign: 'sub', fontSize: 'smaller' },
  mark: { backgroundColor: themeColors.secondery[8] },
};

// const jliffEditorColors = {
//   anchor: themeColors.blue[7],
//   mark: 'yellow',
// };

export const formattingTags = Object.keys(tagStyle);

export const getTextStyle = (formats?: string[]) => {
  let css: Record<string, string> = {};
  // const { mark, anchor } = jliffEditorColors;

  if (!formats) return css;

  formats.forEach((tag) => {
    if (tagStyle[tag]) {
      const style = { ...tagStyle[tag] };
      // merge text decorations
      if (style.textDecoration && css.textDecoration) {
        const decorations = (css.textDecoration as string).split(' ');
        if (!decorations.includes(style.textDecoration as string)) {
          decorations.push(style.textDecoration as string);
          style.textDecoration = decorations.join(' ');
        }
      }
      css = { ...css, ...style };
    }
  });
  // css.color = formats.includes('a') ? anchor : 'inherit';
  // css.backgroundColor = formats.includes('mark') ? mark : 'inherit';
  css.whiteSpace = 'pre-wrap';

  return css;
};

const borderStyle: Record<ElementModes, string> = {
  edit: 'none',
  move: 'solid none',
  resize: 'dashed none',
};

export const elementStyle = (
  isHovered: boolean,
  isDragged: boolean,
  mode: ElementModes
): MantineStyleProp => ({
  marginLeft: isHovered ? '20px' : '0',
  opacity: isDragged ? '0.4' : '1',
  borderStyle: borderStyle[mode],
  borderWidth: 1,
  borderColor: tagColors.focus.fill,
});
