import { AccumulatedWordsServed } from '../../types';

export const getAccumulatedWordsServed = (
  wordsServed: WordsServedResponse[]
) => [
  ...wordsServed.reduce(
    (accum: AccumulatedWordsServed[], { count, timeslice }) => {
      const prevTimesliceFound = accum.find(
        (accumWordsServed) => accumWordsServed?.timeslice === timeslice
      );
      if (prevTimesliceFound) {
        const timesliceIndex = accum.findIndex(
          ({ timeslice: prevTimeslice }) => prevTimeslice === timeslice
        );
        if (timesliceIndex !== -1) {
          accum[timesliceIndex] = {
            ...prevTimesliceFound,
            count: prevTimesliceFound.count + count,
          };
        }
      } else accum.push({ count, timeslice });
      return accum;
    },
    []
  ),
];

export const getWordsServed = (
  wordsServed: WordsServedResponse[],
  prevWordsServed: WordsServedResponse[]
) => {
  const accumulatedWordsServed = getAccumulatedWordsServed(wordsServed);

  const getTotal = (traffic: { count: number }[]) =>
    Math.round(
      traffic.reduce((accum, { count = 0 }) => accum + count, 0) as number
    ) || 0;

  const totalWordsServed = getTotal(accumulatedWordsServed);
  const totalPrevWordsServed = getTotal(prevWordsServed);

  const wordsServedChange =
    (totalWordsServed &&
      totalPrevWordsServed &&
      Math.round(
        (Math.abs(totalWordsServed - totalPrevWordsServed) /
          totalPrevWordsServed) *
          100
      )) ||
    0;

  return {
    accumulatedWordsServed,
    totalWordsServed,
    totalPrevWordsServed,
    wordsServedChange,
  };
};
