import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { BsExclude, BsIntersect } from 'react-icons/bs';
import { InfoIcon } from 'ui';

type Props = {
  currentScope: string;
};

export const ScopeDivider = ({ currentScope }: Props) => {
  const { colors } = useMantineTheme();

  return (
    <Flex align="center" w="100%">
      {currentScope === 'exclude' ? (
        <BsExclude fill="#5D6B89" />
      ) : (
        <BsIntersect fill="#167934" />
      )}
      <Title order={2} ml="0.5rem">
        {currentScope === 'exclude' ? 'Exclusions' : 'Inclusions'}
      </Title>
      <Tooltip
        label={`The ${currentScope === 'exclude' ? 'exclusion' : 'inclusion'} rules you have set are listed below.`}
      >
        <Box display="inline-block" ml={5}>
          <InfoIcon fill={colors.secondary1[0]} size={15} />
        </Box>
      </Tooltip>
      <Divider color="divider.0" size="sm" ml="1rem" w="100%" />
    </Flex>
  );
};

export default ScopeDivider;
