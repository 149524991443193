import React from 'react';
import { Box, Progress, ProgressRootProps, Tooltip } from '@mantine/core';
import { Status, statusObj } from './ActiveBatches';

export const BatchesProgressBar = ({
  statuses,
  ...styles
}: {
  statuses: [status: Status, count: number][];
} & Partial<ProgressRootProps>) => {
  const totalCount = statuses.reduce((accum, [, count]) => {
    return (accum += count);
  }, 0);

  const isEmpty = statuses.every(([, value]) => value === 0);

  const Sections = () =>
    statuses.map(([status, count], index) => {
      const percentage = Math.ceil((count / totalCount) * 100);
      const color = statusObj[status].color;

      //check if following index should be shown
      const nextStatus =
        statuses[index + 1]?.[1] > 0
          ? statuses[index + 1]?.[0]
          : statuses[index + 2]?.[0];
      const nextBg = statusObj[nextStatus]?.color;
      const showBox = index !== statuses.length - 1;

      return (
        <Tooltip key={status} label={statusObj[status].label}>
          <Progress.Section
            value={percentage}
            color={color}
            style={{
              position: 'relative',
            }}
          >
            <Progress.Label>{count}</Progress.Label>
            <Box
              display={showBox ? 'block' : 'none'}
              w={15}
              h="100%"
              bg={nextBg}
              pos="absolute"
              right={0}
            />
            <Box
              display={showBox ? 'block' : 'none'}
              w={15}
              h="100%"
              bg={showBox ? color : 'transparent'}
              pos="absolute"
              right={0}
              style={{
                zIndex: 10,
                borderTopRightRadius: 30,
                borderBottomRightRadius: 30,
              }}
            />
          </Progress.Section>
        </Tooltip>
      );
    });

  return (
    <Progress.Root size={22} style={{ borderRadius: 11 }} {...styles}>
      {isEmpty ? <></> : <Sections />}
    </Progress.Root>
  );
};
