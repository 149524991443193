import { Card } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ResponsiveChoropleth } from '@nivo/geo';
import { useMemo } from 'react';
import { LoadingSkeleton } from 'ui';
import countries from './GeoData.json';

type Props = {
  worldTraffic: Array<{
    count: number;
    country_name: string;
    label: string;
  }>;
  loading: boolean;
};

export const ChoroplethMap = ({ worldTraffic, loading }: Props) => {
  const trafficData = useMemo(() => {
    return worldTraffic
      .filter(({ country_name }) => country_name)
      .map(({ label, count }) => {
        return { id: label, value: count };
      });
  }, [worldTraffic]);

  const maxTrafficCount =
    Math.max(...trafficData.map(({ value }) => value)) || 0;

  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const legends =
    isMobileSize || trafficData.length === 0
      ? []
      : [
          {
            anchor: 'bottom-left',
            direction: 'column',
            translateX: 30,
            translateY: -30,
            itemsSpacing: 5,
            itemWidth: 300,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemTextColor: '#071D49',
            symbolSize: 20,
          },
        ];

  return (
    <Card
      w="100%"
      h={500}
      p={20}
      style={{ borderRadius: 15 }}
      data-testid="pw-metrics-world-traffic-card-1"
    >
      {loading ? (
        <LoadingSkeleton height={460} />
      ) : (
        <ResponsiveChoropleth
          enableGraticule={true}
          data={trafficData}
          features={countries.features}
          domain={[0, maxTrafficCount]}
          projectionScale={125}
          colors="PuBu"
          unknownColor="#F4F4F4"
          label="properties.name"
          valueFormat=">-,.0f"
          projectionType="naturalEarth1"
          projectionTranslation={[0.5, 0.55]}
          projectionRotation={[0, 0, 0]}
          borderWidth={0.5}
          margin={{ top: -20 }}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          legends={legends}
        />
      )}
    </Card>
  );
};
