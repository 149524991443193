import { Card, Group, Stack } from '@mantine/core';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { xapisEnv } from '@glweb/xapis-client';
import { Header, useCurrentProjectData, useGLGOParams } from 'ui';
import { type UsageLoaderData } from '../../../loaders/account/usageLoader';
import { useUsageQueries } from '../../../queries/usageQueries';
import { filterTargets, updateDateParam } from '../../metrics/trafficUtils';
import DateFilter from '../../selectors-filters/DateFilter';
import { getTimeSliceOptions } from '../../utils';
import WordsServedUrls from '../WordsServedUrls';
import WordsServedTimeSeries from './WordsServedTimeSeries';

const WordsServedUsageDetails = () => {
  const { timeSliceFrom, concatenatedKey } = useRouteLoaderData(
    'usage'
  ) as UsageLoaderData;
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectKey = '' } = useGLGOParams();
  const { payKey } = xapisEnv.getHost;

  const targets = useCurrentProjectData().project.translations || [];
  const filteredTranslationKeys = filterTargets(targets).map(
    ({ value }) => value
  );

  const { wordsServed, wordsServedUrls } = useUsageQueries({
    projectKey: projectKey!,
    payKey,
    concatenatedKey,
    timeSliceFrom,
  });

  return (
    <>
      <Group>
        <Header
          title="Words Served Usage"
          tooltipLabel="This graph should give the user an at-a-glance view of the words served usage for their website split out by language.
              The default time span for the graph is the current billing period and the data is displayed daily, but the user has the ability to change both the time span and the slices displayed (i.e. monthly/daily).
              The user can also select a specific language that they would like to view over the given time series."
          flexItems={
            <DateFilter
              timeSliceOptions={getTimeSliceOptions()}
              timeSliceFrom={timeSliceFrom}
              onSelect={(value) =>
                updateDateParam(value, searchParams, setSearchParams)
              }
            />
          }
        />
      </Group>
      <Stack gap={30}>
        <Card p={0}>
          <WordsServedTimeSeries
            timeSliceFrom={timeSliceFrom}
            translationKeys={filteredTranslationKeys}
            wordsServed={wordsServed}
          />
        </Card>
        <WordsServedUrls urls={wordsServedUrls} />
      </Stack>
    </>
  );
};

export default WordsServedUsageDetails;
