import { Card, Flex, Text, Title } from '@mantine/core';
import BatchProgressBar from '../BatchProgressBar';
import React from 'react';
import { useDetailedBatch } from '../../../queries/batchesQueries';

export const BatchProgressDetails = () => {
  const {
    batch: { urls, word_count, progress },
  } = useDetailedBatch();
  return (
    <Card>
      <Flex columnGap={20} rowGap={5} wrap="wrap">
        <Flex gap={8} align="center">
          <Title order={3}>TOTAL URLs</Title>
          <Text>{urls.length}</Text>
        </Flex>
        <Flex gap={8} align="center">
          <Title order={3}>WORDS</Title>
          <Text>{word_count}</Text>
        </Flex>
        <Flex gap={8} align="center" wrap="wrap">
          <Title order={3}>PROGRESS</Title>
          <BatchProgressBar progress={progress} />
        </Flex>
      </Flex>
    </Card>
  );
};
