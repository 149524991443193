import React from 'react';
import ProjectSetupSteps from './ProjectSetupSteps';
import { ProjectSetupProvider } from '../../components/setup/ProjectSetupProvider';
import RemarketStatsScript from './RemarketStatsScript';

const ProjectSetupPage = () => {
  return (
    <>
      <ProjectSetupProvider>
        <ProjectSetupSteps />
      </ProjectSetupProvider>
      <RemarketStatsScript />
    </>
  );
};

export default ProjectSetupPage;
