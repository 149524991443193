import React, { useMemo } from 'react';

import { hexToObject } from 'helpers';
import RulesCard from './RulesCard';

type Props = {
  yyTarget: TranslationKey;
  setYYTarget: React.Dispatch<React.SetStateAction<TranslationKey>>;
  setSpiderTree: React.Dispatch<React.SetStateAction<SpiderTreeNode[]>>;
};
const IncludeRulesSection = ({
  yyTarget,
  setYYTarget,
  setSpiderTree,
}: Props) => {
  const activeTranslationConfig = yyTarget?.translation_config || '';

  const config = hexToObject(activeTranslationConfig);
  const includeRules = useMemo(
    () => config?.spider_rules?.include_uris || [],
    [config?.spider_rules?.include_uris]
  );

  const detailedRules = useMemo(() => {
    return includeRules.length > 0
      ? includeRules.map((rule: string) => ({
          pattern: rule,
          desc:
            rule[rule.length - 1] === '$'
              ? 'is exactly'
              : rule[rule.length - 1] === '+'
                ? 'starts with'
                : 'contains',
          uri: rule.replace(/[.*+?^${}()|[\]\\]/g, ''),
        }))
      : [];
  }, [includeRules]);

  return (
    <RulesCard
      detailedRules={detailedRules}
      rulesList={includeRules}
      yyTarget={yyTarget}
      setYYTarget={setYYTarget}
      setSpiderTree={setSpiderTree}
    />
  );
};

export default IncludeRulesSection;
