import React from 'react';
import { Button, Flex, TextInput } from '@mantine/core';
import { FetcherWithComponents } from 'react-router-dom';
import classes from './EditProfile.module.css';

export type DefaultValues = {
  origin_name: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  user_key: string;
};

type Props = {
  fetcher: FetcherWithComponents<DefaultValues>;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  defaultValues: DefaultValues;
};

const EditProfile = ({ fetcher, setShowForm, defaultValues }: Props) => {
  const hiddenValues = ['origin_name', 'email', 'user_key'];

  return (
    <fetcher.Form
      method="put"
      className={classes.editForm}
      style={{ width: '100%' }}
      onSubmit={() => setShowForm(false)}
    >
      <Flex direction={{ base: 'column', sm: 'row' }} w="100%">
        <Flex direction="column" py="0.5rem" w={{ xs: '100%', sm: '50%' }}>
          <TextInput
            maw={180}
            defaultValue={defaultValues.first_name}
            name="first_name"
            label="First Name"
            placeholder="First Name"
          />
        </Flex>
        <Flex direction="column" py="0.5rem" w={{ xs: '100%', sm: '50%' }}>
          <TextInput
            maw={180}
            defaultValue={defaultValues.last_name}
            label="Last Name"
            name="last_name"
            placeholder="Last Name"
          />
        </Flex>
      </Flex>
      <Flex
        align={{ base: 'start', sm: 'end' }}
        direction={{ base: 'column', sm: 'row' }}
        justify={{ base: 'start', sm: 'space-between' }}
        pt="0.5rem"
        w="100%"
      >
        <TextInput
          label="Phone Number"
          maw={180}
          pb={{ base: '1rem', sm: 0 }}
          placeholder="Phone"
          defaultValue={defaultValues.phone}
          name="phone"
          type="number"
        />
        {hiddenValues.map((value) => (
          <input
            key={value}
            name={value}
            type="hidden"
            defaultValue={defaultValues[value as keyof DefaultValues]}
          />
        ))}
        <Flex gap={20} justify="end">
          <Button variant="outline" onClick={() => setShowForm(false)}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Flex>
      </Flex>
    </fetcher.Form>
  );
};

export default EditProfile;
