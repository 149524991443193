import { notifications } from '@mantine/notifications';
import { Xapis } from '@glweb/xapis-client';
import { ActionFunctionArgs, redirect } from 'react-router-dom';
import { addLabelToConfig, getTranslationConfig } from './utils';
import { asciiToHex } from 'helpers';

const addLanguagesAction = async ({ request }: ActionFunctionArgs) => {
  // TODO: Make a util function
  let data = (await request.json()) || {};
  data = Object.entries(data).reduce(
    (accum, [key, value]) => {
      try {
        accum[key] = JSON.parse(value as string);
      } catch {
        accum[key] = value;
      }
      return accum;
    },
    {} as Record<string, unknown>
  );

  const {
    deployment_method = '',
    projectKey = '',
    selectedTranslations = [],
    yyTarget = {},
    redirectRoute = '',
  } = data;

  const {
    mt_api_code: mtApiCode = '',
    mt_api_key: mtApiKey = '',
    mt_endpoint: mtEndpoint = '',
    source_lang_code = '',
    target_config: yyTargetConfig = {},
    translation_key: yyTranslationKey = '',
    translation_config: translationConfig,
    translation_config_hash: configHash,
  } = yyTarget;

  const hasDeploymentValue = !['domain', 'folder'].includes(deployment_method);

  const createSelectedTranslations = selectedTranslations.map(
    ({ langcode_code = '', langcode_name = '' }) => {
      return Xapis.ProjectTranslation.post(projectKey, {
        linked_translation_key: yyTranslationKey,
        source_lang_code,
        target_lang_code: langcode_code,
        is_live: false,
        mt_api_code: mtApiCode,
        mt_api_key: mtApiKey,
        mt_endpoint: mtEndpoint,
        target_config: yyTargetConfig,
        deployment_value: hasDeploymentValue ? langcode_code.toLowerCase() : '',
        translation_methods:
          source_lang_code === langcode_name ? 'STATONLY' : 'PRO,MT',
      });
    }
  );

  const config = getTranslationConfig(translationConfig);
  const hasCustomLabels = Boolean(
    config?.staging_translation_rules?.language_selector_labels?.length
  );

  const allSettled = await Promise.allSettled([...createSelectedTranslations])
    .then((responses) => {
      if (!hasCustomLabels) return responses;

      const newConfig = responses.reduce((acc, promise) => {
        const { value } = promise as { value: { data: TranslationKey } };
        if (value.data) {
          return addLabelToConfig(acc, value.data, false);
        }
        return acc;
      }, config);
      Xapis.ProjectTranslation.put(projectKey, yyTranslationKey, {
        translation_config: asciiToHex(JSON.stringify(newConfig)),
        translation_config_hash: configHash ?? '',
      });
    })
    .then((responses) => {
      notifications.show({
        message: 'You successfully added translation(s).',
      });
      return { responses };
    })
    .catch((e) => {
      console.error(e);
      notifications.show({
        message: 'Unable to add translations at this time.',
      });
      return {};
    });

  if (redirectRoute) {
    return redirect(redirectRoute);
  }

  return allSettled;
};

export default addLanguagesAction;
