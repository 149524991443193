import React from 'react';
import { Card, Center, Title } from '@mantine/core';
import { CloseIcon } from 'ui';

type Props = {
  setShowEstimate: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function WordsServedEstimateHeader({ setShowEstimate }: Props) {
  return (
    <Card
      py="1rem"
      pl="1rem"
      pr={{ base: '5rem', xs: '1rem' }}
      bg="background2.2"
    >
      <Title order={2} c="text.0" ta={{ base: 'left', xs: 'center' }}>
        Words Served Estimate
      </Title>
      <Center top={0} right={10} h="100%" pos="absolute">
        <CloseIcon color="#FFFFFF" onClick={() => setShowEstimate(false)} />
      </Center>
    </Card>
  );
}
