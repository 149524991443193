import {
  defaultSubscription,
  isStagingEnv,
  xapisEnv,
} from '@glweb/xapis-client';
import { Grid } from '@mantine/core';
import {
  USER_ROLE_GLGO_ADMIN,
  GLGO_PROJECT_TYPE,
  GLWEB_PROJECT_TYPE,
  OLJS_PROJECT_TYPE,
  USER_ROLE_TRANSPERFECT_ADMIN,
  USER_ROLE_TRANSPERFECT_USER,
} from 'helpers';
import { Suspense, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { LoadingSkeleton, useCurrentProjectData, useGLGOParams } from 'ui';
import { ActiveBatches } from '../../components/dashboard/batches/ActiveBatches';
import ActiveBatchesHeader from '../../components/dashboard/batches/ActiveBatchesHeader';
import { MyBatches } from '../../components/dashboard/batches/MyBatches';
import MyBatchesHeader from '../../components/dashboard/batches/MyBatchesHeader';
import ContactsHeader from '../../components/dashboard/contacts/ContactsHeader';
import MyContacts from '../../components/dashboard/contacts/MyContacts';
import { DashboardCard } from '../../components/dashboard/DashboardCard';
import MetricsSummary from '../../components/dashboard/metrics-summary/MetricsSummary';
import MetricsSummaryHeader from '../../components/dashboard/metrics-summary/MetricsSummaryHeader';
import GoLiveModal from '../../components/dashboard/modals/GoLiveModal';
import { MTUsage } from '../../components/dashboard/mt-usage/MTUsage';
import MTUsageHeader from '../../components/dashboard/mt-usage/MTUsageHeader';
import MyLanguages from '../../components/dashboard/my-languages/MyLanguages';
import MyLanguagesHeader from '../../components/dashboard/my-languages/MyLanguagesHeader';
import PlanDetails from '../../components/dashboard/plan-details/PlanDetails';
import PlanDetailsHeader from '../../components/dashboard/plan-details/PlanDetailsHeader';
import WordsServedEstimate from '../../components/dashboard/words-served-estimate/WordsServedEstimate';
import WordsServedEstimateHeader from '../../components/dashboard/words-served-estimate/WordsServedEstimateHeader';
import WordsServedUsage from '../../components/dashboard/words-served-usage/WordsServedUsage';
import WordsServedUsageHeader from '../../components/dashboard/words-served-usage/WordsServedUsageHeader';
import { StatusBanner } from '../../components/shared/StatusBanner';
import { getStatusBannerBody } from '../../components/utils';
import { DashboardLoaderResponse } from '../../loaders/dashboardLoader';
import { useRecommendedThresholdContext } from '../../providers/RecommendedThresholdProvider';
import { useDashboardQueries } from '../../queries/dashboardQueries';

const DashboardPage = () => {
  const { concatenatedKey, timeSliceFrom, shouldQueryQuickQuote } =
    useLoaderData() as DashboardLoaderResponse;

  const { projectId, projectKey = '' } = useGLGOParams();
  const { payKey } = xapisEnv.getHost;

  const [showGoLiveModal, setShowGoLiveModal] = useState(false);

  const thresholdFromEstimate = useRecommendedThresholdContext().threshold;
  const { project } = useCurrentProjectData();

  const {
    subscriptions,
    monthlyWordsServed,
    prevWordsServed,
    wordsServed,
    worldTraffic,
    translatedTraffic,
    quickQuote,
    batches,
  } = useDashboardQueries({
    projectKey: projectKey!,
    payKey,
    concatenatedKey,
    timeSliceFrom,
    shouldQueryQuickQuote,
    origin_name: project?.origin_name || '',
  });

  const { translations: targets = [] } = project;

  const activeProjectStatus = project?.project_status || '';
  const isGLWEBEnterprisePlan = project?.project_type === 'OLJS';
  const isGLGOEnterprisePlan = project?.project_type === 'GLGO-ENTERPRISE';
  const isExpiredPlan = project?.subscription_status === 'expired';
  const hasSku = Boolean(project.subscription_sku);
  const isGLWEBEnterpriseWithoutSku = !hasSku && isGLWEBEnterprisePlan;

  const {
    threshold_value = 0,
    words_served = 0,
    price = 0,
  } = subscriptions[0] || {};

  const hasActiveSubscription = !isExpiredPlan && subscriptions.length > 0;
  const isFreePlan = price === 0;

  const thresholdValue = hasActiveSubscription ? threshold_value : 0;
  const wordsServedAmount = hasActiveSubscription
    ? parseInt(words_served || defaultSubscription.words_served)
    : 0;

  const statusBannerBody = getStatusBannerBody(
    isExpiredPlan,
    isFreePlan,
    activeProjectStatus,
    setShowGoLiveModal,
    projectId || '',
    thresholdFromEstimate
  );

  const [showEstimate, setShowEstimate] = useState(
    isFreePlan && Boolean(quickQuote?.qmessage?.includes('result was cached'))
  );

  if (!project || !projectKey) {
    return <></>;
  }

  return (
    <>
      <GoLiveModal
        activeProjectKey={projectKey}
        setOpened={setShowGoLiveModal}
        opened={showGoLiveModal}
      />
      <Grid grow gutter="sm" align="stretch">
        <DashboardCard
          isVisible={Boolean(statusBannerBody)}
          isCard={false}
          maxColSpan={12}
        >
          <StatusBanner
            statusBody={statusBannerBody}
            statusType={isExpiredPlan ? 'subscription' : 'translations'}
          />
        </DashboardCard>

        <DashboardCard maxColSpan={8} header={<MetricsSummaryHeader />}>
          <MetricsSummary
            timeSlices={translatedTraffic.slices}
            prevTimeSlices={translatedTraffic.prevSlices}
            worldTraffic={worldTraffic}
            wordsServed={wordsServed}
            prevWordsServed={prevWordsServed}
          />
        </DashboardCard>

        <DashboardCard
          header={<ContactsHeader />}
          allowedProjectTypes={new Set([OLJS_PROJECT_TYPE, GLWEB_PROJECT_TYPE])}
        >
          <MyContacts />
        </DashboardCard>

        <DashboardCard
          isVisible={showEstimate}
          maxColSpan={8}
          header={
            <WordsServedEstimateHeader setShowEstimate={setShowEstimate} />
          }
          allowedProjectTypes={new Set([GLGO_PROJECT_TYPE])}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <WordsServedEstimate quickQuote={quickQuote} />
          </Suspense>
        </DashboardCard>

        <DashboardCard
          isVisible={!showEstimate}
          maxColSpan={8}
          header={<WordsServedUsageHeader />}
          allowedProjectTypes={new Set([GLGO_PROJECT_TYPE])}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <WordsServedUsage
              thresholdValue={thresholdValue}
              monthlyWordsServed={monthlyWordsServed}
            />
          </Suspense>
        </DashboardCard>

        <DashboardCard
          maxColSpan={8}
          header={<MTUsageHeader />}
          allowedProjectTypes={new Set([OLJS_PROJECT_TYPE, GLWEB_PROJECT_TYPE])}
        >
          <Suspense fallback={<LoadingSkeleton />}>
            <MTUsage thresholdValue={0} />
          </Suspense>
        </DashboardCard>

        <DashboardCard isCard={false}>
          <DashboardCard
            isNested
            isVisible={!isGLGOEnterprisePlan && !isGLWEBEnterpriseWithoutSku}
            header={<PlanDetailsHeader />}
          >
            <PlanDetails
              isExpiredPlan={isExpiredPlan}
              subscriptions={subscriptions}
              wordsServedAmount={wordsServedAmount}
            />
          </DashboardCard>

          <DashboardCard isNested flex={2} header={<MyLanguagesHeader />}>
            <MyLanguages targets={targets} />
          </DashboardCard>
        </DashboardCard>

        {/* Batches Feature Flagged To Only Live */}

        {isStagingEnv && (
          <>
            <DashboardCard
              allowedRoles={
                new Set([
                  USER_ROLE_TRANSPERFECT_USER,
                  USER_ROLE_TRANSPERFECT_ADMIN,
                  USER_ROLE_GLGO_ADMIN,
                ])
              }
              header={<MyBatchesHeader />}
            >
              <MyBatches batches={batches?.my_batches ?? []} />
            </DashboardCard>

            <DashboardCard
              allowedRoles={
                new Set([
                  USER_ROLE_TRANSPERFECT_USER,
                  USER_ROLE_TRANSPERFECT_ADMIN,
                  USER_ROLE_GLGO_ADMIN,
                ])
              }
              header={<ActiveBatchesHeader />}
            >
              <ActiveBatches activeBatches={batches?.active_batches ?? []} />
            </DashboardCard>
          </>
        )}
      </Grid>
    </>
  );
};

export default DashboardPage;
