import { QueryClient } from '@tanstack/react-query';
import {
  decodeId,
  decodeProjectId,
  filterTranslationKeys,
  getPrevTimeSliceFrom,
} from 'helpers';
import { Params } from 'react-router';
import { projectQuery, xapisEnv } from '@glweb/xapis-client';
import {
  queryBatches,
  queryMonthlyWordsServed,
  queryMTUsage,
  queryPrevWordsServed,
  queryTranslatedTraffic,
  queryWorldTraffic,
} from '../queries/dashboardQueries';
import { queryQuickQuote } from '../queries/shared/queryQuickQuote';
import { querySkus } from '../queries/shared/querySkus';
import { querySubscriptions } from '../queries/shared/querySubscriptions';
import { queryWordsServed } from '../queries/shared/queryWordsServed';

type LoaderArgs = {
  params: Params<string>;
  request: Request;
};

export type DashboardLoaderResponse = {
  concatenatedKey: string;
  timeSliceFrom: string;
  shouldQueryQuickQuote: boolean;
};

const dashboardLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderArgs): Promise<DashboardLoaderResponse> => {
    const { projectId } = params;
    if (!projectId) {
      throw new Response('Project key is not defined', {
        status: 404,
        statusText: 'Project key is not defined',
      });
    }

    const projectKey = decodeProjectId(projectId);
    const { payKey } = xapisEnv.getHost;

    const projectData = await queryClient.ensureQueryData(
      projectQuery(projectKey)
    );
    // Ensure project data
    const {
      origin_name = '',
      translations = [],
      subscription_status,
    } = projectData.project;

    const url = new URL(request.url);
    const timeSliceFrom = url.searchParams.get('d') || '1 month';

    // Ensure subscriptions and SKUs
    await queryClient.ensureQueryData(
      querySubscriptions(payKey, projectKey, subscription_status === 'expired')
    );
    await queryClient.ensureQueryData(querySkus(payKey));

    const translationKeysOffProject = filterTranslationKeys(translations);
    const keysInUrl = url.searchParams.getAll('t');
    const decodedkeysInUrl = keysInUrl.map((key) => decodeId(key));

    const concatenatedKey =
      decodedkeysInUrl.length > 0
        ? decodedkeysInUrl.join(',')
        : translationKeysOffProject.join(',');

    const prevTimeSlice = getPrevTimeSliceFrom(timeSliceFrom);
    const rollup = timeSliceFrom.includes('year') ? 'month' : 'day';

    await queryClient.ensureQueryData(queryMonthlyWordsServed(concatenatedKey));
    await queryClient.ensureQueryData(
      queryPrevWordsServed(
        concatenatedKey,
        prevTimeSlice,
        timeSliceFrom,
        rollup
      )
    );

    await queryClient.ensureQueryData(queryMTUsage(concatenatedKey));

    await queryClient.ensureQueryData(
      queryWordsServed(concatenatedKey, timeSliceFrom, rollup)
    );
    await queryClient.ensureQueryData(
      queryWorldTraffic(concatenatedKey, timeSliceFrom)
    );
    await queryClient.ensureQueryData(
      queryTranslatedTraffic(concatenatedKey, timeSliceFrom)
    );
    await queryClient.ensureQueryData(queryBatches(projectKey));

    const subscriptions = await queryClient.ensureQueryData(
      querySubscriptions(payKey, projectKey, subscription_status === 'expired')
    );

    const shouldQueryQuickQuote =
      subscriptions &&
      subscriptions.length > 0 &&
      subscriptions[0].price === 0 &&
      origin_name?.length > 0;

    // we don't await this one and it will sometimes trigger React suspense
    queryClient.ensureQueryData(
      queryQuickQuote(shouldQueryQuickQuote, origin_name)
    );

    // these params needed for the queries, both here in the loader and when the query is called on the component
    // there is no need for the component to have to figure them all out again so we pass them as loader data
    return {
      concatenatedKey,
      timeSliceFrom,
      shouldQueryQuickQuote,
    };
  };

export default dashboardLoader;
