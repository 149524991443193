import { useContext } from 'react';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';
import { Box, Button, Flex } from '@mantine/core';
import { useUnsavedCount } from '../../store/SegmentsState';

type DashboardProps = {
  onSaveSegments: () => void;
  SortByButtonsAndMenu: React.ReactElement;
  SearchBarAndFilterMenu: React.ReactElement;
};

const EditorDashboard = ({
  SearchBarAndFilterMenu,
  SortByButtonsAndMenu,
  onSaveSegments,
}: DashboardProps) => {
  const unsavedCount = useUnsavedCount();
  const { viewOnly } = useContext(SegmentEditorContext).settings;
  const disableSave = viewOnly || !unsavedCount;

  const saveLabel = (() => {
    if (viewOnly) return 'View Only';
    return unsavedCount ? `Save (${unsavedCount})` : 'Save';
  })();

  return (
    <Flex w="100%" py={8} px={20} justify={'space-between'} bg="blue.0">
      {SearchBarAndFilterMenu}
      <Flex direction="column" rowGap={5} align="flex-end">
        <Button size="xs" onClick={onSaveSegments} disabled={disableSave}>
          {saveLabel}
        </Button>
        {SortByButtonsAndMenu}
      </Flex>
    </Flex>
  );
};

export default EditorDashboard;
