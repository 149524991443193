import React from 'react';
import { Switch } from '@mantine/core';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import classes from './DeployStatusToggle.module.css';

type Props = {
  needsDeploymentValue: boolean;
  is_live: boolean;
  updateTranslationStatus: (
    selectedTranslationKey: string,
    isLive: boolean
  ) => void;
  translation_key: string;
};

const DeployStatusToggle = ({
  needsDeploymentValue,
  is_live,
  updateTranslationStatus,
  translation_key,
}: Props) => {
  return (
    <Switch
      disabled={needsDeploymentValue}
      checked={is_live}
      color={is_live ? 'text3.0' : 'text3.1'}
      thumbIcon={
        is_live ? (
          <HiOutlineEye
            style={{
              width: '16px',
              height: '12px',
            }}
          />
        ) : (
          <HiOutlineEyeOff
            style={{
              color: '#EAF0FF',
              width: '16px',
              height: '12px',
            }}
          />
        )
      }
      classNames={{
        track: classes.deployStatusTrack,
        input: classes.deployStatusInput,
        thumb: classes.deployStatusThumb,
      }}
      styles={{
        track: {
          cursor: needsDeploymentValue ? 'not-allowed' : 'pointer',
        },
        thumb: {
          backgroundColor: needsDeploymentValue
            ? 'var(--mantine-color-cta1-4)'
            : is_live
              ? 'var(--mantine-color-border-3)'
              : 'var(--mantine-color-background2-4)',
        },
      }}
      onClick={() => updateTranslationStatus(translation_key, is_live)}
    />
  );
};

export default DeployStatusToggle;
