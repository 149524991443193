import { randomId } from '@mantine/hooks';
import { Dispatch, SetStateAction } from 'react';
import { GlossaryRuleForm } from './GlossaryRuleForm';

type TargetOption = {
  value: string;
  label: string;
};

export const GlossaryItemMenu = ({
  item,
  targetOptions,
  setLoading,
}: {
  item: GlossaryItem;
  targetOptions: TargetOption[];
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    comment,
    is_case_sensitive,
    is_mt,
    rule,
    source_text,
    target_text,
    translation_key,
  } = item || {};

  const formInitialValues = {
    comment,
    is_case_sensitive,
    is_mt,
    rule,
    source_text,
    targetTextList:
      target_text?.map((t) => ({ targetText: t, id: randomId() })) || [],
    translation_keys: [translation_key],
  };

  return (
    <GlossaryRuleForm
      buttonProps={{
        w: 20,
        mih: 20,
        h: 20,
        p: 0,
        justify: 'center',
        variant: 'transparent',
        c: 'var(--mantine-color-text)',
      }}
      targetsOptions={targetOptions}
      type="Edit"
      initialValues={formInitialValues}
      setLoading={setLoading}
    />
  );
};
