import { MantineStyleProps } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { useViewSize } from 'ui';
import dashboardRC from './routerConstants';

export default function useMainOutletStyles(
  isDesktopToggled: boolean
): MantineStyleProps {
  const { pathname } = useLocation();
  const { isTabletSize, isMobileSize } = useViewSize();

  const paddingProps = {
    pt: 60,
    pb: isMobileSize ? 100 : 10,
    pr: 10,
    pl: {
      base: 10,
      md: 88,
      lg: isDesktopToggled || isTabletSize ? 88 : 245,
    },
  };

  const isOrderConfirmation = pathname.includes(
    dashboardRC.PAGE_ORDER_CONFIRMATION
  );
  const isDowngradeConfirmation = pathname.includes(
    dashboardRC.PAGE_DOWNGRADE_CONFIRMATION
  );
  const isCancellationConfirmation = pathname.includes(
    dashboardRC.PAGE_CANCELLATION_CONFIRMATION
  );

  if (
    isOrderConfirmation ||
    isDowngradeConfirmation ||
    isCancellationConfirmation
  ) {
    return {
      ...paddingProps,
      pt: 48,
      pb: 0,
      pr: 0,
      pl: {
        base: 0,
        md: 60,
        lg: isDesktopToggled || isTabletSize ? 60 : 220,
      },
    };
  }

  return paddingProps;
}
