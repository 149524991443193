import React, { useState } from 'react';
import { Box, Flex, Popover, UnstyledButton } from '@mantine/core';
import { capitilize } from '../../functions/segmentsFunctions';
import { SortAscIcon } from '../../icons/ActionIcons';
import { SortBy, SortKey } from '../../types/editor';
import classes from './SortSegments.module.css';
import { MdArrowDropDown } from 'react-icons/md';
import { getColor } from '../../theme/utils';

type SortSegmentProps = {
  setSortBy: React.Dispatch<React.SetStateAction<SortBy>>;
  sortBy: SortBy;
};

const SortSegments = ({ setSortBy, sortBy }: SortSegmentProps) => {
  const [open, setOpen] = useState(false);

  const sortByOptions: SortKey[] = ['status', 'type', 'source', 'date'];

  const handleBySelection = (value: SortKey) => {
    setSortBy((prev) => ({ ...prev, field: value }));
    setTimeout(() => setOpen(false), 600);
  };

  const SelectItem = ({ value }: { value: SortKey }) => {
    return (
      <Box className={classes.item} onClick={() => handleBySelection(value)}>
        {capitilize(value)}
      </Box>
    );
  };

  const SortIcon = ({ ascending }: { ascending: boolean }) => {
    return (
      <Flex
        className={classes.icon}
        style={{
          transform: `scaleY(${!ascending ? -1 : 1})`,
        }}
        onClick={() =>
          setSortBy((prev) => ({ ...prev, ascending: !prev.ascending }))
        }
      >
        <SortAscIcon />
      </Flex>
    );
  };

  return (
    <Flex gap={5} align={'center'} fz="sm">
      <SortIcon ascending={sortBy.ascending} />
      <Box c="blue.7" fw={600}>
        Sort by:
      </Box>
      <Popover
        withArrow={false}
        position="top-end"
        opened={open}
        onChange={setOpen}
        closeOnEscape
        offset={0}
        shadow="md"
      >
        <Popover.Target>
          <UnstyledButton onClick={() => setOpen(!open)}>
            <Flex align={'center'}>
              <Box fw={700} fz="sm" miw={50} c="blue.6">
                {capitilize(sortBy.field)}
              </Box>
              <MdArrowDropDown size={24} color={getColor('blue', 7)} />
            </Flex>
          </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown className={classes.dropdown}>
          <Box className={classes.itemContainer}>
            {sortByOptions.map((key) => (
              <SelectItem key={key} value={key} />
            ))}
          </Box>
        </Popover.Dropdown>
      </Popover>
    </Flex>
  );
};

export default SortSegments;
