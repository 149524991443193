import { Xapis } from '@glweb/xapis-client/src/xapis-wrappers/xapis';
import { Card } from '@mantine/core';
import React, { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { isSuccessStatus } from 'helpers';
import { MRT_RowSelectionState } from 'mantine-react-table';
import { failureNotification, Header, successNotification } from 'ui';
import { ActiveBatchesTable } from '../../components/batches/active-batches/ActiveBatchesTable';
import { CancelBatchesModal } from '../../components/batches/CancelBatchesModal';
import { CompleteBatchesModal } from '../../components/batches/CompleteBatchModal';
import ActiveBatchFilters from '../../components/batches/active-batches/filters/ActiveBatchFilters';
import { useFilteredActiveBatches } from '../../queries/batchesQueries';
import dashboardRC from '../../routerConstants';

export type BatchFieldOptions = 'phase' | 'phase_status';

const ActiveBatchesPage = () => {
  const { batches, assigneeOptions } = useFilteredActiveBatches();

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(
    {} as MRT_RowSelectionState
  );

  const [
    cancelBatchModalOpened,
    { open: openCancelModal, close: closeCancelModal },
  ] = useDisclosure();
  const [
    completeBatchModalOpened,
    { open: openCompleteModal, close: closeCompleteModal },
  ] = useDisclosure();

  const [batchKeysToChange, setBatchKeyssToChange] = useState([] as string[]);

  const openCancelBatchesModal = (batches: string[]) => {
    setBatchKeyssToChange(batches);
    openCancelModal();
  };

  const openCompleteBatchesModal = (batches: string[]) => {
    setBatchKeyssToChange(batches);
    openCompleteModal();
  };

  const handleBatchChange = (
    batchKeys: string[],
    params: Partial<Record<Partial<BatchFieldOptions>, string>>
  ) => {
    const batchesToChange = batches.filter(({ batch_key }) =>
      batchKeys.includes(batch_key)
    );
    const organizedBatches = batchesToChange.reduce(
      (acc, batch) => {
        const { translation_key, batch_key } = batch;
        if (!acc[translation_key]) {
          return { ...acc, [translation_key]: [batch_key] };
        }
        acc[translation_key].push(batch_key);
        return { ...acc };
      },
      {} as Record<string, string[]>
    );

    Promise.allSettled(
      Object.entries(organizedBatches).map(([translationKey, batchList]) => {
        return Xapis.Batches.patch({
          translationKey,
          batchKeyList: batchList.join(','),
          params,
        });
      })
    )
      .then((xapisBatchPatchResults) => {
        xapisBatchPatchResults.forEach((xapisBatchPatchResult) => {
          if (xapisBatchPatchResult.status === 'fulfilled') {
            const xapisBatchResponse = xapisBatchPatchResult.value || {};
            const { status, data } = xapisBatchResponse;
            const hasBatchNames = Boolean(data?.batches[0]?.batch_name);

            if (!isSuccessStatus(status)) {
              hasBatchNames
                ? failureNotification(
                    xapisBatchResponse,
                    `Batch${data.batches.length > 1 ? 'es' : ''} (${data.batches.map(({ batch_name }: ActiveBatch) => batch_name).join(', ')}) failed.`
                  )
                : failureNotification(xapisBatchResponse);
            } else {
              hasBatchNames
                ? successNotification(
                    xapisBatchResponse,
                    `Batch${data.batches.length > 1 ? 'es' : ''} (${data.batches.map(({ batch_name }: ActiveBatch) => batch_name).join(', ')}) updated.`
                  )
                : successNotification(xapisBatchResponse);
            }
          } else {
            failureNotification(xapisBatchPatchResult.reason);
          }
        });
      })
      .finally(() => {
        const updatedPhaseStatus = params['phase_status']?.toLowerCase();
        if (['completed', 'cancelled'].includes(updatedPhaseStatus ?? '')) {
          setRowSelection({} as MRT_RowSelectionState);
        }
        closeCompleteModal();
        closeCancelModal();
      });
  };

  return (
    <>
      <Header title={dashboardRC.PAGE_TITLE_ACTIVE_BATCHES} />
      <ActiveBatchFilters
        selectedBatches={Object.entries(rowSelection)
          .filter(([, value]) => value)
          .map(([key]) => key)}
        assigneeOptions={assigneeOptions}
        handleBatchChange={handleBatchChange}
        openCancelBatchModal={openCancelBatchesModal}
        openCompleteBatchModal={openCompleteBatchesModal}
      />
      <Card p={0} style={{ boxShadow: 'none' }} mt={20}>
        <ActiveBatchesTable
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          handleBatchChange={handleBatchChange}
          batches={batches}
          openCancelBatchesModal={openCancelBatchesModal}
          openCompleteBatchesModal={openCompleteBatchesModal}
        />
      </Card>
      <CancelBatchesModal
        multipleBatches={batchKeysToChange.length > 1}
        cancelBatch={(params) => handleBatchChange(batchKeysToChange, params)}
        opened={cancelBatchModalOpened}
        close={closeCancelModal}
      />
      <CompleteBatchesModal
        multipleBatches={batchKeysToChange.length > 1}
        completeBatch={(params) => handleBatchChange(batchKeysToChange, params)}
        opened={completeBatchModalOpened}
        close={closeCompleteModal}
      />
    </>
  );
};

export default ActiveBatchesPage;
