import { Xapis, SpiderTreeResponse } from '@glweb/xapis-client';
import { Params } from 'react-router-dom';
import { decodeTId } from 'helpers';

const spiderTreeLoader = async ({
  params,
}: {
  params: Params<string>;
}): Promise<SpiderTreeResponse> => {
  const { yyTranslationId = '' } = params;

  if (!yyTranslationId) {
    throw new Response('Translation key is not defined', {
      status: 404,
      statusText: 'Translation key is not defined',
    });
  }
  const yyTranslationKey = decodeTId(yyTranslationId);
  return await Xapis.SpiderTree.get(yyTranslationKey);
};

export default spiderTreeLoader;
