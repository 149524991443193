import { LoadingSkeleton, NoDataFoundPlaceHolder } from 'ui';

export const DataWrapper = ({
  loading,
  noDataFound,
  children,
}: {
  loading: boolean;
  noDataFound: boolean;
  children: React.ReactNode;
}) => {
  if (loading) {
    return <LoadingSkeleton />;
  }

  if (noDataFound) {
    return <NoDataFoundPlaceHolder />;
  }

  return children;
};
