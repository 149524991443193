import {
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Grid,
  ScrollArea,
  Text,
} from '@mantine/core';
import { getYYTarget } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useSubmit } from 'react-router-dom';
import { SearchBar, useCurrentProjectData, useGLGOParams } from 'ui';
import { DOMAIN, FOLDER } from '../deployment/utils';
import classes from './AddLanguages.module.css';

type Props = {
  showSpecifyModal: boolean;
  setShowSpecifyModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddLanguagesModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTranslations: Partial<LanguageCode>[];
  setSelectedTranslations: React.Dispatch<
    React.SetStateAction<Partial<LanguageCode>[]>
  >;
  availableTranslations: Partial<LanguageCode>[];
};
export const AddLanguages = ({
  setShowAddLanguagesModal,
  availableTranslations,
  setShowSpecifyModal,
  selectedTranslations,
  setSelectedTranslations,
}: Props) => {
  const submit = useSubmit();
  const { projectKey = '' } = useGLGOParams();
  const { project: { translations = [], deployment_method = '' } = {} } =
    useCurrentProjectData();

  const yyTarget = getYYTarget(translations);

  const [searchText, setSearchText] = useState('');
  const [filteredTranslations, setFilteredTranslations] = useState<
    Partial<LanguageCode>[]
  >(availableTranslations);

  useEffect(() => {
    setFilteredTranslations(availableTranslations);
  }, [availableTranslations]);

  const handleSearch = (value: string = '') => {
    setSearchText(value);

    if (value.length === 0) {
      setFilteredTranslations(availableTranslations);
      return;
    }
    const filteredTranslationsBySearch = availableTranslations.filter(
      ({ langcode_name }) => {
        return (
          langcode_name?.includes(value) ||
          langcode_name?.toLowerCase()?.includes(value)
        );
      }
    );
    setFilteredTranslations(filteredTranslationsBySearch);
  };

  const handleSelectedTranslation = (selectedTarget: Partial<LanguageCode>) => {
    const prevTargetNames = selectedTranslations.map(
      ({ langcode_name }) => langcode_name
    );
    const isSelected = prevTargetNames?.includes(selectedTarget?.langcode_name);

    if (isSelected) {
      const filteredSelectedTargets = selectedTranslations.filter(
        ({ langcode_name }) => {
          return langcode_name !== selectedTarget?.langcode_name;
        }
      );
      setSelectedTranslations(filteredSelectedTargets);
    } else {
      setSelectedTranslations((prevSelectedTargets) => [
        ...prevSelectedTargets,
        selectedTarget,
      ]);
    }
  };

  return (
    <Flex
      w="100%"
      h="100%"
      rowGap="1rem"
      direction="column"
      px="0.5rem"
      pt={0}
      pb="0.75rem"
    >
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        justify={{ sm: 'space-between' }}
        columnGap="0.5rem"
        rowGap="1rem"
      >
        <SearchBar
          searchText={searchText}
          onSearch={handleSearch}
          style={{ flex: 1 }}
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const hasEmptyValues =
              deployment_method === DOMAIN || deployment_method === FOLDER;

            if (hasEmptyValues) {
              setShowSpecifyModal(true);
              setShowAddLanguagesModal(false);
            } else {
              submit(
                {
                  projectKey,
                  yyTarget: JSON.stringify(yyTarget),
                  selectedTranslations: JSON.stringify(selectedTranslations),
                  deployment_method,
                },
                {
                  method: 'post',
                  encType: 'application/json',
                }
              );
            }
          }}
        >
          <Button
            w={{ base: '100%', sm: 'auto' }}
            data-testid="pw-modal-add-language-button"
            type="submit"
            disabled={selectedTranslations.length === 0}
          >
            Add
          </Button>
        </form>
      </Flex>
      <Card pl={15} mih={0} flex={1}>
        <ScrollArea
          h={395}
          mah="100%"
          scrollbarSize={13}
          style={{ display: 'flex', rowGap: 10 }}
        >
          {filteredTranslations?.map((target, key) => {
            const translationFound: Partial<LanguageCode> | undefined =
              selectedTranslations?.find(
                ({ langcode_code }) => langcode_code === target?.langcode_code
              );

            return (
              <React.Fragment key={target?.langcode_code || key}>
                <Grid
                  onClick={() => handleSelectedTranslation(target)}
                  className={classes.handleSelectWrapper}
                  gutter={0}
                  align="center"
                >
                  <Grid.Col span={1}>
                    <Checkbox
                      size="sm"
                      data-testid={`pw-modal-language-checkbox-${key}`}
                      checked={translationFound !== undefined}
                      readOnly
                    />
                  </Grid.Col>
                  <Grid.Col span={11}>
                    <Text
                      fw={600}
                      data-testid={`pw-language-modal-index-${key}`}
                    >
                      {target.langcode_name}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Divider my="0.5rem" />
              </React.Fragment>
            );
          })}
        </ScrollArea>
      </Card>
    </Flex>
  );
};

export default AddLanguages;
