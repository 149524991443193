import { getHostConstants, LIVE, STAGING } from '@glweb/constants';
import { Poster, Xapis, xapisEnv } from '@glweb/xapis-client';
import { notifications } from '@mantine/notifications';
import { AuthContextProps, shouldSkipSSO } from 'auth';
import { asciiToHex } from 'helpers';
import { handleHubSpotContact, sendZapierData } from 'integrations';
import useProjectsStore, {
  filterProject,
  type Project,
} from '../../store/ProjectsStore';
import {
  BOTTOM_LEFT_POSITION,
  DARK_THEME,
  getCustomCSS,
  getLanguageSelectorPositions,
  LanguageSelectorPosition,
  LanguageSelectorTheme,
} from './LanguageSelectorAppearances';
import { startSpiderJob } from './startSpiderJob';
import { AccountForm } from './types';

const defaultUser = {
  email: '',
  first_name: '',
  company_name: '',
  last_name: '',
  phone: '',
  user_key: '',
};

const handleError = (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  message?: string | null | undefined,
  autoClose: boolean = true
) => {
  if (typeof message === 'string' && message.length > 0) {
    notifications.show({
      message:
        message ||
        'Unable to process user at this time. Please try logging in or forgot password.',
      color: 'red',
      autoClose,
      // id,
    });
  }
  setLoading(false);
};

export const handleCreateProject = (
  isCreatingProject: boolean,
  setIsCreatingProject: React.Dispatch<React.SetStateAction<boolean>>,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  selectedSourceLangCode: string | null,
  selectedTargetLangCodes: string[] = [],
  selectedTheme: LanguageSelectorTheme = DARK_THEME,
  selectedPosition: LanguageSelectorPosition = BOTTOM_LEFT_POSITION,
  domain: string = '',
  user: User = defaultUser,
  setXapisUser: (user: User) => void
) => {
  if (isCreatingProject) return;
  setIsCreatingProject(true);

  const pay_key = xapisEnv.getHost.payKey;
  const { setActiveProject, setProjects, setXapisProjects } =
    useProjectsStore.getState();

  const { email = '' } = user;

  const { topBottom, leftRight } =
    getLanguageSelectorPositions(selectedPosition);
  const customCSS = getCustomCSS(topBottom, leftRight, selectedTheme);

  Poster(
    'Register',
    {
      domain,
      pay_key,
      source_lang_code: selectedSourceLangCode,
      targets: selectedTargetLangCodes,
      email: email,
      custom_css: asciiToHex(customCSS),
    },
    ({ data }) => {
      const { project_key = '', project_keys: projects = [] } = data;

      const filteredProjects: Project[] = projects.map(filterProject);

      if (filteredProjects.length > 0) {
        const projectFound = filteredProjects.find(({ projectKey }) => {
          return projectKey === project_key;
        });

        if (projectFound?.projectKey) {
          setProjects(filteredProjects);
          setXapisProjects(projects);
          setActiveProject(projectFound);
          setXapisUser({ ...user, project_keys: projects });
          Xapis.ProjectTranslation.get(project_key)
            .then((res) => {
              const yyTarget = res.data.translation_keys.find(
                ({ target_lang_code = '' }) => target_lang_code === 'YY'
              );
              setActiveProject({
                ...projectFound,
                translations: res.data.translation_keys,
              });
              startSpiderJob(yyTarget.translation_key);
            })
            .catch((e) => console.error(e));
          setIsCreatingProject(false);
          setActiveStep((prevStep) => prevStep + 0.5);

          handleHubSpotContact(
            {
              properties: {
                website: domain,
              },
            },
            email
          );
        }
      }
    },
    {
      errorMessage: 'Unable to create project at this time. Please try again.',
    }
  );
};

export const handleCreateAccount = (
  formParams: Partial<AccountForm>,
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAlreadyCreated: React.Dispatch<React.SetStateAction<boolean>>,
  auth: AuthContextProps,
  searchParams: URLSearchParams
) => {
  const notificationId = 'register';
  notifications.hide(notificationId);
  if (loading) return;
  setIsAlreadyCreated(false);
  setLoading(true);

  const {
    email = '',
    password = '',
    first_name = '',
    last_name = '',
    subscribed_to_marketing = false,
  } = formParams;

  const payKey = xapisEnv.getHost.payKey;
  const userInfo = {
    email,
    password,
    first_name,
    last_name,
    pay_key: payKey,
  };

  Poster(
    'Register',
    userInfo,
    async ({ data }) => {
      const { user_key: userKey = '', otac = '', message = '' } = data;
      if (userKey) {
        if (message === 'already created.') {
          setIsAlreadyCreated(true);
          handleError(
            setLoading,
            'Account already created. Please try logging in.',
            false
          );
          return;
        }

        if (shouldSkipSSO()) {
          sessionStorage.setItem('user-email', email);
        }

        const { label = '' } = xapisEnv.getHost;
        const { url: zapierUrl = null, ...rest } =
          getHostConstants().zapier ?? {};

        const isLiveEnv = label === LIVE;
        const isLiveOrStagingEnv = isLiveEnv || label === STAGING;

        if (isLiveOrStagingEnv) {
          if (zapierUrl && 'fields' in rest) {
            sendZapierData(zapierUrl, {
              first_name,
              last_name,
              email,
              fq_email_sub_c: subscribed_to_marketing,
              ga_key_c: '',
              ga_landing_c: '',
              utm_campaign_c: searchParams.get('utm_campaign') ?? '',
              utm_content_c: searchParams.get('utm_content') ?? '',
              utm_medium_c: searchParams.get('utm_medium') ?? '',
              utm_source_c: searchParams.get('utm_source') ?? '',
              fq_hidden_url_c: window.location.href,
              ...rest.fields,
            });
          }
        }

        if (isLiveEnv) {
          const todaysDate = new Date();
          const todaysDateInMS = Date.UTC(
            todaysDate.getUTCFullYear(),
            todaysDate.getUTCMonth(),
            todaysDate.getUTCDate()
          );

          Xapis.CRM.Hubspot.post({
            properties: {
              email: email,
              hs_all_assigned_business_unit_ids: 1557374,
              glgo_account_create_date: todaysDateInMS,
              hs_marketable_status: true,
              hs_marketable_reason_type: 'FORM_SUBMISSION',
              subscribed_to_marketing,
            },
          });
        }

        if (otac || shouldSkipSSO()) {
          auth
            .signinRedirect({
              extraQueryParams: { acr_values: `otac:${otac}` },
            })
            .catch((error) => {
              console.error('Auth Error: ', error);
              handleError(setLoading);
            });
        } else {
          handleError(
            setLoading,
            'Account created, unable to access auto login. Please log in manually',
            false
          );
        }
      }
    },
    {
      errorMessage: 'Unable to create account at this time.',
      action: () => handleError(setLoading, null),
    },
    { 'user-only': 1, generate_otac: 1 }
  );
};
