import { QueryClient } from '@tanstack/react-query';
import { Params } from 'react-router-dom';
import { projectQuery, xapisEnv } from '@glweb/xapis-client';
import { queryReleaseNotes } from '../queries/releaseNotesQuery';
import { querySubscriptions } from '../queries/shared/querySubscriptions';
const projectQueryLoader =
  (queryClient: QueryClient) =>
  async ({ params }: { params: Params<string> }) => {
    const { projectId } = params || {};
    if (!projectId) {
      throw new Response('Project key is not defined', {
        status: 404,
        statusText: 'Project key is not defined',
      });
    }
    const { payKey } = xapisEnv.getHost;
    const projectData = await queryClient.ensureQueryData(
      projectQuery(projectId)
    );
    const { project } = projectData;
    queryClient.ensureQueryData(
      querySubscriptions(
        payKey,
        project.project_key,
        project.subscription_status === 'expired'
      )
    );

    const releaseNotes = await queryClient.ensureQueryData(queryReleaseNotes());

    return { projectData, releaseNotes };
  };

export type ProjectQueryLoaderData = Awaited<
  ReturnType<Awaited<ReturnType<typeof projectQueryLoader>>>
>;

export default projectQueryLoader;
